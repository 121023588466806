import { apiRequest, authorizedApiRequest, addLanguageAndTimezone } from '../setup/ApiService'

export default {
  login,
  requestPasswordResetMail,
  checkConfirmationToken,
  checkToken,
  changePasswordUsingToken,
  checkChangeToken,
  renewToken,
  invalidateTokens,
  loginAsUser,
}

function login(email, password) {
  return apiRequest(
    '/authentication/login', //
    'POST',
    addLanguageAndTimezone({
      email,
      password,
    }),
  )
}

function requestPasswordResetMail(email) {
  return apiRequest(
    '/authentication/request-password-reset-mail', //
    'POST',
    {
      email,
    },
  )
}

function checkConfirmationToken(token, purpose) {
  return apiRequest(
    '/authentication/check-confirmation-token', //
    'POST',
    {
      token,
      purpose,
    },
  )
}

function checkChangeToken(token, email) {
  return apiRequest(
    '/authentication/check-confirmation-token', //
    'POST',
    {
      token,
      purpose: 'emailChange',
      email,
    },
  )
}

function changePasswordUsingToken(token, newPassword) {
  return apiRequest(
    '/authentication/change-password-using-token', //
    'POST',
    {
      token: token,
      newPassword: newPassword,
    },
  )
}

function checkToken() {
  return authorizedApiRequest(
    '/authentication/check-token', //
    'GET',
  )
}

function renewToken() {
  return authorizedApiRequest(
    '/authentication/renew-token', //
    'POST',
  )
}

function invalidateTokens() {
  return authorizedApiRequest(
    '/authentication/invalidate-tokens', //
    'POST',
  )
}

function loginAsUser(id) {
  return authorizedApiRequest(
    `/authentication/login-as/${id}`, //
    'GET',
  )
}
