<template>
  <McLayoutForm>
    <div v-if="loadingState">
      <McSpinner large />
    </div>
    <div v-else-if="errorState">
      <h2>{{ $t('general.Error') }}</h2>
      <i18n path="mileage.InvalidLinkPleaseLogInToMarkTrip.Outer" tag="p">
        <b-link to="/mileage">{{ $t('mileage.InvalidLinkPleaseLogInToMarkTrip.LogIn') }}</b-link>
      </i18n>
    </div>
    <div v-else>
      <!--h2>{{ $t('mileage.MarkTrip') }}</h2-->
      <i18n path="mileage.YouJustDroveXXXMarkTripAsColon" tag="p" class="text-center">
        <template v-slot:distance>
          <strong>{{ $options.filters.distance(trip.distanceMeter) }}</strong>
        </template>
        <template v-slot:tracker>
          <strong>{{ tracker.name }}</strong>
        </template>
      </i18n>

      <div class="text-center">
        <McButton square class="m-2" :variant="trip.type === 'business' ? 'primary' : 'secondary'" icon="building" @click="e => setTripType('business')" :text="$t('mileage.BusinessType')" />
        <McButton square class="m-2" :variant="trip.type === 'private' ? 'primary' : 'secondary'" :icon="['fad', 'house']" @click="e => setTripType('private')" :text="$t('mileage.PrivateType')" />
      </div>

      <McAlert v-if="showformsuccess" type="success" @dismissed="showformsuccess = false">
        <i18n path="mileage.TheTripIsNowMarkedAsXXyouCanStillChangeIt" tag="span">
          <template v-slot:type>
            <strong>{{ $t('mileage.triptype' + (trip.type == 'private' ? 'Private' : 'Business')) }}</strong>
          </template>
        </i18n>
      </McAlert>

      <h3 class="mt-3">{{ $t('mileage.Details') }}</h3>
      <b-table-simple :small="true" stacked="md" striped class="mileage-table mt-3 mb-0">
        <b-thead>
          <b-tr>
            <b-th>{{ $t('mileage.Start') }}</b-th>
            <b-th class="d-none d-lg-table-cell"></b-th>
            <b-th>{{ $t('mileage.End') }}</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td :stacked-heading="$t('mileage.Start')">
              <TnLocalTime class="badge badge-dark" :time="trip.startDatetime" format="l LT" />
              <TnTripAddress :address="trip.startAddress[LanguageStore.currentLanguage]" />
              <b-badge variant="info">{{ $t('mileage.OdometerColon', { odometer: $options.filters.odometer(trip.startOdometer) }) }}</b-badge>
            </b-td>
            <b-td class="d-none d-lg-table-cell">
              <span class="mileage_rightarrow"></span>
            </b-td>
            <b-td :stacked-heading="$t('mileage.End')">
              <TnLocalTime class="badge badge-dark" :time="trip.endDatetime" format="l LT" />
              <TnTripAddress :address="trip.endAddress[LanguageStore.currentLanguage]" />
              <b-badge variant="info">{{ $t('mileage.OdometerColon', { odometer: $options.filters.odometer(trip.startOdometer + trip.distanceMeter) }) }}</b-badge>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <MapWithTrack :points="points" class="mark_trip_map_elm my-2" />

      <!--pre>{{ $route.params }}</pre>
      <pre>{{ trip }}</pre-->
    </div>
  </McLayoutForm>
</template>

<script>
import { FormatError } from '../../helpers/ErrorFormatting'
import { FormatDistance, FormatOdometer } from '../../helpers/Formatting'
import { mapState, mapActions } from 'vuex'
import MileageService from '../../services/MileageService'

export default {
  data() {
    return {
      errorState: false,
      loadingState: true,
      showformsuccess: false,
      trip: null,
      points: [],
      tracker: null,
      map: null,
    }
  },
  created() {
    this.checkTripSecret(this.$route.params.imei, this.$route.params.tripId, this.$route.params.secret)
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    setTripType(value) {
      this.showformsuccess = false
      this.trip.type = value
      MileageService.setTypeWithSecret(this.$route.params.imei, this.$route.params.tripId, this.$route.params.secret, value).then(ret => {
        this.showformsuccess = true
      })
    },

    getAddresses() {
      if (!this.trip.startAddress[this.lang] || !this.trip.endAddress[this.lang]) {
        MileageService.getAddressesWithSecret(this.$route.params.imei, this.$route.params.tripId, this.$route.params.secret)
          .then(data => {
            this.$set(this.trip.startAddress, this.lang, data.startAddress)
            this.$set(this.trip.endAddress, this.lang, data.endAddress)
          })
          .catch(error => {
            console.error(FormatError(error))
          })
      }
    },

    checkTripSecret(imei, tripId, secret) {
      this.showformsuccess = false
      return new Promise((resolve, reject) => {
        MileageService.checkTripSecret(imei, tripId, secret)
          .then(data => {
            this.loadingState = false
            this.trip = data.trip
            this.tracker = data.tracker
            this.points = data.points
            this.getAddresses()
            if (this.trip.type !== 'unknown') {
              this.showformsuccess = true
            }
            resolve(data)
          })
          .catch(error => {
            console.error(FormatError(error))
            this.loadingState = false
            this.errorState = true
          })
      })
    },
  },
  watch: {
    lang(newlang) {
      this.getAddresses()
    },
  },
  computed: {
    ...mapState({
      LanguageStore: state => state.LanguageStore,
    }),
    lang() {
      return this.LanguageStore.currentLanguage
    },
  },
  filters: {
    odometer(meters) {
      return FormatOdometer(meters)
    },
    distance(meters) {
      return FormatDistance(meters)
    },
  },
}
</script>

<style>
.mark_trip_map_elm {
  width: 100%;
  height: 300px;
}
</style>
