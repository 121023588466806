<template>
  <div class="contact-address-wrap">
    <div v-if="contact.email">
      <a :href="'mailto:' + contact.email">{{ contact.email }}</a>
    </div>
    <div v-if="contact.phone">
      <a :href="'tel:' + contact.phone">{{ contact.phone }}</a>
    </div>
    <div v-if="contact.website">
      <a :href="contact.website" target="_blank">{{ contact.website }}</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contact: Object,
  },
}
</script>
