import Vue from 'vue'

import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faEnvelope as fasEnvelope, //
  faUnlock as fasUnlock,
  faSearch as fasSearch,
  faSpinner as fasSpinner,
  faAt as fasAt,
  faKey as fasKey,
  faExclamationTriangle as fasExclamationTriangle,
  faRadiation as fasRadiation,
  faInfoCircle as fasInfoCircle,
  faCheckCircle as fasCheckCircle,
  faPaperPlane as fasPaperPlane,
  faUserPlus as fasUserPlus,
  faTrash as fasTrash,
  faSearchLocation as fasSearchLocation,
  faPlus as fasPlus,
  faEdit as fasEdit,
  faUserEdit as fasUserEdit,
  faBolt as fasBolt,
  faPlug as fasPlug,
  faQuestion as fasQuestion,
  faMapMarker as fasMapMarker,
  faHashtag as fasHashtag,
  faPhone as fasPhone,
  faSimCard as fasSimCard,
  faSignature as fasSignature,
  faUser as fasUser,
  faBallotCheck as fasBallotCheck,
  faTimes as fasTimes,
  faSearchMinus as fasSearchMinus,
  faArrowUp as fasArrowUp,
  faArrowDown as fasArrowDown,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faCalendarCheck as fasCalendarCheck,
  faTimesCircle as fasTimesCircle,
  faBuilding as fasBuilding,
  faCity as fasCity,
  faSave as fasSave,
  faBell as fasBell,
  faBellSlash as fasBellSlash,
  faCog as fasCog,
  faPen as fasPen,
  faFilePdf as fasFilePdf,
  faFile as fasFile,
  faPiggyBank as fasPiggyBank,
  faCheck as fasCheck,
  faPrint as fasPrint,
  faSignIn as fasSignIn,
} from '@fortawesome/pro-solid-svg-icons'

library.add(fasEnvelope)
library.add(fasUnlock)
library.add(fasSearch)
library.add(fasSpinner)
library.add(fasAt)
library.add(fasKey)
library.add(fasExclamationTriangle)
library.add(fasRadiation)
library.add(fasInfoCircle)
library.add(fasCheckCircle)
library.add(fasPaperPlane)
library.add(fasUserPlus)
library.add(fasTrash)
library.add(fasSearchLocation)
library.add(fasPlus)
library.add(fasEdit)
library.add(fasUserEdit)
library.add(fasBolt)
library.add(fasPlug)
library.add(fasQuestion)
library.add(fasMapMarker)
library.add(fasHashtag)
library.add(fasPhone)
library.add(fasSimCard)
library.add(fasSignature)
library.add(fasUser)
library.add(fasBallotCheck)
library.add(fasTimes)
library.add(fasSearchMinus)
library.add(fasArrowUp)
library.add(fasArrowDown)
library.add(fasChevronLeft)
library.add(fasChevronRight)
library.add(fasCalendarCheck)
library.add(fasTimesCircle)
library.add(fasBuilding)
library.add(fasCity)
library.add(fasSave)
library.add(fasBell)
library.add(fasBellSlash)
library.add(fasCog)
library.add(fasPen)
library.add(fasFilePdf)
library.add(fasFile)
library.add(fasPiggyBank)
library.add(fasCheck)
library.add(fasPrint)
library.add(fasSignIn)

import {
  faClock as farClock, //
  faCalendar as farCalendar,
  faTrashAlt as farTrashAlt,
  faTimesCircle as farTimesCircle,
  faCheckCircle as farCheckCircle,
  faRuler as farRuler,
  faEnvelopeOpenText as farEnvelopeOpenText,
  faPercentage as farPercentage,
  faHeartbeat as farHeartbeat,
} from '@fortawesome/pro-regular-svg-icons'

library.add(farClock)
library.add(farCalendar)
library.add(farTrashAlt)
library.add(farTimesCircle)
library.add(farCheckCircle)
library.add(farRuler)
library.add(farEnvelopeOpenText)
library.add(farPercentage)
library.add(farHeartbeat)

import {
  faSignalAlt as fadSignalAlt, //
  faSignalAlt1 as fadSignalAlt1,
  faSignalAlt2 as fadSignalAlt2,
  faSignalAlt3 as fadSignalAlt3,
  faBatteryEmpty as fadBatteryEmpty,
  faBatteryFull as fadBatteryFull,
  faBatteryHalf as fadBatteryHalf,
  faBatteryQuarter as fadBatteryQuarter,
  faBatteryThreeQuarters as fadBatteryThreeQuarters,
  faSnooze as fadSnooze,
  faHistory as fadHistory,
  faLocation as fadLocation,
  faMapMarked as fadMapMarked,
  faMapMarkedAlt as fadMapMarkedAlt,
  faMapMarkerAlt as fadMapMarkerAlt,
  faCars as fadCars,
  faClipboardListCheck as fadClipboardListCheck,
  faIdCard as fadIdCard,
  faAlarmExclamation as fadAlarmExclamation,
  faUsers as fadUsers,
  faCreditCardFront as fadCreditCardFront,
  faUserCrown as fadUserCrown,
  faUserChart as fadUserChart,
  faUserCog as fadUserCog,
  faBoxes as fadBoxes,
  faWandMagic as fadWandMagic,
  faBrowser as fadBrowser,
  faGlobeEurope as fadGlobeEurope,
  faFileSignature as fadFileSignature,
  faIdCardAlt as fadIdCardAlt,
  faUserTag as fadUserTag,
  faHouse as fadHouse,
  faInbox as fadInbox,
  faTachometerSlow as fadTachometerSlow,
  faCloudDownloadAlt as fadCloudDownloadAlt,
  faCommentsAlt as fadCommentsAlt,
  faMoneyCheckAlt as fadMoneyCheckAlt,
  faBadgeDollar as fadBadgeDollar,
  faPiggyBank as fadPiggyBank,
  faCarGarage as fadCarGarage,
  faBoxCheck as fadBoxCheck,
  faFileInvoiceDollar as fadFileInvoiceDollar,
  faBook as fadBook,
} from '@fortawesome/pro-duotone-svg-icons'

library.add(fadBatteryEmpty)
library.add(fadBatteryFull)
library.add(fadBatteryHalf)
library.add(fadBatteryQuarter)
library.add(fadBatteryThreeQuarters)
library.add(fadSignalAlt)
library.add(fadSignalAlt1)
library.add(fadSignalAlt2)
library.add(fadSignalAlt3)
library.add(fadSnooze)
library.add(fadHistory)
library.add(fadLocation)
library.add(fadMapMarked)
library.add(fadMapMarkedAlt)
library.add(fadMapMarkerAlt)
library.add(fadCars)
library.add(fadClipboardListCheck)
library.add(fadIdCard)
library.add(fadAlarmExclamation)
library.add(fadUsers)
library.add(fadCreditCardFront)
library.add(fadUserCrown)
library.add(fadUserChart)
library.add(fadUserCog)
library.add(fadBoxes)
library.add(fadWandMagic)
library.add(fadBrowser)
library.add(fadGlobeEurope)
library.add(fadFileSignature)
library.add(fadIdCardAlt)
library.add(fadUserTag)
library.add(fadHouse)
library.add(fadInbox)
library.add(fadTachometerSlow)
library.add(fadCloudDownloadAlt)
library.add(fadCommentsAlt)
library.add(fadMoneyCheckAlt)
library.add(fadBadgeDollar)
library.add(fadPiggyBank)
library.add(fadCarGarage)
library.add(fadBoxCheck)
library.add(fadFileInvoiceDollar)
library.add(fadBook)

/** TRACKER ICONS **/
export const trackerIcons = [
  //'accessible-icon',
  'ambulance',
  'baby-carriage',
  'bicycle',
  'bus',
  'bus-alt',
  'car',
  'car-alt',
  'car-building',
  'car-bump',
  'car-bus',
  'car-crash',
  'car-side',
  'car-tilt',
  'cars',
  'drone',
  'drone-alt',
  'fighter-jet',
  'helicopter',
  'horse',
  'horse-saddle',
  'motorcycle',
  'paper-plane',
  'pegasus',
  'plane',
  'plane-alt',
  'rocket',
  'rv',
  'ship',
  'shopping-cart',
  'shuttle-van',
  'ski-lift',
  'snowmobile',
  'snowplow',
  'space-shuttle',
  'subway',
  'taxi',
  'tractor',
  'train',
  'tram',
  'truck',
  'truck-monster',
  'truck-pickup',
  'truck-plow',
  'wheelchair',
]

import {
  faAmbulance as tiAmbulance,
  faBabyCarriage as tiBabyCarriage,
  faBicycle as tiBicycle,
  faBus as tiBus,
  faBusAlt as tiBusAlt,
  faCar as tiCar,
  faCarAlt as tiCarAlt,
  faCarBuilding as tiCarBuilding,
  faCarBump as tiCarBump,
  faCarBus as tiCarBus,
  faCarCrash as tiCarCrash,
  faCarSide as tiCarSide,
  faCarTilt as tiCarTilt,
  faCars as tiCars,
  faDrone as tiDrone,
  faDroneAlt as tiDroneAlt,
  faFighterJet as tiFighterJet,
  faHelicopter as tiHelicopter,
  faHorse as tiHorse,
  faHorseSaddle as tiHorseSaddle,
  faMotorcycle as tiMotorcycle,
  faPaperPlane as tiPaperPlane,
  faPegasus as tiPegasus,
  faPlane as tiPlane,
  faPlaneAlt as tiPlaneAlt,
  faRocket as tiRocket,
  faRv as tiRv,
  faShip as tiShip,
  faShoppingCart as tiShoppingCart,
  faShuttleVan as tiShuttleVan,
  faSkiLift as tiSkiLift,
  faSnowmobile as tiSnowmobile,
  faSnowplow as tiSnowplow,
  faSpaceShuttle as tiSpaceShuttle,
  faSubway as tiSubway,
  faTaxi as tiTaxi,
  faTractor as tiTractor,
  faTrain as tiTrain,
  faTram as tiTram,
  faTruck as tiTruck,
  faTruckMonster as tiTruckMonster,
  faTruckPickup as tiTruckPickup,
  faTruckPlow as tiTruckPlow,
  faWheelchair as tiWheelchair,
} from '@fortawesome/pro-solid-svg-icons'

library.add(tiAmbulance)
library.add(tiBabyCarriage)
library.add(tiBicycle)
library.add(tiBus)
library.add(tiBusAlt)
library.add(tiCar)
library.add(tiCarAlt)
library.add(tiCarBuilding)
library.add(tiCarBump)
library.add(tiCarBus)
library.add(tiCarCrash)
library.add(tiCarSide)
library.add(tiCarTilt)
library.add(tiCars)
library.add(tiDrone)
library.add(tiDroneAlt)
library.add(tiFighterJet)
library.add(tiHelicopter)
library.add(tiHorse)
library.add(tiHorseSaddle)
library.add(tiMotorcycle)
library.add(tiPaperPlane)
library.add(tiPegasus)
library.add(tiPlane)
library.add(tiPlaneAlt)
library.add(tiRocket)
library.add(tiRv)
library.add(tiShip)
library.add(tiShoppingCart)
library.add(tiShuttleVan)
library.add(tiSkiLift)
library.add(tiSnowmobile)
library.add(tiSnowplow)
library.add(tiSpaceShuttle)
library.add(tiSubway)
library.add(tiTaxi)
library.add(tiTractor)
library.add(tiTrain)
library.add(tiTram)
library.add(tiTruck)
library.add(tiTruckMonster)
library.add(tiTruckPickup)
library.add(tiTruckPlow)
library.add(tiWheelchair)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)

export default library
