<template>
  <transitionToAuto v-if="LayoutStore.footer">
    <footer id="footer_wrap" class="footer navbar-static-bottom bg-navy py-5 text-storm link-light">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6">
            <img class="footer_logo" src="../assets/TrackNordic-logo-light-60h.png" width="141" height="60" />
            <!--p>Dorem lorem ipsum dolor sit amet.</p>
            <p>Maecenas vel justo ipsum. Donec dictum sagittis ligula ac rutrum. Nulla ullamcorper est faucibus, cursus elit a, pharetra nunc. Nulla id finibus urna.</p-->
          </div>
          <div class="col-6 col-md-3">
            <!--h4>Links</h4>
            <ul>
              <li><a href="#">Dorem</a></li>
              <li><a href="#">Lorem</a></li>
              <li><a href="#">Ipsum</a></li>
            </ul-->
          </div>
          <div class="col-6 col-md-3">
            <!--h4>About</h4>
            <ul>
              <li><a href="#">Dolor</a></li>
              <li><a href="#">Sit</a></li>
              <li><a href="#">Amet</a></li>
            </ul-->
          </div>
        </div>
      </div>
    </footer>
  </transitionToAuto>
</template>

<style>
.footer_logo {
  width: auto;
  height: 30px;
}
</style>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      LayoutStore: state => state.LayoutStore,
    }),
  },
}
</script>
