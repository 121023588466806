<template>
  <McLayoutForm>
    <div v-if="loadingState">
      <McSpinner large />
    </div>
    <div v-else-if="errorState">
      <h2>{{ $t('general.Error') }}</h2>
      <i18n path="mileage.InvalidLinkPleaseLogInToAdjustOdometer.Outer" tag="p">
        <b-link to="/mileage">{{ $t('mileage.InvalidLinkPleaseLogInToAdjustOdometer.LogIn') }}</b-link>
      </i18n>
    </div>
    <div v-else>
      <h2>{{ $t('mileage.Odometer') }}</h2>
      <p>{{ $t('mileage.EnterCurrentOdometerIfCorrectJustHitSave') }}</p>
      <MileageOdometerForm :secret="$route.params.secret" :imei="$route.params.imei" @odometerUpdated="odometerUpdated" :value="trackerOdometer" />
      <!--pre>{{ $route.params }}</pre>
      <pre>{{ trackerOdometer }}</pre-->
    </div>
  </McLayoutForm>
</template>

<script>
import { FormatError } from '../../helpers/ErrorFormatting'
import { mapActions } from 'vuex'
import MileageService from '../../services/MileageService'

export default {
  data() {
    return {
      errorState: false,
      loadingState: true,
      showformsuccess: false,
      trackerOdometer: null,
      tracker: null,
    }
  },
  created() {
    this.getOdometerBySecret(this.$route.params.imei, this.$route.params.secret)
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    odometerUpdated(data) {
      this.trackerOdometer = data
    },

    getAddresses() {
      if (!this.trip.startAddress[this.lang] || !this.trip.endAddress[this.lang]) {
        MileageService.getAddressesWithSecret(this.$route.params.imei, this.$route.params.tripId, this.$route.params.secret)
          .then(data => {
            this.$set(this.trip.startAddress, this.lang, data.startAddress)
            this.$set(this.trip.endAddress, this.lang, data.endAddress)
          })
          .catch(error => {
            console.error(FormatError(error))
          })
      }
    },

    getOdometerBySecret(imei, secret) {
      this.showformsuccess = false
      return new Promise((resolve, reject) => {
        MileageService.getOdometerBySecret(imei, secret)
          .then(data => {
            this.loadingState = false
            this.trackerOdometer = data.odometer
            this.tracker = data.tracker
            resolve(data)
          })
          .catch(error => {
            console.error(FormatError(error))
            this.loadingState = false
            this.errorState = true
          })
      })
    },
  },
}
</script>
