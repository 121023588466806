import moment from 'moment-timezone'

export const gmtTime = mysqlTimeString => {
  return new Date(mysqlTimeString.replace(' ', 'T') + 'Z')
}

export const mysqlTimeStringFromDate = dateObject => {
  return moment(dateObject)
    .utc()
    .format('YYYY-MM-DD HH:mm:ss')
}

export const userTimeZone = () => {
  return moment.tz.guess()
}

export const momentFromTime = mysqlTimeString => {
  return moment(gmtTime(mysqlTimeString))
}

export const ageFromMysqlTimeString = mysqlTimeString => {
  return Math.round(moment.duration(moment().diff(moment(gmtTime(mysqlTimeString)))) / 1000)
}

export const localizedDatetime = (mysqlTimeString, format = 'YYYY-MM-DD HH:mm:ss') => {
  return momentFromTime(mysqlTimeString).format(format)
}

export const dateToLocalizedHumanreadableDate = date => {
  return moment(date).format('lll')
}

export const dateToGMTHumanreadableDate = date => {
  return momentFromTime(date)
    .tz('GMT')
    .format('l')
}

export const dateToShortLocalizedHumanreadableDate = date => {
  return momentFromTime(date).format('l LT')
}

export const localizedHumanreadableAge = mysqlTimeString => {
  return moment.duration(moment(gmtTime(mysqlTimeString)).diff(moment())).humanize(true)
}

export const dateTodatetimeLocalString = date => {
  return moment(date).format('YYYY-MM-DDTHH:mm')
}

export const datetimeLocalStringToDate = inStr => {
  return moment(inStr)
}
