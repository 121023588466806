import { authorizedApiRequest, addLanguageAndTimezone, queryParameters } from '../setup/ApiService'

export default {
  delete: function(id) {
    return authorizedApiRequest(
      `/orders/${id}`, //
      'DELETE',
    )
  },
  orderDownload: function(id) {
    return authorizedApiRequest(
      `/orders/${id}/order-download`, //
      'GET',
    )
  },
  createOrUpdate: function(orderData) {
    return authorizedApiRequest(
      '/orders/create-or-update-from-admin', //
      'POST',
      addLanguageAndTimezone({ orderData }),
    )
  },
  unhandledOrders: function() {
    return authorizedApiRequest(
      '/orders/unhandled', //
      'GET',
    )
  },

  query: function(params) {
    return authorizedApiRequest(
      '/orders?' + queryParameters(params), //
      'GET',
    )
  },
}
