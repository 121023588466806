<template>
  <McForm
    ref="mcFormInstance"
    v-bind="$attrs"
    @reset="handleReset"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    :submiticon="isEditing ? 'save' : 'plus'"
    :title="isEditing ? $t('journal.EditNote') : $t('journal.AddNote')"
    :submittext="isEditing ? $t('general.Save') : $t('journal.AddNote')"
  >
    <!-- eslint-disable-next-line -->
    <mc-form-input
      name="note"
      type="textarea"
      v-model="curData.text"
      :label="$t('journal.Note')"
      :placeholder="$t('journal.EnterNote')"
      key="journalentryform.note"
      icon="signature"
      v-validate="'required'"
      :errorHint="errors.first('note')"
      :state="!errors.has('note')"
      :data-vv-as="$t('journal.Note')"
    />
    <!--pre> {{ curData }} </pre-->
  </McForm>
</template>

<script>
import JournalService from '../services/JournalService'
import { isEmptyObject } from '../helpers/Misc'

const defaultData = {
  id: -1,
  text: '',
}

export default {
  components: {},
  data() {
    return {
      curData: { ...defaultData },
      isEditing: false,
    }
  },
  props: {
    value: Object,
    imei: String,
  },
  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  watch: {
    value(val) {
      console.log(val)
      this.$refs['mcFormInstance'].forceReset()

      this.isEditing = !isEmptyObject(val)

      let newVal = {}
      for (var field in defaultData) {
        newVal[field] = val.hasOwnProperty(field) ? val[field] : defaultData[field]
      }
      this.curData = newVal
    },
  },
  methods: {
    handleReset() {
      this.curData = { ...defaultData }
    },

    handleSubmit(e) {
      return JournalService.addOrEditNote(this.imei, this.curData).then(
        data => {
          return Promise.resolve(data)
        },
        error => {
          console.error('Error saving note', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
