<template>
  <b-form-group :label="label" :description="description">
    <div class="custom-file mc-icon-picker">
      <McIcon @click="setIcon(item)" :class="item == value ? 'active' : ''" v-for="item in trackerIcons" v-bind:key="item" sized :icon="item" />
    </div>
  </b-form-group>
</template>

<script>
import { trackerIcons } from '../setup/FontAwesome'

export default {
  props: {
    label: String,
    description: String,
    value: String,
    options: [Array],
  },
  computed: {
    trackerIcons: () => trackerIcons,
  },
  methods: {
    setIcon(newIcon) {
      //console.log(newIcon)
      this.$emit('input', newIcon)
    },
  },
}
</script>

<style>
.mc-icon-picker .McIcon {
  font-size: 1.65em;
  border: 1px solid transparent;
  cursor: pointer;
}
.mc-icon-picker .McIcon.active {
  background: #ddd;
  border: 1px solid #333;
}
</style>
