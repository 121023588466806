<template>
  <McLayoutDefault :heading="$t('headings.users')">
    <b-card :title="$t('permissions.LackingPermissions')" class="mb-2" v-if="!$perms.isAdministratorOfTrackers() && !$perms.isAdministrator()">
      <p>{{ $t('permissions.LackingPermissionsLonger') }}</p>
    </b-card>
    <McTable v-else class="users-table" ref="usersTable" :sortBy="sortBy" :primaryKey="primaryKey" :fields="fields" :provider="dataProvider">
      <McButton @click="editUser({})" variant="primary" v-if="$perms.isAdministratorOfTrackers() || $perms.isAdministrator()" icon="user-plus" :text="$t('users.add')" />
      <template v-slot:cell(actions)="data">
        <b-button-group>
          <!-- eslint-disable-next-line -->
          <McButton
            @click="loginAsUser(data.item)"
            variant="success"
            size="sm"
            :icon="['fas', 'sign-in']"
            v-if="$perms.isAdministrator()"
          />
          <McButton @click="editUser(data.item)" variant="primary" size="sm" icon="user-edit" />
          <McButton
            icon="trash"
            variant="danger"
            size="sm"
            @click="deleteuser(data.item.id, data.item.name)"
            v-if="data.item.id !== $perms.userId() && (!$perms.isAdministrator() || data.item.ownedTrackers.length === 0)"
          />
        </b-button-group>
      </template>
      <template v-slot:cell(ownedTrackerCount)="data">
        <span v-if="data.item.role === 'administrator'">-</span>
        <span v-else>{{ data.item.ownedTrackers.length }}</span>
      </template>
      <template v-slot:cell(permissionedTrackerCount)="data">
        <span v-if="data.item.role === 'administrator'">{{ formatRole(data.item.role) }}</span>
        <span v-else>{{ data.item.permissionedTrackers.length }}</span>
      </template>
      <template v-slot:cell(permissionedTrackerCount_DISABLED)="data">
        <strong v-if="data.item.role === 'administrator'">{{ formatRole(data.item.role) }}</strong>
        <ul v-else-if="data.item.permissionedTrackers.length" class="billingOwnerList">
          <li v-for="imei in data.item.permissionedTrackers" v-bind:key="imei">
            <McIcon v-if="trackerInfo[imei].icon" sized :style="{ color: trackerInfo[imei].color }" :icon="trackerInfo[imei].icon" />
            <span class="trackerName">{{ trackerInfo[imei].name }}</span>
          </li>
        </ul>
      </template>
      <template v-slot:cell(ownedTrackerCount_DISABLED)="data">
        <ul v-if="data.item.role !== 'administrator' && data.item.ownedTrackers.length" class="billingOwnerList">
          <li v-for="imei in data.item.ownedTrackers" v-bind:key="imei">
            <McIcon v-if="trackerInfo[imei].icon" sized :style="{ color: trackerInfo[imei].color }" :icon="trackerInfo[imei].icon" />
            <span class="trackerName">{{ trackerInfo[imei].name }}</span>
          </li>
        </ul>
      </template>
    </McTable>
    <UserForm id="userform" isModal :value="editingUser" :title="$t('users.add')" @completed="refreshTable()" />
  </McLayoutDefault>
</template>

<script>
import Router from '@/setup/Router'
import store from '@/setup/Store'
import { mapState, mapActions } from 'vuex'
import UserService from './../../services/UserService'
import AuthenticationService from './../../services/AuthenticationService'
import TrackerService from './../../services/TrackerService'

export default {
  mounted() {
    TrackerService.list().then(data => {
      this.updateTrackerList({ ...data })
      this.loaded = true
    })
  },
  data() {
    return {
      primaryKey: 'id',
      sortBy: 'name',
      dataProvider: UserService.query,
      editingUser: {},
      loaded: false,
    }
  },
  computed: {
    ...mapState({
      MapViewStore: state => state.MapViewStore,
    }),
    fields() {
      let retval = []
      retval.push({ key: 'name', priority: 0, mw: 200, sortable: true, label: this.$t('users.name') })
      //retval.push({ key: 'role', priority: 1, mw: 160, sortable: true, label: this.$t('users.role'), formatter: this.formatRole })
      retval.push({ key: 'email', priority: 2, mw: 200, sortable: true, label: this.$t('users.email') })
      if (this.$perms.isAdministrator()) {
        retval.push({ key: 'ownedTrackerCount', priority: 3, mw: 160, sortable: true, label: this.$t('users.ownedTrackers') })
      }
      retval.push({ key: 'permissionedTrackerCount', priority: 4, mw: 160, sortable: true, label: this.$t('users.permissionedTrackers') })
      retval.push({ key: 'actions', priority: 0, mw: 120, label: this.$t('general.Actions'), class: 'text-right' })

      return retval
    },
    trackerInfo() {
      return { ...this.MapViewStore.trackerList }
    },
  },
  methods: {
    ...mapActions('MapViewStore', [
      'updateTrackerList', //
    ]),
    editUser(user) {
      this.editingUser = { ...user }
      this.$bvModal.show('userform')
    },
    refreshTable() {
      this.$refs.usersTable.refresh()
    },
    deleteuser(id, name) {
      if (confirm(this.$t('users.confirmdelete', { name }))) {
        UserService.delete(id).then(data => {
          this.refreshTable()
        })
      }
    },
    loginAsUser(user) {
      AuthenticationService.loginAsUser(user.id).then(data => {
        store.commit('LoginStore/loginSuccess', data.userInfo)
        TrackerService.list().then(data => {
          this.updateTrackerList({ ...data })
          Router.push('/')
        })
      })
    },
    formatRole(value) {
      if (value === 'administrator') {
        return this.$t('users.userTypeAdministrator')
      } else if (value === 'user') {
        return this.$t('users.userTypeUser')
      }
      return value
    },
  },
}
</script>
