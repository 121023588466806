const state = {
  footer: true,
}

const actions = {
  hideFooter({ commit }) {
    commit('hideFooter')
  },
  showFooter({ commit }) {
    commit('showFooter')
  },
}

const mutations = {
  hideFooter() {
    state.footer = false
  },
  showFooter() {
    state.footer = true
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
