<template>
  <b-form-group :label="label" :description="description" :state="!errorHint">
    <b-input-group>
      <b-input-group-text v-if="icon" slot="prepend" class="bg-primary text-white">
        <McIcon :icon="icon" />
      </b-input-group-text>
      <b-input-group-text v-if="prepend" slot="prepend" class="bg-primary text-white">
        {{ prepend }}
      </b-input-group-text>
      <!-- prettier-ignore -->
      <date-picker
        @dp-change="newValue"
        :value="value"
        :config="internalConfig"
      />
    </b-input-group>
    <TransitionToAuto v-if="errorHint">
      <div class="invalid-feedback d-block">{{ errorHint }}</div>
    </TransitionToAuto>
  </b-form-group>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    listeners() {
      return {
        ...this.$listeners,
      }
    },
    ...mapState({
      LanguageStore: state => state.LanguageStore,
    }),
    internalConfig() {
      return Object.assign(
        {},
        {
          useCurrent: false,
          inline: false,
          locale: this.LanguageStore.currentLanguage,
          ignoreReadonly: true,
          showClose: true,
          /*widgetPositioning: {
            horizontal: 'right',
            vertical: 'top',
          },*/
          icons: {
            time: 'far fa-clock',
            date: 'far fa-calendar',
            up: 'fas fa-arrow-up',
            down: 'fas fa-arrow-down',
            previous: 'fas fa-chevron-left',
            next: 'fas fa-chevron-right',
            today: 'fas fa-calendar-check',
            clear: 'far fa-trash-alt',
            close: 'far fa-check-circle',
          },
        },
        this.min ? { minDate: moment(this.min) } : {},
        this.max ? { maxDate: moment(this.max) } : {},
        this.config,
      )
    },
  },
  methods: {
    newValue(e) {
      //console.log('dpchange', e.date.toDate())
      if (e.date && e.date != this.value) {
        this.$emit('input', e.date.toDate())
      }
    },
  },
  props: {
    icon: [String, Array],
    errorHint: String,
    label: String,
    description: String,
    prepend: String,
    min: Date,
    max: Date,
    config: {
      type: Object,
      default() {
        return {}
      },
    },
    value: {
      type: [Date, String],
      default() {
        return new Date()
      },
    },
  },
}
</script>

<style>
.bootstrap-datetimepicker-widget {
  z-index: 99999;
}
</style>
