import { i18n } from '../setup/i18n'
import tracknordicMarker from './tracknordicMarker'
import animateLabels from './labelAnimation'
import autoFit from './autoFitting'
import { momentFromTime, dateToLocalizedHumanreadableDate } from '../helpers/Time'
import './mapStyles.scss'
import { dom } from '@fortawesome/fontawesome-svg-core'
dom.watch()

export default function tracknordicMap(map, google) {
  this.loaded = true
  this.markers = {}
  this.trackers = {}
  this.historyTrack = null
  this.historyMarker = null
  this.map = map
  animateLabels(this)
  this.autofit = autoFit(this)

  this.addTracker = function(imei, tracker) {
    if (this.trackers.hasOwnProperty(imei)) {
      return
    }
    this.markers['imei_' + imei] = tracknordicMarker({
      id: 'imei_' + imei,
      name: tracker.name,
      color: tracker.color,
      icon: tracker.icon,
      map: map,
    })
    this.trackers[imei] = {
      label: this.markers['imei_' + imei],
      tracker: tracker,
    }
  }

  this.addMarkerAndUpdateIfExists = function(id, coordinate, color, html, offset) {
    let prefixedId = 'id_' + id
    if (!this.markers.hasOwnProperty(prefixedId)) {
      this.addMarker(id, coordinate, color, html, offset)
    } else {
      this.markers[prefixedId].color = color
      this.markers[prefixedId].html = html
      this.markers[prefixedId].updateStatus({ coordinate })
    }
  }

  this.addMarker = function(id, coordinate, color, html, offset) {
    let prefixedId = 'id_' + id
    if (this.markers.hasOwnProperty(prefixedId)) {
      return
    }
    this.markers[prefixedId] = tracknordicMarker({
      id: prefixedId,
      color: color,
      html: html,
      map: map,
    })
    if (offset) {
      this.markers[prefixedId].offset = offset
    }
    this.markers[prefixedId].updateStatus({ coordinate })
  }

  this.removeMarker = function(id) {
    let prefixedId = 'id_' + id
    if (!this.markers.hasOwnProperty(prefixedId)) {
      return
    }
    this.markers[prefixedId].setMap(null)
    this.markers[prefixedId] = null
    delete this.markers[prefixedId]
  }

  this.drawHistory = function(imei, name, icon, color, points) {
    this.historyTrack = new google.maps.Polyline({
      path: this.polylineCoords(points),
      geodesic: true,
      strokeColor: color,
      strokeOpacity: 1.0,
      strokeWeight: 3,
      zIndex: 0,
    })
    this.historyImei = imei

    this.historyTrack.setMap(this.map)

    this.markers['history'] = tracknordicMarker({
      id: 'history',
      name: name,
      color: color,
      icon: icon,
      map: this.map,
    })
    if (this.markers['imei_' + imei]) {
      this.markers['history'].offset.x = this.markers['imei_' + imei].offset.x
      this.markers['history'].offset.y = this.markers['imei_' + imei].offset.y
    }
    this.updateHistory(points[0])
  }

  this.updateHistory = function(status) {
    if (this.trackers[this.historyImei].tracker.type == 110) {
      status.subLabel = dateToLocalizedHumanreadableDate(momentFromTime(status.datetime))
    }
    this.markers['history'].updateStatus(status)
  }

  this.clearHistory = function() {
    if (this.historyTrack) {
      this.historyTrack.setMap(null)
    }
    this.historyTrack = null
    if (this.markers['history']) {
      this.markers['history'].setMap(null)
      delete this.markers['history']
    }
  }

  this.updateTrackerBasics = function(imei, { name, icon, color }) {
    if (!this.markers['imei_' + imei]) {
      return
    }
    this.markers['imei_' + imei].name = name
    this.markers['imei_' + imei].icon = icon
    this.markers['imei_' + imei].color = color
  }

  this.removeTracker = function(imei) {
    if (!this.trackers.hasOwnProperty(imei)) {
      return
    }
    this.trackers[imei].label.remove()
    this.markers['imei_' + imei] = null
    this.trackers[imei] = null
    delete this.markers['imei_' + imei]
    delete this.trackers[imei]
  }

  this.updateTracker = function(imei, status) {
    if (!this.trackers.hasOwnProperty(imei)) {
      console.error('Trying to update tracker that is not added: ' + imei)
      return
    }
    if (this.trackers[imei].tracker.type == 110 && status.datetime) {
      status.subLabel = dateToLocalizedHumanreadableDate(momentFromTime(status.datetime))
    } else if (status.datetime && status.stalled) {
      status.subLabel = i18n.t('map.BadSignal') + '<br>' + dateToLocalizedHumanreadableDate(momentFromTime(status.datetime))
    } else if (status.hasOwnProperty('stalled') && !status.stalled) {
      status.subLabel = false
    }
    this.trackers[imei].label.updateStatus(status)
  }

  this.polylineCoords = function(points) {
    let coords = [points.length - 1]
    for (let i in points) {
      coords[i] = points[i]['coordinate']
    }
    return coords
  }

  let self = this
  return {
    addMarker(id, coordinate, color, html, offset) {
      self.addMarker(id, coordinate, color, html, offset)
    },
    addMarkerAndUpdateIfExists(id, coordinate, color, html, offset) {
      self.addMarkerAndUpdateIfExists(id, coordinate, color, html, offset)
    },
    removeMarker(id) {
      self.removeMarker(id)
      self.autofit.trigger()
    },
    addTracker(imei, tracker, status) {
      self.addTracker(imei, tracker, status)
      if (status) {
        self.updateTracker(imei, status)
      }
      self.autofit.trigger()
    },
    updateTrackerBasics(imei, { name, icon, color }) {
      self.updateTrackerBasics(imei, { name, icon, color })
    },
    updateTracker(imei, status) {
      self.updateTracker(imei, status)
      self.autofit.trigger()
    },
    removeTracker(imei) {
      self.removeTracker(imei)
      self.autofit.trigger()
    },
    drawHistory(imei, name, icon, color, points) {
      self.drawHistory(imei, name, icon, color, points)
    },
    updateHistory(status) {
      self.updateHistory(status)
    },
    clearHistory(points) {
      self.clearHistory()
      self.autofit.trigger()
    },
    autofit(jump = false) {
      self.autofit.trigger(jump)
    },
    unload() {
      this.loaded = false
    },
  }
}
