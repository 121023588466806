import Vue from 'vue'

import store from '../setup/Store'

const state = {
  viewedTrackers: {},
  zoomedTracker: false,
  shownHistory: false,
  trackerList: {},
  menuIsOut: false,
  autofit: true,
}

const actions = {
  updateTrackerList({ commit }, newList) {
    commit('updateTrackerListMutation', newList)
  },
  showMenu({ commit }) {
    commit('showMenu')
  },
  hideMenu({ commit }) {
    commit('hideMenu')
  },
  enableAutofit({ commit }) {
    commit('enableAutofit')
  },
  disableAutofit({ commit }) {
    commit('disableAutofit')
  },
  setViewedTrackers({ commit }, newState) {
    commit('setViewedTrackers', newState)
  },
  viewTracker({ commit }, imei) {
    commit('viewTracker', imei)
  },
  unviewTracker({ commit }, imei) {
    commit('unviewTracker', imei)
  },
  zoomTracker({ commit }, imei) {
    commit('hideMenu')
    commit('zoomTracker', imei)
  },
  unzoomTracker({ commit }) {
    commit('unzoomTracker')
  },
  showHistory({ commit }, data) {
    commit('showHistory', data)
  },
  showHistoryPoint({ commit }, data) {
    commit('showHistoryPoint', data)
  },
  quitHistory({ commit }) {
    commit('quitHistory')
  },
}

const mutations = {
  updateTrackerListMutation(state, data) {
    Vue.set(state, 'trackerList', data)
  },
  enableAutofit(state) {
    Vue.set(state, 'autofit', true)
  },
  disableAutofit(state) {
    Vue.set(state, 'autofit', false)
  },
  showMenu(state) {
    Vue.set(state, 'menuIsOut', true)
  },
  hideMenu(state) {
    Vue.set(state, 'menuIsOut', false)
  },
  setViewedTrackers(state, newState) {
    Vue.set(state, 'viewedTrackers', newState)
  },
  viewTracker(state, imei) {
    Vue.set(state.viewedTrackers, imei, true)
    if (store.state.LoginStore.user) {
      window.localStorage.setItem('viewedTrackers|' + store.state.LoginStore.user.id, JSON.stringify(state.viewedTrackers))
    }
  },
  unviewTracker(state, imei) {
    Vue.set(state.viewedTrackers, imei, false)
    if (store.state.LoginStore.user) {
      window.localStorage.setItem('viewedTrackers|' + store.state.LoginStore.user.id, JSON.stringify(state.viewedTrackers))
    }
  },
  zoomTracker(state, imei) {
    Vue.set(state, 'zoomedTracker', imei)
  },
  unzoomTracker(state) {
    Vue.set(state, 'zoomedTracker', false)
  },
  showHistory(state, data) {
    Vue.set(state, 'shownHistory', data)
  },
  showHistoryPoint(state, data) {
    Vue.set(state.shownHistory, 'currentPoint', data)
  },
  quitHistory(state) {
    Vue.set(state, 'shownHistory', false)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
