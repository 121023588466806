<template>
  <b-form-group v-bind="$attrs">
    <b-input-group class="mb-1">
      <select v-model="duedateRangeQuick" @change="quickChange">
        <option value="custom">{{ this.quicklabel ? this.quicklabel : this.$t('general.QuickSelectRange') }}</option>
        <option :value="dateRangeAllClone">{{ this.$t('general.DateRangeAll') }}</option>
        <optgroup v-for="(optiongroup, ogId) in dateOptions" v-bind:key="ogId" :label="optiongroup.label">
          <option v-for="(option, oId) in optiongroup.options" v-bind:key="oId" :value="option.value">{{ option.label }}</option>
        </optgroup>
      </select>
    </b-input-group>
    <mcFormDateRange
      :value="duedateRange"
      :config="{
        format: 'YYYY-MM-DD',
      }"
    />
    <!--pre> {{ duedateRange }} </pre-->
  </b-form-group>
</template>

<script>
import moment from 'moment'

let dateRangeAll = {
  from: moment('2010-01-01')
    .startOf('year')
    .toDate(),
  to: moment('2030-01-01')
    .endOf('year')
    .toDate(),
}
let dateRangeAllClone = {
  from: moment('2010-01-01')
    .startOf('year')
    .toDate(),
  to: moment('2030-01-01')
    .endOf('year')
    .toDate(),
}

export default {
  props: {
    value: {
      default: dateRangeAll,
      type: Object,
    },
    quicklabel: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      duedateRangeQuick: 'custom',
      duedateRange: dateRangeAll,
      dateRangeAllClone: dateRangeAllClone,
    }
  },
  watch: {
    'duedateRange.to'() {
      this.manualChange()
    },
    'duedateRange.from'() {
      this.manualChange()
    },
  },
  computed: {
    dateOptions() {
      let monthOptions = []
      for (let i = 6; i > -6; i--) {
        let monthDate = moment().subtract(i, 'month')
        monthOptions.push({
          value: {
            from: monthDate.startOf('month').toDate(),
            to: monthDate.endOf('month').toDate(),
          },
          label: monthDate.format('MMMM YY'),
        })
      }

      let quarterOptions = []
      for (let i = 3; i > -3; i--) {
        let monthDate = moment().subtract(i * 3, 'month')
        quarterOptions.push({
          value: {
            from: monthDate.startOf('quarter').toDate(),
            to: monthDate.endOf('quarter').toDate(),
          },
          label: monthDate.format('\\QQ YYYY'),
        })
      }

      let yearOptions = []
      let yearDate = moment('2020-06-01')
      while (parseInt(yearDate.format('YYYY')) <= 1 + parseInt(moment().format('YYYY'))) {
        yearOptions.push({
          value: {
            from: yearDate.startOf('year').toDate(),
            to: yearDate.endOf('year').toDate(),
          },
          label: yearDate.format('YYYY'),
        })
        yearDate = yearDate.add(1, 'year')
      }

      let options = [
        {
          label: this.$t('general.Month'),
          options: monthOptions,
        },
        {
          label: this.$t('general.Quarter'),
          options: quarterOptions,
        },
        {
          label: this.$t('general.Year'),
          options: yearOptions,
        },
      ]
      return options
    },
  },
  methods: {
    quickChange(e) {
      if (this.duedateRangeQuick !== 'custom') {
        this.duedateRange = { ...this.duedateRangeQuick }
      }
    },
    manualChange(e) {
      this.duedateRangeQuick = 'custom'
      this.$emit('input', this.duedateRange)
    },
  },
}
</script>
