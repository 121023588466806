<template>
  <b-tabs lazy>
    <b-tab :title="$t('billing.Invoices')" active>
      <BillingInvoicesAdmin />
    </b-tab>
    <b-tab>
      <template v-slot:title>
        {{ $t('billing.ExpiredTrackers') }}
        <b-badge small v-if="expiredTrackers && Object.keys(expiredTrackers).length > 0">{{ Object.keys(expiredTrackers).length }}</b-badge>
      </template>
      <p class="my-3">{{ $t('billing.TheseTrackersAreExpired') }}</p>
      <router-link
        :to="'/trackers/' + imei"
        v-for="(tracker, imei) in expiredTrackers"
        v-bind:key="imei"
        class="badge mx-1"
        :style="{ fontSize: '1em', fontWeight: 'normal', background: tracker.color, color: contrast(tracker.color) }"
      >
        <McIcon v-if="tracker.icon" sized :icon="tracker.icon" />
        <span v-if="tracker" class="trackerName">{{ tracker.name }}</span>
        <span v-else>{{ imei }}</span>
        <br />
        <small>{{ $t('billing.expiredOn', { date: shortDate(tracker.dateExpires) }) }}</small>
      </router-link>
    </b-tab>
  </b-tabs>
</template>

<script>
var Color = require('color')
import TrackerService from '../services/TrackerService'
import { FormatError } from '../helpers/ErrorFormatting'
import { dateToGMTHumanreadableDate } from '../helpers/Time'

export default {
  data() {
    return {
      expiredTrackers: [],
    }
  },
  methods: {
    contrast(color) {
      return Color(color).contrast(Color('#000')) > 12 ? '#000' : '#fff'
    },
    shortDate(v) {
      return dateToGMTHumanreadableDate(v)
    },
  },
  created() {
    TrackerService.listExpired()
      .then(data => {
        this.expiredTrackers = data
      })
      .catch(error => {
        alert(FormatError(error))
      })
  },
}
</script>
