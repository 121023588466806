import Store from './Store'

import TrackerStatusService from '../services/TrackerStatusService'

let stateFetcher = {
  stateTimer: null,
  currentDelay: 0,
  fetchedIds: {},

  init: function() {
    this.trigger()
  },

  trigger: function(delay = 500) {
    if (this.stateTimer !== null && delay > this.currentDelay) {
      return //Already running with a shorter delay, don't prolong
    }
    this.currentDelay = delay
    if (this.stateTimer !== null) {
      clearTimeout(this.stateTimer) //
    }
    this.stateTimer = setTimeout(() => {
      this.fetchState()
    }, delay)
  },

  fetchState: function() {
    this.stateTimer = null
    let fetchData = {}
    let elmId, elm, imei, fields, fieldId, field
    for (elmId in Store.state.TrackerStatusStore.subscribers) {
      elm = Store.state.TrackerStatusStore.subscribers[elmId]
      for (imei in elm) {
        fields = elm[imei]
        for (fieldId in fields) {
          field = fields[fieldId]
          if (!fetchData[imei]) {
            fetchData[imei] = {}
          }
          if (!fetchData[imei].hasOwnProperty(field)) {
            if (this.fetchedIds.hasOwnProperty(imei) && this.fetchedIds[imei].hasOwnProperty(field)) {
              fetchData[imei][field] = this.fetchedIds[imei][field]
            } else {
              fetchData[imei][field] = -1
            }
          }
        }
      }
    }
    if (Object.keys(fetchData).length == 0) {
      return
    }

    TrackerStatusService.getStatus(fetchData)
      .then(data => {
        for (let imei in data) {
          if (data[imei].hasOwnProperty('id')) {
            for (let field in fetchData[imei]) {
              if (!this.fetchedIds.hasOwnProperty(imei)) {
                this.fetchedIds[imei] = {}
              }
              this.fetchedIds[imei][field] = data[imei].id
            }
          }
        }
        Store.dispatch('TrackerStatusStore/updateState', data)
      })
      .then(() => {
        this.trigger(10000)
      })
  },
}

stateFetcher.init()

export default stateFetcher
