<template>
  <div class="billing-invoices-admin-wrap">
    <b-row>
      <b-col md="6" cols="12" class="pt-3">
        <mcDateRangeWithQuickSelect v-model="duedateRange" :quicklabel="$t('billing.DueDateRange')" @input="updateSearch" />
        <!--pre>{{ duedateRange }}</pre-->
      </b-col>
      <b-col md="6" cols="12" class="pt-3">
        <mcDateRangeWithQuickSelect v-model="paiddateRange" :quicklabel="$t('billing.PaidDateRange')" @input="updateSearch" />
        <!--pre>{{ paiddateRange }}</pre-->
      </b-col>
    </b-row>
    <b-row>
      <!--pre>{{ sums }}</pre-->
      <table id="billingSumsTable" class="my-5" v-if="sums.unpaid">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>{{ $t('billing.Unpaid') }}</th>
            <th>{{ $t('billing.Paid') }}</th>
            <th>{{ $t('billing.Cancelled') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>{{ $t('billing.sumTypeSMS') }}</th>
            <td>{{ niceCurrency(sums.unpaid.sms.exVat, 'dkk') }} ({{ niceCurrency(sums.unpaid.sms.incVat, 'dkk') }})</td>
            <td>{{ niceCurrency(sums.paid.sms.exVat, 'dkk') }} ({{ niceCurrency(sums.paid.sms.incVat, 'dkk') }})</td>
            <td>{{ niceCurrency(sums.cancelled.sms.exVat, 'dkk') }} ({{ niceCurrency(sums.cancelled.sms.incVat, 'dkk') }})</td>
          </tr>
          <tr>
            <th>{{ $t('billing.sumTypeSubscription') }}</th>
            <td>{{ niceCurrency(sums.unpaid.subscription.exVat, 'dkk') }} ({{ niceCurrency(sums.unpaid.subscription.incVat, 'dkk') }})</td>
            <td>{{ niceCurrency(sums.paid.subscription.exVat, 'dkk') }} ({{ niceCurrency(sums.paid.subscription.incVat, 'dkk') }})</td>
            <td>{{ niceCurrency(sums.cancelled.subscription.exVat, 'dkk') }} ({{ niceCurrency(sums.cancelled.subscription.incVat, 'dkk') }})</td>
          </tr>
          <tr>
            <th>{{ $t('billing.sumTotal') }}</th>
            <td>{{ niceCurrency(sums.unpaid.sms.exVat + sums.unpaid.subscription.exVat, 'dkk') }} ({{ niceCurrency(sums.unpaid.sms.incVat + sums.unpaid.subscription.incVat, 'dkk') }})</td>
            <td>{{ niceCurrency(sums.paid.sms.exVat + sums.paid.subscription.exVat, 'dkk') }} ({{ niceCurrency(sums.paid.sms.incVat + sums.paid.subscription.incVat, 'dkk') }})</td>
            <td>{{ niceCurrency(sums.cancelled.sms.exVat + sums.cancelled.subscription.exVat, 'dkk') }} ({{ niceCurrency(sums.cancelled.sms.incVat + sums.cancelled.subscription.incVat, 'dkk') }})</td>
          </tr>
        </tbody>
      </table>
    </b-row>
    <McTable class="invoices-table" ref="adminInvoicesTable" :sortBy="sortBy" :primaryKey="primaryKey" :fields="fields" :provider="dataProvider" :tbody-tr-class="item => 'status-' + item.textStatus">
      <div style="float:right;white-space:nowrap">
        <!--div style="display: inline-block;vertical-align:top;" class="mr-2"-->
        <mc-form-checkboxes prepend="Status" name="status" v-model="statuses" :options="invoiceStatusOptions" @input="updateSearch" inline multiple />
        <!--/div-->
        <div style="display: inline-block;vertical-align:top;" class="mr-2">
          <mc-form-checkboxes prepend="Type" name="type" v-model="types" :options="invoiceTypeOptions" @input="updateSearch" inline multiple />
        </div>
        <mc-button text="Print" :icon="['fas', 'print']" @click="doExport" />
      </div>
      <template v-slot:cell(cb)="data">
        <input type="checkbox" v-if="data.item.textStatus == 'unpaid'" :value="data.item" v-model="selectedInvoices" :checked="false" />
      </template>
      <template v-slot:cell(id)="data">
        <a target="_blank" :href="data.item.download">#{{ data.item.id }}</a>
        <div v-if="$perms.isAdministrator() && data.item.textStatus == 'unpaid'">
          <McButton icon="trash" :title="$t('billing.CancelInvoice')" variant="danger" @click="cancelInvoice(data.item.id)" size="sm" />
        </div>
      </template>
      <template v-slot:cell(address)="data">
        <pre style="font-size:.8em">{{ data.item.address }}<br>({{ data.item.email }})</pre>
      </template>
      <template v-slot:cell(text)="data">
        <a target="_blank" :href="data.item.download">{{ data.item.text }}</a>
        <span v-if="data.item.textStatus == 'paid' && data.item.paidBy">
          <br />
          <i18n path="billing.PaidByPayment" tag="small" class="text-success">
            <template v-slot:date>{{ shortDate(data.item.paidBy.dateReceived) }}</template>
            <template v-slot:method>{{ data.item.paidBy.method | niceMethod }}</template>
            <template v-slot:paymentId>{{ data.item.paidBy.humanPaymentId }}</template>
            <template v-slot:amount>{{ niceCurrency(data.item.paidBy.amountInCents, data.item.paidBy.currency) }}</template>
          </i18n>
        </span>
      </template>
      <template v-slot:cell(dateDue)="data">{{ data.item.dateDue | shortDate }}</template>
      <template v-slot:cell(dateReceived)="data">
        <span v-if="data.item.textStatus == 'unpaid'">
          <span class="text-muted">{{ $t('billing.Unpaid') }}</span>
        </span>
        <span v-else-if="data.item.textStatus == 'paid' && data.item.paidBy">
          <span class="text-success">{{ data.item.paidBy.dateReceived | shortDate }}</span>
        </span>
        <span v-if="data.item.textStatus == 'cancelled'">
          <span class="text-muted">{{ $t('billing.Cancelled') }}</span>
        </span>
      </template>
      <template v-slot:cell(amount)="data">{{ niceCurrency(data.item.amountExVatInCents, data.item.currency) }} ({{ niceCurrency(data.item.amountIncVatInCents, data.item.currency) }})</template>
    </McTable>
    <div style="clear:both">
      <ManualPaymentForm v-if="selectedInvoices.length > 0" :invoices="selectedInvoices" @completed="refreshTable" />
    </div>
    <!--pre>{{ selectedInvoices }}</pre-->
  </div>
</template>
<script>
import moment from 'moment'
import BillingService from '../services/BillingService'
import { dateToGMTHumanreadableDate } from '../helpers/Time'
import { niceCurrency, niceMethod } from '../helpers/Formatting'
import McButton from './McButton.vue'

let dateRangeAll = {
  from: moment('2010-01-01')
    .startOf('year')
    .toDate(),
  to: moment('2030-01-01')
    .endOf('year')
    .toDate(),
}

export default {
  components: { McButton },
  data() {
    return {
      primaryKey: 'id',
      sortBy: 'dateDue',
      editingTracker: {},
      statuses: ['unpaid', 'paid'],
      types: ['sms', 'subscription'],
      duedateRange: dateRangeAll,
      paiddateRange: { ...dateRangeAll },
      selectedInvoices: [],
      printCtx: {},
      sums: {},
      invoiceStatusOptions: [
        { value: 'unpaid', label: this.$t('billing.Unpaid') }, //
        { value: 'paid', label: this.$t('billing.Paid') },
        { value: 'cancelled', label: this.$t('billing.Cancelled') },
      ],
      invoiceTypeOptions: [
        { value: 'sms', label: this.$t('billing.invoiceTypeSMS') }, //
        { value: 'subscription', label: this.$t('billing.invoiceTypeSubscription') },
      ],
      prevParms: {},
    }
  },
  methods: {
    updateSearch() {
      if (this.prevParms !== JSON.stringify(this.curParms)) {
        //console.log('changed', this.prevParms, JSON.stringify(this.curParms))
        this.refreshTable()
      }
    },
    dataProvider(ctx) {
      this.selectedInvoices = []
      this.prevParms = JSON.stringify(this.curParms)
      this.printCtx = ctx
      let self = this
      return new Promise((resolve, reject) => {
        BillingService.adminInvoices({ ...this.curParms, ...ctx }).then(function(data) {
          self.sums = data.sums
          resolve(data)
        })
      })
    },
    cancelInvoice(invoiceId) {
      if (confirm(this.$t('billing.ConfirmCancel', { invoiceId }))) {
        BillingService.cancelInvoice(invoiceId).then(data => {
          this.refreshTable()
        })
      }
    },
    refreshTable() {
      this.$refs.adminInvoicesTable.refresh()
    },
    niceCurrency(amountInCents, currency) {
      return niceCurrency(amountInCents, currency)
    },
    shortDate(longdate) {
      return dateToGMTHumanreadableDate(longdate)
    },
    doExport() {
      BillingService.adminInvoicesExport({ ...this.curParms, ...this.printCtx }).then(
        data => {
          if (data.downloadUrl) {
            window.location = data.downloadUrl
          }
        },
        error => {
          console.error('Error generating export', error)
        },
      )
    },
  },
  computed: {
    curParms() {
      return {
        statuses: this.statuses,
        types: this.types,
        dateDueFrom: moment(this.duedateRange.from).format('YYYY-MM-DD'),
        dateDueTo: moment(this.duedateRange.to).format('YYYY-MM-DD'),
        datePaidFrom: moment(this.paiddateRange.from).format('YYYY-MM-DD'),
        datePaidTo: moment(this.paiddateRange.to).format('YYYY-MM-DD'),
      }
    },
    fields() {
      return [
        { key: 'cb', priority: 0, mw: 10, sortable: false, label: '' }, //
        { key: 'id', priority: 0, mw: 120, sortable: true, label: this.$t('billing.InvoiceNumber') },
        { key: 'dateDue', priority: 3, mw: 120, sortable: true, label: this.$t('billing.DateDue') },
        { key: 'dateReceived', priority: 3, mw: 120, sortable: true, label: this.$t('billing.DateReceived') },
        { key: 'address', priority: 4, mw: 200, sortable: false, label: this.$t('billing.Address') },
        { key: 'text', priority: 3, mw: 200, sortable: false, label: this.$t('billing.InvoiceText') },
        { key: 'amount', priority: 0, mw: 100, sortable: false, label: this.$t('billing.Amount'), class: 'text-right' },
      ]
    },
  },
  filters: {
    shortDate(v) {
      return dateToGMTHumanreadableDate(v)
    },
    niceMethod(v) {
      return niceMethod(v)
    },
  },
}
</script>

<style lang="scss">
.status-cancelled {
  text-decoration: line-through;
}
#billingSumsTable {
  width: 100%;
  border: 1px solid #eee;
  th {
    background: #eee;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
  }
  th,
  td {
    text-align: right;
    padding: 5px;
  }
  td {
    border-top: 1px solid #eee;
    border-left: 1px solid #eee;
  }
}
</style>
