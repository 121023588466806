<template>
  <div class="unhandled-orders-wrap my-5">
    <div v-if="loadingState">
      <McSpinner large />
    </div>
    <div v-else-if="orders.length == 0"></div>
    <div v-else>
      <h3 class="text-center">{{ $t('orders.UnhandledOrders') }} <McButton size="sm" @click="editOrder({})" icon="plus" :text="$t('orders.CreateManualOrder')" /></h3>
      <b-table-simple :small="true" stacked="md" striped class="mileage-table mt-3 mb-0">
        <b-thead>
          <b-tr>
            <b-th class="text-center">{{ $t('orders.DateCreated') }}</b-th>
            <b-th>{{ $t('orders.Address') }}</b-th>
            <b-th>{{ $t('orders.Trackers') }}</b-th>
            <b-th>&nbsp;</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="order in orders" v-bind:key="order.id">
            <b-td class="text-center align-top">
              <TnLocalTime class="badge badge-dark" :time="order.dateCreated" format="l LT" />
            </b-td>
            <b-td class="align-top">
              <!--pre>{{ order }}</pre-->
              <div v-if="order.adminUser">
                {{ $t('orders.ExistingUserColon') }}
                <br />
                <strong>{{ order.adminUser.name }}</strong>
                <br />
                <ContactInfo :contact="order.adminUser" />
              </div>
              <div v-else>
                <ContactAddress :contact="order.customer" />
                <ContactInfo :contact="order.customer" />
              </div>
            </b-td>
            <b-td class="align-top">
              <OrderTrackers :value="order.trackers" />
            </b-td>
            <b-td class="align-top text-right">
              <b-button-group>
                <McButton icon="edit" variant="outline-primary" @click="editOrder(order)" :text="$t('orders.Edit')" />
                <McButton :icon="['fad', 'box-check']" variant="outline-primary" @click="handleOrder(order)" :text="$t('orders.Handle')" />
              </b-button-group>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <HandleOrderForm @completed="getOrders()" ref="handleOrderForm" id="handleOrderForm" isModal />
    <ManualOrderForm @completed="getOrders()" ref="editOrderForm" :value="editingOrder" id="editOrderForm" isModal />
  </div>
</template>

<script>
import OrdersService from '../services/OrdersService'
import { FormatPhone } from '../helpers/Formatting'
import { FormatError } from '../helpers/ErrorFormatting'

export default {
  data() {
    return {
      loadingState: true,
      activeState: false,
      orders: [],
      editingOrder: {},
    }
  },
  mounted() {
    this.getOrders()
  },
  methods: {
    FormatPhone(phone) {
      return FormatPhone(phone)
    },
    getOrders() {
      return OrdersService.unhandledOrders()
        .then(data => {
          this.orders = data.orders
          this.loadingState = false
        })
        .catch(error => {
          this.loadingState = FormatError(error)
        })
    },
    handleOrder(order) {
      this.$refs['handleOrderForm'].handleOrder(order)
      this.$bvModal.show('handleOrderForm')
    },
    editOrder(item) {
      this.editingOrder = { ...item }
      this.$bvModal.show('editOrderForm')
    },
  },
}
</script>
