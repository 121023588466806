//const apiUrl = 'https://api.tracknordic.dk'
const apiUrl = 'https://my.tracknordic.dk/api'

import VueCookies from 'vue-cookies'
import Store from './Store'
import { userTimeZone } from '../helpers/Time'
import { objHash } from '../helpers/Hashing'

function AuthHeader() {
  let token = VueCookies.get('token')

  if (token) {
    return {
      Authorization: 'Bearer ' + token,
    }
  } else {
    return {}
  }
}

export function addLanguageAndTimezone(body = {}) {
  return {
    language: Store.state.LanguageStore.currentLanguage,
    timezone: userTimeZone(),
    ...body,
  }
}

export function apiRequest(endpoint, method, body, additionalHeaders = {}) {
  let requestOptions = {
    method: method,
    headers: { 'Content-Type': 'application/json', Accept: 'application/json', ...additionalHeaders },
  }
  if (body) {
    requestOptions.body = JSON.stringify(body)
  }
  return fetch(`${apiUrl}${endpoint}`, requestOptions)
    .then(handleResponse)
    .catch(error => {
      console.error('Error in apiRequest', error)
      throw error
    })
}

export function authorizedApiRequest(endpoint, method, body, additionalHeaders = {}) {
  return apiRequest(endpoint, method, body, { ...AuthHeader(), ...additionalHeaders })
}

let cachedReplies = {}
export function cachedApiRequest(endpoint, method, body, additionalHeaders = {}) {
  let reqId = objHash({ endpoint, method, body, additionalHeaders })
  //console.log(reqId)
  if (cachedReplies.hasOwnProperty(reqId)) {
    //console.log('cached', cachedReplies[reqId])
    return cachedReplies[reqId]
  }
  cachedReplies[reqId] = apiRequest(endpoint, method, body, { ...AuthHeader(), ...additionalHeaders })
  //console.log('fresh', cachedReplies[reqId])
  return cachedReplies[reqId]
}

export function queryParameters(params) {
  return Object.keys(params)
    .filter(k => k !== 'apiUrl' && params[k] !== null)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&')
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if (response.status === 200) {
      if (data.newToken) {
        //The "Remember" setting is stored in localstorage as
        //we may receive a new token at any point...
        //The token itself must be a cookie to auto-delete
        //on session end. Localstorage persists and
        //sessionstorage does not span different tabs.
        if (parseInt(localStorage.getItem('remember')) == 1) {
          VueCookies.set('token', data.newToken, '30d')
        } else {
          VueCookies.set('token', data.newToken, '0')
        }
      }
    } else {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        Store.dispatch('LoginStore/logout')
      }

      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}
