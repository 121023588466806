<template>
  <McLayoutDefault :heading="$t('headings.orders')">
    <b-card :title="$t('permissions.LackingPermissions')" class="mb-2" v-if="!$perms.isAdministrator()">
      <p>{{ $t('permissions.LackingPermissionsLonger') }}</p>
    </b-card>
    <McTable v-else class="orders-table" ref="ordersTable" :sortBy="sortBy" :sortDesc="sortDesc" :primaryKey="primaryKey" :fields="fields" :provider="dataProvider">
      <McButton size="sm" @click="editOrder({})" icon="plus" :text="$t('orders.CreateManualOrder')" />
      <template v-slot:cell(dateCreated)="data">
        <TnLocalTime class="badge badge-dark" :time="data.item.dateCreated" format="l LT" />
      </template>
      <template v-slot:cell(dateCompleted)="data">
        <p v-if="data.item.status == 0" class="badge bg-warning text-white">{{ $t('orders.Unhandled') }}</p>
        <p v-if="data.item.status == 1" class="badge bg-success text-white">{{ $t('orders.Handled') }}</p>
        <span v-if="data.item.status == 1"><br /><TnLocalTime class="badge badge-dark" :time="data.item.dateCompleted" format="l LT" /></span>
      </template>
      <template v-slot:cell(address)="data">
        <!--pre>{{ order }}</pre-->
        <div v-if="data.item.adminUser">
          {{ $t('orders.ExistingUserColon') }}
          <br />
          <strong>{{ data.item.adminUser.name }}</strong>
          <br />
          <ContactInfo :contact="data.item.adminUser" />
        </div>
        <div v-else>
          <ContactAddress :contact="data.item.customer" />
          <ContactInfo :contact="data.item.customer" />
        </div>
      </template>
      <template v-slot:cell(trackers)="data">
        <OrderTrackers :value="data.item.trackers" />
      </template>
      <template v-slot:cell(actions)="data">
        <b-button-group>
          <McButton v-if="data.item.status == 0" icon="edit" variant="secondary" size="sm" @click="editOrder(data.item)" />
          <McButton v-if="data.item.status == 0" :icon="['fad', 'box-check']" size="sm" variant="primary" @click="handleOrder(data.item)" />
          <McButton v-if="data.item.status == 1" :icon="['fad', 'cloud-download-alt']" size="sm" variant="success" @click="getOrderDownload(data.item.id)" />
          <McButton icon="trash" variant="danger" size="sm" @click="deleteorder(data.item.id)" />
        </b-button-group>
      </template>
    </McTable>
    <HandleOrderForm @completed="refreshTable()" ref="handleOrderForm" id="handleOrderForm" isModal />
    <ManualOrderForm @completed="refreshTable()" ref="editOrderForm" :value="editingOrder" id="editOrderForm" isModal />
  </McLayoutDefault>
</template>

<script>
import OrdersService from '../../services/OrdersService'
//import { FormatPhone } from '../../helpers/Formatting'
//import { FormatError } from '../../helpers/ErrorFormatting'

export default {
  data() {
    return {
      primaryKey: 'id',
      sortBy: 'dateCreated',
      sortDesc: true,
      dataProvider: OrdersService.query,
      editingOrder: {},
    }
  },
  computed: {
    fields() {
      let retval = []
      retval.push({ key: 'dateCreated', priority: 0, mw: 100, sortable: true, label: this.$t('orders.DateCreated') })
      retval.push({ key: 'dateCompleted', priority: 0, mw: 100, sortable: true, label: this.$t('orders.Status') })
      retval.push({ key: 'address', priority: 4, mw: 160, sortable: false, label: this.$t('orders.Address') })
      retval.push({ key: 'trackers', priority: 4, mw: 160, sortable: false, label: this.$t('orders.Trackers') })
      retval.push({ key: 'actions', priority: 0, mw: 120, label: this.$t('general.Actions'), class: 'text-right' })

      return retval
    },
  },
  methods: {
    editOrder(order) {
      this.editingOrder = { ...order }
      this.$bvModal.show('editOrderForm')
    },
    handleOrder(order) {
      this.$refs['handleOrderForm'].handleOrder(order)
      this.$bvModal.show('handleOrderForm')
    },
    refreshTable() {
      this.$refs.ordersTable.refresh()
    },
    deleteorder(id) {
      if (confirm(this.$t('orders.confirmdelete'))) {
        OrdersService.delete(id).then(data => {
          this.refreshTable()
        })
      }
    },
    getOrderDownload(id) {
      OrdersService.orderDownload(id).then(data => {
        if (data.downloadUrl) {
          window.location = data.downloadUrl
        } else {
          console.log(data)
        }
      })
    },
  },
}
</script>
