<template>
  <div class="autopaycards-wrap">
    <McSpinner v-if="autopayCards === null" large class="my-5" />
    <div v-else>
      <!--BillingAutopayCard v-for="(card, index) in autopayCards" v-bind:key="index" @click="card.status === 'active' ? payByAutopayCard(card.id) : ''" :isPayment="isPayment" :card="card" @delete="deleteCard(card)" /-->
      <BillingAutopayCard v-for="(card, index) in autopayCards" v-bind:key="index" @click="card.status === 'active' ? fakePayByAutopayCard() : ''" :isPayment="isPayment" :card="card" @delete="deleteCard(card)" />
      <BillingAutopayCard v-if="!isPayment" addCard @click="addNewCard" :text="$t('billing.AddNewCard')" :title="$t('billing.AddNewCard')" />
      <BillingAutopayCard v-if="isPayment" addCard @click="addNewCard" :text="$t('billing.PayWithNewCardAndSave')" :title="$t('billing.PayWithNewCardAndRememberInfo')" />
      <BillingAutopayCard v-if="isPayment" payByCard @click="payByCard" :text="$t('billing.PayByCreditCardDontSave')" :title="$t('billing.PayByCreditCardDontRememberInfo')" />
      <BillingAutopayCard v-if="isPayment" payByMobilepay @click="payByMobilepay" :text="$t('billing.PayByMobilepay')" :title="$t('billing.PayByMobilepay')" />
    </div>
    <b-modal hide-footer static hisde-header :id="'quickpay-modal-' + _uid" :title="$t('quickpay.payment')" no-close-on-backdrop>
      <PaymentPanel
        ref="paymentpanel"
        :withToken="withToken"
        :withSmsToken="withSmsToken"
        @cardAdded="cardAdded"
        @cardNotAdded="cardNotAdded"
        @cardAddedPaymentCompleted="cardAddedPaymentCompleted"
        @paymentCompleted="paymentCompleted"
        @paymentNotCompleted="paymentNotCompleted"
        @finished="quickpayFinished"
      />
    </b-modal>
    <!--pre>{{ autopayCards }}</pre-->
    <!--pre>{{ withToken }}</pre-->
    <!--pre>{{ chosenPackage }} {{ contactId }}</pre-->
  </div>
</template>
<script>
import BillingService from '../services/BillingService'
import { FormatError } from '../helpers/ErrorFormatting'
import { mapActions } from 'vuex'

export default {
  props: {
    currency: {
      type: String,
      default: 'DKK',
    },
    withToken: {
      type: [Object],
      default: () => null,
    },
    withSmsToken: {
      type: [Object],
      default: () => null,
    },
    autopayCards: {
      type: [Array, null],
      default: () => null,
    },
    invoiceIds: {
      type: [Array, null],
      default: () => null,
    },
  },
  computed: {
    isPayment() {
      return this.invoiceIds && this.invoiceIds.length > 0
    },
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
      'toastWarning',
    ]),
    addNewCard() {
      this.$bvModal.show('quickpay-modal-' + this._uid)
      this.$refs.paymentpanel.addNewCard(this.currency, this.invoiceIds)
    },
    fakePayByAutopayCard() {
      this.boxOne = ''
      this.$bvModal
        .msgBoxOk(this.$t('billing.AutopaycardsNotWorkingDueToEU'))
        .then(value => {
          this.payByCard()
        })
        .catch(err => {})
    },
    payByCard() {
      if (!this.isPayment) {
        return
      }
      this.$bvModal.show('quickpay-modal-' + this._uid)
      this.$refs.paymentpanel.payByCard('creditcard', this.currency, this.invoiceIds)
    },
    payByMobilepay() {
      if (!this.isPayment) {
        return
      }
      this.$bvModal.show('quickpay-modal-' + this._uid)
      this.$refs.paymentpanel.payByCard('mobilepay', this.currency, this.invoiceIds)
    },
    payByAutopayCard(cardId) {
      if (!this.isPayment) {
        return
      }
      if (!this.isPayment) {
        return
      }
      this.$bvModal.show('quickpay-modal-' + this._uid)
      this.$refs.paymentpanel.payByAutopayCard(cardId, this.currency, this.invoiceIds)
    },
    quickpayFinished() {
      this.$bvModal.hide('quickpay-modal-' + this._uid)
    },
    cardAdded() {
      this.toastSuccess(this.$t('billing.CardAdded'))
      this.$emit('cardsUpdated')
    },
    cardNotAdded() {
      this.toastWarning(this.$t('billing.CardNotAdded'))
      this.$emit('cardsUpdated')
    },
    cardAddedPaymentCompleted() {
      this.toastSuccess(this.$t('billing.cardAddedPaymentCompleted'))
      this.$emit('cardsUpdated')
      this.$emit('invoicesUpdated')
    },
    paymentCompleted() {
      this.toastSuccess(this.$t('billing.PaymentCompleted'))
      this.$emit('invoicesUpdated')
    },
    paymentNotCompleted() {
      this.toastWarning(this.$t('billing.PaymentNotCompleted'))
      this.$emit('cardsUpdated')
    },
    cardDeleted() {
      this.toastWarning(this.$t('billing.CardDeleted'))
      this.$emit('cardsUpdated')
    },
    deleteCard(card) {
      if (confirm(this.$t('billing.ConfirmDeleteCard', card))) {
        let action
        if (this.withToken) {
          action = BillingService.deleteAutopayCardByToken(card.id, this.withToken.invoice, this.withToken.secret)
        } else if (this.withSmsToken) {
          action = BillingService.deleteAutopayCardBySmsToken(card.id, this.withSmsToken.secret)
        } else {
          action = BillingService.deleteAutopayCard(card.id)
        }
        action
          .then(data => {
            this.cardDeleted()
          })
          .catch(error => {
            alert(FormatError(error))
          })
      }
    },
  },
}
</script>
