<template>
  <div id="mapWrap">
    <div id="map"></div>
    <TheMapTrackerMenu />
    <TheMapAutofitButton />
    <!-- eslint-disable-next-line -->
    <transition name="fadezoom">
      <div v-if="!MapViewStore.zoomedTracker">
        <!-- eslint-disable-next-line -->
        <address-autocomplete
          id="mapautocomplete"
          classname="form-control"
          :placeholder="$t('general.SearchAddress')"
          @placechanged="placeAddressMarker"
          @cleared="removeAddressMarker"
        />
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
#mapWrap {
  width: 100%;
  height: auto;
  position: relative;
  flex-grow: 1;
}
#map {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
#mapautocomplete {
  position: absolute;
  left: 50%;
  top: 10px;
  width: 300px;
  transform: translateX(-50%);
}
.address_marker_wrap {
  background: #fff;
  padding: 5px;
  border: 2px solid #094ba2;
  border-radius: 4px;
  max-width: 250px;
}
#theMapTrackerMenu button {
  min-width: 120px;
}
@media screen and (max-width: 600px) {
  #mapautocomplete {
    right: 10px;
    left: auto;
    top: 60px;
    width: 120px;
    transform: none;
    transition: width .2s ease-in-out;
  }
  #mapautocomplete.focused {
    width: 300px;
  }
}
</style>

<script>
import { mapActions, mapState } from 'vuex'
import gmapsInit from '../map/googleMaps'
import tracknordicMap from '../map/tracknordicMap'
import { userCoordinates } from '../helpers/Misc'

export default {
  name: 'App',
  data() {
    return {
      map: null,
      tnMap: null,
      watchers: {},
      vuexUnsubscriber: null,
      autocenterTimer: null,
    }
  },
  mounted: function() {
    try {
      this.loadMap().then(() => {
        this.testinit()
      })
    } catch (error) {
      console.error(error)
    }
  },
  beforeDestroy() {
    this.vuexUnsubscriber()
    if (this.tnMap) {
      this.tnMap.unload()
    }
    for (var imei in this.watchers) {
      this.removeTracker(imei)
    }
    if (this.autocenterTimer) {
      clearInterval(this.autocenterTimer)
    }
  },
  watch: {
    '$route.params'(to, from) {
      if (to.imei) {
        //console.log('zoom', to, from)
        this.zoomTracker(to.imei)
        this.tnMap.autofit()
      } else {
        //console.log('unzoom', to, from)
        this.unzoomTracker()
        this.tnMap.autofit()
      }
    },
  },
  computed: {
    ...mapState({
      TrackerStatusStore: state => state.TrackerStatusStore.trackers,
      LoggedInUser: state => state.LoginStore.user,
    }),
    ...mapState({
      MapViewStore: state => state.MapViewStore,
    }),
  },
  methods: {
    ...mapActions('TrackerStatusStore', [
      'subscribe', //
      'unsubscribe',
      'updateState',
    ]),
    ...mapActions('MapViewStore', [
      'viewTracker', //
      'zoomTracker', //
      'unzoomTracker', //
      'setViewedTrackers',
    ]),
    async loadMap() {
      try {
        const google = await gmapsInit()
        await userCoordinates().then(userCoords => {
          this.map = new google.maps.Map(this.$el.querySelector('#map'), {
            center: {
              lat: userCoords.lat,
              lng: userCoords.lng,
            },
            zoom: 5,
            minZoom: 2,
            restriction: {
              latLngBounds: {
                north: 85,
                south: -85,
                west: -180,
                east: 180,
              },
            },
            streetViewControl: false,
            fullscreenControlOptions: {
              position: google.maps.ControlPosition.RIGHT_BOTTOM,
            },
            mapTypeControl: true,
            mapTypeControlOptions: {
              style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
              position: google.maps.ControlPosition.TOP_LEFT,
            },
          })

          this.tnMap = new tracknordicMap(this.map, google)

          if (false) {
            this.tnMap.addMarkerAndUpdateIfExists(
              'userMarker',
              {
                lat: userCoords.lat,
                lng: userCoords.lng,
              },
              '#094ba2',
              '<div class="address_marker_wrap">Du er her</div>',
            )
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    placeAddressMarker: function(e) {
      this.removeAddressMarker()
      console.log(e.adr_address)
      this.tnMap.addMarkerAndUpdateIfExists(
        'address',
        {
          lat: e.geometry.location.lat(),
          lng: e.geometry.location.lng(),
        },
        '#094ba2',
        '<div class="address_marker_wrap">' + e.adr_address.replaceAll(', ', '<br>') + '</div>',
      )
    },
    removeAddressMarker: function() {
      this.tnMap.removeMarker('address')
    },
    trackerStatus: function(imei) {
      return this.TrackerStatusStore[imei]
    },
    addTracker: function(imei) {
      if (this.watchers.hasOwnProperty(imei)) {
        return
      }
      this.tnMap.addTracker(imei, this.MapViewStore.trackerList[imei], this.trackerStatus(imei))

      if (!this.watchers.hasOwnProperty(imei)) {
        this.watchers[imei] = this.$store.watch(
          state => state.TrackerStatusStore.trackers[imei],
          (newValue, oldValue) => {
            this.tnMap.updateTracker(imei, this.trackerStatus(imei))
          },
        )
        this.subscribe({
          imei: imei,
          fields: ['position'],
          elmId: this._uid,
        })
      }
    },
    removeTracker: function(imei) {
      if (this.watchers.hasOwnProperty(imei)) {
        this.watchers[imei]()
        delete this.watchers[imei]
        this.unsubscribe({
          imei: imei,
          elmId: this._uid,
        })
        this.tnMap.removeTracker(imei)
      }
    },
    drawHistory: function(data) {
      const imei = this.MapViewStore.zoomedTracker
      this.tnMap.drawHistory(imei, this.MapViewStore.trackerList[imei].name, this.MapViewStore.trackerList[imei].icon, this.MapViewStore.trackerList[imei].color, data.points)
    },
    updateHistory: function(status) {
      this.tnMap.updateHistory(status)
    },
    clearHistory: function() {
      this.tnMap.clearHistory()
    },
    loadViewedTrackers() {
      //console.log(this.LoggedInUser)
      if (!this.LoggedInUser) {
        //console.log('not logged in')
        return
      }

      //console.log('logged in,', this.LoggedInUser.id, strViewedTrackers)
      let loadedViewedTrackers = {}
      let strViewedTrackers = localStorage.getItem('viewedTrackers|' + this.LoggedInUser.id)
      if (strViewedTrackers) {
        loadedViewedTrackers = JSON.parse(strViewedTrackers) || {}
      }
      let objViewedTrackers = {}
      let defViewed = Object.keys(this.MapViewStore.trackerList).length <= 20
      for (var imei in this.MapViewStore.trackerList) {
        objViewedTrackers[imei] = typeof loadedViewedTrackers[imei] === 'undefined' ? defViewed : loadedViewedTrackers[imei]
      }
      this.setViewedTrackers(objViewedTrackers)
    },
    sycncViewedTrackers() {
      for (var imei in this.MapViewStore.trackerList) {
        if (typeof this.MapViewStore.viewedTrackers[imei] === 'undefined') {
          //console.log('Not adding tracker (not on viewed list)', imei)
        } else if (this.MapViewStore.viewedTrackers[imei] === true) {
          //console.log('Adding tracker (enabled on viewed list)', imei)
          this.addTracker(imei)
          this.tnMap.updateTrackerBasics(imei, this.MapViewStore.trackerList[imei])
        } else {
          //console.log('Not adding tracker (disabled on viewed list)', imei)
          this.removeTracker(imei)
        }
      }
      if (this.$route.params.hasOwnProperty('imei') && this.$route.params.imei) {
        //console.log('Zooming tracker after loading map', this.$route.params.imei)
        this.viewTracker(this.$route.params.imei)
        this.zoomTracker(this.$route.params.imei)
        //console.log('start zoomed', this.$route.params.imei)
      } else {
        this.unzoomTracker()
      }
    },
    testinit: function() {
      this.map.addListener('clickLabel', e => {
        if (!this.MapViewStore.zoomedTracker && e.labelId.substring(0, 5) === 'imei_') {
          let imei = e.labelId.substring(5)
          this.$router.push('/map/' + imei)
        }
      })

      this.vuexUnsubscriber = this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'MapViewStore/viewTracker') {
          //console.log('Adding tracker (viewTracker mutation)', mutation.payload)
          let imei = mutation.payload
          if (this.MapViewStore.trackerList[imei]) {
            this.addTracker(imei)
          }
        } else if (mutation.type === 'MapViewStore/unviewTracker') {
          this.removeTracker(mutation.payload)
        } else if (mutation.type === 'MapViewStore/showHistory') {
          this.drawHistory(mutation.payload)
        } else if (mutation.type === 'MapViewStore/showHistoryPoint') {
          this.updateHistory(mutation.payload)
        } else if (mutation.type === 'MapViewStore/quitHistory') {
          this.clearHistory()
        } else if (mutation.type === 'MapViewStore/updateTrackerListMutation') {
          this.loadViewedTrackers()
        } else if (mutation.type === 'MapViewStore/setViewedTrackers') {
          this.sycncViewedTrackers()
        } else {
          //console.log(mutation)
        }
      })

      this.sycncViewedTrackers()

      this.tnMap.autofit(true)
    },
  },
}
</script>
