<template>
  <div class="billing-invoices-paid-wrap">
    <McSpinner v-if="invoices === null" large class="my-5" />
    <div v-else-if="paidInvoices.length == 0" class="alert alert-success">
      <p class="my-2">{{ $t('billing.invoicesPaidEmptyDescription') }}</p>
    </div>
    <div v-else>
      <McTable class="paid-invoices-table" ref="paidInvoicesTable" :sortBy="sortBy" :primaryKey="primaryKey" :fields="fields" :items="paidInvoices" :provider="null">
        <template v-slot:cell(id)="data">
          <a target="_blank" :href="data.item.download">#{{ data.item.id }}</a>
        </template>
        <template v-slot:cell(text)="data">
          <a target="_blank" :href="data.item.download">{{ data.item.text }}</a>
          <span v-if="data.item.paidBy">
            <br />
            <i18n path="billing.PaidByPayment" tag="small" class="text-success">
              <template v-slot:date>{{ shortDate(data.item.paidBy.dateReceived) }}</template>
              <template v-slot:method>{{ data.item.paidBy.method | niceMethod }}</template>
              <template v-slot:paymentId>{{ data.item.paidBy.humanPaymentId }}</template>
              <template v-slot:amount>{{ niceCurrency(data.item.paidBy.amountInCents, data.item.paidBy.currency) }}</template>
            </i18n>
          </span>
        </template>
        <template v-slot:cell(dateDue)="data">{{ data.item.dateDue | shortDate }}</template>
        <template v-slot:cell(amount)="data">{{ niceCurrency(data.item.amountIncVatInCents, data.item.currency) }}</template>
      </McTable>
    </div>
  </div>
</template>
<script>
import { dateToGMTHumanreadableDate } from '../helpers/Time'
import { niceCurrency, niceMethod } from '../helpers/Formatting'

export default {
  props: {
    invoices: {
      type: [Object, null],
      default: () => null,
    },
  },
  data() {
    return {
      primaryKey: 'id',
      sortBy: 'dateDue',
    }
  },
  methods: {
    niceCurrency(amountInCents, currency) {
      return niceCurrency(amountInCents, currency)
    },
    shortDate(longdate) {
      return dateToGMTHumanreadableDate(longdate)
    },
  },
  computed: {
    paidInvoices() {
      let paidInvoices = []
      paidInvoices = this.invoices.paid
      return paidInvoices
    },
    fields() {
      return [
        { key: 'id', priority: 0, mw: 120, sortable: true, label: this.$t('billing.InvoiceNumber') }, //
        { key: 'dateDue', priority: 3, mw: 120, sortable: true, label: this.$t('billing.DateDue') },
        { key: 'text', priority: 3, mw: 200, sortable: true, label: this.$t('billing.InvoiceText') },
        { key: 'amount', priority: 0, mw: 100, sortable: true, label: this.$t('billing.Amount'), class: 'text-right' },
      ]
    },
  },
  filters: {
    shortDate(v) {
      return dateToGMTHumanreadableDate(v)
    },
    niceMethod(v) {
      return niceMethod(v)
    },
  },
}
</script>
