<template>
  <!-- eslint-disable-next-line -->
  <McForm
    ref="mcFormInstance"
    :loading="loadingState"
    v-bind="$attrs"
    @reset="handleReset"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    :title="$t('alarms.EditAlarmRules')"
    :warnBeforeClose="warnBeforeClose"
  >
    <div v-if="insuranceSettings" :class="!insuranceSettings.contact1.name || !insuranceSettings.contact1.phone || !insuranceSettings.contact2.name || !insuranceSettings.contact2.phone ? 'missing-insurance-contacts' : ''">
      <h3>{{ $t('alarms.ContactsFormInsurance') }}</h3>
      <p>{{ $t('alarms.ContactsFormInsuranceDescription') }}</p>
      <TrackerAlarmsInsuranceContactsInner withBackupContact v-model="insuranceSettings" />

      <!--pre>{{ insuranceSettings }}</pre-->
      <hr />
    </div>
    <div class="alarm_rule_list">
      <TnAlarmRule v-for="(rule, id) in alarmRules" v-bind:key="id" :geofences="geofences" :rule="rule" @editClick="editRule(id)" @removeClick="removeRule(id)" />
      <div class="text-center"><McButton icon="plus" size="sm" class="mb-2" :text="$t('alarms.AddAlarmRule')" @click="editRule(-1)" /></div>
    </div>

    <TrackerAlarmForm
      id="trackeralarmform"
      isModal
      :defaultTexts="defaultTexts"
      :geofences="geofences"
      @geofencesEdited="geofencesEdited"
      :alarmContacts="alarmContacts"
      @alarmContactsEdited="alarmContactsEdited"
      :supported="supported"
      :value="editingRule"
      @ruleEdited="ruleEdited"
    />
  </McForm>
</template>

<script>
import { mapActions } from 'vuex'
import { FormatError } from '../helpers/ErrorFormatting'
import AlarmService from '../services/AlarmService'
import MiscService from '../services/MiscService'
import deepEqual from 'deep-eql'
import { FormatPhone } from '../helpers/Formatting'

export default {
  components: {},
  data() {
    return {
      alarmRules: [],
      origAlarmRules: [],
      origInsuranceSettings: {},
      editingRule: {},
      editingRuleId: -1,
      supported: [],

      types: [],

      loadingState: true,
      alarmContacts: [],
      insuranceSettings: null,
      geofences: [],
      defaultTexts: {},
      imei: '',
    }
  },
  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  computed: {
    warnBeforeClose() {
      //console.log(this.hashAlarms(this.alarmRules), this.hashAlarms(this.origAlarmRules))
      if (deepEqual(this.hashAlarms(this.alarmRules), this.hashAlarms(this.origAlarmRules)) && deepEqual(this.insuranceSettings, this.origInsuranceSettings)) {
        return ''
      }
      return this.$t('general.YouHaveUnsavedChangesDoYouWantToCloseTheFormWithoutSaving')
    },
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),
    hashAlarms(alarmArray) {
      //console.log(alarmArray)
      let out = []
      alarmArray.forEach(elm => {
        let hash = {
          id: elm.id,
          type: elm.type,
          smsText: elm.smsText,
          emailText: elm.emailText,
          details: {
            schedule: elm.details.schedule,
            minDistance: elm.details.minDistance,
            maxAge: elm.details.maxAge,
            maxSpeed: elm.details.maxSpeed,
            timespanInSeconds: elm.details.timespanInSeconds,
            geofence: elm.details.geofence,
          },
          contacts: [],
        }
        elm.contacts
          .sort((a, b) => {
            if (a.email === b.email) {
              if (a.phone === b.phone) {
                return a.name < b.name
              }
              return a.phone < b.phone
            }
            return a.email < b.email
          })
          .forEach(ctc => {
            hash.contacts.push({
              name: ctc.name,
              email: ctc.email,
              phone: ctc.phone,
            })
          })
        out.push(hash)
      })
      return out
    },
    setImei(imei) {
      this.$refs['mcFormInstance'].forceReset()
      this.editingRule = {}
      this.imei = imei

      this.loadingState = true
      Promise.all([
        MiscService.alarmContactsDefaultTextsAndFences().then(data => {
          this.alarmContacts = data.contacts
          this.geofences = data.fences
          this.defaultTexts = data.defaultTexts
        }),
        AlarmService.alarmsByImei(this.imei).then(data => {
          this.alarmRules = data.rules
          this.origAlarmRules = [...data.rules]
          this.supported = data.supported

          if (data.insuranceSettings) {
            data.insuranceSettings.contact1.phone = data.insuranceSettings.contact1.phone ? FormatPhone(data.insuranceSettings.contact1.phone) : ''
            data.insuranceSettings.contact2.phone = data.insuranceSettings.contact2.phone ? FormatPhone(data.insuranceSettings.contact2.phone) : ''
          }

          this.insuranceSettings = data.insuranceSettings
          this.origInsuranceSettings = JSON.parse(JSON.stringify(data.insuranceSettings))
        }),
      ])
        .then(data => {
          this.loadingState = false
        })
        .catch(error => {
          this.loadingState = FormatError(error)
        })
    },
    editRule(id) {
      this.editingRuleId = id
      if (id == -1) {
        this.editingRule = {}
      } else {
        this.editingRule = { ...this.alarmRules[id] }
      }
      this.$bvModal.show('trackeralarmform')
    },

    removeRule(id) {
      let newVal = []
      for (var i in this.alarmRules) {
        if (i != id) {
          newVal.push(this.alarmRules[i])
        }
      }
      this.alarmRules = newVal
    },

    ruleEdited(rule) {
      let newVal = [...this.alarmRules]
      if (this.editingRuleId == -1) {
        newVal.push(rule)
      } else {
        newVal[this.editingRuleId] = rule
      }
      this.alarmRules = newVal
    },

    handleReset() {
      this.alarmRules = []
    },

    geofencesEdited(newVal) {
      this.geofences = newVal
    },

    alarmContactsEdited(newVal) {
      this.alarmContacts = newVal
      //TODO: Implement this in lower levels!
    },

    handleSubmit(e) {
      return AlarmService.replaceAlarmsByImei(this.imei, this.alarmRules, this.geofences, this.insuranceSettings).then(
        data => {
          this.origAlarmRules = this.alarmRules
          this.origInsuranceSettings = JSON.parse(JSON.stringify(this.insuranceSettings))
          this.toastSuccess(this.$t('alarms.AlarmsSettingsSaved'))
          return Promise.resolve(data)
        },
        error => {
          console.error('Error saving alarms', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
