<template>
  <div>
    <p class="historyHeading bg-secondary">
      <McButton v-on:click="quitHistory" class="m-0 boxShadow" variant="danger" :icon="'times'" />
      <i18n :path="screenWidth > 800 ? 'map.historyheadingwithname' : 'map.historyheadingnoname'">
        <template v-slot:tracker>
          <em>{{ trackerInfo.name }}</em>
        </template>
        <template v-slot:from>
          <strong>{{ dateToLocalizedHumanreadableDate(MapViewStore.shownHistory.range.from) }}</strong>
        </template>
        <template v-slot:to>
          <strong>{{ dateToLocalizedHumanreadableDate(MapViewStore.shownHistory.range.to) }}</strong>
        </template>
      </i18n>
    </p>
    <div style="width: 80%; margin-left: 10%">
      <HistorySlider @input="updateHistoryMarker" v-model="pointId" :label="currentDate" :max="MapViewStore.shownHistory.points.length - 1" />
    </div>
    <!--pre style="max-height:90px; font-size: 8px;">{{ MapViewStore.shownHistory.points[pointId] }}</pre-->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { dateToLocalizedHumanreadableDate, dateToShortLocalizedHumanreadableDate } from '../helpers/Time'

export default {
  data() {
    return {
      pointId: 0,
      screenWidth: null,
    }
  },

  methods: {
    dateToLocalizedHumanreadableDate(date) {
      return dateToLocalizedHumanreadableDate(date)
    },
    handleResize() {
      this.screenWidth = document.body.scrollWidth
    },
    updateHistoryMarker() {
      let point = this.MapViewStore.shownHistory.points[this.pointId]
      this.showHistoryPoint(point)
    },
    ...mapActions('MapViewStore', [
      'quitHistory', //
      'showHistoryPoint',
    ]),
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  computed: {
    ...mapState({
      MapViewStore: state => state.MapViewStore,
    }),
    currentDate() {
      return dateToShortLocalizedHumanreadableDate(this.MapViewStore.shownHistory.points[this.pointId].datetime)
    },
    imei() {
      return this.MapViewStore.zoomedTracker
    },
    trackerInfo() {
      return this.MapViewStore.trackerList[this.imei]
    },
  },
}
</script>
