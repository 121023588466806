import Vue from 'vue'

import '../assets/bootstrap-theme.scss'

//Lazy way - much larger bundle size
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)

/*
//Somewhat optimized, but still includes lots of unneeded code
import {
  Navbar, //
  Form,
  FormGroup,
  FormInput,
  InputGroup,
  FormCheckbox,
  Button,
} from 'bootstrap-vue/es/components'
Vue.use(Navbar)
Vue.use(Form)
Vue.use(FormGroup)
Vue.use(FormInput)
Vue.use(InputGroup)
Vue.use(FormCheckbox)
Vue.use(Button)
*/

//Manually optimized
/*import bNavbar from 'bootstrap-vue/es/components/navbar/navbar'
Vue.component('b-navbar', bNavbar)
import bNavbarNav from 'bootstrap-vue/es/components/navbar/navbar-nav'
Vue.component('b-navbar-nav', bNavbarNav)
import bNavbarBrand from 'bootstrap-vue/es/components/navbar/navbar-brand'
Vue.component('b-navbar-brand', bNavbarBrand)
import bNavbarToggle from 'bootstrap-vue/es/components/navbar/navbar-toggle'
Vue.component('b-navbar-toggle', bNavbarToggle)

import bNavForm from 'bootstrap-vue/es/components/nav/nav-form'
Vue.component('b-nav-form', bNavForm)
import bNavItem from 'bootstrap-vue/es/components/nav/nav-item'
Vue.component('b-nav-item', bNavItem)
import bNavItemDropdown from 'bootstrap-vue/es/components/nav/nav-item-dropdown'
Vue.component('b-nav-item-dropdown', bNavItemDropdown)
import bDropdownItem from 'bootstrap-vue/es/components/dropdown/dropdown-item'
Vue.component('b-dropdown-item', bDropdownItem)
import bCollapse from 'bootstrap-vue/es/components/collapse/collapse'
Vue.component('b-collapse', bCollapse)

import bForm from 'bootstrap-vue/es/components/form/form'
Vue.component('b-form', bForm)
import bFormGroup from 'bootstrap-vue/es/components/form-group/form-group'
Vue.component('b-form-group', bFormGroup)
import bInputGroup from 'bootstrap-vue/es/components/input-group/input-group'
Vue.component('b-input-group', bInputGroup)
import bInputGroupAppend from 'bootstrap-vue/es/components/input-group/input-group-append'
Vue.component('b-input-group-append', bInputGroupAppend)
import bInputGroupText from 'bootstrap-vue/es/components/input-group/input-group-text'
Vue.component('b-input-group-text', bInputGroupText)
import bFormInput from 'bootstrap-vue/es/components/form-input/form-input'
Vue.component('b-form-input', bFormInput)
import bFormCheckbox from 'bootstrap-vue/es/components/form-checkbox/form-checkbox'
Vue.component('b-form-checkbox', bFormCheckbox)
import bButton from 'bootstrap-vue/es/components/button/button'
Vue.component('b-button', bButton)
import bFormInvalidFeedback from 'bootstrap-vue/es/components/form/form-invalid-feedback'
Vue.component('b-form-invalid-feedback', bFormInvalidFeedback)

import bFormRow from 'bootstrap-vue/es/components/layout/form-row'
Vue.component('b-form-row', bFormRow)
import bCol from 'bootstrap-vue/es/components/layout/col'
Vue.component('b-col', bCol)
import bRow from 'bootstrap-vue/es/components/layout/row'
Vue.component('b-row', bRow)

import bTable from 'bootstrap-vue/es/components/table/table'
Vue.component('b-table', bTable)
*/
