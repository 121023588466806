import { distance, angle } from '../helpers/Geometry'
import store from '../setup/Store'

const getForce = function(strength, angle) {
  return {
    x: strength * Math.cos(angle),
    y: strength * Math.sin(angle),
  }
}
const multiplyVector = function(vector, multiplier) {
  return {
    x: multiplier * vector.x,
    y: multiplier * vector.y,
  }
}
const getRepulsiveForce = function(p1, p2) {
  let dist = distance(p2, p1)
  if (dist > 200) {
    return {
      x: 0,
      y: 0,
    }
  }
  let ang = angle(p1, p2)
  let strength = (200 - dist) * 0.05
  //var strength = 100 / dist
  return getForce(strength, ang)
}
const addForces = function(f1, f2) {
  return {
    x: f1.x + f2.x,
    y: f1.y + f2.y,
  }
}

let prev_frame = false
export default function animateLabels(tracknordicMap) {
  function doAnimate() {
    if (!tracknordicMap.loaded) {
      return
    }
    var cur_frame = new Date().getTime()
    var time_elapsed = Math.min(cur_frame - prev_frame, 0.5)

    const markerIds = Object.keys(tracknordicMap.markers)

    let forces = []
    let i, i1, i2, distFromArrow, angleFromArrow, forceFromArrow, thisLabel, otherLabel, repulsiveForceFromDiv, repulsiveForceFromArrow
    for (i = 0; i < markerIds.length; i++) {
      forces[i] = {
        x: 0,
        y: 0,
      }
    }

    let prioritized = false
    if (store.state.MapViewStore.shownHistory) {
      prioritized = 'history'
    } else if (store.state.MapViewStore.zoomedTracker) {
      prioritized = 'imei_' + store.state.MapViewStore.zoomedTracker
    }

    for (i1 = 0; i1 < markerIds.length; i1++) {
      thisLabel = tracknordicMap.markers[markerIds[i1]]
      if (!thisLabel.arrowPoint) {
        continue
      }

      //Be attracted to 100px from arrowPoint
      if (prioritized === thisLabel.id) {
        let diff = {
          x: (thisLabel.arrowPoint.x - thisLabel.divPoint.x - 80) / 10,
          y: (thisLabel.arrowPoint.y - thisLabel.divPoint.y + 20) / 10,
        }
        forces[i1] = addForces(forces[i1], diff)
      } else {
        distFromArrow = distance(thisLabel.arrowPoint, thisLabel.divPoint)
        angleFromArrow = angle(thisLabel.divPoint, thisLabel.arrowPoint)
        forceFromArrow = getForce((80 - distFromArrow) / 20, angleFromArrow)
        forces[i1] = addForces(forces[i1], forceFromArrow)
      }

      if (prioritized === false) {
        //Be repelled by any other label nearby
        for (i2 = i1 + 1; i2 < markerIds.length; i2++) {
          otherLabel = tracknordicMap.markers[markerIds[i2]]
          if (!otherLabel.arrowPoint) {
            continue
          }
          repulsiveForceFromDiv = getRepulsiveForce(thisLabel.divPoint, otherLabel.divPoint)
          if (prioritized !== thisLabel.id) {
            forces[i1] = addForces(forces[i1], repulsiveForceFromDiv)
          }
          //This one goes both ways!
          if (prioritized !== otherLabel.id) {
            forces[i2] = addForces(forces[i2], multiplyVector(repulsiveForceFromDiv, -1))
          }
          //forces[i1] = addForces(forces[i1], getRepulsiveForce(thisLabel.divPoint, otherLabel.arrowPoint))
        }
        //Be repelled by any other label arrows nearby
        for (i2 = 0; i2 < markerIds.length; i2++) {
          if (i1 === i2) {
            continue
          }
          otherLabel = tracknordicMap.markers[markerIds[i2]]
          if (!otherLabel.arrowPoint) {
            continue
          }
          if (prioritized === thisLabel.id) {
            continue
          }
          repulsiveForceFromArrow = getRepulsiveForce(thisLabel.divPoint, otherLabel.arrowPoint)
          forces[i1] = addForces(forces[i1], multiplyVector(repulsiveForceFromArrow, 0.5))
        }
      }
    }

    for (i1 = 0; i1 < markerIds.length; i1++) {
      thisLabel = tracknordicMap.markers[markerIds[i1]]

      //Add force to velocity
      thisLabel.velocity = addForces(thisLabel.velocity, forces[i1])

      //Add drag to prevent bungy-labels
      thisLabel.velocity = multiplyVector(thisLabel.velocity, 0.8)

      thisLabel.offset.x += thisLabel.velocity.x * time_elapsed
      thisLabel.offset.y += thisLabel.velocity.y * time_elapsed

      thisLabel.draw()
    }
    setTimeout(() => {
      window.requestAnimationFrame(doAnimate)
    }, 50)
  }
  doAnimate()
}
