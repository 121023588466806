<template>
  <!-- eslint-disable-next-line -->
  <McForm
    ref="mcFormInstance"
    :loading="loadingState"
    v-bind="$attrs"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    :title="$t('journal.TrackerJournal')"
    :successText="false"
    :submittext="$t('general.OK')"
    submiticon="check"
  >
    <template v-slot:secondarybuttons><span></span></template>

    <b-table-simple v-if="entries.length > 0" :small="true" stacked="md" striped class="journal-table mt-3 mb-0">
      <b-thead>
        <b-tr>
          <b-th class="text-center">{{ $t('journal.Date') }}</b-th>
          <b-th>{{ $t('journal.Text') }}</b-th>
          <b-th class="text-center"></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="entry in entries" v-bind:key="entry.id">
          <b-td class="text-center align-top">
            <TnLocalTime class="badge badge-dark" :time="entry.dateCreated" format="l LT" />
            <div v-if="entry.dateEdited != entry.dateCreated">
              <span class="badge badge-light">
                Redigeret:
                <br />
                <TnLocalTime :time="entry.dateEdited" format="l LT" />
              </span>
            </div>
          </b-td>
          <b-td v-html="entry.text.replace(/(?:\r\n|\r|\n)/g, '<br />')" />
          <b-td class="text-right">
            <b-button-group>
              <McButton icon="edit" variant="primary" size="sm" @click="editEntry(entry)" />
              <McButton icon="trash" variant="danger" size="sm" @click="deleteEntry(entry)" />
            </b-button-group>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <div class="text-center mt-4"><McButton icon="plus" size="sm" class="mb-2" :text="$t('journal.AddNote')" @click="editEntry({})" /></div>

    <JournalEntryForm :imei="imei" id="journalentryform" isModal :value="editingEntry" @completed="setImei(imei)" />

    <!--pre>{{ entries }}</pre-->
  </McForm>
</template>

<script>
import { mapActions } from 'vuex'
import { FormatError } from '../helpers/ErrorFormatting'
import JournalService from '../services/JournalService'

export default {
  components: {},
  data() {
    return {
      entries: {},
      loadingState: true,
      imei: '',
      editingEntry: {},
    }
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),
    editEntry(item) {
      //console.log(item)
      this.editingEntry = { ...item }
      this.$bvModal.show('journalentryform')
    },
    deleteEntry(item) {
      if (confirm(this.$t('journal.confirmdelete', { name }))) {
        JournalService.deleteNote(this.imei, item.id).then(data => {
          this.setImei(this.imei)
        })
      }
    },

    setImei(imei) {
      this.imei = imei

      this.loadingState = true
      JournalService.getByImei(this.imei)
        .then(data => {
          this.entries = data.entries
        })
        .then(data => {
          this.loadingState = false
        })
        .catch(error => {
          this.loadingState = FormatError(error)
        })
    },

    handleSubmit(e) {
      return Promise.resolve(e)
    },
  },
}
</script>
