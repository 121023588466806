<template>
  <McForm
    ref="mcFormInstance"
    v-bind="$attrs"
    @reset="handleReset"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    :submiticon="'plus'"
    :title="$t('contacts.addCustomer')"
    :submittext="$t('contacts.addCustomer')"
  >
    <ContactFormInner v-model="curData" />

    <b-form-group>
      <b-input-group>
        <b-form-checkbox class="ml" v-model="curData.vatApplies">
          {{ $t('contactform.vatApplies') }}
        </b-form-checkbox>
      </b-input-group>
    </b-form-group>

    <!--pre> {{ curData }} </pre-->
  </McForm>
</template>

<script>
import { mapActions } from 'vuex'
import ContactsService from '../services/ContactsService'

const defaultData = {
  id: -2,
  userId: 0,
  type: 'I',
  name: '',
  company: '',
  cvr: '',
  ean: '',
  reference: '',
  rekvisition: '',
  address1: '',
  address2: '',
  zip: '',
  city: '',
  country: 'Danmark',
  email: '',
  phone: '',
  website: '',
  vatApplies: true,
}

export default {
  components: {},
  data() {
    return {
      curData: { ...defaultData },
    }
  },
  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    handleReset() {
      this.curData = { ...defaultData }
    },

    handleSubmit(e) {
      return ContactsService.createOrUpdate(this.curData).then(
        data => {
          this.toastSuccess(this.$t('contactform.CustomerCreated'))
          return Promise.resolve(data)
        },
        error => {
          console.error('Error creating customer', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
