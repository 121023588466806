import Vue from 'vue'
import Vuex from 'vuex'

import LoginStore from '../store/LoginStore'
import ToastStore from '../store/ToastStore'
import LayoutStore from '../store/LayoutStore'
import LanguageStore from '../store/LanguageStore'
import TrackerStatusStore from '../store/TrackerStatusStore'
import MapViewStore from '../store/MapViewStore'
import GeocodeStore from '../store/GeocodeStore'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    LoginStore,
    ToastStore,
    LayoutStore,
    LanguageStore,
    TrackerStatusStore,
    MapViewStore,
    GeocodeStore,
  },
})
