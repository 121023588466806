<template>
  <div class="billing-invoices-unpaid-wrap">
    <McSpinner v-if="loadingState === true" large class="my-5" />
    <McAlert v-else-if="typeof loadingState === 'string'">
      {{ loadingState }}
    </McAlert>
    <div v-else>
      <b-card class="my-4" v-for="(smsBalance, id) in smsBalances" v-bind:key="id">
        <div>
          <span>{{ $t('billing.currentBalanceColon') }}</span>
          <strong class="ml-4">{{ $t('billing.xSmss', { x: smsBalance.balance }) }}</strong>
          <select style="max-width: 90%;" class="ml-4" v-model="chosenPackage[id]">
            <option :value="null">{{ $t('billing.ChooseAmountToRefill') }}</option>
            <option v-for="(packageInfo, pId) in packages" v-bind:key="pId" :value="packageInfo">{{ $t('billing.BuyNsmsForX', { N: packageInfo.count, X: niceCurrency(smsBalance.vatApplies ? packageInfo.priceInCentsIncVat : packageInfo.priceInCents, currency) }) }}</option>
          </select>
        </div>
        <div v-if="chosenPackage[id]">
          {{ $t('billing.PayXTotalForNSmss', { N: chosenPackage[id].count, X: niceCurrency(chosenPackage[id][smsBalance.vatApplies ? 'priceInCentsIncVat' : 'priceInCents'], currency) }) }}
          <BillingAutopayCards
            :invoiceIds="['sms|' + id + '|' + chosenPackage[id].count]"
            :autopayCards="autopayCards"
            :currency="currency"
            @invoicesUpdated="purchaseCompleted"
            @cardsUpdated="e => $emit('cardsUpdated', e)"
          />
        </div>
        <template v-slot:footer>
          <p>
            {{ $tc('billing.ThisSmsAccountIsSharedFor', smsBalance.trackers.length) }}
            <br v-if="smsBalance.trackers.length > 1" />
            <span v-for="imei in smsBalance.trackers" v-bind:key="imei" class="badge mx-1" :style="{ background: trackerInfo[imei].color, color: contrast(trackerInfo[imei].color) }">
              <McIcon v-if="trackerInfo[imei] && trackerInfo[imei].icon" sized :icon="trackerInfo[imei].icon" />
              <span v-if="trackerInfo[imei]" class="trackerName">{{ trackerInfo[imei].name }}</span>
              <span v-else>{{ imei }}</span>
            </span>
          </p>
        </template>
        <!--pre>{{ smsBalance }}</pre-->
      </b-card>
      <!--pre>{{ smsBalances }}</pre>
      <pre v-if="trackerInfo">{{ trackerInfo }}</pre-->
    </div>
  </div>
</template>
<script>
var Color = require('color')
import { mapState, mapActions } from 'vuex'
import { niceCurrency } from '../helpers/Formatting'
import BillingService from '../services/BillingService'
import TrackerService from './../services/TrackerService'
import { FormatError } from '../helpers/ErrorFormatting'

export default {
  props: {
    currency: {
      type: String,
      default: 'DKK',
    },
    autopayCards: {
      type: [Array, null],
      default: () => null,
    },
  },
  data() {
    return {
      chosenPackage: {},
      loadingState: true,
      smsBalances: null,
      packages: {},
    }
  },
  mounted() {
    Promise.all([this.getBalances(), this.getTrackers()])
      .then(() => {
        this.loadingState = false
        //console.log('done')
      })
      .catch(error => {
        this.loadingState = FormatError(error)
        //console.log('fail')
      })
  },
  computed: {
    ...mapState({
      MapViewStore: state => state.MapViewStore,
    }),
    trackerInfo() {
      return { ...this.MapViewStore.trackerList }
    },
  },
  methods: {
    ...mapActions('MapViewStore', [
      'updateTrackerList', //
    ]),
    purchaseCompleted() {
      this.getBalances()
      this.$emit('invoicesUpdated')
    },
    contrast(color) {
      return Color(color).contrast(Color('#000')) > 12 ? '#000' : '#fff'
    },
    getBalances() {
      return BillingService.smsBalances().then(data => {
        this.smsBalances = data.balances
        for (var id in data.balances) {
          this.$set(this.chosenPackage, id, null)
        }
        this.packages = data.packages
      })
    },
    getTrackers() {
      return TrackerService.list().then(data => {
        this.updateTrackerList({ ...data })
      })
    },
    niceCurrency(amountInCents, currency) {
      return niceCurrency(amountInCents, currency)
    },
  },
}
</script>

<style lang="scss">
.smsBalance_wrap {
  border: 1px solid;
}
</style>
