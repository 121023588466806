export const distance = (p1, p2) => {
  let dx = p1.x - p2.x
  let dy = p1.y - p2.y
  let dist = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2))
  return dist
}

export const angle = (p1, p2) => {
  return Math.atan2(p1.y - p2.y, p1.x - p2.x)
}
