<template>
  <div class="quickpay-popup">
    <TransitionToAuto move v-if="iframeLoaded === false">
      <div class="quickpay-popup-spinner-screen">
        <h2>{{ $t('quickpay.payment') }}</h2>
        <McSpinner large class="my-5" />
        <p>{{ $t('quickpay.onemoment') }}</p>
      </div>
    </TransitionToAuto>
    <iframe ref="paymentframe" :style="link && iframeLoaded ? {} : { height: '1px', opacity: 0 }" @load="hasLoaded" class="quickpay-paymentframe" />
  </div>
</template>

<script>
import BillingService from '../services/BillingService'
import { FormatError } from '../helpers/ErrorFormatting'

export default {
  props: {
    withToken: {
      type: [Object],
      default: () => null,
    },
    withSmsToken: {
      type: [Object],
      default: () => null,
    },
  },
  mounted() {
    window.addEventListener('message', this.handleMessage)
  },
  destroyed() {
    window.removeEventListener('message', this.handleMessage)
  },
  data() {
    return {
      iframeLoaded: false,
      link: null,
      currency: 'DKK',
      subscriptionId: null,
      paymentId: null,
      successEvent: 'cardAdded',
      failureEvent: 'cardNotAdded',
      checkingStartTime: null,
      payInvoicesAfterCompletion: false,
    }
  },
  methods: {
    hasLoaded() {
      if (!this.link) {
        return
      }
      this.iframeLoaded = true
    },
    restart(successEvent, failureEvent) {
      this.iframeLoaded = false
      this.link = null
      this.subscriptionId = null
      this.paymentId = null
      this.successEvent = successEvent
      this.failureEvent = failureEvent
      this.payInvoicesAfterCompletion = false
    },

    addNewCard(currency, invoiceIds) {
      //console.log(invoiceIds)
      this.currency = currency
      if (invoiceIds) {
        this.restart('cardAddedPaymentCompleted', 'paymentNotCompleted')
        this.payInvoicesAfterCompletion = invoiceIds
      } else {
        this.restart('cardAdded', 'cardNotAdded')
      }
      let action
      if (this.withToken) {
        action = BillingService.addAutopayCardByToken(currency, this.withToken.invoice, this.withToken.secret)
      } else if (this.withSmsToken) {
        action = BillingService.addAutopayCardBySmsToken(currency, this.withSmsToken.secret)
      } else {
        action = BillingService.addAutopayCard(currency)
      }
      action
        .then(data => {
          this.subscriptionId = data.subscriptionId
          this.link = data.link
          this.$refs.paymentframe.src = this.link
        })
        .catch(error => {
          alert(FormatError(error))
          this.$emit(this.failureEvent)
          this.$emit('finished')
        })
    },
    payByCard(cardType, currency, invoiceIds) {
      this.currency = currency
      this.restart('paymentCompleted', 'paymentNotCompleted')
      let action
      if (this.withToken) {
        action = BillingService.payByCardByToken(cardType, currency, this.withToken.invoice, this.withToken.secret)
      } else if (this.withSmsToken) {
        action = BillingService.payByCardBySmsToken(cardType, currency, this.withSmsToken.count, this.withSmsToken.secret)
      } else {
        action = BillingService.payByCard(cardType, currency, invoiceIds)
      }
      action
        .then(data => {
          this.paymentId = data.paymentId
          this.link = data.link
          this.$refs.paymentframe.src = this.link
        })
        .catch(error => {
          alert(FormatError(error))
          this.$emit(this.failureEvent)
          this.$emit('finished')
        })
    },
    payByAutopayCard(cardId, currency, invoiceIds) {
      this.currency = currency
      this.restart('paymentCompleted', 'paymentNotCompleted')
      let action
      if (this.withToken) {
        action = BillingService.payByAutopayCardByToken(cardId, currency, this.withToken.invoice, this.withToken.secret)
      } else if (this.withSmsToken) {
        action = BillingService.payByAutopayCardBySmsToken(cardId, currency, this.withSmsToken.count, this.withSmsToken.secret)
      } else {
        action = BillingService.payByAutopayCard(cardId, currency, invoiceIds)
      }
      action
        .then(data => {
          this.paymentId = data.paymentId
          this.checkingStartTime = new Date()
          this.checkCompletion()
        })
        .catch(error => {
          alert(FormatError(error))
          this.$emit(this.failureEvent)
          this.$emit('finished')
        })
    },
    checkCompletion() {
      let action
      if (this.paymentId && this.withToken) {
        action = BillingService.checkPaymentStatusByToken(this.paymentId, this.withToken.invoice, this.withToken.secret)
      } else if (this.paymentId && this.withSmsToken) {
        action = BillingService.checkPaymentStatusBySmsToken(this.paymentId, this.withSmsToken.secret)
      } else if (this.paymentId) {
        action = BillingService.checkPaymentStatus(this.paymentId)
      } else if (this.subscriptionId && this.withToken) {
        action = BillingService.checkSubscriptionStatusByToken(this.subscriptionId, this.withToken.invoice, this.withToken.secret)
      } else if (this.subscriptionId && this.withSmsToken) {
        action = BillingService.checkSubscriptionStatusBySmsToken(this.subscriptionId, this.withSmsToken.secret)
      } else if (this.subscriptionId) {
        action = BillingService.checkSubscriptionStatus(this.subscriptionId)
      } else {
        return
      }
      action
        .then(data => {
          if (data.status == 'wait') {
            let nowDate = new Date()
            if ((nowDate.getTime() - this.checkingStartTime.getTime()) / 1000 < 30) {
              setTimeout(this.checkCompletion, 1000)
            } else {
              alert(this.$t('billing.timeoutWaitingForConfirmation'))
              this.$emit(this.failureEvent)
              this.$emit('finished')
            }
          } else if (data.status == 'completed') {
            if (this.payInvoicesAfterCompletion) {
              this.payByAutopayCard(this.subscriptionId, this.currency, this.payInvoicesAfterCompletion)
              this.$emit('cardAdded')
            } else {
              this.$emit(this.successEvent)
              this.$emit('finished')
            }
          } else if (data.status == 'failed') {
            alert(FormatError('billing.paymentError'))
            this.$emit(this.failureEvent)
            this.$emit('finished')
          } else {
            alert(FormatError('billing.unknownError'))
            this.$emit(this.failureEvent)
            this.$emit('finished')
          }
        })
        .catch(error => {
          alert(FormatError(error))
          this.$emit(this.failureEvent)
          this.$emit('finished')
        })
    },
    handleMessage() {
      if (typeof event.data !== 'object') {
        return
      }
      //console.log('got event', event.data)
      if (event.data.hasOwnProperty('paymentStatus')) {
        setTimeout(() => {
          //Wait a moment before doing this
          this.iframeLoaded = false
        }, 100)
        if (event.data.paymentStatus === 'continue') {
          this.checkingStartTime = new Date()
          this.checkCompletion()
        } else if (event.data.paymentStatus === 'cancel') {
          this.$emit(this.failureEvent)
          this.$emit('finished')
        }
      } else {
        //eslint-disable-next-line
        //console.log(event.data)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.quickpay-paymentframe {
  width: 100%;
  border: none;
  border-top: none;
  display: block;
  height: 650px;
  transition: height 0.2s ease-in-out;
}
.quickpay-popup-spinner-screen {
  text-align: center;
}
</style>
