<template>
  <b-form-group :label="label" :state="!errorHint">
    <b-input-group :class="'mc-form-alarm-contacts'">
      <b-input-group-text v-if="icon" slot="prepend" class="bg-primary text-white">
        <McIcon :icon="icon" />
      </b-input-group-text>
      <!-- prettier-ignore -->
      <div class="custom-file">
        {{ description || placeholder }}
          <ul v-if="this.value.length > 0">
            <li v-for="(contact, id) in this.value" v-bind:key="id" @click="editAlarmContact(id)">
              <span class="contact_label">{{ contact.name }} <br class="d-md-none" /><strong>({{ contact.email ? contact.email : FormatPhone(contact.phone) }})</strong></span>
              <button class="btn ml-1 btn-sm remove_contact" @click.prevent.stop="e => removeAlarmContact(id)"><McIcon icon="times" /></button>
            </li>
          </ul>
          <div class="text-center"><McButton icon="plus" size="sm" class="mb-2 mt-2" :text="$t('alarmContacts.addRecipient')" @click="editAlarmContact(-1)" /></div>
      </div>
    </b-input-group>
    <TransitionToAuto v-if="errorHint">
      <div class="invalid-feedback d-block">{{ errorHint }}</div>
    </TransitionToAuto>
    <AlarmContactForm
      :id="'alarmcontactform' + _uid"
      isModal
      :value="editingAlarmContact"
      :existingContacts="existingContacts"
      :alarmContacts="alarmContacts"
      @alarmContactUpdated="alarmContactUpdated"
    />
  </b-form-group>
</template>

<script>
import { FormatPhone } from '../helpers/Formatting'

export default {
  data() {
    return {
      internalValue: this.value,
      editingAlarmContact: {},
      editingAlarmContactId: -1,
      existingContacts: [],
    }
  },
  watch: {
    internalValue(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.internalValue = val
    },
  },
  props: {
    icon: [String, Array],
    errorHint: String,
    label: String,
    description: String,
    placeholder: String,
    value: Array,
    alarmContacts: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    FormatPhone(phone) {
      return FormatPhone(phone)
    },
    editAlarmContact(id) {
      this.editingAlarmContactId = id
      if (id == -1) {
        this.editingAlarmContact = {}
      } else {
        this.editingAlarmContact = this.internalValue[id]
      }
      this.existingContacts = []
      for (var i in this.internalValue) {
        if (i != id) {
          this.existingContacts.push(this.internalValue[i].email + this.internalValue[i].phone)
        }
      }
      this.$bvModal.show('alarmcontactform' + this._uid)
    },

    removeAlarmContact(id) {
      let newVal = []
      for (var i in this.internalValue) {
        if (i != id) {
          newVal.push(this.internalValue[i])
        }
      }
      this.internalValue = newVal
    },

    alarmContactUpdated(contact) {
      let newVal = [...this.internalValue]
      if (this.editingAlarmContactId == -1) {
        newVal.push(contact)
      } else {
        newVal[this.editingAlarmContactId] = contact
      }
      this.internalValue = newVal
    },
  },
}
</script>

<style lang="scss">
.input-group.mc-form-alarm-contacts {
  > .custom-file {
    height: auto;
    display: block;
    padding: 0 10px;
    overflow: auto;
    border: 1px solid #ced4da;
    border-left: 0;
    border-radius: 0 3px 3px 0;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  ul {
    list-style: none;
    padding: 1em 0;
    margin: 0;
  }
  li {
    margin: 0;
    font-size: 0.8em;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
    > span {
      display: inline-block;
      padding: 0.2em;
      vertical-align: middle;
    }
    &:hover {
      background: #eee;
    }
  }
  button.remove_contact {
    padding: 0.1em;
  }
  li:hover button.remove_contact {
    color: #dc3545;
    &:hover {
      background: #dc3545;
      color: #fff;
    }
  }
}
</style>
