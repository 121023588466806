import AuthenticationService from '../services/AuthenticationService'

import VueCookies from 'vue-cookies'

const token = VueCookies.get('token')
let user = null
let permissions = null

if (token) {
  const userStr = localStorage.getItem('user')
  user = JSON.parse(userStr)
  const permissionsStr = localStorage.getItem('permissions')
  permissions = JSON.parse(permissionsStr)
}

const state = {
  loggingIn: false,
  user: user,
  permissions: permissions,
  token: token,
}

const actions = {
  login({ commit }, { email, password, remember }) {
    commit('loginRequest', { email })

    //The "Remember" setting is stored in localstorage as
    //we may receive a new token at any point...
    localStorage.setItem('remember', remember ? 1 : 0)

    return new Promise((resolve, reject) => {
      AuthenticationService.login(email, password).then(
        data => {
          commit('loginSuccess', data)
          resolve(data)
        },
        error => {
          commit('loginFailure', error)
          reject(error)
        },
      )
    })
  },
  logout({ commit }) {
    commit('logout')
  },
}

const mutations = {
  loginRequest(state) {
    state.loggingIn = true
  },
  loginSuccess(state, data) {
    state.loggingIn = false
    state.user = data.user
    state.permissions = data.permissions
    localStorage.setItem('user', JSON.stringify(data.user))
    localStorage.setItem('permissions', JSON.stringify(data.permissions))
  },
  loginFailure(state) {
    state.loggingIn = false
    state.user = null
    state.permissions = null
  },
  logout(state) {
    state.loggingIn = false
    state.user = null
    state.permissions = null
    VueCookies.remove('token')
    localStorage.removeItem('user')
    localStorage.removeItem('permissions')
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
