<template>
  <McLayoutForm>
    <div v-if="loadingState">
      <McSpinner large />
    </div>
    <div v-else-if="errorState">
      <h2>{{ $t('general.Error') }}</h2>
      <p>{{ $t('general.InvalidLink') }}</p>
    </div>
    <div v-else-if="status === 'paid'">
      <h2>{{ $t('quickpay.payment') }}</h2>
      <div v-if="this.wasUnpaid">
        <p>{{ $t('billing.ThankYouInvoiceWasPaid') }}</p>
        <i18n path="billing.IfYouNeedAnInvoiceClickHere" tag="p">
          <template v-slot:invoiceId>
            <a :href="invoice.download" target="_blank">#{{ invoice.id }}</a>
          </template>
        </i18n>
      </div>
      <div v-else>
        <i18n path="billing.InvoiceAlreadyPaid" tag="p">
          <template v-slot:invoiceId>
            <a :href="invoice.download" target="_blank">#{{ invoice.id }}</a>
          </template>
        </i18n>
      </div>
      <i18n path="billing.YouCanAlwaysFindYourInvoicesUnderBilling" tag="p">
        <template v-slot:link>
          <router-link to="/billing">{{ $t('headings.billing') }}</router-link>
        </template>
      </i18n>
    </div>
    <div v-else-if="status === 'cancelled'">
      <h2>{{ $t('quickpay.payment') }}</h2>
      <i18n path="billing.InvoiceCancelled" tag="p">
        <template v-slot:invoiceId>
          <a :href="invoice.download" target="_blank">#{{ invoice.id }}</a>
        </template>
      </i18n>
      <p>{{ $t('billing.LoginToPanelForMoreInfo') }}</p>
    </div>
    <div v-else>
      <h2>{{ $t('quickpay.payment') }}</h2>
      <i18n path="billing.YouAreAboutToPayInvoice" tag="p">
        <template v-slot:invoiceId>
          <a :href="invoice.download" target="_blank">#{{ invoice.id }}</a>
        </template>
        <template v-slot:amount>
          <strong>{{ niceCurrency(invoice.amountIncVatInCents, invoice.currency) }}</strong>
        </template>
      </i18n>
      <BillingAutopayCards
        style="font-size: 14px"
        :withToken="{
          invoice: $route.params.invoice,
          secret: $route.params.secret,
        }"
        :invoiceIds="[invoice.id]"
        :autopayCards="autopayCards"
        :currency="currency"
        @invoicesUpdated="doUpdate"
        @cardsUpdated="doUpdate"
      />
    </div>
    <!--pre>{{ invoice }}</pre>
    <pre>{{ autopayCards }}</pre-->
  </McLayoutForm>
</template>

<script>
import { FormatError } from '../../helpers/ErrorFormatting'
import BillingService from '../../services/BillingService'
import { niceCurrency } from '../../helpers/Formatting'

export default {
  data() {
    return {
      status: null,
      loadingState: true,
      errorState: false,
      autopayCards: null,
      invoice: null,
      currency: 'DKK', //TODO: Make this switchable
      wasUnpaid: false,
    }
  },
  methods: {
    doUpdate() {
      return BillingService.paymentByToken(this.$route.params.invoice, this.$route.params.secret).then(data => {
        if (data.status == 'unpaid') {
          this.wasUnpaid = true
        }
        this.status = data.status
        this.autopayCards = data.cards
        this.invoice = data.invoice
      })
    },
    niceCurrency(amountInCents, currency) {
      return niceCurrency(amountInCents, currency)
    },
  },
  created() {
    this.doUpdate()
      .then(() => {
        this.loadingState = false
      })
      .catch(error => {
        console.error(FormatError(error))
        this.loadingState = false
        this.errorState = true
      })
  },
}
</script>
