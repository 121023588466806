<template>
  <div class="trackerstatus">
    <McSpinner v-if="!myStatus" />
    <div v-else-if="myStatus.error">
      {{ myError }}
    </div>
    <div v-else :class="full ? 'full' : 'list'">
      <b-button-group class="text-white" v-if="!full">
        <McIcon sized :class="oldClass" :icon="isOld ? ['fad', 'history'] : ['far', 'clock']" :title="oldTitle" />

        <McIcon sized :class="ignitionClass" icon="bolt" :title="ignitionTitle" v-if="typeof myStatus.ignition === 'boolean'" />

        <McIcon sized :class="batteryClass" :icon="batteryIcon" :title="batteryTitle" v-if="myStatus.battery" />

        <McIcon sized :class="chargingClass" :title="chargingTitle" icon="plug" v-if="typeof myStatus.charging === 'boolean'" />

        <McIcon sized :class="signalClass" :title="signalTitle" :icon="signalIcon" v-if="myStatus.signal" />
      </b-button-group>
      <table v-if="full">
        <tr>
          <td>
            <b-button-group class="text-white">
              <McIcon sized :class="oldClass" :icon="isOld ? ['fad', 'history'] : ['far', 'clock']" :title="oldTitle" />
            </b-button-group>
          </td>
          <td>{{ oldTitle }}</td>
        </tr>
        <tr v-if="typeof myStatus.ignition === 'boolean'">
          <td>
            <b-button-group class="text-white">
              <McIcon sized :class="ignitionClass" icon="bolt" :title="ignitionTitle" />
            </b-button-group>
          </td>
          <td>{{ ignitionTitle }}</td>
        </tr>
        <tr v-if="myStatus.battery">
          <td>
            <b-button-group class="text-white">
              <McIcon sized :class="batteryClass" :icon="batteryIcon" :title="batteryTitle" />
            </b-button-group>
          </td>
          <td>{{ batteryTitle }}</td>
        </tr>
        <tr v-if="typeof myStatus.charging === 'boolean'">
          <td>
            <b-button-group class="text-white">
              <McIcon sized :class="chargingClass" :title="chargingTitle" icon="plug" />
            </b-button-group>
          </td>
          <td>{{ chargingTitle }}</td>
        </tr>
        <tr v-if="myStatus.signal">
          <td>
            <b-button-group class="text-white">
              <McIcon sized :class="signalClass" :title="signalTitle" :icon="signalIcon" />
            </b-button-group>
          </td>
          <td>{{ signalTitle }}</td>
        </tr>
        <tr v-if="myStatus.coordinate">
          <td>
            <b-button-group class="text-white">
              <McIcon sized class="bg-primary" :title="$t('trackers.lastknownposition')" :icon="['fad', 'location']" />
            </b-button-group>
          </td>
          <td><tnCoordinate :coordinate="myStatus.coordinate" /></td>
        </tr>
      </table>

      <!--pre style="font-size:8px">{{ myStatus }}</pre-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.list {
  text-align: center;
}
table td:first-child {
  vertical-align: top;
}
</style>

<script>
import { mapActions, mapState } from 'vuex'
import { localizedDatetime, localizedHumanreadableAge } from '../helpers/Time'
import { FormatError } from '../helpers/ErrorFormatting'

export default {
  props: {
    imei: String,
    list: Boolean,
    full: Boolean,
  },
  data() {
    return {
      ticker: 0, //Force refresh every second
      updateTimer: null,
    }
  },
  mounted() {
    this.updateTimer = setInterval(() => {
      this.ticker++ //Force refresh every few seconds
    }, 10000)
    this.subscribe({
      imei: this.imei,
      fields: ['state'],
      elmId: this._uid,
    })
  },
  beforeDestroy() {
    if (this.updateTimer !== null) {
      clearInterval(this.updateTimer)
    }
    this.unsubscribe({
      imei: this.imei,
      elmId: this._uid,
    })
  },
  computed: {
    ...mapState({
      TrackerStatusStore: state => state.TrackerStatusStore.trackers,
      MapViewStore: state => state.MapViewStore,
    }),
    myStatus() {
      return this.TrackerStatusStore[this.imei]
    },
    myError() {
      return FormatError(this.myStatus.error)
    },
    isOld() {
      return this.myStatus.outdated
    },
    oldClass() {
      return this.isOld ? 'bg-danger' : 'bg-success'
    },
    oldTitle() {
      // eslint-disable-next-line
      let dummy = this.ticker //Force refresh every few seconds
      return this.$t(this.isOld ? 'trackers.positionisold' : 'trackers.positionisnotold', {
        ago: this.localizedHumanreadableAge,
        datetime: this.localizedDatetime,
      })
    },

    localizedHumanreadableAge() {
      // eslint-disable-next-line
      let dummy = this.ticker //Force refresh every few seconds
      return localizedHumanreadableAge(this.myStatus.datetime)
    },

    localizedDatetime() {
      // eslint-disable-next-line
      let dummy = this.ticker //Force refresh every few seconds
      return localizedDatetime(this.myStatus.datetime, 'YYYY-MM-DD HH:mm')
    },

    ignitionClass() {
      return this.myStatus.ignition ? 'bg-success' : 'bg-danger'
    },
    ignitionTitle() {
      return this.myStatus.ignition ? this.$t('trackers.ignitionison') : this.$t('trackers.ignitionisoff')
    },
    chargingClass() {
      return this.myStatus.charging ? 'bg-success' : 'bg-danger'
    },
    chargingTitle() {
      return this.myStatus.charging ? this.$t('trackers.ischarging') : this.$t('trackers.isnotcharging')
    },
    batteryClass() {
      if (this.myStatus.battery === 'full') {
        return 'bg-success'
      }
      if (this.myStatus.battery === 'low') {
        return 'bg-danger'
      }
      return 'bg-warning'
    },
    batteryIcon() {
      if (this.myStatus.battery === 'full') {
        return ['fad', 'battery-full']
      }
      if (this.myStatus.battery === 'low') {
        return ['fad', 'battery-empty']
      }
      if (this.myStatus.battery === 'unknown') {
        return 'battery-unknown'
      }
      return ['fad', 'battery-half']
    },
    batteryTitle() {
      if (this.myStatus.battery === 'full') {
        return this.$t('trackers.batteryisfull')
      }
      if (this.myStatus.battery === 'low') {
        return this.$t('trackers.batteryislow')
      }
      if (this.myStatus.battery === 'unknown') {
        return this.$t('trackers.batteryisunknown')
      }
      return this.$t('trackers.batteryishalf')
    },
    signalClass() {
      if (this.myStatus.hasOwnProperty('stalled') && this.myStatus.stalled) {
        return 'bg-danger'
      }
      if (this.myStatus.signal === 'good') {
        return 'bg-success'
      }
      if (this.myStatus.signal === 'bad') {
        return 'bg-danger'
      }
      return 'bg-warning'
    },
    signalIcon() {
      if (this.myStatus.hasOwnProperty('stalled') && this.myStatus.stalled) {
        return ['fad', 'signal-alt-1']
      }
      if (this.myStatus.signal === 'sleep') {
        return 'signal-sleep'
      }
      if (this.myStatus.signal === 'good') {
        return ['fad', 'signal-alt']
      }
      if (this.myStatus.signal === 'bad') {
        return ['fad', 'signal-alt-1']
      }
      if (this.myStatus.signal === 'unknown') {
        return 'signal-unknown'
      }
      return ['fad', 'signal-alt-3']
    },
    signalTitle() {
      if (this.myStatus.hasOwnProperty('stalled') && this.myStatus.stalled) {
        return this.$t('trackers.signalisbad')
      }
      if (this.myStatus.signal === 'sleep') {
        return this.$t('trackers.signalissleep')
      }
      if (this.myStatus.signal === 'good') {
        return this.$t('trackers.signalisgood')
      }
      if (this.myStatus.signal === 'bad') {
        return this.$t('trackers.signalisbad')
      }
      if (this.myStatus.signal === 'unknown') {
        return this.$t('trackers.signalisunknown')
      }
      return this.$t('trackers.signalismedium')
    },
  },
  methods: {
    ...mapActions('TrackerStatusStore', [
      'subscribe', //
      'unsubscribe',
    ]),
  },
}
</script>
