<template>
  <McLayoutDefault :heading="$t('homepage.h1', { name: LoginStore.user.name })">
    <!-- eslint-disable -->
    <div class="text-center">
      <McButton square class="m-2" variant="primary" v-if="$perms.hasTrackersWithPerm('currentPosition') || $perms.isAdministrator()" to="/map" :icon="['fad', 'map-marked-alt']" :text="$t('headings.map')" />
      <McButton square class="m-2" variant="primary" v-if="$perms.isAdministratorOfTrackers() || $perms.hasTrackersWithAnyEditPerms() || $perms.isAdministrator()" to="/trackers" :icon="['fad', 'cars']" :text="$t('headings.trackers')" />
      <McButton square class="m-2" variant="primary" v-if="$perms.hasTrackersWithPerm('mileage') || $perms.isAdministrator()" to="/mileage" :icon="['fad', 'clipboard-list-check']" :text="$t('headings.mileage')" />
      <McButton square class="m-2" variant="primary" v-if="$perms.isAdministratorOfTrackers() || $perms.isAdministrator()" to="/users" :icon="['fad', 'users']" :text="$t('headings.users')" />
      <McButton square class="m-2" variant="primary" v-if="$perms.isAdministratorOfTrackers() || $perms.isAdministrator()" to="/contacts" :icon="['fad', 'users']" :text="$t('headings.contacts')" />
      <McButton square class="m-2" variant="primary" v-if="$perms.isBillingOfTrackers() || $perms.isAdministrator()" to="/billing" :icon="['fad', 'credit-card-front']" :text="$t('headings.billing')" />

      <McButton square class="m-2" variant="primary" to="/profile" :icon="['fad', 'id-card']" :text="$t('headings.Profile')" />
      <McButton square class="m-2" variant="primary" v-if="$perms.isAdministrator()" to="/orders" :icon="['fad', 'file-invoice-dollar']" :text="$t('headings.orders')" />
      <McButton square class="m-2" variant="primary" v-if="$perms.isAdministrator()" to="/mechanics" :icon="['fad', 'car-garage']" :text="$t('headings.mechanics')" />
      <McButton square class="m-2" variant="primary" v-if="$perms.isAdministrator()" to="/newsletter" :icon="['far', 'envelope-open-text']" :text="$t('headings.newsletter')" />
      <McButton square class="m-2" variant="primary" v-if="$perms.isAdministrator()" to="/status-message" :icon="['far', 'heartbeat']" :text="$t('headings.statusMessage')" />
    </div>
    <!-- eslint-enable -->
    <UnhandledOrders v-if="$perms.isAdministrator()" />
    <InsuranceTests v-if="$perms.isAdministrator()" />
  </McLayoutDefault>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      LoginStore: state => state.LoginStore,
    }),
  },
}
</script>
