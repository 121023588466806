<template>
  <McLayoutDefault :heading="$t('headings.contacts')">
    <!--b-card :title="$t('permissions.LackingPermissions')" class="mb-2" v-if="!$perms.isAdministratorOfTrackers() && !$perms.isAdministrator()">
      <p>{{ $t('permissions.LackingPermissionsLonger') }}</p>
    </b-card-->
    <div v-if="!loaded">
      <McSpinner large />
    </div>
    <McTable v-else class="contacts-table" ref="contactsTable" :sortBy="sortBy" :primaryKey="primaryKey" :fields="fields" :provider="dataProvider">
      <McButton @click="editContact({})" variant="primary" icon="plus" :text="$t('contacts.add')" />
      <template v-slot:cell(pre)="data">
        <pre>{{ data.item }}</pre>
      </template>
      <template v-slot:cell(userName)="data">
        {{ data.item.userName }}<br />
        <small>({{ data.item.userEmail }})</small>
      </template>
      <template v-slot:cell(billingOwner)="data">
        <div v-if="data.item.ownerOf.length">
          {{ $t('contacts.OwnerOfColon') }}
          <ul class="billingOwnerList">
            <li v-for="imei in data.item.ownerOf" v-bind:key="imei">
              <McIcon v-if="trackerInfo[imei] && trackerInfo[imei].icon" sized :style="{ color: trackerInfo[imei].color }" :icon="trackerInfo[imei].icon" />
              <span v-if="trackerInfo[imei]" class="trackerName">{{ trackerInfo[imei].name }}</span>
              <span v-else>{{ imei }}</span>
            </li>
          </ul>
        </div>
        <div v-if="data.item.billingOf.length">
          {{ $t('contacts.BillingOfColon') }}
          <ul class="billingOwnerList">
            <li v-for="imei in data.item.billingOf" v-bind:key="imei">
              <McIcon v-if="trackerInfo[imei] && trackerInfo[imei].icon" sized :style="{ color: trackerInfo[imei].color }" :icon="trackerInfo[imei].icon" />
              <span v-if="trackerInfo[imei]" class="trackerName">{{ trackerInfo[imei].name }}</span>
              <span v-else>{{ imei }}</span>
            </li>
          </ul>
        </div>
      </template>
      <template v-slot:cell(fullAddress)="data">
        <ContactAddress :contact="data.item" />
      </template>
      <template v-slot:cell(contactInfo)="data">
        <ContactInfo :contact="data.item" />
      </template>

      <template v-slot:cell(actions)="data">
        <b-button-group>
          <!-- eslint-disable-next-line -->
          <McButton
            icon="edit"
            variant="secondary"
            @click="editContact(data.item)"
            size="sm"
          />
          <McButton icon="trash" variant="danger" size="sm" @click="deletecontact(data.item.id, data.item.name)" v-if="data.item.billingCount == 0 && data.item.ownerCount == 0" />
        </b-button-group>
      </template>
    </McTable>
    <ContactForm id="contactform" isModal :value="editingContact" @completed="refreshTable()" />
  </McLayoutDefault>
</template>

<style lang="scss">
.billingOwnerList {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>

<script>
import { mapState, mapActions } from 'vuex'
import ContactsService from './../../services/ContactsService'
import TrackerService from './../../services/TrackerService'

export default {
  mounted() {
    TrackerService.list().then(data => {
      this.updateTrackerList({ ...data })
      this.loaded = true
    })
  },
  data() {
    return {
      primaryKey: 'imei',
      sortBy: 'name',
      dataProvider: ContactsService.query,
      editingContact: {},
      loaded: false,
    }
  },
  computed: {
    ...mapState({
      MapViewStore: state => state.MapViewStore,
    }),
    fields() {
      let retval = []
      if (this.$perms.isAdministrator()) {
        retval.push({ key: 'userName', priority: 0, mw: 200, sortable: true, label: this.$t('general.adminUser') })
      }
      retval.push({ key: 'contactInfo', mw: 250, sortable: false, label: this.$t('contacts.contactInfo') })
      retval.push({ key: 'fullAddress', mw: 250, sortable: false, label: this.$t('contacts.details') })
      retval.push({ key: 'billingOwner', priority: 10, mw: 200, label: this.$t('contacts.billingOwner') })
      //retval.push({ key: 'pre', priority: 0, mw: 200, label: 'pre' })
      retval.push({ key: 'actions', priority: 0, mw: 200, label: this.$t('general.Actions'), class: 'text-right' })
      return retval
    },
    trackerInfo() {
      return { ...this.MapViewStore.trackerList }
    },
  },
  methods: {
    ...mapActions('MapViewStore', [
      'updateTrackerList', //
    ]),
    refreshTable() {
      this.$refs.contactsTable.refresh()
    },
    editContact(item) {
      //console.log(item)
      this.editingContact = { ...item }
      this.$bvModal.show('contactform')
    },
    deletecontact(id, name) {
      if (confirm(this.$t('contacts.confirmdelete', { name }))) {
        ContactsService.delete(id).then(data => {
          this.refreshTable()
        })
      }
    },
  },
}
</script>
