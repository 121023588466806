import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const requireComponent = require.context('../components', false, /[\w]+\.vue$/)

requireComponent.keys().forEach(filename => {
  const componentConfig = requireComponent(filename)

  const componentName = upperFirst(camelCase(filename.replace(/^\.\//, '').replace(/\.\w+$/, '')))

  Vue.component(componentName, componentConfig.default || componentConfig)
})

const requireTransition = require.context('../transitions', false, /[\w]+\.vue$/)

requireTransition.keys().forEach(filename => {
  const componentConfig = requireTransition(filename)

  const componentName = upperFirst(camelCase(filename.replace(/^\.\//, '').replace(/\.\w+$/, '')))

  Vue.component(componentName, componentConfig.default || componentConfig)
})

const requireForm = require.context('../forms', false, /[\w]+\.vue$/)

requireForm.keys().forEach(filename => {
  const componentConfig = requireForm(filename)

  const componentName = upperFirst(camelCase(filename.replace(/^\.\//, '').replace(/\.\w+$/, '')))

  Vue.component(componentName, componentConfig.default || componentConfig)
})

import datePicker from 'vue-bootstrap-datetimepicker'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
Vue.use(datePicker)

import VueCurrencyInput from 'vue-currency-input'
const pluginOptions = {
  globalOptions: { currency: null },
}
Vue.use(VueCurrencyInput, pluginOptions)

import 'vue-wysiwyg/dist/vueWysiwyg.css'
import wysiwyg from 'vue-wysiwyg'
Vue.use(wysiwyg, {})

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
