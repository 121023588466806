<template>
  <div>
    <!-- eslint-disable-next-line -->
    <McForm
      ref="mcFormInstance"
      v-bind="$attrs"
      :onSubmit="handleSubmit"
      :submittext="$t('general.Download')"
      :submiticon="['fad', 'cloud-download-alt']"
      :successText="false"
      :progressText="progressText"
    >
      <McFormDateRange :label="$t('mileage.ChooseDateRange')" v-model="curData.dateRange" :min="minDate" :max="maxDate" />

      <mc-form-select
        name="minDistance"
        v-model="curData.minDistance"
        :label="$t('general.minDistance')"
        :placeholder="$t('general.chooseMinDistance')"
        :description="$t('mileage.chooseMinDistanceForMileageDisplay')"
        key="mileageexportform.minDistance"
        :errorHint="errors.first('minDistance')"
        :state="!errors.has('minDistance')"
        :options="minDistances"
        :icon="['far', 'ruler']"
      />

      <mc-form-select
        name="format"
        v-model="curData.format"
        :label="$t('general.Format')"
        :placeholder="$t('general.ChooseFormat')"
        key="mileageexportform.format"
        :errorHint="errors.first('format')"
        :state="!errors.has('format')"
        :options="[
          { value: 'pdf', label: $t('mileage.PDFFormat') },
          { value: 'csv', label: $t('mileage.CSVFormat') },
        ]"
        :icon="['fas', 'file']"
      />

      <mc-form-checkboxes name="type" v-model="curData.type" :label="$t('mileage.TripType')" key="mileageexportform.type" :options="typeOptions" :icon="['fas', 'car']" inline />

      <mc-form-checkboxes name="other" v-model="curData.other" :label="$t('mileage.OtherSettings')" key="mileageexportform.other" :options="otherOptions" multiple :icon="['fad', 'clipboard-list-check']">
        <template slot="distanceRadios">
          <div class="distance-radios" v-if="curData.other.includes('showDistances') && curData.format == 'csv'">
            <label><input type="radio" name="mileageExportDistanceUnit" value="m" v-model="curData.distanceUnit" />{{ $t('mileage.distanceUnit.m') }}</label>
            <label><input type="radio" name="mileageExportDistanceUnit" value="km" v-model="curData.distanceUnit" />{{ $t('mileage.distanceUnit.km') }}</label>
          </div>
        </template>
      </mc-form-checkboxes>
    </McForm>
  </div>
</template>

<script>
import moment from 'moment'
import MileageService from '../services/MileageService'
import { mysqlTimeStringFromDate } from '../helpers/Time'
const defaultData = {
  format: 'pdf',
  dateRange: {
    from: moment()
      .startOf('month')
      .toDate(),
    to: moment()
      .endOf('day')
      .toDate(),
  },
  minDistance: 500,
  type: 'all',
  distanceUnit: 'km',
  other: ['showAdjustments', 'showNotes', 'showDistances', 'showTotals', 'distancesInKm'],
}

export default {
  components: {},
  data() {
    return {
      progressText: false,
      curData: { ...defaultData },
      typeOptions: [
        { value: 'all', label: this.$t('mileage.AllTrips') }, //
        { value: 'business', label: this.$t('mileage.BusinessTrips') },
        { value: 'private', label: this.$t('mileage.PrivateTrips') },
      ],
      minDistances: [
        { value: 0, label: this.$t('general.minDistanceAnyMovement') },
        { value: 250, label: this.$t('general.minDistance250') },
        { value: 500, label: this.$t('general.minDistance500') },
        { value: 1000, label: this.$t('general.minDistance1000') },
      ],
      minDate: moment(new Date())
        .subtract(18, 'month')
        .startOf('day')
        .toDate(),
      maxDate: moment(new Date())
        .endOf('day')
        .toDate(),
    }
  },
  computed: {
    otherOptions() {
      let retval = []
      retval.push({ value: 'showAdjustments', label: this.$t('mileage.ShowAdjustments') })
      retval.push({ value: 'showNotes', label: this.$t('mileage.ShowNotesColumn') })
      retval.push({ value: 'showDistances', label: this.$tc('mileage.ShowDistances'), slotname: 'distanceRadios' })
      retval.push({ value: 'showDurations', label: this.$tc('mileage.ShowDurations') })
      if (this.curData.format == 'pdf') {
        retval.push({ value: 'showTotals', label: this.$tc('mileage.ShowTotals') })
      } else {
        retval.push({ value: 'showCoordinates', label: this.$tc('mileage.ShowCoordinates') })
      }
      if (this.curData.type == 'all') {
        retval.push({ value: 'showPauses', label: this.$tc('mileage.ShowPauses') })
      }
      if (this.curData.type != 'all') {
        retval.push({ value: 'showSummaryOfOtherTypes', label: this.$t('mileage.ShowSummaryOfOtherTrips') })
      }
      return retval
    },
  },
  props: {
    imei: {
      type: String,
      default: null,
    },
  },

  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    handleReset() {
      // Don't reset this one
    },

    handleSubmit(e) {
      this.progressText = this.progressText ? this.progressText : this.$t('general.PleaseWait')
      return MileageService.export(
        this.imei, //
        mysqlTimeStringFromDate(this.curData.dateRange.from),
        mysqlTimeStringFromDate(this.curData.dateRange.to),
        this.curData.minDistance,
        this.curData.type,
        this.curData.other,
        this.curData.format,
        this.curData.distanceUnit,
      ).then(
        data => {
          if (data.downloadUrl) {
            window.location = data.downloadUrl
            this.progressText = false
          } else {
            this.progressText = this.$t('mileage.PleaseWaitWhileFetchingAddresses', { remainingAddresses: data.remainingAddresses })
            return this.handleSubmit(e)
          }
        },
        error => {
          this.progressText = false
          console.error('Error generating export', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>

<style>
.distance-radios {
  margin-left: 1.5em;
}
.distance-radios label {
  display: inline-block !important;
}
</style>
