import { authorizedApiRequest, cachedApiRequest } from '../setup/ApiService'

export default {
  contactsAndUsers: function() {
    return authorizedApiRequest(
      '/misc/contacts-and-users', //
      'GET',
    )
  },

  alarmContactsDefaultTextsAndFences: function() {
    return authorizedApiRequest(
      '/misc/alarm-contacts-default-texts-and-fences', //
      'GET',
    )
  },

  geocode: function(coordinate) {
    return authorizedApiRequest(
      '/misc/geocode', //
      'POST',
      {
        coordinate,
      },
    )
  },

  trackerTypes: function() {
    return cachedApiRequest(
      '/misc/tracker-types', //
      'GET',
    )
  },

  getStatusMessage: function() {
    return authorizedApiRequest(
      '/misc/status-message', //
      'GET',
    )
  },
}
