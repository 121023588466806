import { authorizedApiRequest, queryParameters, addLanguageAndTimezone } from '../setup/ApiService'

export default {
  query,
  createOrUpdate,
  delete: _delete,
}

function query(params) {
  return authorizedApiRequest(
    '/contacts?' + queryParameters(params), //
    'GET',
  )
}

function createOrUpdate(contact) {
  return authorizedApiRequest(
    `/contacts/${contact.id}`, //
    'PUT',
    addLanguageAndTimezone(contact),
  )
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return authorizedApiRequest(
    `/contacts/${id}`, //
    'DELETE',
  )
}
