export const strHash = function(str) {
  // https://stackoverflow.com/a/7616484/3631281
  var hash = 0
  for (var i = 0; i < str.length; i++) {
    var chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0
  }
  return hash
}

export const objHash = function(obj) {
  return strHash(JSON.stringify(obj))
}
