var Color = require('color')
import { i18n } from '../setup/i18n'
import store from '../setup/Store'

const createTracknordicMarker = ({ OverlayView = google.maps.OverlayView, ...args }) => {
  class tracknordicMarker extends OverlayView {
    constructor() {
      super()
      this.setMap(args.map)

      this.id = args.id
      this.arrowPoint = null
      let angle = Math.random() * Math.PI * 2
      this.offset = { x: 80 * Math.cos(angle), y: 80 * Math.sin(angle) }
      this.force = { x: 0, y: 0 }
      this.velocity = { x: 0, y: 0 }

      Object.defineProperty(this, 'divPoint', {
        get: () => {
          if (!this.arrowPoint) {
            return null
          }
          return {
            x: this.offset.x + this.arrowPoint.x,
            y: this.offset.y + this.arrowPoint.y,
          }
        },
      })

      this.internal_status = args.status || {}
      Object.defineProperty(this, 'status', {
        get: () => {
          return this.internal_status || {}
        },
        set: newStatus => {
          this.internal_status = newStatus
          this.updateElements()
        },
      })

      this.updateStatus = function(newStatus) {
        for (let statusType in newStatus) {
          let newValue = newStatus[statusType]
          switch (statusType) {
            case 'speed':
              this.internal_status.speed = newValue
              break
            case 'ignition':
              this.internal_status.ignition = newValue
              break
            case 'subLabel':
              if (typeof newValue === 'string') {
                this.internal_status.subLabel = newValue
              } else if (this.internal_status.hasOwnProperty('subLabel')) {
                delete this.internal_status.subLabel
              }
              break
            case 'trail':
              this.internal_status.trail = newValue
              break
            case 'direction':
              this.internal_status.direction = newValue
              break
            case 'coordinate':
              var newLatLng = new google.maps.LatLng(newValue)
              if (this.internal_status.latlng) {
                var oldpos = this.getProjection().fromLatLngToDivPixel(this.internal_status.latlng)
                var newpos = this.getProjection().fromLatLngToDivPixel(newLatLng)
                var difference = {
                  x: oldpos.x - newpos.x,
                  y: oldpos.y - newpos.y,
                }

                this.offset.x += difference.x
                this.offset.y += difference.y
              }

              this.internal_status.coordinate = newValue
              this.internal_status.latlng = newLatLng
              break
          }
        }
        this.updateElements()
        this.draw()
      }

      this.internal_color = args.color
      this.contrast = Color(this.internal_color).contrast(Color('#000')) > 12 ? '#000' : '#fff'
      Object.defineProperty(this, 'color', {
        get: () => {
          return this.internal_color
        },
        set: newColor => {
          this.internal_color = newColor
          this.contrast = Color(this.internal_color).contrast(Color('#000')) > 12 ? '#000' : '#fff'
          this.updateElements()
        },
      })

      this.internal_name = args.name
      Object.defineProperty(this, 'name', {
        get: () => {
          return this.internal_name
        },
        set: newName => {
          this.internal_name = newName
          this.updateElements()
        },
      })

      this.internal_icon = args.icon
      Object.defineProperty(this, 'icon', {
        get: () => {
          return this.internal_icon
        },
        set: newIcon => {
          this.internal_icon = newIcon
          this.updateElements()
        },
      })

      this.internal_html = args.html
      Object.defineProperty(this, 'html', {
        get: () => {
          if (this.internal_html) {
            return this.internal_html
          }
          let html = `<div class="trackerLabel" title="` + i18n.t('map.clickfordetails') + `">`
          html += `<span class="trackerLabel__icon colorbg contrastfg">`
          html += `<i class="fas fa-` + this.icon + `"></i>`
          html += `</span>`
          html += `<div class="trackerLabel__details colorborder">`
          html += '<span class="trackerLabel__title">' + this.name + '</span>'
          if (this.status.hasOwnProperty('subLabel')) {
            html += '<span>' + this.status.subLabel + '</span>'
          } else {
            let ignitionColor = '#000'
            if (this.status.hasOwnProperty('ignition')) {
              ignitionColor = '#d00'
              if (this.status.ignition) {
                ignitionColor = '#0d0'
              }
            }
            html += '<span style="color:' + ignitionColor + '">'
            if (this.status.speed > 5) {
              html += '<span class="trackerLabel__speed">' + i18n.t('trackers.speedkmh', { speed: Math.round(this.status.speed) })
              html += '&nbsp; &nbsp; <span class="trackerLabel__direction" style="transform:rotate(' + Math.round(this.status.direction) + 'deg)"><i class="fas fa-arrow-up"></i></span>'
              html += '</span>'
            } else if (this.status.hasOwnProperty('ignition')) {
              if (this.status.ignition) {
                html += '<span class="trackerLabel__speed">' + i18n.t('trackers.ignitionison') + '</span>'
              } else {
                html += '<span class="trackerLabel__speed">' + i18n.t('trackers.stopped') + '</span>'
                //html += '<span class="trackerLabel__speed">' + i18n.t('trackers.ignitionisoff') + '</span>'
              }
            } else {
              html += '<span class="trackerLabel__speed">' + i18n.t('trackers.stopped') + '</span>'
            }
            html += '</span>'
          }
          html += `</div>`
          html += `</div>`

          return html
        },
        set: newHTML => {
          this.internal_html = newHTML
          this.updateElements()
        },
      })
    }

    updateArrowPoint() {
      if (this.internal_status.hasOwnProperty('latlng')) {
        this.arrowPoint = this.getProjection().fromLatLngToDivPixel(this.internal_status.latlng)
      }
    }

    getCenterPoint() {
      return this.getProjection().fromLatLngToDivPixel(this.map.getCenter())
    }

    updateElements() {
      if (this.div) {
        this.div.innerHTML = this.html
        this.div.querySelectorAll('.colorbg').forEach(elm => (elm.style.backgroundColor = this.color))
        this.div.querySelectorAll('.colorfg').forEach(elm => (elm.style.color = this.color))
        this.div.querySelectorAll('.colorborder').forEach(elm => (elm.style.borderColor = this.color))
        this.div.querySelectorAll('.contrastbg').forEach(elm => (elm.style.backgroundColor = this.contrast))
        this.div.querySelectorAll('.contrastfg').forEach(elm => (elm.style.color = this.contrast))
        this.div.querySelectorAll('.contrastborder').forEach(elm => (elm.style.borderColor = this.contrast))
      }
      if (this.trail) {
        this.trail.setPath(this.trailCoords())
        this.trail.setOptions({ strokeColor: this.color })
      }
    }

    trailCoords() {
      if (!this.status.hasOwnProperty('trail')) {
        return []
      }
      return Object.values(this.status.trail)
    }

    addElements() {
      const panes = this.getPanes()
      if (panes) {
        this.div = document.createElement('div')
        if (this.id) {
          this.div.id = 'tnLabel___' + this.id
        }
        this.div.className = 'tnLabel_div'
        this.div.style.position = 'absolute'
        this.div.style.transform = 'translate(-50%, -50%)'
        this.div.style.zIndex = '8'

        this.trail = new google.maps.Polyline({
          geodesic: true,
          strokeColor: this.color,
          strokeOpacity: 1.0,
          strokeWeight: 3,
          zIndex: 1,
          clickable: false,
        })
        this.trail.setMap(this.map)

        this.updateElements()

        let draggedFrom = false
        const startMousing = e => {
          //console.log('mousedown', e)
          draggedFrom = {
            x: e.screenX,
            y: e.screenY,
          }
        }
        const endMousing = e => {
          let dragDist = Math.max(Math.abs(draggedFrom.x - e.screenX), Math.abs(draggedFrom.y - e.screenY))
          if (dragDist < 5) {
            e.preventDefault()
            e.stopPropagation()
            e.labelId = this.id
            google.maps.event.trigger(this.map, 'clickLabel', e)
          }
          draggedFrom = false
        }

        google.maps.event.addDomListener(this.div, 'touchstart', e => {
          e.screenX = event.changedTouches[event.changedTouches.length - 1].pageX
          e.screenY = event.changedTouches[event.changedTouches.length - 1].pageY
          startMousing(e)
        })
        google.maps.event.addDomListener(this.div, 'mousedown', startMousing)
        google.maps.event.addDomListener(this.div, 'touchend', e => {
          e.screenX = event.changedTouches[event.changedTouches.length - 1].pageX
          e.screenY = event.changedTouches[event.changedTouches.length - 1].pageY
          endMousing(e)
        })
        google.maps.event.addDomListener(this.div, 'mouseup', endMousing)

        google.maps.event.addDomListener(this.div, 'mouseover', function(event) {
          this.classList.add('hovered')
          this.style.zIndex = 9
        })
        google.maps.event.addDomListener(this.div, 'mouseout', function(event) {
          this.classList.remove('hovered')
          this.style.zIndex = 8
        })

        this.arrow = document.createElement('div')
        this.arrow.className = 'tnLabel__arrow'
        this.arrow.style.position = 'absolute'
        this.arrow.style.transform = 'translate(-50%, -50%)'
        this.arrow.style.zIndex = 7

        panes.overlayMouseTarget.appendChild(this.div)
        panes.overlayMouseTarget.appendChild(this.arrow) //Yes, it needs to be in the mousetarget layer - to be on top of polylines!
      }
    }

    draw() {
      if (!this.status.hasOwnProperty('coordinate')) {
        return
      }
      if (!this.div) {
        this.addElements()
      }
      if (this.div) {
        let prioritized = false
        if (store.state.MapViewStore.shownHistory) {
          prioritized = 'history'
        } else if (store.state.MapViewStore.zoomedTracker) {
          prioritized = 'imei_' + store.state.MapViewStore.zoomedTracker
        }

        if (prioritized === this.id) {
          this.div.classList.add('zoomed')
          this.arrow.classList.add('zoomed')
          this.div.classList.remove('notZoomed')
          this.arrow.classList.remove('notZoomed')
          this.trail.setOptions({ strokeOpacity: 1 })
        } else if (prioritized) {
          this.div.classList.add('notZoomed')
          this.arrow.classList.add('notZoomed')
          this.div.classList.remove('zoomed')
          this.arrow.classList.remove('zoomed')
          this.trail.setOptions({ strokeOpacity: 0 })
        } else {
          this.div.classList.remove('notZoomed')
          this.div.classList.remove('zoomed')
          this.arrow.classList.remove('notZoomed')
          this.arrow.classList.remove('zoomed')
          this.trail.setOptions({ strokeOpacity: 1 })
        }
        this.updateArrowPoint()
        this.drawDiv()
        this.drawArrow()
      }
    }
    drawDiv() {
      const divpoint = this.divPoint

      this.div.style.left = Math.round(divpoint.x) + 'px'
      this.div.style.top = Math.round(divpoint.y) + 'px'
    }
    drawArrow() {
      const arrowpoint = this.arrowPoint
      const divpoint = this.divPoint

      this.arrow.style.left = `${arrowpoint.x}px`
      this.arrow.style.top = `${arrowpoint.y}px`
      let newH = Math.ceil(Math.abs(this.offset.y) * 2 + 40)
      let newW = Math.ceil(Math.abs(this.offset.x) * 2 + 40)
      this.arrow.style.height = newH + 'px'
      this.arrow.style.width = newW + 'px'

      let angle = (Math.atan2(this.offset.y, this.offset.x) * 180) / Math.PI
      let angle1 = angle + 40
      let angle2 = angle - 40

      let shape = [
        'M',
        newW / 2,
        newH / 2,
        newW / 2 + divpoint.x - arrowpoint.x,
        newH / 2 + divpoint.y - arrowpoint.y,
        'M',
        Math.cos((Math.PI * angle1) / 180) * 10 + newW / 2,
        Math.sin((Math.PI * angle1) / 180) * 10 + newH / 2,
        newW / 2,
        newH / 2,
        Math.cos((Math.PI * angle2) / 180) * 10 + newW / 2,
        Math.sin((Math.PI * angle2) / 180) * 10 + newH / 2,
        newW / 2,
        newH / 2,
      ].join(' ')

      let lines = `<svg style="filter: drop-shadow(0.5px 1.5px 1px rgba(0, 0, 0, .3));" width="` + newW + `" height="` + newH + `" viewBox="0 0 ` + newW + ` ` + newH + `">`
      //lines += `<defs><filter id="shadow"><feDropShadow dx=".5" dy="1.5" stdDeviation="1"/></filter></defs>`
      lines += `<path d="` + shape + `" stroke-width="3" stroke="` + this.color + `"/>`
      lines += `</svg>`

      this.arrow.innerHTML = lines
    }

    remove() {
      if (this.div) {
        this.div.parentNode.removeChild(this.div)
        this.div = null
      }
      if (this.arrow) {
        this.arrow.parentNode.removeChild(this.arrow)
        this.arrow = null
      }
      if (this.trail) {
        this.trail.setMap(null)
        this.trail = null
      }
      this.setMap(null)
    }
  }
  return new tracknordicMarker()
}

export default createTracknordicMarker
