<template>
  <McForm
    ref="mcFormInstance"
    v-bind="$attrs"
    @reset="handleReset"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    :submiticon="isEditing ? 'edit' : 'plus'"
    :title="isEditing ? $t('geofences.EditGeofence') : $t('geofences.AddGeofence')"
    :submittext="isEditing ? $t('general.Save') : $t('geofences.AddGeofence')"
  >
    <mc-form-input
      name="name"
      type="text"
      v-model="curData.name"
      v-validate="'required|max:64'"
      :data-vv-as="$t('geofences.Name')"
      :label="$t('geofences.Name')"
      :placeholder="$t('geofences.EnterName')"
      key="geofenceform.name"
      :errorHint="errors.first('name')"
      :state="!errors.has('name')"
      icon="signature"
    />

    <!--pre style="height: 100px;overflow:auto;">{{ curData }}</pre><pre style="height: 100px;overflow:auto;">{{ drawnCircles.length }}</pre-->

    <div v-html="$t('geofences.HelpText')" />
    <div class="geofencemap_wrapper">
      <div ref="geofenceMap" class="geofence_editor_map_elm mb-2"></div>
      <div class="map_buttons">
        <div v-if="!isAdding">
          <McButton variant="primary" :text="$t('geofences.AddCircle')" :icon="['fas', 'plus']" @click="startAddingCircle" />
          <address-autocomplete id="geofencemapautocomplete" classname="form-control" :placeholder="$t('general.AddAddress')" @placechanged="placeAddressRing" />
        </div>
        <div class="geofence_editor_adding_help" v-else>
          {{ $t('geofences.HelpTextAdding') }}
        </div>
      </div>
    </div>
  </McForm>
</template>

<script>
import gmapsInit from '../map/googleMaps'
import { isEmptyObject, userCoordinates } from '../helpers/Misc'

const defaultData = {
  circles: [],
  name: '',
}

export default {
  components: {},
  data() {
    return {
      curData: { ...defaultData },
      isEditing: false,
      map: null,
      drawnCircles: [],
      link: null,
      isAdding: false,
    }
  },
  props: {
    value: Object,
  },
  watch: {
    value(val) {
      this.handleNewValue(val)
    },
  },
  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    startAddingCircle() {
      if (this.curData.circles.length < 10) {
        this.isAdding = true
      } else {
        alert(this.$t('geofences.YouCanAddAtMost10Circles'))
      }
    },
    placeAddressRing(e) {
      if (e.types.includes('route')) {
        alert(this.$t('general.EnterHouseNumber'))
        return
      }
      let centerPoint = {
        lat: e.geometry.location.lat(),
        lng: e.geometry.location.lng(),
      }
      let newCircle = new google.maps.Circle({
        radius: 200,
        center: centerPoint,
        geodesic: true,
        fillColor: '#0000FF',
        fillOpacity: 0.2,
        strokeColor: '#0000FF',
        strokeOpacity: 1.0,
        strokeWeight: 1,
        zIndex: 999,
      })
      newCircle.setMap(this.map)
      this.drawnCircles.push(newCircle)
      this.handleChange()
      //console.log(center, size)
    },

    handleNewValue(val) {
      this.$refs['mcFormInstance'].forceReset()

      //If empty object passed, we are not editing an existing object
      this.isEditing = !isEmptyObject(val)

      let newVal = {}
      for (var field in defaultData) {
        newVal[field] = val.hasOwnProperty(field) ? val[field] : defaultData[field]
      }
      this.curData = newVal

      this.drawnCircles = []

      setTimeout(() => {
        try {
          this.loadMap(this.$refs['geofenceMap']).then(() => {
            this.isAdding = false
            let centerPoint = false
            let elasticCircle = null
            let hoveredCircleId = null
            var overlay = new google.maps.OverlayView()
            overlay.draw = function() {}
            overlay.setMap(this.map)

            var bounds = new google.maps.LatLngBounds()
            if (this.curData.circles.length == 0) {
              userCoordinates().then(userCoords => {
                let gC = new google.maps.Circle({
                  radius: 10000,
                  center: {
                    lat: userCoords.lat,
                    lng: userCoords.lng,
                  },
                })
                bounds = gC.getBounds()
                this.map.fitBounds(bounds)
              })
            } else {
              for (let i in this.curData.circles) {
                let circle = this.curData.circles[i]
                //console.log(circle)
                let gCircle = new google.maps.Circle({
                  radius: circle.radius,
                  center: {
                    lat: circle.center.lat,
                    lng: circle.center.lng,
                  },
                  geodesic: true,
                  fillColor: '#0000FF',
                  fillOpacity: 0.2,
                  strokeColor: '#0000FF',
                  strokeOpacity: 1.0,
                  strokeWeight: 1,
                  zIndex: 999,
                  map: this.map,
                })
                this.drawnCircles.push(gCircle)
                bounds.union(gCircle.getBounds())
              }
              this.map.fitBounds(bounds)
            }

            google.maps.event.addDomListener(this.$refs['geofenceMap'], 'mousemove', e => {
              let proj = overlay.getProjection()
              if (!proj) {
                return
              }
              let curpos = proj.fromContainerPixelToLatLng({
                x: e.offsetX,
                y: e.offsetY,
              })
              if (this.isAdding && centerPoint) {
                e.preventDefault()
                e.stopPropagation()
                if (!elasticCircle) {
                  elasticCircle = new google.maps.Circle({
                    radius: 1,
                    center: centerPoint,
                    geodesic: true,
                    fillColor: '#AA0000',
                    fillOpacity: 0.2,
                    strokeColor: '#AA0000',
                    strokeOpacity: 1.0,
                    strokeWeight: 1,
                    zIndex: 999,
                  })
                  elasticCircle.setMap(this.map)
                }
                let dist = google.maps.geometry.spherical.computeDistanceBetween(centerPoint, curpos)
                elasticCircle.setRadius(Math.min(dist, 1000000))
                elasticCircle.setCenter(centerPoint)
              } else {
                //Check for hovered circles
                hoveredCircleId = null
                let smallestR = null
                for (let cId = this.drawnCircles.length - 1; cId >= 0; cId--) {
                  let dist = google.maps.geometry.spherical.computeDistanceBetween(curpos, this.drawnCircles[cId].getCenter())
                  let r = this.drawnCircles[cId].getRadius()
                  if (dist < r) {
                    if (!smallestR || smallestR > r) {
                      smallestR = r
                      hoveredCircleId = cId
                    }
                  }
                  this.drawnCircles[cId].setOptions({ fillColor: '#0000ff' })
                }
                if (hoveredCircleId !== null) {
                  this.drawnCircles[hoveredCircleId].setOptions({ fillColor: '#ffff00' })
                }
              }
            })

            google.maps.event.addDomListener(this.$refs['geofenceMap'], 'mousedown', e => {
              if (this.isAdding && e.which == 1) {
                e.preventDefault()
                e.stopPropagation()
                this.map.setOptions({ draggable: false })
                centerPoint = overlay.getProjection().fromContainerPixelToLatLng({
                  x: e.offsetX,
                  y: e.offsetY,
                })
                //console.log('Drawing now!', centerPoint)
              } else if (e.which == 3 && hoveredCircleId !== null) {
                this.drawnCircles[hoveredCircleId].setMap(null)
                this.drawnCircles.splice(hoveredCircleId, 1)
                hoveredCircleId = null
                this.handleChange()
              }
            })
            google.maps.event.addDomListener(this.$refs['geofenceMap'], 'mouseup', e => {
              if (!elasticCircle) {
                return
              }
              e.preventDefault()
              e.stopPropagation()
              elasticCircle.setOptions({
                fillColor: '#0000ff',
                strokeColor: '#0000ff',
              })

              this.drawnCircles.push(elasticCircle)

              this.handleChange()

              elasticCircle = null
              this.isAdding = false
              centerPoint = false
              this.map.setOptions({ draggable: true })
            })
            this.autoFit()
          })
        } catch (error) {
          console.error(error)
        }
      }, 1)
    },

    removeOverlapping() {
      console.log('Removing overlaps of ' + this.drawnCircles.length + ' circles')
      for (let cId1 = 0; cId1 < this.drawnCircles.length; cId1++) {
        for (let cId2 = 0; cId2 < this.drawnCircles.length; cId2++) {
          if (cId1 == cId2) continue
          let c1 = this.drawnCircles[cId1]
          let c2 = this.drawnCircles[cId2]
          let dist = google.maps.geometry.spherical.computeDistanceBetween(c1.getCenter(), c2.getCenter())
          let r1 = c1.getRadius()
          let r2 = c2.getRadius()
          console.log('tea', cId1, cId2, dist, r1, r2)
          if (dist + r1 < r2) {
            console.log('teb', dist + r1 + ' < ' + r2)
            this.drawnCircles[cId1].isContained = true
          } else if (dist + r1 == r2 && typeof c1.isContained === 'undefined' && typeof c2.isContained === 'undefined') {
            console.log('tec', dist + r1 + ' = ' + r2)
            this.drawnCircles[cId1].isContained = true
          } else {
            console.log('ted', dist + r1 + ' > ' + r2)
          }
        }
      }
      for (let cId = this.drawnCircles.length - 1; cId >= 0; cId--) {
        if (typeof this.drawnCircles[cId].isContained !== 'undefined') {
          this.drawnCircles[cId].setMap(null)
          this.drawnCircles.splice(cId, 1)
        }
      }
    },

    autoFit() {
      var bounds = new google.maps.LatLngBounds()
      for (let cId = 0; cId < this.drawnCircles.length; cId++) {
        bounds.union(this.drawnCircles[cId].getBounds())
      }
      if (this.drawnCircles.length > 0) {
        this.map.fitBounds(bounds)
      }
    },

    handleChange() {
      this.removeOverlapping()
      this.autoFit()

      let circles = []
      for (let cId = 0; cId < this.drawnCircles.length; cId++) {
        circles.push({
          center: {
            lat: this.drawnCircles[cId].getCenter().lat(),
            lng: this.drawnCircles[cId].getCenter().lng(),
          },
          radius: this.drawnCircles[cId].getRadius(),
        })
      }
      this.curData.circles = circles
    },

    handleReset() {
      this.curData = { ...defaultData }
    },

    handleSubmit(e) {
      if (this.curData.circles.length == 0) {
        return Promise.reject(this.$t('geofences.YouMustAddAtLeastOneCircle'))
      }
      return new Promise((resolve, reject) => {
        this.$emit('geofenceUpdated', { ...this.curData })
        resolve(this.curData)
      })
    },

    async loadMap(elm) {
      try {
        const google = await gmapsInit()
        const mapOpts = {
          center: {
            lat: 55.68,
            lng: 11.1,
          },
          zoom: 12,
          minZoom: 2,
          restriction: {
            latLngBounds: {
              north: 85,
              south: -85,
              west: -180,
              east: 180,
            },
          },
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
            position: google.maps.ControlPosition.TOP_LEFT,
          },
        }
        this.map = new google.maps.Map(elm, mapOpts)
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<style>
.geofence_editor_map_elm {
  width: 100%;
  height: 350px;
}
.geofencemap_wrapper {
  position: relative;
}
.geofencemap_wrapper .map_buttons {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
  max-width: calc(100% - 100px);
}
.geofencemap_wrapper .form-control,
.geofencemap_wrapper button {
  margin-top: 10px;
  margin-right: 10px;
}
.geofencemap_wrapper .form-control {
  display: inline-block;
  width: 200px;
  max-width: calc(100% - 10px);
  vertical-align: middle;
}
.geofence_editor_adding_help {
  background: rgba(255, 255, 255, 0.8);
  margin-top: 0.3em;
  padding: 0.2em 1em;
  display: inline-block;
  border-radius: 5em;
}
</style>
