<template>
  <TransitionToAuto move zoom>
    <div v-if="dismissed == false">
      <div :class="'alert alert-' + type + ' alert-dismissible'">
        <button @click="dismiss" type="button" aria-label="Close" class="close">×</button>
        <slot></slot>
      </div>
    </div>
  </TransitionToAuto>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'danger',
    },
  },
  data() {
    return {
      dismissed: false,
    }
  },
  methods: {
    dismiss() {
      this.dismissed = true
      this.$emit('dismissed')
    },
  },
}
</script>
