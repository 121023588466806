<template>
  <div>
    <div class="bg-summersky text-white py-2 px-3" v-if="heading">
      <h1>{{ heading }}</h1>
    </div>
    <div class="row">
      <div class="col-12">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['heading'],
}
</script>
