import { authorizedApiRequest } from '../setup/ApiService'

export default {
  setStatusMessage: function(data) {
    return authorizedApiRequest(
      '/admin/status-message', //
      'PUT',
      data,
    )
  },
  getStatusMessage: function() {
    return authorizedApiRequest(
      '/admin/status-message', //
      'GET',
    )
  },
}
