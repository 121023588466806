import store from '../setup/Store'

export default function autoFit(tnMap) {
  const mapDiv = tnMap.map.getDiv()

  let fitBoundsTimeout = null

  let isAutoFitting = false

  let dragging = false
  google.maps.event.addListener(tnMap.map, 'dragstart', function() {
    dragging = true
  })
  google.maps.event.addListener(tnMap.map, 'dragend', function () {
    dragging = false
  })
  google.maps.event.addListener(tnMap.map, 'center_changed', function () {
    if (dragging) {
      store.dispatch('MapViewStore/disableAutofit')
    }
  })
  google.maps.event.addListener(tnMap.map, 'zoom_changed', function() {
    if (!isAutoFitting) {
      store.dispatch('MapViewStore/disableAutofit')
    }
  })

  const cancelScheduledAutofit = function() {
    if (fitBoundsTimeout !== null) {
      clearTimeout(fitBoundsTimeout)
      fitBoundsTimeout = null
    }
  }

  const fitBoundsSoon = function(jump = false, delay = 100) {
    cancelScheduledAutofit()
    fitBoundsTimeout = setTimeout(() => {
      window.requestAnimationFrame(() => {
        fitBounds(jump)
      })
    }, delay)
  }

  const fitBounds = function(jump = false) {
    cancelScheduledAutofit()

    const proj = tnMap.map.getProjection()
    if (!proj || !tnMap.loaded || !store.state.MapViewStore.autofit || dragging) {
      fitBoundsSoon(jump)
      return
    }

    let bounds = new google.maps.LatLngBounds()
    let hasMarkers = false
    for (var i in tnMap.markers) {
      if (tnMap.markers[i].status.coordinate && (!store.state.MapViewStore.zoomedTracker || tnMap.markers[i].id === 'imei_' + store.state.MapViewStore.zoomedTracker)) {
        hasMarkers = true
        bounds.extend(tnMap.markers[i].status.coordinate)
      }
    }

    if (!hasMarkers) {
      fitBoundsSoon(jump)
      return
    }

    if (tnMap.historyTrack) {
      tnMap.historyTrack.getPath().forEach(function(item, index) {
        bounds.extend(item)
      })
    }

    let minDim, padding, dstSize, bestZoom, curZoom, setZoom

    minDim = Math.min(document.documentElement.clientWidth, document.documentElement.clientHeight)
    padding = Math.min(minDim / 5, 150)

    dstSize = {
      width: mapDiv.offsetWidth - padding * 2,
      height: mapDiv.offsetHeight - padding * 2,
    }

    bestZoom = getBestZoom(bounds, dstSize)
    curZoom = tnMap.map.getZoom()
    setZoom = false

    if (curZoom > bestZoom) {
      //Always zoom out
      setZoom = true
    } else if (bestZoom - curZoom > 1.2) {
      //Zoom in only if everything fits *well* inside the viewport to prevent jumping
      setZoom = true
    }

    if (setZoom) {
      isAutoFitting = true
      tnMap.map.setZoom(Math.floor(bestZoom))
      isAutoFitting = false
    }

    let bestCenter = bounds.getCenter()
    let curCenter = tnMap.map.getCenter()
    var diff = {
      lat: curCenter.lat() - bestCenter.lat(),
      lng: curCenter.lng() - bestCenter.lng(),
    }

    let bcP = tnMap.map.getProjection().fromLatLngToPoint(bestCenter)
    let ccP = tnMap.map.getProjection().fromLatLngToPoint(curCenter)

    var scale = Math.pow(2, curZoom)

    let pDiff = {
      x: (bcP.x - ccP.x) * scale,
      y: (bcP.y - ccP.y) * scale,
    }

    let remDiff = Math.max(Math.abs(pDiff.x), Math.abs(pDiff.y))

    if (remDiff > 3000 || remDiff < 1 || jump || setZoom) {
      tnMap.map.setCenter(bestCenter)
      fitBoundsSoon(false, 1000)
    } else {
      var newCenter = new google.maps.LatLng(curCenter.lat() - diff.lat * 0.05, curCenter.lng() - diff.lng * 0.05)
      tnMap.map.setCenter(newCenter)
      window.requestAnimationFrame(() => {
        fitBounds()
      })
    }
  }

  const getBestZoom = function(bounds, mapDim) {
    var WORLD_DIM = { height: 256, width: 256 }
    var ZOOM_MAX = 16

    function latRad(lat) {
      var sin = Math.sin((lat * Math.PI) / 180)
      var radX2 = Math.log((1 + sin) / (1 - sin)) / 2
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
    }

    function zoom(mapPx, worldPx, fraction) {
      return Math.log(mapPx / worldPx / fraction) / Math.LN2
    }

    var ne = bounds.getNorthEast()
    var sw = bounds.getSouthWest()

    var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI

    var lngDiff = ne.lng() - sw.lng()
    var lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360

    var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction)
    var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction)

    return Math.min(latZoom, lngZoom, ZOOM_MAX)
  }

  return {
    trigger: function(jump = false) {
      fitBoundsSoon(jump)
    },
  }
}
