<template>
  <div>
    <span v-if="address" v-html="address.replace('\n', '<br>') + '<br>'" />
    <component :is="address ? 'small' : 'span'">({{ coordinate.lat.toFixed(4) }}, {{ coordinate.lng.toFixed(4) }})</component>
    <McButton v-if="!address" style="margin-left: 20px" size="sm" variant="secondary" @click="fetchAddress" :text="$t('general.fetchaddress')" />
  </div>
</template>

<script>
import Store from '../setup/Store'
import MiscService from '../services/MiscService'
import { FormatError } from '../helpers/ErrorFormatting'
import { mapState } from 'vuex'

export default {
  props: {
    coordinate: Object,
    showAddress: Boolean,
  },
  mounted() {
    if (this.showAddress) {
      this.fetchAddress()
    }
  },
  computed: {
    coordId() {
      return this.coordinate.lat.toFixed(4) + ',' + this.coordinate.lng.toFixed(4)
    },
    ...mapState({
      GeocodeStore: state => state.GeocodeStore.addresses,
    }),
    address() {
      return this.GeocodeStore[this.coordId]
    },
  },
  methods: {
    fetchAddress() {
      MiscService.geocode(this.coordinate)
        .then(data => {
          Store.dispatch('GeocodeStore/addAddress', {
            coordId: this.coordId,
            address: data.address,
          })
        })
        .catch(error => {
          alert(FormatError(error))
        })
    },
  },
  filters: {
    nl2br(val) {
      return val.replace('\n', '<br>')
    },
  },
}
</script>
