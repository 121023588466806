import Vue from 'vue'
import FetchStatus from '../setup/FetchStatus'
import { ageFromMysqlTimeString } from '../helpers/Time'

let state = {
  trackers: {},
  subscribers: {},
}

const actions = {
  updateState({ commit }, newState) {
    commit('updateStateMutation', newState)
  },
  subscribe({ commit }, { imei, fields, elmId }) {
    commit('addSubscriber', { imei, fields, elmId })
  },
  unsubscribe({ commit }, { elmId, imei }) {
    commit('removeSubscriber', { elmId, imei })
  },
}

const mutations = {
  updateStateMutation(state, data) {
    for (var imei in data) {
      let newTrail = {}
      let hasOldTrail = state.trackers.hasOwnProperty(imei) && state.trackers[imei].hasOwnProperty('trail')
      let hasNewTrail = data[imei].hasOwnProperty('trail')
      if (hasNewTrail && hasOldTrail) {
        //Add the old and the new trail if we have an old trail
        newTrail = Object.assign({}, state.trackers[imei].trail, data[imei].trail)
      } else if (hasOldTrail) {
        newTrail = Object.assign({}, state.trackers[imei].trail)
      } else if (hasNewTrail) {
        newTrail = data[imei].trail
      }

      let maxAge = 60 * 60
      let age
      for (var datetime in newTrail) {
        age = ageFromMysqlTimeString(datetime)
        if (age > maxAge) {
          //console.log(datetime + ' is older than 60m (' + age + 's)')
          delete newTrail[datetime]
        } else {
          //Since we're deleting from the oldest position we ca quit once we find one that is recent...
          //console.log(datetime + ' is younger than 60m (' + age + 's)')
          break
        }
      }

      data[imei].trail = newTrail

      Vue.set(state.trackers, imei, Object.assign({}, state.trackers[imei], data[imei]))
    }
  },
  addSubscriber(state, data) {
    if (!state.subscribers.hasOwnProperty(data.elmId)) {
      Vue.set(state.subscribers, data.elmId, { [data.imei]: data.fields })
    } else {
      let newSubscriptions = state.subscribers[data.elmId]
      if (!newSubscriptions.hasOwnProperty(data.imei)) {
        newSubscriptions[data.imei] = data.fields
      } else {
        newSubscriptions[data.imei] = Object.assign({}, newSubscriptions[data.imei], data.fields)
      }

      Vue.set(state.subscribers, data.elmId, newSubscriptions)
    }
    FetchStatus.trigger()
    //console.log('added subscriber ' + data.elmId + '-' + data.imei + '-' + data.fields, state.subscribers)
  },
  removeSubscriber(state, data) {
    if (state.subscribers.hasOwnProperty(data.elmId)) {
      let newSubscriptions = state.subscribers[data.elmId]
      if (newSubscriptions.hasOwnProperty(data.imei)) {
        delete newSubscriptions[data.imei]
        Vue.set(state.subscribers, data.elmId, newSubscriptions)
      }
    }
    //console.log('removed subscriber ' + data.elmId + '-' + data.imei + '-' + data.field, state.subscribers)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
