import { i18n } from '../setup/i18n'
import Store from '../setup/Store'
import Normalize from './Normalize'

/**
 * @param {Number|String} size
 */
export const FormatFileSize = size => {
  const units = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const threshold = 1024
  size = Number(size) * threshold
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(threshold))
  return `${(size / Math.pow(threshold, i)).toFixed(2) * 1} ${units[i]}`
}

export const FormatDistance = distanceInMeters => {
  if (Math.abs(distanceInMeters) < 20000) {
    let formatter = new Intl.NumberFormat(Store.state.LanguageStore.currentLanguage, { style: 'decimal', minimumFractionDigits: 1, maximumFractionDigits: 1 })
    return formatter.format(distanceInMeters / 1000) + 'km'
  }
  let formatter = new Intl.NumberFormat(Store.state.LanguageStore.currentLanguage, { style: 'decimal', maximumFractionDigits: 0 })
  return formatter.format(distanceInMeters / 1000) + 'km'
}

export const FormatOdometer = distanceInMeters => {
  return new Intl.NumberFormat(Store.state.LanguageStore.currentLanguage, { style: 'decimal', maximumFractionDigits: 0 }).format(distanceInMeters / 1000)
}

export const FormatPhone = phone => {
  if (phone.substring(0, 2) !== '00') {
    phone = Normalize.phone(phone)
  }
  if (phone.substring(0, 2) === '00') {
    phone = '+' + phone.substring(2)
  }

  let match = phone.match(/^(\+[0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})/, phone)
  if (match) {
    phone = match[1] + ' ' + match[2] + ' ' + match[3] + ' ' + match[4] + ' ' + match[5]
  }

  if (phone.substring(0, 4) === '+45 ') {
    return phone.substring(4)
  }

  return phone
}
export const niceMinDistance = function(meters) {
  meters = Math.floor(meters)
  if (meters < 10) {
    return i18n.t('alarms.minDistances.AnyMovement')
  } else if (meters < 1000) {
    return i18n.t('alarms.minDistances.xMeters', { x: meters })
  }
  let kilometers = Math.floor(meters / 1000)
  return i18n.t('alarms.minDistances.xKiloMeters', { x: kilometers })
}
export const niceTimespanInSeconds = function(seconds) {
  let minutes = Math.floor(seconds / 60)
  if (minutes < 2 * 60) {
    return i18n.t('alarms.withinX', { x: i18n.tc('alarms.timespans.xMinutes', minutes) })
  }
  let hours = Math.floor(minutes / 60)
  return i18n.t('alarms.withinX', { x: i18n.tc('alarms.timespans.xHours', hours) })
}
export const niceMaxSpeed = function(maxSpeed) {
  return i18n.t('alarms.maxSpeeds.xKiloMetersPerHour', { x: maxSpeed })
}
export const niceMaxAge = function(seconds) {
  let minutes = Math.floor(seconds / 60)
  if (minutes < 2 * 60) {
    return i18n.tc('alarms.maxAges.xMinutes', minutes)
  }
  let hours = Math.floor(minutes / 60)
  if (hours < 2 * 24) {
    return i18n.tc('alarms.maxAges.xHours', hours)
  }
  let days = Math.floor(hours / 24)
  return i18n.tc('alarms.maxAges.xDays', days)
}
export const niceCurrency = function(cents, currency) {
  return new Intl.NumberFormat(Store.state.LanguageStore.currentLanguage, {
    style: 'currency',
    currency: currency,
  }).format(cents / 100)
}
export const niceMethod = function(v) {
  let first = v.split('|')[0]
  //console.log(v)
  return i18n.t('billing.paidByMethods.' + first)
}
