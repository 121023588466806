<template>
  <McLayoutFullScreen>
    <div v-if="loadingState">
      <McSpinner large />
    </div>
    <div v-else-if="errorState">
      <h2>{{ $t('general.Error') }}</h2>
      <p>{{ $t('general.InvalidLink') }}</p>
    </div>
    <div v-else id="mapandpanels_alarmmap">
      <MapWithTrack ref="theMap" :markers="eventMarkers" :points="eventPoints" :circles="eventCircles" class="map_alarmmap" />
      <div class="mappanel_insurance" v-if="insuranceDetails" v-html="insuranceDetails" />
    </div>
  </McLayoutFullScreen>
</template>

<script>
import { FormatError } from '../../helpers/ErrorFormatting'
import AlarmService from '../../services/AlarmService'
import { localizedDatetime, dateToLocalizedHumanreadableDate } from '../../helpers/Time'
import { niceMinDistance, niceTimespanInSeconds, niceMaxAge, niceMaxSpeed } from '../../helpers/Formatting'

export default {
  data() {
    return {
      errorState: false,
      loadingState: true,
      event: null,
      map: null,
      intervalTimer: null,
    }
  },
  created() {
    this.checkAlarmSecret(this.$route.params.imei, this.$route.params.alarmId, this.$route.params.secret)
  },
  beforeDestroy() {
    if (this.intervalTimer) clearTimeout(this.intervalTimer)
  },
  computed: {
    eventCircles() {
      let circles = []
      if (this.event.details.geofenceCircles) {
        this.event.details.geofenceCircles.forEach(circle => {
          circles.push({
            color: this.event.type == 'fenceEntry' ? '#f00' : '#0f0',
            ...circle,
          })
        })
      }
      return circles
    },
    eventPoints() {
      let ret = []
      if (this.event.points) {
        for (var i in this.event.points) {
          if (this.event.points[i].coordinate) ret.push(this.event.points[i].coordinate)
        }
      }
      return ret
    },
    insuranceDetails() {
      if (!['insuranceTow', 'insuranceBattery', 'insuranceDisconnect', 'insuranceAge', 'insuranceTest'].includes(this.event.type)) {
        return ''
      }
      let detailsHtml = this.event.detailsHtml
        .replace('{TRIGGER_ADDRESS}', this.event.nearestAddressStart.replace('\n', '<br>') + '<br><small>(' + this.event.startCoordinate.lat + ', ' + this.event.startCoordinate.lng + ')</small>')
        .replace('{CURRENT_ADDRESS}', this.event.nearestAddressCurrent.replace('\n', '<br>') + '<br><small>(' + this.event.currentCoordinate.lat + ', ' + this.event.currentCoordinate.lng + ')</small>')
        .replace('{CURRENT_DATETIME}', dateToLocalizedHumanreadableDate(localizedDatetime(this.event.latestPosition)))
      return detailsHtml
    },
    eventMarkers() {
      let markers = []
      let startSignX = -1
      let startSignY = 1
      let endSignX = 1
      let endSignY = -1
      if (this.event.endCoordinate.lat !== this.event.startCoordinate.lat || this.event.endCoordinate.lng !== this.event.startCoordinate.lng) {
        if (this.event.endCoordinate.lat < this.event.startCoordinate.lat) {
          startSignY *= -1
          endSignY *= -1
        }
        if (this.event.endCoordinate.lng < this.event.startCoordinate.lng) {
          startSignX *= -1
          endSignX *= -1
        }
      }

      let alarmText = this.$t('alarmTypes.alarmMap.' + this.event.type)

      if (['tow', 'insuranceTow', 'movement'].includes(this.event.type)) {
        alarmText = alarmText.replace('{minDistance}', '<strong>' + niceMinDistance(this.event.details.minDistance) + '</strong>')
      }
      if (['age', 'insuranceAge'].includes(this.event.type)) {
        alarmText = alarmText.replace('{maxAge}', '<strong>' + niceMaxAge(this.event.details.maxAge) + '</strong>')
      }
      if (['speed'].includes(this.event.type)) {
        alarmText = alarmText.replace('{maxSpeed}', '<strong>' + niceMaxSpeed(this.event.details.maxSpeed) + '</strong>')
      }
      if (['movement'].includes(this.event.type)) {
        alarmText = alarmText.replace('{timespan}', '<strong>' + niceTimespanInSeconds(this.event.details.timespan) + '</strong>')
      }
      if (['fenceEntry', 'fenceExit'].includes(this.event.type)) {
        alarmText = alarmText.replace('{geofence}', '<strong>' + this.event.details.geofence + '</strong>')
      }

      let markerHtml = ''
      if (['insuranceTow', 'insuranceBattery', 'insuranceDisconnect', 'insuranceAge'].includes(this.event.type)) {
        markerHtml = '<div class="alarm_marker_wrap"><strong>' + dateToLocalizedHumanreadableDate(localizedDatetime(this.event.startDatetime)) + '</strong>:<br><span>' + alarmText + '</span></div>'
      } else {
        markerHtml =
          '<div class="alarm_marker_wrap">' + //
          '<strong>' + this.event.trackerName + ':</strong>' +
          '<br>' +
          '<span>' + alarmText + '</span>' +
          '<br>' +
          '<span>' + this.$t('alarms.triggeredAtColon', { time: dateToLocalizedHumanreadableDate(localizedDatetime(this.event.startDatetime)) }) + '</span>' +
          '<br>' +
          this.$t('alarms.nearestAddressColon') +
          '<br>' +
          '<em class="alarm_marker_address">' + this.event.nearestAddressStart.replace("\n", '<br>') + '</em>' +
          (this.event.nearestAddressEnd !== this.event.nearestAddressStart ? '' : '<span>' + this.$t('alarms.LastTriggeredAtColon', { time: dateToLocalizedHumanreadableDate(localizedDatetime(this.event.endDatetime)) }) + '</span>') +
          '</div>'
      }

      markers.push({
        coordinate: this.event.startCoordinate,
        color: '#f00',
        offset: { x: startSignX * 10, y: startSignY * 80 },
        html: markerHtml,
      })
      if (this.event.currentCoordinate) {
        markers.push({
          coordinate: this.event.currentCoordinate,
          color: '#00f',
          offset: { x: endSignX * 10, y: endSignY * 80 },
          html: '<div class="alarm_marker_wrap info"><strong>' + dateToLocalizedHumanreadableDate(localizedDatetime(this.event.latestPosition)) + '</strong>:<br><span>Senest kendte position</span></div>',
        })
      } else if (this.event.nearestAddressEnd !== this.event.nearestAddressStart) {
        markers.push({
          coordinate: this.event.endCoordinate,
          color: '#f00',
          offset: { x: endSignX * 10, y: endSignY * 80 },
          html: '<div class="alarm_marker_wrap">' + //
            '<span>' + this.$t('alarms.LastTriggeredAtColon', { time: dateToLocalizedHumanreadableDate(localizedDatetime(this.event.endDatetime)) }) + '</span>' +
            '<br>' +
            this.$t('alarms.nearestAddressColon') +
            '<br>' +
            '<em class="alarm_marker_address">' + this.event.nearestAddressEnd.replace("\n", '<br>') + '</em>' +
            '</div>',
        })
      }
      return markers
    },
  },
  methods: {
    checkAlarmSecret(imei, alarmId, secret) {
      return new Promise((resolve, reject) => {
        AlarmService.getEventBySecret(imei, alarmId, secret)
          .then(data => {
            this.event = data
            this.loadingState = false
            this.intervalTimer = setTimeout(() => {
              this.checkAlarmSecret(this.$route.params.imei, this.$route.params.alarmId, this.$route.params.secret).then(() => {
                this.$refs['theMap'].drawStuff()
                this.$refs['theMap'].fitBounds()
              })
            }, 30000)
            resolve(data)
          })
          .catch(error => {
            console.error(FormatError(error))
            this.loadingState = false
            this.errorState = true
          })
      })
    },
  },
}
</script>

<style lang="scss">
.map_alarmmap {
  height: 100%;
  width: 100%;
}
#mapandpanels_alarmmap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 30px;
  min-width: 30px;
  display: flex;
  flex-direction: column;
}
.alarm_marker_wrap {
  background: #fff;
  padding: 5px;
  border: 2px solid #f00;
  border-radius: 4px;
  max-width: 250px;
}
.alarm_marker_wrap.info {
  border-color: #00f;
}
.alarm_marker_address {
  display: block;
  margin: 4px 4px 4px 10px;
}
.mappanel_insurance {
  position: absolute;
  bottom: 10px;
  left: 10px;
  max-height: calc(100% - 20px);
  background: rgba(255,255,255,.8);
  overflow: auto;
  font-size: 12px;
  border-radius: 3px;
}
.insurancetable {
  tr:nth-child(odd) td,
  tr:nth-child(odd) th {
    background: rgba(0, 0, 0, 0.1);
  }
  th,
  td {
    vertical-align: top;
    padding: 0.2em;
  }
  th {
    text-align: right;
    font-weight: normal;
    padding-right: 0.5em;
  }
  td {
    padding-left: 0.5em;
  }
  a {
    white-space: nowrap;
  }
}
@media screen and (max-width: 900px) {
  .insurancetable {
    font-size: 10px;
    th,
    td {
      display: block;
      text-align: left;
    }
  }
  .mappanel_insurance {
    bottom: 0px;
    left: 0px;
    max-height: calc(100%);
  }
}
</style>
