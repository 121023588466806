<template>
  <McForm v-bind="$attrs" @reset="handleReset" :onSubmit="handleSubmit" submiticon="unlock" :submittext="$t('general.logIn')">
    <mc-form-input
      name="email"
      type="email"
      v-model="curData.email"
      v-validate="'required|email'"
      :placeholder="$t('general.enterEmail')"
      key="loginform.email"
      :errorHint="errors.first('email')"
      :state="!errors.has('email')"
      icon="at"
    />

    <mc-form-input
      name="password"
      type="password"
      v-model="curData.password"
      v-validate="'required'"
      :placeholder="$t('general.enterPassword')"
      key="loginform.password"
      :errorHint="errors.first('password')"
      :state="!errors.has('password')"
      icon="key"
    />

    <b-form-group>
      <b-input-group>
        <b-form-checkbox name="remember" v-model="curData.remember">
          {{ $t('loginform.remember') }}
        </b-form-checkbox>
      </b-input-group>
    </b-form-group>

    <template v-slot:secondarybuttons>
      <b-button to="/reset-password" variant="link">{{ $t('general.forgotPassword') }}</b-button>
    </template>
  </McForm>
</template>

<script>
import Router from '../setup/Router'
import { mapState, mapActions } from 'vuex'

const defaultData = {
  email: '',
  password: '',
  remember: false,
}

export default {
  components: {},
  data() {
    return {
      curData: { ...defaultData },
      from: this.$route.query.from,
    }
  },
  computed: {
    ...mapState('LoginStore', ['status']),
  },
  created() {
    // Log out when opening this form
    this.logout()
  },
  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  watch: {
    '$route.query.from'() {
      this.from = this.$route.query.from
    },
  },
  methods: {
    ...mapActions('LoginStore', [
      'login', //
      'logout',
    ]),
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    handleReset() {
      this.curData = { ...defaultData }
    },

    handleSubmit(e) {
      return this.login(this.curData).then(
        data => {
          if (this.from) {
            Router.push('/' + this.from)
          } else {
            Router.push('/')
          }
          this.toastSuccess(this.$t('loginform.success', { name: data.user.name }))
          return Promise.resolve(data)
        },
        error => {
          console.error('Error logging in', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
