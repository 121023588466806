import Vue from 'vue'
let state = {
  addresses: {},
}

const actions = {
  addAddress({ commit }, { coordId, address }) {
    commit('addAddressMutation', {
      coordId,
      address,
    })
  },
}

const mutations = {
  addAddressMutation(state, data) {
    Vue.set(state.addresses, data.coordId, data.address)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
