<template>
  <transition-to-auto v-if="MapViewStore.zoomedTracker && trackerInfo">
    <div id="theMapDetailsPanel" :class="'boxShadow ' + (screenWidth > 800 ? 'large' : 'small')">
      <div v-if="!MapViewStore.shownHistory">
        <h3 class="bg-secondary">
          <McButton v-on:click="unzoom" class="m-0 boxShadow" variant="danger" :icon="'times'" />
          <strong>{{ trackerInfo.name }}</strong>
        </h3>
        <div class="outerDetails">
          <div class="innerDetails" v-if="screenWidth >= 800 || detailsOnSmall == 'select'">
            <TrackerStatus :full="screenWidth >= 800" :imei="imei" />
          </div>
          <div class="text-center pb-2" v-if="screenWidth < 800 && detailsOnSmall == 'select'">
            <b-button-group>
              <McButton v-on:click="detailsOnSmall = 'address'" class="m-0 boxShadow" variant="primary" :icon="['fad', 'location']" :text="$t('map.Address')" />
              <McButton
                v-on:click="detailsOnSmall = 'history'"
                v-if="$perms.hasPermission(imei, 'history') || $perms.isAdministrator()"
                class="m-0 boxShadow"
                variant="secondary"
                :icon="['fad', 'history']"
                :text="$t('map.History')"
              />
            </b-button-group>
          </div>
          <div v-else-if="screenWidth < 800 && detailsOnSmall == 'address'" class="text-center">
            <tnCoordinate class="m-4" :coordinate="myStatus.coordinate" showAddress />
            <McButton class="m-2" @click="detailsOnSmall = 'select'" :text="$t('general.Back')" variant="primary" />
          </div>
          <HistoryForm
            @cancel="detailsOnSmall = 'select'"
            :hasCancel="screenWidth < 800"
            v-else-if="(screenWidth >= 800 || detailsOnSmall == 'history') && ($perms.hasPermission(imei, 'history') || $perms.isAdministrator())"
            class="historyForm"
          />
        </div>
      </div>
      <TheMapHistoryPanel v-else />
    </div>
  </transition-to-auto>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      screenWidth: null,
      detailsOnSmall: 'select',
    }
  },

  methods: {
    handleResize() {
      this.screenWidth = document.body.scrollWidth
    },
    unzoom() {
      this.detailsOnSmall = 'select'
      this.$router.push('/map/')
    },
    ...mapActions('MapViewStore', [
      'quitHistory', //
    ]),
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  computed: {
    ...mapState({
      TrackerStatusStore: state => state.TrackerStatusStore.trackers,
      MapViewStore: state => state.MapViewStore,
    }),
    myStatus() {
      return this.TrackerStatusStore[this.imei]
    },
    imei() {
      return this.MapViewStore.zoomedTracker
    },
    trackerInfo() {
      return this.MapViewStore.trackerList[this.imei]
    },
  },
}
</script>

<style lang="scss">
#theMapDetailsPanel {
  background: #fff;
  &.small {
    .hidewhensmall {
      display: none;
    }
    .outerDetails {
      display: block;
    }
    .historyForm,
    .innerDetails {
      width: auto;
    }
    h3 {
      font-size: 16px;
    }
  }
  p.historyHeading,
  h3 {
    color: #fff;
    padding: 12px;
    margin: 0;
    position: relative;
    padding-right: 150px;
    button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 3px;
    }
  }
  p.historyHeading {
    padding: 3px;
    padding-right: 54px;
  }
  .outerDetails {
    display: flex;
  }
  .historyForm,
  .innerDetails {
    padding: 12px;
    width: 50%;
    display: flow-root;
  }
  .historyForm {
    background: #eee;
  }
}
</style>
