<template>
  <div ref="outer">
    <TransitionToAuto v-if="!submitted" v-html="$t('mileage.MileageDescription')" />
    <McForm v-bind="$attrs" @reset="handleReset" :onSubmit="handleSubmit" :successText="false" :submiticon="['fad', 'history']" :submittext="$t('mileage.FetchMileage')">
      <McFormDateRange :label="$t('mileage.ChooseDateRange')" v-model="curData.dateRange" :min="minDate" :max="maxDate" />

      <mc-form-select
        name="minDistance"
        v-model="curData.minDistance"
        :label="$t('general.minDistance')"
        :placeholder="$t('general.chooseMinDistance')"
        :description="$t('mileage.chooseMinDistanceForMileageDisplay')"
        key="mileagenoticeform.minDistance"
        :errorHint="errors.first('minDistance')"
        :state="!errors.has('minDistance')"
        :options="minDistances"
        :icon="['far', 'ruler']"
      />
    </McForm>

    <div v-if="mileageTrips && this.submitted === this.imei">
      <b-card>
        <div class="mileage_legend">
          <label>{{ $t('mileage.SymbolsColon') }}</label>
          <span><McIcon :icon="'question'" size="sm" /> {{ $t('mileage.UnknownType') }}</span>
          <span><McIcon :icon="'building'" size="sm" /> {{ $t('mileage.BusinessType') }}</span>
          <span><McIcon :icon="['fad', 'house']" size="sm" /> {{ $t('mileage.PrivateType') }}</span>
        </div>
      </b-card>
      <b-table-simple :small="isSmall" stacked="md" striped class="mileage-table my-5">
        <b-thead>
          <b-tr>
            <b-th :stacked-heading="$t('mileage.TripType')" style="width: 120px">{{ $t('mileage.TripType') }}</b-th>
            <b-th :stacked-heading="$t('mileage.Start')" style="width: 180px">{{ $t('mileage.Start') }}</b-th>
            <b-th class="d-none d-lg-table-cell" style="width: 60px" />
            <b-th :stacked-heading="$t('mileage.End')" style="width: 180px">{{ $t('mileage.End') }}</b-th>
            <b-th class="text-right" :stacked-heading="$t('mileage.Distance')" style="width: 100px">{{ $t('mileage.Distance') }}</b-th>
            <b-th :stacked-heading="$t('mileage.Note')" style="width: 200px">{{ $t('mileage.Note') }}</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <!-- eslint-disable-next-line -->
          <MileageRow
            v-for="trip in tripAndAdjustmentsArr"
            v-bind:key="trip.id"
            :trip="trip"
            @setType="value => setTripType(trip, value)"
            @editNote="editNote(trip)"
          />
        </b-tbody>
      </b-table-simple>
    </div>

    <tripNoteForm :imei="imei" id="tripnoteform" isModal :value="editingNote" @noteUpdated="noteUpdated" />
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapActions } from 'vuex'
import { mysqlTimeStringFromDate } from '../helpers/Time'
import MileageService from './../services/MileageService'

const defaultData = {
  dateRange: {
    from: moment()
      .startOf('month')
      .toDate(),
    to: moment()
      .endOf('day')
      .toDate(),
  },
  minDistance: 500,
}

export default {
  components: {},
  data() {
    return {
      submitted: false,
      primaryKey: 'id',
      sortBy: 'id',
      mileageTrips: null,
      mileageAdjustments: null,
      curData: {
        ...defaultData,
      },
      minDistances: [
        { value: 0, label: this.$t('general.minDistanceAnyMovement') },
        { value: 250, label: this.$t('general.minDistance250') },
        { value: 500, label: this.$t('general.minDistance500') },
        { value: 1000, label: this.$t('general.minDistance1000') },
      ],
      minDate: moment(new Date())
        .subtract(18, 'month')
        .startOf('day')
        .toDate(),
      maxDate: moment(new Date())
        .endOf('day')
        .toDate(),
      editingNote: {},
      componentWidth: 0,
    }
  },
  props: {
    imei: {
      type: String,
      default: null,
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  computed: {
    ...mapState({
      MapViewStore: state => state.MapViewStore,
      LanguageStore: state => state.LanguageStore,
    }),
    isSmall() {
      return this.componentWidth < 800
    },
    lang() {
      return this.LanguageStore.currentLanguage
    },
    missingAdresses() {
      let missing = []
      for (let tId in this.mileageTrips) {
        let trip = this.mileageTrips[tId]
        if (!trip.startAddress[this.lang] || !trip.endAddress[this.lang]) {
          missing.push(trip.id)
          if (missing.length >= 5) {
            return missing
          }
        }
      }
      return missing
    },
    tripAndAdjustmentsArr() {
      let retval = []
      let queuedAdjustments = this.mileageAdjustments ? [...this.mileageAdjustments.adjustments] : []
      let nextAdjustment = queuedAdjustments.shift()
      Object.values(this.mileageTrips).forEach(trip => {
        while (nextAdjustment && trip.startDatetime > nextAdjustment.datetime) {
          retval.push({ type: 'adjustment', ...nextAdjustment })
          nextAdjustment = queuedAdjustments.shift()
        }
        retval.push(trip)
      })
      return retval
    },
  },
  watch: {
    lang(newlang) {
      this.getAddresses()
    },
  },
  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    ...mapActions('MapViewStore', [
      'showHistory', //
    ]),
    handleResize() {
      const target = this.$refs.outer
      this.componentWidth = target.getBoundingClientRect().width
    },
    editNote(item) {
      //console.log(item)
      this.editingNote = {
        imei: this.imei,
        ...item,
      }
      this.$bvModal.show('tripnoteform')
    },
    noteUpdated(e) {
      this.$set(this.mileageTrips[e.id], 'note', e.note)
    },
    handleReset() {
      //Don't reset this one...
    },

    getAddresses() {
      if (this.missingAdresses.length == 0) {
        return
      }
      return MileageService.getAddresses(
        this.imei, //
        this.missingAdresses,
      ).then(
        data => {
          for (let tripId in data) {
            this.$set(this.mileageTrips[tripId].startAddress, this.lang, data[tripId].startAddress)
            this.$set(this.mileageTrips[tripId].endAddress, this.lang, data[tripId].endAddress)
          }
          setTimeout(this.getAddresses, 500)
          //console.log(data)
        },
        error => {
          console.error(error)
        },
      )
    },

    setTripType(trip, type) {
      return MileageService.setType(this.imei, trip.id, type).then(() => {
        this.mileageTrips[trip.id].type = type
      })
    },

    handleSubmit(e) {
      this.submitted = this.imei
      return MileageService.fetch(
        this.imei, //
        mysqlTimeStringFromDate(this.curData.dateRange.from),
        mysqlTimeStringFromDate(this.curData.dateRange.to),
        this.curData.minDistance,
      ).then(
        data => {
          if (data.trips.length === 0) {
            return Promise.reject(this.$t('mileage.NoMileageInPeriod'))
          } else {
            this.mileageAdjustments = data.adjustments
            this.mileageTrips = data.trips
            setTimeout(this.getAddresses, 500)
            return Promise.resolve(data)
          }
        },
        error => {
          console.error(error)
        },
      )
    },
  },
}
</script>

<style lang="scss">
.mileage_rightarrow {
  display: block;
  width: 1em;
  height: 1em;
  margin: 2em 1em 1em;
  border: 0.3em solid #888;
  border-bottom: 0;
  border-left: 0;
  transform: rotate(45deg);
}
.mileage_note {
  white-space: pre-wrap;
}
.mileage_legend {
  > label,
  > span {
    margin: 0.5em 1em 0.5em 0;
    white-space: nowrap;
    display: inline-block;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
}
</style>
