import { authorizedApiRequest } from '../setup/ApiService'

export default {
  setLanguage: function(newLanguage) {
    return authorizedApiRequest(
      '/profile/set-language', //
      'POST',
      {
        language: newLanguage,
      },
    )
  },

  update: function(user) {
    return authorizedApiRequest(
      `/profile/update`, //
      'POST',
      user,
    )
  },
}
