<template>
  <div>
    <mc-form-checkboxes
      name="type"
      v-model="curData.type"
      :label="$t('general.ContactType')"
      key="contactform.type"
      :errorHint="errors.first('type')"
      :state="!errors.has('type')"
      :options="typesList"
      icon="ballot-check"
      v-if="!skipType"
    />

    <mc-form-input
      name="name"
      type="text"
      v-model="curData.name"
      v-validate="allowEmpty ? '' : 'required'"
      :data-vv-as="curData.type === 'I' ? $t('general.Name') : $t('general.ContactName')"
      :label="curData.type === 'I' ? $t('general.Name') : $t('general.ContactName')"
      :placeholder="curData.type === 'I' ? $t('general.EnterName') : $t('general.EnterContactName')"
      key="contactform.name"
      :errorHint="errors.first('name')"
      :state="!errors.has('name')"
      icon="signature"
      :description="curData.type === 'I' ? '' : $t('general.ContactNameDescription')"
    />

    <mc-form-input
      v-if="curData.type !== 'I'"
      name="company"
      type="text"
      v-model="curData.company"
      v-validate="allowEmpty ? '' : 'required'"
      :data-vv-as="$t('general.Company')"
      :label="$t('general.Company')"
      :placeholder="$t('general.EnterCompany')"
      key="contactform.company"
      :errorHint="errors.first('company')"
      :state="!errors.has('company')"
      icon="building"
      :description="$t('general.CompanyDescription')"
    />

    <mc-form-input
      v-if="curData.type === 'P' || curData.type === 'C'"
      name="cvr"
      type="text"
      v-validate="allowEmpty ? 'numeric' : 'required|numeric'"
      :data-vv-as="$t('general.Cvr')"
      :label="$t('general.Cvr')"
      v-model="curData.cvr"
      :placeholder="$t('general.EnterCvr')"
      key="contactform.cvr"
      :errorHint="errors.first('cvr')"
      :state="!errors.has('cvr')"
      :icon="['fad', 'id-card-alt']"
    />

    <mc-form-input
      v-if="curData.type === 'P'"
      name="ean"
      type="text"
      v-validate="allowEmpty ? 'numeric' : 'required|numeric'"
      :data-vv-as="$t('general.Ean')"
      :label="$t('general.Ean')"
      v-model="curData.ean"
      :placeholder="$t('general.EnterEan')"
      key="contactform.ean"
      :errorHint="errors.first('ean')"
      :state="!errors.has('ean')"
      :icon="['fad', 'id-card-alt']"
    />

    <mc-form-input
      v-if="curData.type === 'P'"
      name="reference"
      type="text"
      :label="$t('general.Reference')"
      v-model="curData.reference"
      :placeholder="$t('general.EnterReference')"
      key="contactform.reference"
      :icon="['fad', 'id-card-alt']"
    />

    <mc-form-input
      v-if="curData.type === 'P'"
      name="rekvisition"
      type="text"
      :label="$t('general.Rekvisition')"
      v-model="curData.rekvisition"
      :placeholder="$t('general.EnterRekvisition')"
      key="contactform.rekvisition"
      :icon="['fad', 'id-card-alt']"
    />

    <mc-form-input
      name="address1"
      type="text"
      v-model="curData.address1"
      v-validate="allowEmpty ? '' : 'required'"
      :data-vv-as="$t('general.Address1')"
      :label="$t('general.Address1')"
      :placeholder="$t('general.EnterAddress1')"
      key="contactform.address1"
      :errorHint="errors.first('address1')"
      :state="!errors.has('address1')"
      icon="map-marker"
    />

    <mc-form-input
      name="address2"
      type="text"
      v-model="curData.address2"
      :placeholder="$t('general.EnterAddress2')"
      :data-vv-as="$t('general.Address2')"
      :label="$t('general.Address2')"
      key="contactform.address2"
      :errorHint="errors.first('address2')"
      :state="!errors.has('address2')"
      icon="map-marker"
    />

    <!-- eslint-disable-next-line -->
    <mcFormDualInputs
      :errorHint="errors.first('zip') || errors.first('city')"
      :state="!errors.has('zip') && !errors.has('city')"
    >
      <template v-slot:input1>
        <!-- eslint-disable-next-line -->
        <mc-form-input
          icon="city"
          name="zip"
          type="text"
          v-validate="allowEmpty ? 'numeric' : 'required|numeric'"
          :data-vv-as="$t('general.Zip')"
          :label="$t('general.Zip')"
          v-model="curData.zip"
          :placeholder="$t('general.EnterZip')"
          key="contactform.zip"
        />
      </template>
      <template v-slot:input2>
        <!-- eslint-disable-next-line -->
        <mc-form-input
          icon="city"
          name="city"
          type="text"
          v-validate="allowEmpty ? '' : 'required'"
          :data-vv-as="$t('general.City')"
          :label="$t('general.City')"
          v-model="curData.city"
          :placeholder="$t('general.EnterCity')"
          key="contactform.city"
        />
      </template>
    </mcFormDualInputs>

    <mc-form-input
      name="country"
      type="text"
      v-validate="allowEmpty ? '' : 'required'"
      v-model="curData.country"
      :placeholder="$t('general.EnterCountry')"
      :data-vv-as="$t('general.Country')"
      :label="$t('general.Country')"
      key="contactform.country"
      :errorHint="errors.first('country')"
      :state="!errors.has('country')"
      :icon="['fad', 'globe-europe']"
      v-if="!skipCountry"
    />

    <mc-form-input
      name="email"
      type="email"
      v-model="curData.email"
      v-validate="allowEmpty ? 'email' : 'required|email'"
      :data-vv-as="$t('general.Email')"
      :label="$t('general.Email')"
      :placeholder="$t('general.EnterEmail')"
      key="contactform.email"
      :errorHint="errors.first('email')"
      :state="!errors.has('email')"
      :disabled="isEditing && curData.email == $perms.loggedInUser().email"
      :description="isEditing && curData.email == $perms.loggedInUser().email ? $t('contacts.YouHaveToEditYourUser') : ''"
      icon="at"
      v-if="!skipEmail"
    />

    <mc-form-input
      name="phone"
      type="text"
      v-model="curData.phone"
      v-validate="allowEmpty ? 'numeric' : 'required|numeric'"
      :data-vv-as="$t('general.Phone')"
      :label="$t('general.Phone')"
      :placeholder="$t('general.EnterPhone')"
      key="contactform.phone"
      :errorHint="errors.first('phone')"
      :state="!errors.has('phone')"
      icon="phone"
      v-if="!skipPhone"
    />

    <!--mc-form-input
      name="website"
      type="url"
      v-model="curData.website"
      v-validate="'url'"
      :data-vv-as="$t('general.Website')"
      :label="$t('general.Website')"
      :placeholder="$t('general.EnterWebsite')"
      key="contactform.website"
      :errorHint="errors.first('website')"
      :state="!errors.has('website')"
      :icon="['fad', 'browser']"
    /-->
  </div>
</template>

<script>
export default {
  inject: ['$validator'],
  data() {
    return {
      curData: {},
      settingInternal: false,
    }
  },
  props: {
    value: Object,
    isEditing: Boolean,
    skipType: Boolean,
    skipEmail: Boolean,
    skipCountry: Boolean,
    skipPhone: Boolean,
    allowEmpty: Boolean,
  },
  computed: {
    typesList: function() {
      return [
        { value: 'I', label: this.$t('general.ContactTypeIndividual') }, //
        { value: 'C', label: this.$t('general.ContactTypeCompany') },
        { value: 'P', label: this.$t('general.ContactTypePublic') },
        { value: 'A', label: this.$t('general.ContactTypeAssociation') },
      ]
    },
  },
  created() {
    this.settingInternal = true
    this.curData = this.value
    this.settingInternal = false
  },
  watch: {
    value(newVal) {
      this.settingInternal = true
      this.curData = newVal
      this.settingInternal = false
    },
    curData(newVal) {
      if (!this.settingInternal) {
        //this.$emit('input', { ...newVal })
      }
    },
  },
}
</script>
