import { authorizedApiRequest } from '../setup/ApiService'

export default {
  getByImei: imei => {
    return authorizedApiRequest(
      '/trackers/' + imei + '/journal', //
      'GET',
    )
  },

  addOrEditNote: (imei, note) => {
    return authorizedApiRequest(
      '/trackers/' + imei + '/journal', //
      'POST',
      note,
    )
  },

  deleteNote: (imei, id) => {
    return authorizedApiRequest(
      '/trackers/' + imei + '/journal/' + id, //
      'DELETE',
    )
  },
}
