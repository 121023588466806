<template>
  <div>
    <!-- eslint-disable-next-line -->
    <McForm
      ref="mcFormInstance"
      v-bind="$attrs"
      :loading="loadingState"
      :onSubmit="handleSubmit"
      :submittext="$t('mileage.SaveOdometerAdjustmentNotices')"
    >

      <mc-form-checkboxes name="enable" v-model="curData.enable" :label="$t('mileage.EnableOdometerAdjustmentNotices')" :options="enabledOptions" :icon="['fad', 'inbox']" inline />

      <!-- eslint-disable-next-line -->
      <McFormAlarmContacts
        v-if="curData.enable"
        name="contacts"
        v-model="curData.contacts"
        :alarmContacts="alarmContacts"
        :label="$t('alarms.recipient(s)')"
        :icon="['fad', 'users']"
      />

      <!--pre>{{ curData }}</pre-->
    </McForm>
  </div>
</template>

<script>
import AlarmService from '../services/AlarmService'
import MiscService from '../services/MiscService'

const defaultData = {
  enable: false,
  ruleId: -1,
  contacts: [],
}

export default {
  components: {},
  data() {
    return {
      loadingState: true,
      alarmContacts: [],
      curData: { ...defaultData },
      enabledOptions: [
        { value: false, label: this.$t('general.Disable') }, //
        { value: true, label: this.$t('general.Enable') },
      ],
    }
  },
  props: {
    imei: {
      type: String,
      default: null,
    },
    value: Object,
  },
  mounted() {
    MiscService.alarmContactsDefaultTextsAndFences()
      .then(data => {
        this.loadingState = false
        this.alarmContacts = data.contacts
      })
      .catch(error => {
        this.loadingState = FormatError(error)
      })
  },
  watch: {
    value(val) {
      if (typeof val !== 'object') {
        val = {}
      }
      this.$refs['mcFormInstance'].forceReset()

      this.$set(this.curData, 'enable', val.hasOwnProperty('ruleId'))
      this.$set(this.curData, 'ruleId', val.hasOwnProperty('ruleId') ? val.ruleId : defaultData.ruleId)
      this.$set(this.curData, 'contacts', val.hasOwnProperty('contacts') ? [...val.contacts] : [...defaultData.contacts])
    },
  },

  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    handleReset() {
      // Don't reset this one
    },

    handleSubmit(e) {
      if (this.curData.enable && this.curData.contacts.length < 1) {
        return Promise.reject(this.$t('error.YouNeedToChooseAtLeastOneRecipient'))
      }

      let action = null
      if (this.curData.enable) {
        action = AlarmService.replaceOdometerAdjustmentNoticeByImei(this.imei, this.curData.contacts)
      } else {
        action = AlarmService.removeOdometerAdjustmentNoticeByImei(this.imei)
      }
      return action.then(
        data => {
          //console.log('done', data)
        },
        error => {
          console.error('Error saving odometer adjustment notice', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
