import { authorizedApiRequest, queryParameters } from '../setup/ApiService'

export default {
  query: function(params) {
    return authorizedApiRequest(
      '/users?' + queryParameters(params), //
      'GET',
    )
  },

  getById: function(id) {
    return authorizedApiRequest(
      `/users/${id}`, //
      'GET',
    )
  },

  create: function(user) {
    return authorizedApiRequest(
      `/users`, //
      'POST',
      user,
    )
  },

  update: function(user) {
    return authorizedApiRequest(
      `/users/${user.id}`, //
      'PUT',
      user,
    )
  },

  delete: function(id) {
    return authorizedApiRequest(
      `/users/${id}`, //
      'DELETE',
    )
  },
}
