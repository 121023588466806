import Vue from 'vue'
import Router from 'vue-router'

//No idea why this is needed, but everything breaks if removed :/
// eslint-disable-next-line
import uselessimport from '../services/AuthenticationService'

import LoginStore from '../store/LoginStore'

//Public pages
import LoginPage from '../views/public/LoginPage'
import ResetPasswordPage from '../views/public/ResetPasswordPage'
import ConfirmEmailPage from '../views/public/ConfirmEmailPage'
import MileageMarkPage from '../views/public/MileageMarkPage'
import AlarmPage from '../views/public/AlarmPage'
import DoInstallationPage from '../views/public/DoInstallationPage'
import AdjustOdometerPage from '../views/public/AdjustOdometerPage'
import ConfirmCancellationPage from '../views/public/ConfirmCancellationPage'
import ConfirmRecreationPage from '../views/public/ConfirmRecreationPage'
import PaymentPage from '../views/public/PaymentPage'
import RefillSmsPage from '../views/public/RefillSmsPage'
import EnterInsuranceDetailsPage from '../views/public/EnterInsuranceDetailsPage'

//Protected pages
import Home from '../views/protected/Home'
import Map from '../views/protected/Map'
import Trackers from '../views/protected/Trackers'
import Contacts from '../views/protected/Contacts'
import Mechanics from '../views/protected/Mechanics'
import Mileage from '../views/protected/Mileage'
import Users from '../views/protected/Users'
import Billing from '../views/protected/Billing'
import Cancellation from '../views/protected/Cancellation'
import Profile from '../views/protected/Profile'
import NewCustomer from '../views/protected/NewCustomer'
import Newsletter from '../views/protected/Newsletter'
import StatusMessage from '../views/protected/StatusMessage'
import Orders from '../views/protected/Orders'

//Test pages
import TestToast from '../views/testpages/TestToast'
import TestPermissions from '../views/testpages/TestPermissions'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    //Public routes
    { path: '/login', component: LoginPage },
    { path: '/reset-password', component: ResetPasswordPage },
    { path: '/:action(reset|choose)-password/:token', component: ResetPasswordPage },
    { path: '/enter-insurance-details/:secret', component: EnterInsuranceDetailsPage },
    { path: '/confirm-change/:token/:email', component: ConfirmEmailPage },
    { path: '/mileage/:imei/adjust-odometer/:secret', component: AdjustOdometerPage },
    { path: '/do-installation/:imei/:secret', component: DoInstallationPage },
    { path: '/mileage/:imei/:tripId/:secret', component: MileageMarkPage },
    { path: '/alarm/:imei/:alarmId/:secret', component: AlarmPage },
    { path: '/confirm-cancellation/:imei/:secret', component: ConfirmCancellationPage },
    { path: '/confirm-recreation/:imei/:secret', component: ConfirmRecreationPage },
    { path: '/pay/:invoice/:secret', component: PaymentPage },
    { path: '/refill-sms/:secret', component: RefillSmsPage },

    //Protected routes
    { path: '/', component: Home },
    { path: '/map', component: Map },
    { path: '/map/:imei', component: Map },
    { path: '/trackers', component: Trackers },
    { path: '/trackers/:statusFilter(active|unassigned|expired)', component: Trackers },
    { path: '/trackers/:statusFilter(active|unassigned|expired)/:query', component: Trackers },
    { path: '/trackers/:query', component: Trackers },
    { path: '/contacts', component: Contacts },
    { path: '/mechanics', component: Mechanics },
    { path: '/mileage', component: Mileage },
    { path: '/users', component: Users },
    { path: '/orders', component: Orders },
    { path: '/billing', component: Billing },
    { path: '/billing/:activeTab(autopay|unpaid|sms|closed|cancel)', component: Billing },
    { path: '/cancellation', component: Cancellation },
    { path: '/profile', component: Profile },
    { path: '/new-customer', component: NewCustomer },
    { path: '/newsletter', component: Newsletter },
    { path: '/status-message', component: StatusMessage },

    //Test-only routes
    { path: '/test/toast', component: TestToast },
    { path: '/test/permissions', component: TestPermissions },

    // otherwise redirect to home
    { path: '*', redirect: '/' },
  ],
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/reset-password', '/register']
  let authRequired = !publicPages.includes(to.path)

  //http://my.tracknord.dk:6999/enter-insurance-details/k3nvjNIJCkHL2t6P
  if (to.path.match(/^\/enter-insurance-details\/[a-zA-Z0-9]{10,}/)) {
    authRequired = false
  }

  //http://my.tracknord.dk:6999/mileage/352093081347683/292/uS8SuKwjAGi0YBNM
  if (to.path.match(/^\/mileage\/[0-9]{15}\/[0-9]{1,}\/[a-zA-Z0-9]{10,}/)) {
    authRequired = false
  }

  //http://my.tracknord.dk:6999/mileage/358480087192235/adjust-odometer/cL3n4UJp1gry3sgz
  if (to.path.match(/^\/mileage\/[0-9]{15}\/adjust-odometer\/[a-zA-Z0-9]{10,}/)) {
    authRequired = false
  }

  //GET http://my.tracknord.dk:6999/do-installation/123456789012345/oPcm58QZ7ytybxA5
  if (to.path.match(/^\/do-installation\/[0-9]{15}\/[a-zA-Z0-9]{10,}/)) {
    authRequired = false
  }

  //http://my.tracknord.dk:6999/alarm/352093084238046/15/zyNpuvTvAS6bZQZb
  if (to.path.match(/^\/alarm\/[0-9]{15}\/[0-9]{1,}\/[a-zA-Z0-9]{10,}/)) {
    authRequired = false
  }

  if (to.path.match(/^\/pay\/.*/)) {
    authRequired = false
  }
  if (to.path.match(/^\/refill-sms\/.*/)) {
    authRequired = false
  }
  if (to.path.match(/^\/(reset|choose)-password\/.{2,}/)) {
    authRequired = false
  }
  if (to.path.match(/^\/confirm-(change|cancellation|recreation)\/.{2,}\/.{2,}/)) {
    authRequired = false
  }
  if (to.path.match(/^\/test\/.+/)) {
    authRequired = false
  }
  const loggedIn = LoginStore.state.user ? true : false

  if (authRequired && !loggedIn) {
    const loginpath = to.path.replace(new RegExp('^/+'), '')
    return next({
      path: '/login',
      query: loginpath
        ? {
            from: loginpath,
          }
        : null,
    })
  }

  next()
})

export default router
