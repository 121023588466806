<template>
  <McForm
    ref="mcFormInstance"
    v-bind="$attrs"
    @reset="handleReset"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    :submiticon="isEditing ? 'edit' : 'plus'"
    :title="isEditing ? $t('alarmContacts.editRecipient') : $t('alarmContacts.addRecipient')"
    :submittext="isEditing ? $t('general.Save') : $t('alarmContacts.addRecipient')"
  >
    <mc-auto-complete
      v-if="!isEditing"
      name="name"
      type="autocomplete"
      :data="internalOptions"
      :minMatchingChars="0"
      v-model="curData.name"
      @hit="autocompletehit"
      v-validate="'required'"
      :data-vv-as="$t('general.Name')"
      :label="$t('general.Name')"
      :placeholder="$t('general.EnterName')"
      key="alarmcontactform.name"
      :errorHint="errors.first('name')"
      :state="!errors.has('name')"
      icon="signature"
    />
    <mc-form-input
      v-if="isEditing"
      name="name"
      type="text"
      v-model="curData.name"
      v-validate="'required'"
      :data-vv-as="$t('general.Name')"
      :label="$t('general.Name')"
      :placeholder="$t('general.EnterName')"
      key="alarmcontactform.name"
      :errorHint="errors.first('name')"
      :state="!errors.has('name')"
      icon="signature"
    />

    <mc-form-checkboxes
      name="type"
      v-model="curData.type"
      :label="$t('alarmContacts.type')"
      key="alarmcontactform.type"
      :errorHint="errors.first('type')"
      :state="!errors.has('type')"
      :options="contactTypes"
      :icon="['fad', 'user-tag']"
      inline
    />

    <mc-form-input
      v-if="curData.type == 'email'"
      name="email"
      type="email"
      v-model="curData.email"
      v-validate="'required|email'"
      :data-vv-as="$t('general.Email')"
      :label="$t('general.Email')"
      :placeholder="$t('general.EnterEmail')"
      key="alarmcontactform.email"
      :errorHint="errors.first('email')"
      :state="!errors.has('email')"
      icon="at"
    />

    <mc-form-input
      v-if="curData.type == 'phone'"
      name="phone"
      type="text"
      v-model="curData.phone"
      v-validate="'required|phone'"
      :data-vv-as="$t('general.Phone')"
      :label="$t('general.Phone')"
      :placeholder="$t('general.EnterPhone')"
      key="alarmcontactform.phone"
      :errorHint="errors.first('phone')"
      :state="!errors.has('phone')"
      icon="phone"
    />
  </McForm>
</template>

<script>
import { isEmptyObject } from '../helpers/Misc'
import Normalize from '../helpers/Normalize'
import { FormatPhone } from '../helpers/Formatting'

const defaultData = {
  id: -1,
  name: '',
  type: null,
  email: '',
  phone: '',
}

export default {
  components: {},
  data() {
    return {
      curData: { ...defaultData },
      isEditing: false,
      contactTypes: [
        { value: 'email', label: this.$t('alarmContacts.contactTypeEmail') }, //
        { value: 'phone', label: this.$t('alarmContacts.contactTypePhone') },
      ],
    }
  },
  props: {
    value: Object,
    alarmContacts: Array,
    existingContacts: Array,
  },
  computed: {
    options: function() {
      var theList = []
      for (var contactId in this.alarmContacts) {
        if (this.alarmContacts[contactId].email) {
          theList.push({ value: this.alarmContacts[contactId], label: this.alarmContacts[contactId].name + ' (' + this.alarmContacts[contactId].email + ')' })
        } else {
          theList.push({ value: this.alarmContacts[contactId], label: this.alarmContacts[contactId].name + ' (' + FormatPhone(this.alarmContacts[contactId].phone) + ')' })
        }
      }
      return theList
    },
    internalOptions() {
      //Autocomplete options
      let textOptions = []
      for (let oId in this.options) {
        if (textOptions.includes(this.options[oId].label)) {
          continue //No duplicates
        } else if (this.existingContacts.includes(this.options[oId].value.email + this.options[oId].value.phone)) {
          continue //Omit existing contacts from autocomplete
        }
        textOptions.push(this.options[oId].label)
      }
      return textOptions
    },
  },
  watch: {
    value(val) {
      this.handleNewValue(val)
    },
  },
  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    handleNewValue(val) {
      this.$refs['mcFormInstance'].forceReset()

      //If empty object passed, we are not editing an existing object
      this.isEditing = !isEmptyObject(val)

      let newVal = {}
      for (var field in defaultData) {
        newVal[field] = val.hasOwnProperty(field) ? val[field] : defaultData[field]
      }
      if (newVal.email) {
        newVal.type = 'email'
      } else if (newVal.phone) {
        newVal.type = 'phone'
        newVal.phone = FormatPhone(val.phone)
      }
      this.curData = newVal
    },
    handleReset() {
      this.curData = { ...defaultData }
    },

    autocompletehit(val) {
      for (let oId in this.options) {
        if (this.options[oId].label == val) {
          this.curData.name = '-'
          setTimeout(() => {
            this.handleNewValue({ ...this.options[oId].value })
            this.isEditing = false
          }, 1)
          break
        }
      }
    },

    handleSubmit(e) {
      return new Promise((resolve, reject) => {
        let finalized = {
          id: this.curData.id,
          name: this.curData.name,
          email: this.curData.type == 'email' ? Normalize.email(this.curData.email) : '',
          phone: this.curData.type == 'phone' ? Normalize.phone(this.curData.phone) : '',
        }
        if (this.existingContacts.includes(finalized.email + finalized.phone)) {
          //console.log(this.errors)
          if (this.curData.type == 'phone') {
            this.errors.add({
              field: 'phone',
              msg: this.$t('alarmContacts.SMSRecipientExists'),
            })
          } else {
            this.errors.add({
              field: 'email',
              msg: this.$t('alarmContacts.EmailRecipientExists'),
            })
          }
          reject(this.$t('alarmContacts.' + (this.curData.type == 'email' ? 'Email' : 'SMS') + 'RecipientExists'))
          return
        }
        this.$emit('alarmContactUpdated', finalized)
        resolve(this.curData)
      })
    },
  },
}
</script>
