<template>
  <McForm
    ref="mcFormInstance"
    v-bind="$attrs"
    @reset="handleReset"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    submiticon="edit"
    :title="$t('mileage.EditNote')"
    :submittext="$t('general.Save')"
  >
    <!-- eslint-disable-next-line -->
    <mc-form-input
      name="note"
      type="textarea"
      v-model="curData.note"
      :label="$t('mileage.Note')"
      :placeholder="$t('mileage.EnterNote')"
      key="tripnoteform.note"
      icon="signature"
    />
    <!--pre> {{ curData }} </pre-->
  </McForm>
</template>

<script>
import { mapActions } from 'vuex'
import MileageService from '../services/MileageService'

const defaultData = {
  id: -1,
  imei: null,
  note: '',
}

export default {
  components: {},
  data() {
    return {
      curData: { ...defaultData },
    }
  },
  props: {
    value: Object,
  },
  watch: {
    value(val) {
      this.$refs['mcFormInstance'].forceReset()

      let newVal = {}
      for (var field in defaultData) {
        newVal[field] = val.hasOwnProperty(field) ? val[field] : defaultData[field]
      }
      this.curData = newVal
    },
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    handleReset() {
      this.curData = { ...defaultData }
    },

    handleSubmit(e) {
      return MileageService.setNote(this.curData.imei, this.curData.id, this.curData.note).then(
        data => {
          this.toastSuccess(this.$t('mileage.tripnotesuccess'))
          this.$emit('noteUpdated', this.curData)
          return Promise.resolve(data)
        },
        error => {
          console.error('Error saving note', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
