<template>
  <b-form-group :label="label" :state="!errorHint">
    <b-input-group :class="'mc-form-checkboxes' + (inline ? ' inline' : '')">
      <b-input-group-text v-if="icon" slot="prepend" class="bg-primary text-white">
        <McIcon :icon="icon" />
      </b-input-group-text>
      <b-input-group-text v-if="prepend" slot="prepend" class="bg-primary text-white">
        {{ prepend }}
      </b-input-group-text>
      <!-- prettier-ignore -->
      <div class="custom-file">
        {{ description || placeholder }}
        <div class="checkboxes" v-if="multiple">
          <template v-for="(item, id) in options">
            <label v-bind:key="id"><input type="checkbox" v-model="internalValue" :value="item.value" /><span>{{ item.label }}</span></label>
            <slot v-if="item.slotname" :name="item.slotname"></slot>
          </template>
        </div>
        <div class="radioboxes" v-else>
          <label v-for="(item, id) in options" v-bind:key="id"><input type="radio" v-model="internalValue" :value="item.value" /><span>{{ item.label }}</span></label>
        </div>
      </div>
    </b-input-group>
    <TransitionToAuto v-if="errorHint">
      <div class="invalid-feedback d-block">{{ errorHint }}</div>
    </TransitionToAuto>
  </b-form-group>
</template>

<script>
export default {
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        return val
      },
    },
  },
  props: {
    icon: [String, Array],
    errorHint: String,
    label: String,
    description: String,
    placeholder: String,
    prepend: String,
    value: [Number, String, Array, Boolean],
    options: [Array, Object],
    inline: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style>
.input-group.mc-form-checkboxes > .custom-file {
  height: auto;
  display: block;
  padding: 0 10px;
  max-height: 200px;
  overflow: auto;
  border: 1px solid #ced4da;
  border-left: 0;
  border-radius: 0 3px 3px 0;
  margin-top: 0px;
  margin-bottom: 0px;
}
.input-group.mc-form-checkboxes input {
  margin-right: 10px;
  margin-left: 10px;
}
.input-group.mc-form-checkboxes label {
  display: block;
  margin: 0;
  white-space: nowrap;
}
.input-group.mc-form-checkboxes label span {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
  max-width: calc(100% - 40px);
}
.input-group.mc-form-checkboxes.inline label span {
  max-width: none;
}
.input-group.mc-form-checkboxes.inline label {
  display: inline-block;
  line-height: 2em;
}
</style>
