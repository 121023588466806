import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '../language/en.json'
import da from '../language/da.json'

Vue.use(VueI18n)

const messages = {
  en: en,
  da: da,
}

let useLocale = localStorage.getItem('currentLanguage') || (navigator.language || navigator.userLanguage).substring(0, 2)
if (!useLocale || (useLocale !== 'en' && useLocale !== 'da')) {
  useLocale = 'en'
}

export const i18n = new VueI18n({
  locale: useLocale,
  fallbackLocale: 'en',
  messages,
})
