<template>
  <div @mousedown="startJumpSlide" @touchstart="startTouchSlide" :class="'historySlider' + (sliding !== false ? ' sliding' : '')">
    <div ref="theTrack" class="sliderTrack">
      <div ref="theHandle" @mousedown="startSlide" :style="'left:' + (100 * value) / max + '%'" class="sliderHandle boxShadow btn-primary">
        <span class="theLabel">{{ label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.addEventListener('mousemove', this.doSlide)
    window.addEventListener('mouseup', this.endSlide)
    window.addEventListener('touchmove', this.doTouchSlide)
    window.addEventListener('touchend', this.endSlide)
    this.curx = 0
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.doSlide)
    window.removeEventListener('mouseup', this.endSlide)
    window.removeEventListener('touchmove', this.doTouchSlide)
    window.removeEventListener('touchend', this.endSlide)
  },
  methods: {
    startSlide(e) {
      if (e.button != 0) {
        return
      }
      e.preventDefault()
      e.stopPropagation()
      let rect = e.target.getBoundingClientRect()
      let eventPos = e.clientX - rect.left - rect.width / 2
      this.sliding = eventPos
    },
    startTouchSlide(e) {
      e.button = 0
      e.clientX = e.touches[0].pageX
      this.startJumpSlide(e)
    },
    doTouchSlide(e) {
      e.button = 0
      e.clientX = e.touches[0].pageX
      this.startJumpSlide(e)
    },
    startJumpSlide(e) {
      if (e.button != 0) {
        return
      }
      e.preventDefault()
      e.stopPropagation()
      let rect = this.$refs.theTrack.getBoundingClientRect()
      let eventPos = e.clientX - rect.left
      this.sliding = 0
      let percentPos = eventPos / rect.width
      this.value = percentPos * this.max
    },
    endSlide(e) {
      this.sliding = false
    },
    doSlide(e) {
      if (this.sliding !== false) {
        e.preventDefault()
        e.stopPropagation()
        let rect = this.$refs.theTrack.getBoundingClientRect()
        let eventPos = e.clientX - rect.left - this.sliding
        let percentPos = eventPos / rect.width
        this.value = percentPos * this.max
      }
    },
  },
  computed: {
    value: {
      get() {
        return this.internal_value
      },
      set(newValue) {
        this.internal_value = Math.round(Math.max(0, Math.min(this.max, newValue)))
        this.$emit('input', this.internal_value)
      },
    },
  },
  props: {
    max: {
      type: Number,
      default: 100,
    },
    label: String,
  },
  data() {
    return {
      sliding: false,
      internal_value: 0,
    }
  },
}
</script>

<style lang="scss" scoped>
.theLabel {
  position: absolute;
  text-align: center;
  top: -1.3em;
  transform: translateX(-50%);
  left: 50%;
  color: #000;
  font-size: 12px;
  white-space: nowrap;
}
.historySlider {
  display: flow-root;
  height: 66px;
  position: relative;
  &.sliding,
  &.sliding .sliderHandle {
    cursor: grabbing;
  }
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.sliderTrack {
  position: absolute;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.3) inset;
  top: 38px;
  bottom: 18px;
  left: 18px;
  right: 18px;
}
.sliderHandle {
  position: absolute;
  top: 50%;
  left: 0px;
  height: 36px;
  width: 36px;
  border-radius: 36px;
  transform: translate(-50%, -50%);
  cursor: grab;
}
</style>
