import { apiRequest } from '../setup/ApiService'
import Router from '../setup/Router'

export default {
  reportMissing,
}

function reportMissing(locale, path) {
  //return Promise.resolve('')
  return apiRequest(
    '/translations/report-missing', //
    'POST',
    {
      locale,
      path,
      uri: Router.currentRoute.path,
    },
  )
}
