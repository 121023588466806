import { apiRequest, authorizedApiRequest, queryParameters, addLanguageAndTimezone } from '../setup/ApiService'

export default {
  fetch: function(imei, from, to, minDistance) {
    const params = {
      imei,
      from,
      to,
      minDistance,
    }
    return authorizedApiRequest(
      '/mileage/fetch?' + queryParameters(params), //
      'GET',
    )
  },
  getAddresses: function (imei, tripIds) {
    const params = {
      imei,
      tripIds,
    }
    return authorizedApiRequest(
      '/mileage/get-addresses?' + queryParameters(addLanguageAndTimezone(params)), //
      'GET',
    )
  },
  getAddressesWithSecret: function(imei, tripId, secret) {
    const params = {
      imei,
      tripId,
      secret,
    }
    return apiRequest(
      '/mileage/mark-trip/get-addresses?' + queryParameters(addLanguageAndTimezone(params)), //
      'GET',
    )
  },
  setNote: function(imei, id, note) {
    return authorizedApiRequest(
      '/mileage/' + imei + '/' + id, //
      'PUT',
      {
        note,
      },
    )
  },
  setOdometer: function(imei, odometer) {
    return authorizedApiRequest(
      '/mileage/' + imei + '/odometer', //
      'PUT',
      {
        odometer,
      },
    )
  },
  setOdometerWithSecret: function(imei, odometer, secret) {
    return apiRequest(
      '/mileage/adjust-odometer/' + imei + '?' + queryParameters({ secret }), //
      'PUT',
      {
        odometer,
      },
    )
  },
  getOdometer: function(imei) {
    return authorizedApiRequest(
      '/mileage/' + imei + '/odometer', //
      'GET',
    )
  },
  getOdometerBySecret: function(imei, secret) {
    return apiRequest(
      '/mileage/adjust-odometer/' + imei + '?' + queryParameters({ secret }), //
      'GET',
    )
  },
  setType: function(imei, id, type) {
    return authorizedApiRequest(
      '/mileage/' + imei + '/' + id, //
      'PUT',
      {
        type,
      },
    )
  },
  setTypeWithSecret: function(imei, id, secret, type) {
    return apiRequest(
      '/mileage/mark-trip/' + imei + '/' + id + '?' + queryParameters({ secret }), //
      'PUT',
      {
        type,
      },
    )
  },
  export: function(imei, from, to, minDistance, type, otherOptions, format, distanceUnit) {
    return authorizedApiRequest(
      '/mileage/' +
        imei +
        '/export?' +
        queryParameters(
          addLanguageAndTimezone({
            format,
            from,
            to,
            minDistance,
            type,
            otherOptions,
            distanceUnit,
          }),
        ),
      'GET',
    )
  },
  checkTripSecret: function(imei, tripId, secret) {
    return apiRequest(
      '/mileage/mark-trip' +
        '/' +
        imei +
        '/' +
        tripId +
        '?' +
        queryParameters(
          addLanguageAndTimezone({
            secret,
          }),
        ),
      'GET',
    )
  },
}
