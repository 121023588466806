<!-- eslint-disable -->
<template>
  <McLayoutDefault>
    <h1>Your user:</h1>
    <pre>{{ LoginStore.user }}</pre>
    
    <h1>Your $perms:</h1>
    <pre>{{ LoginStore.permissions }}</pre>
    
    <h3>Tests:</h3>
    <table class="permstests">
      <tr><td>isAdministrator():</td><td>{{ $perms.isAdministrator() }}</td></tr>
      <tr><td>hasRole('administrator'):</td><td>{{ $perms.hasRole('administrator') }}</td></tr>
      <tr><td>hasRole('user'):</td><td>{{ $perms.hasRole('user') }}</td></tr>

      <tr><td>isAdministratorOfTrackers():</td><td>{{ $perms.isAdministratorOfTrackers() }}</td></tr>
      <tr><td>isOwnerOfTrackers():</td><td>{{ $perms.isOwnerOfTrackers() }}</td></tr>
      <tr><td>isBillingOfTrackers():</td><td>{{ $perms.isBillingOfTrackers() }}</td></tr>
      <tr><td>hasTrackersWithAnyEditPerms():</td><td>{{ $perms.hasTrackersWithAnyEditPerms() }}</td></tr>

      <tr><td>hasTrackersWithPerm('currentPosition'):</td><td>{{ $perms.hasTrackersWithPerm('currentPosition') }}</td></tr>
      <tr><td>hasTrackersWithPerm('history'):</td><td>{{ $perms.hasTrackersWithPerm('currentPosition') }}</td></tr>
      <tr><td>hasTrackersWithPerm('mileage'):</td><td>{{ $perms.hasTrackersWithPerm('mileage') }}</td></tr>
      <tr><td>hasTrackersWithPerm('name'):</td><td>{{ $perms.hasTrackersWithPerm('name') }}</td></tr>
      <tr><td>hasTrackersWithPerm('colorAndIcon'):</td><td>{{ $perms.hasTrackersWithPerm('colorAndIcon') }}</td></tr>
      <tr><td>hasTrackersWithPerm('alarmRules'):</td><td>{{ $perms.hasTrackersWithPerm('alarmRules') }}</td></tr>
      <tr><td>hasTrackersWithPerm('settings'):</td><td>{{ $perms.hasTrackersWithPerm('settings') }}</td></tr>
      <tr><td>hasTrackersWithPerm('currentPosition'):</td><td>{{ $perms.hasTrackersWithPerm('currentPosition') }}</td></tr>
      <tr><td>hasTrackersWithPerm('currentPosition'):</td><td>{{ $perms.hasTrackersWithPerm('currentPosition') }}</td></tr>
    </table>
  </McLayoutDefault>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      LoginStore: state => state.LoginStore,
    }),
  },
}
</script>

<style lang="scss" scoped>
.permstests {
  td:first-child {
    text-align: right;
    padding-right: 1em;
  }
}
</style>
