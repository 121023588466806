import Vue from 'vue'

//Our own messages
import validationMessagesEn from '../language/en-validations'
import validationMessagesDa from '../language/da-validations'

//Import the minimal version without rules and messages
import { Validator, install as VeeValidate } from 'vee-validate/dist/vee-validate.minimal.esm.js'
import { required, email, min, max, is, length, numeric, url } from 'vee-validate/dist/rules.esm.js'

// Add the rules we need.
Validator.extend('required', required)
Validator.extend('email', email)
Validator.extend('min', min)
Validator.extend('max', max)
Validator.extend('is', is)
Validator.extend('length', length)
Validator.extend('numeric', numeric)
Validator.extend('url', url)

Validator.extend('phone', value => Promise.resolve({ valid: value.match(/^\s*\+?[0-9\s\-]+$/) !== null }))

// Register plugin...
Vue.use(VeeValidate, {
  locale: 'en',
  dictionary: {
    en: validationMessagesEn,
    da: validationMessagesDa,
  },

  // we'll instantiate manually
  inject: false,

  // default conflicts, set non-standard
  fieldsBagName: 'veeFields',
})
