<template>
  <div class="insurance-tests-wrap my-5">
    <div v-if="loadingState">
      <McSpinner large />
    </div>
    <div v-else>
      <h3 class="text-center">{{ $t('insuranceTests.ConnectionTests') }} <McButton size="sm" :active="activeState" :text="$t('insuranceTests.TestNow')" :icon="'car'" @click="doTest" /></h3>
      <b-table-simple :small="true" stacked="md" striped class="mileage-table mt-3 mb-0">
        <b-thead>
          <b-tr>
            <b-th class="text-center">{{ $t('insuranceTests.DateCreated') }}</b-th>
            <b-th>{{ $t('insuranceTests.Recipients') }}</b-th>
            <b-th class="text-center">{{ $t('insuranceTests.DateConfirmed') }}</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="journal in journals" v-bind:key="journal.id">
            <b-td class="text-center align-middle">
              <TnLocalTime class="badge badge-dark" :time="journal.dateCreated" format="l LT" />
            </b-td>
            <b-td class="align-middle">
              <div v-for="(contact, id) in journal.data.recipients" v-bind:key="id">
                <span class="contact_label">{{ contact.name }} <br class="d-md-none" /><strong>({{ contact.email ? contact.email : FormatPhone(contact.phone) }})</strong></span>
              </div>
            </b-td>
            <b-td class="text-center align-middle">
              <b-button-group v-if="journal.data.status == 0">
                <McButton size="sm" :active="activeMarking == journal.id" variant="success" :text="$t('insuranceTests.TestSucceeded')" :icon="['fas', 'check-circle']" @click="markTest(journal.id, 1)" />
                <McButton size="sm" :active="activeMarking == journal.id" variant="danger" :text="$t('insuranceTests.TestFailed')" :icon="['fas', 'times-circle']" @click="markTest(journal.id, 2)" />
              </b-button-group>
              <div v-else-if="journal.data.status == 1">
                <McIcon icon="check-circle" class="text-success" />
                {{ $t('insuranceTests.TestSucceeded') }}
                <TnLocalTime v-if="journal.data.dateConfirmed" :class="'badge badge-dark bg-' + (journal.data.status == 1 ? 'success' : 'danger')" :time="journal.data.dateConfirmed" format="l LT" />
              </div>
              <div v-else>
                <McIcon icon="times-circle" class="text-danger" />
                {{ $t('insuranceTests.TestFailed') }}
                <br />
                <McButton size="sm" :active="activeMarking == journal.id" icon="trash" variant="outline-danger" :text="$t('insuranceTests.DeleteFailedTest')" @click="deleteTest(journal.id)" />
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
import InsuranceTestsService from '../services/InsuranceTestsService'
import { FormatPhone } from '../helpers/Formatting'
import { FormatError } from '../helpers/ErrorFormatting'

export default {
  data() {
    return {
      loadingState: true,
      activeState: false,
      activeMarking: -1,
      journals: null,
    }
  },
  mounted() {
    this.getRecent()
  },
  methods: {
    FormatPhone(phone) {
      return FormatPhone(phone)
    },
    getRecent() {
      return InsuranceTestsService.recent()
        .then(data => {
          this.loadingState = false
          this.journals = data.journals
        })
        .catch(error => {
          this.loadingState = FormatError(error)
        })
    },
    doTest() {
      this.activeState = true
      InsuranceTestsService.createTest().finally(() => {
        this.getRecent().finally(() => {
          this.activeState = false
        })
      })
    },
    markTest(id, status) {
      this.activeMarking = id
      InsuranceTestsService.mark(id, status).finally(() => {
        this.getRecent().finally(() => {
          this.activeMarking = -1
        })
      })
    },
    deleteTest(id) {
      this.activeMarking = id
      InsuranceTestsService.delete(id).finally(() => {
        this.getRecent().finally(() => {
          this.activeMarking = -1
        })
      })
    },
  },
}
</script>
