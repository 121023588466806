<template>
  <b-form-group :label="label" :description="description" :state="!errorHint">
    <!--pre style="height: 100px;overflow:auto;">{{ geofenceList }}</pre>
    <pre style="height: 100px;overflow:auto;">{{ geofences }}</pre-->

    <b-input-group class="mc-form-geofence-input">
      <b-input-group-text v-if="icon" slot="prepend" class="bg-primary text-white">
        <McIcon :icon="icon" />
      </b-input-group-text>
      <!-- prettier-ignore -->

      <div class="custom-file">
        <div class="select_and_button">
          <select @input="emitValue" :value="value" :style="value=='-1' ? 'border-bottom: none' : ''">
            <option v-for="(item, id) in geofenceList" v-bind:key="id" :value="item.value">{{ item.label }}</option>
          </select>
          <McButton v-if="value == '-1'" icon="plus" :title="$t('geofences.Add')" @click="editGeofence(value)" />
          <McButton v-if="value != '-1'" icon="edit" :title="$t('geofences.Edit')" @click="editGeofence(value)" />
        </div>
        <div ref="minimap" :class="'minimap ' + (value!='-1' ? 'shown' : 'hidden')"></div>
      </div>
    </b-input-group>
    <TransitionToAuto v-if="errorHint">
      <div class="invalid-feedback d-block">{{ errorHint }}</div>
    </TransitionToAuto>
    <GeofenceForm size="xl" :id="'geofenceform' + _uid" isModal :value="editingGeofence" @geofenceUpdated="geofenceUpdated" />
  </b-form-group>
</template>

<script>
import gmapsInit from '../map/googleMaps'

export default {
  data() {
    return {
      editingGeofence: {},
      editingGeofenceId: 0,
      map: null,
    }
  },
  mounted: function() {
    this.showGeoFence()
  },
  props: {
    value: [String, Number],
    icon: [String, Array],
    errorHint: String,
    label: String,
    description: String,
    geofences: Array,
  },
  watch: {
    value(newVal) {
      this.showGeoFence()
    },
  },
  computed: {
    geofenceList() {
      let list = []
      list.push({ value: -1, label: this.$t('geofences.CreateNewGeofence') })
      this.geofences.forEach(geofence => {
        list.push({ value: geofence.id, label: geofence.name })
      })
      return list
    },
  },
  methods: {
    geoFenceIdFromId(id) {
      for (let i in this.geofences) {
        if (this.geofences[i].id == id) {
          return i
        }
      }
      return null
    },

    editGeofence(id) {
      if (id == -1) {
        this.editingGeofenceId = -1
        this.editingGeofence = {}
      } else {
        this.editingGeofenceId = this.geoFenceIdFromId(id)
        this.editingGeofence = { ...this.geofences[this.editingGeofenceId] }
      }
      this.$bvModal.show('geofenceform' + this._uid)
    },
    geofenceUpdated(geofence) {
      let newGeofences = [...this.geofences]
      if (this.editingGeofenceId == -1) {
        let newId = 0
        do {
          newId++
        } while (this.geoFenceIdFromId('new' + newId))
        geofence.id = 'new' + newId
        newGeofences.push(geofence)
      } else {
        geofence.id = this.geofences[this.editingGeofenceId].id
        newGeofences[this.editingGeofenceId] = geofence
      }
      this.$emit('geofencesEdited', newGeofences)
      setTimeout(() => {
        this.$emit('input', geofence.id)
        this.showGeoFence()
      }, 100)
    },
    showGeoFence: function() {
      if (this.value == '-1') {
        return
      }
      try {
        this.loadMap(this.$el.querySelector('.minimap')).then(() => {
          let arrI = this.geoFenceIdFromId(this.value)

          var bounds = new google.maps.LatLngBounds()

          for (let i in this.geofences[arrI].circles) {
            let circle = this.geofences[arrI].circles[i]
            //console.log(circle)
            let gCircle = new google.maps.Circle({
              radius: circle.radius,
              center: {
                lat: circle.center.lat,
                lng: circle.center.lng,
              },
              geodesic: true,
              fillColor: '#0000FF',
              fillOpacity: 0.2,
              strokeColor: '#0000FF',
              strokeOpacity: 1.0,
              strokeWeight: 1,
              zIndex: 999,
              map: this.map,
            })
            bounds.union(gCircle.getBounds())
          }
          this.map.fitBounds(bounds)
        })
      } catch (error) {
        console.error(error)
      }
    },
    emitValue: function(e) {
      let newVal = e.target.value
      this.$emit('input', newVal)
    },
    async loadMap(elm) {
      try {
        const google = await gmapsInit()
        const mapOpts = {
          center: {
            lat: 55.68,
            lng: 11.1,
          },
          zoom: 12,
          minZoom: 2,
          restriction: {
            latLngBounds: {
              north: 85,
              south: -85,
              west: -180,
              east: 180,
            },
          },
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
        }
        this.map = new google.maps.Map(elm, mapOpts)
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<style lang="scss">
.input-group.mc-form-geofence-input {
  > .custom-file {
    height: auto;
    display: block;
    padding: 0 0;
    overflow: auto;
    border: 1px solid #ced4da;
    border-left: 0;
    border-radius: 0 3px 3px 0;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .select_and_button {
    display: flex;
    button {
      border-radius: 0px;
    }
  }
  select {
    text-align: center;
    width: 100%;
    border: none;
    border-bottom: 1px solid #ced4da;
    margin: 0;
    height: 38px;
  }
  .minimap {
    width: 100%;
    height: 140px;
    &.hidden {
      display: none;
    }
  }
}
.largermap {
  width: 100%;
  height: 400px;
}
</style>
