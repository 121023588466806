<template>
  <div class="billing-invoices-unpaid-wrap">
    <McSpinner v-if="invoices === null" large class="my-5" />
    <div v-else-if="unpaidInvoices.length == 0" class="alert alert-success">
      <p class="my-2">{{ $t('billing.invoicesUnpaidEmptyDescription') }}</p>
    </div>
    <div v-else>
      <McTable class="unpaid-invoices-table" ref="unpaidInvoicesTable" :sortBy="sortBy" :primaryKey="primaryKey" :fields="fields" :items="unpaidInvoices" :provider="null">
        <template v-slot:cell(cb)="data">
          <input type="checkbox" :value="data.item.id" v-model="selectedInvoiceIds" :checked="data.item.daysUntilDueDate < 14" />
        </template>
        <template v-slot:cell(id)="data">
          <a target="_blank" :href="data.item.download">#{{ data.item.id }}</a>
        </template>
        <template v-slot:cell(text)="data">
          <a target="_blank" :href="data.item.download">{{ data.item.text }}</a>
        </template>
        <template v-slot:cell(dateDue)="data">{{ data.item.dateDue | shortDate }}</template>
        <template v-slot:cell(amount)="data">{{ niceCurrency(data.item.amountIncVatInCents, data.item.currency) }}</template>
      </McTable>
      <div v-if="selectedInvoices.length > 0">
        {{ $tc('billing.PayTotalForChosenInvoices', selectedInvoices.length, { total: totalAmount, invoiceCount: selectedInvoices.length }) }}
        <BillingAutopayCards
          :invoiceIds="selectedInvoiceIds"
          :autopayCards="autopayCards"
          :currency="currency"
          @invoicesUpdated="e => $emit('invoicesUpdated', e)"
          @cardsUpdated="e => $emit('cardsUpdated', e)"
        />
      </div>
      <div v-else-if="unpaidInvoices.length > 0">
        {{ $t('billing.ChooseInvoicesAbove') }}
      </div>
      <!--pre>{{ selectedInvoices }}</pre-->
    </div>
  </div>
</template>
<script>
import { dateToGMTHumanreadableDate } from '../helpers/Time'
import { niceCurrency } from '../helpers/Formatting'

export default {
  props: {
    invoices: {
      type: [Object, null],
      default: () => null,
    },
    currency: {
      type: String,
      default: 'DKK',
    },
    autopayCards: {
      type: [Array, null],
      default: () => null,
    },
  },
  data() {
    return {
      primaryKey: 'id',
      sortBy: 'dateDue',
      selectedInvoiceIds: [],
    }
  },
  methods: {
    niceCurrency(amountInCents, currency) {
      return niceCurrency(amountInCents, currency)
    },
  },
  computed: {
    selectedInvoices() {
      let selectedInvoices = []
      for (var idx in this.invoices.unpaid) {
        if (this.selectedInvoiceIds.includes(this.invoices.unpaid[idx].id)) {
          selectedInvoices.push(this.invoices.unpaid[idx])
        }
      }
      return selectedInvoices
    },
    totalAmount() {
      let total = 0
      let currency = 'DKK'
      this.selectedInvoices.forEach(element => {
        total += element.amountIncVatInCents
        currency = element.currency
      })
      return niceCurrency(total, currency)
    },
    unpaidInvoices() {
      let unpaidInvoices = []
      unpaidInvoices = this.invoices.unpaid
      return unpaidInvoices
    },
    fields() {
      return [
        { key: 'cb', priority: 0, mw: 10, sortable: false, label: '' }, //
        { key: 'id', priority: 0, mw: 120, sortable: true, label: this.$t('billing.InvoiceNumber') }, //
        { key: 'dateDue', priority: 3, mw: 120, sortable: true, label: this.$t('billing.DateDue') },
        { key: 'text', priority: 3, mw: 200, sortable: true, label: this.$t('billing.InvoiceText') },
        { key: 'amount', priority: 0, mw: 100, sortable: true, label: this.$t('billing.Amount'), class: 'text-right' },
      ]
    },
  },
  filters: {
    shortDate(v) {
      return dateToGMTHumanreadableDate(v)
    },
  },
}
</script>
