export const isEmptyObject = function(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

let currentPosition = false

export const userCoordinates = function() {
  return new Promise((resolve, reject) => {
    if (currentPosition) {
      return resolve(currentPosition)
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          currentPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }
          //console.log('Got user coords: ', currentPosition)
          return resolve(currentPosition)
        },
        error => {
          //Fallback:
          currentPosition = {
            lat: 55.27,
            lng: 11.72,
          }
          //console.error('Failed to get user coords')
          return resolve(currentPosition)
        },
      )
    }
  })
}
