<template>
  <McLayoutDefault :heading="$t('headings.billing')">
    <b-card :title="$t('permissions.LackingPermissions')" class="mb-2" v-if="!$perms.isBillingOfTrackers() && !$perms.isAdministrator()">
      <p>{{ $t('permissions.LackingPermissionsLonger') }}</p>
    </b-card>
    <div v-else-if="$perms.isAdministrator()">
      <AdminBilling />
    </div>
    <div v-else>
      <b-tabs lazy>
        <b-tab :title="$t('billing.AutopayCards')" :active="typeof $route.params.activeTab === 'undefined' || $route.params.activeTab === 'autopay'">
          <p class="mt-3">{{ $t('billing.autopayCardsDescription1') }}</p>
          <p class="mb-3">{{ $t('billing.autopayCardsDescription2') }}</p>
          <BillingAutopayCards :autopayCards="autopayCards" :currency="currency" @cardsUpdated="cardsUpdated()" />
        </b-tab>
        <b-tab :active="typeof $route.params.activeTab !== 'undefined' && $route.params.activeTab === 'unpaid'">
          <template v-slot:title>
            {{ $t('billing.OpenInvoices') }}
            <b-badge small v-if="invoices && invoices.unpaid.length > 0">{{ invoices.unpaid.length }}</b-badge>
          </template>
          <p class="mt-3">{{ $t('billing.invoicesUnpaidDescription1') }}</p>
          <p>{{ $t('billing.invoicesUnpaidDescription2') }}</p>
          <p class="mb-3">{{ $t('billing.invoicesUnpaidDescription3') }}</p>
          <BillingInvoicesUnpaid :autopayCards="autopayCards" :currency="currency" @cardsUpdated="cardsUpdated()" @invoicesUpdated="invoicesUpdated()" :invoices="invoices" />
        </b-tab>
        <b-tab :active="typeof $route.params.activeTab !== 'undefined' && $route.params.activeTab === 'sms'">
          <template v-slot:title>
            {{ $t('billing.SMSaccount') }}
            <!--b-badge small v-if="smsBalance < 30">{{ smsBalance }}</b-badge-->
          </template>
          <p class="mt-3">{{ $t('billing.SMSaccountDescription1') }}</p>
          <p class="mb-3">{{ $t('billing.SMSaccountDescription2') }}</p>
          <BillingSmsAccount :autopayCards="autopayCards" :currency="currency" @cardsUpdated="cardsUpdated()" @invoicesUpdated="invoicesUpdated()" />
        </b-tab>
        <b-tab :title="$t('billing.ClosedInvoices')" :active="typeof $route.params.activeTab !== 'undefined' && $route.params.activeTab === 'closed'">
          <p class="my-3">{{ $t('billing.invoicesPaidDescription') }}</p>
          <BillingInvoicesPaid :invoices="invoices" />
        </b-tab>
        <b-tab :title="$t('headings.cancellationRecreation')" :active="typeof $route.params.activeTab !== 'undefined' && $route.params.activeTab === 'cancel'">
          <BillingCancellation />
        </b-tab>
      </b-tabs>
    </div>
  </McLayoutDefault>
</template>

<script>
import BillingService from '../../services/BillingService'
import { FormatError } from '../../helpers/ErrorFormatting'

export default {
  data() {
    return {
      autopayCards: null,
      invoices: null,
      currency: 'DKK', //TODO: Make this switchable
    }
  },
  methods: {
    cardsUpdated() {
      return BillingService.autopayCards().then(data => {
        this.autopayCards = data.cards
      })
    },
    invoicesUpdated() {
      return BillingService.invoices().then(data => {
        this.invoices = data.invoices
      })
    },
  },
  created() {
    Promise.all([
      this.cardsUpdated(), //
      this.invoicesUpdated(),
    ])
      .then(() => {
        this.loadingState = false
        //console.log('done')
      })
      .catch(error => {
        this.loadingState = FormatError(error)
        //console.log('fail')
      })
  },
}
</script>
