import { authorizedApiRequest } from '../setup/ApiService'

export default {
  send: function geocode(data) {
    return authorizedApiRequest(
      '/newsletter/send', //
      'POST',
      data,
    )
  },
}
