<template>
  <McLayoutDefault :heading="$t('headings.mechanics')">
    <b-card :title="$t('permissions.LackingPermissions')" class="mb-2" v-if="!$perms.isAdministrator()">
      <p>{{ $t('permissions.LackingPermissionsLonger') }}</p>
    </b-card>
    <McTable v-else class="mechanics-table" ref="mechanicsTable" :sortBy="sortBy" :primaryKey="primaryKey" :fields="fields" :provider="dataProvider">
      <McButton @click="editMechanic({})" variant="primary" icon="plus" :text="$t('mechanics.add')" />
      <template v-slot:cell(pre)="data">
        <pre>{{ data.item }}</pre>
      </template>
      <template v-slot:cell(fullAddress)="data">
        <div v-if="data.item.name && data.item.type === 'I'">{{ data.item.name }}</div>
        <div v-if="data.item.company">{{ data.item.company }}</div>
        <div v-if="data.item.name && data.item.type !== 'I'">att: {{ data.item.name }}</div>
        <div v-if="data.item.address1">{{ data.item.address1 }}</div>
        <div v-if="data.item.address2">{{ data.item.address2 }}</div>
        <div>{{ data.item.zip }} {{ data.item.city }}</div>
        <div v-if="data.item.country.toLowerCase() !== 'danmark'">{{ data.item.country }}</div>
        <div v-if="data.item.cvr">
          <small>{{ $t('general.CvrColon', { cvr: data.item.cvr }) }}</small>
        </div>
        <div v-if="data.item.ean">
          <small>{{ $t('general.EanColon', { ean: data.item.ean }) }}</small>
        </div>
        <div v-if="data.item.reference">
          <small>{{ $t('general.ReferenceColon', { reference: data.item.reference }) }}</small>
        </div>
        <div v-if="data.item.rekvisition">
          <small>{{ $t('general.RekvisitionColon', { rekvisition: data.item.rekvisition }) }}</small>
        </div>
      </template>
      <template v-slot:cell(mechanicInfo)="data">
        <div v-if="data.item.email">
          <a :href="'mailto:' + data.item.email">{{ data.item.email }}</a>
        </div>
        <div v-if="data.item.phone">
          <a :href="'tel:' + data.item.phone">{{ data.item.phone }}</a>
        </div>
        <div v-if="data.item.website">
          <a :href="data.item.website" target="_blank">{{ data.item.website }}</a>
        </div>
      </template>

      <template v-slot:cell(actions)="data">
        <b-button-group>
          <!-- eslint-disable-next-line -->
          <McButton
            icon="edit"
            variant="secondary"
            @click="editMechanic(data.item)"
            size="sm"
          />
          <McButton icon="trash" variant="danger" size="sm" @click="deletemechanic(data.item.id, data.item.name)" />
        </b-button-group>
      </template>
    </McTable>
    <MechanicForm id="mechanicform" isModal :value="editingMechanic" @completed="refreshTable()" />
  </McLayoutDefault>
</template>

<script>
import MechanicsService from './../../services/MechanicsService'

export default {
  data() {
    return {
      primaryKey: 'id',
      sortBy: 'name',
      dataProvider: MechanicsService.query,
      editingMechanic: {},
    }
  },
  computed: {
    fields() {
      let retval = []
      retval.push({ key: 'mechanicInfo', mw: 250, sortable: false, label: this.$t('mechanics.mechanicInfo') })
      retval.push({ key: 'fullAddress', mw: 250, sortable: false, label: this.$t('mechanics.details') })
      //retval.push({ key: 'pre', priority: 0, mw: 200, label: 'pre' })
      retval.push({ key: 'actions', priority: 0, mw: 200, label: this.$t('general.Actions'), class: 'text-right' })
      return retval
    },
  },
  methods: {
    refreshTable() {
      this.$refs.mechanicsTable.refresh()
    },
    editMechanic(item) {
      //console.log(item)
      this.editingMechanic = { ...item }
      this.$bvModal.show('mechanicform')
    },
    deletemechanic(id, name) {
      if (confirm(this.$t('mechanics.confirmdelete', { name }))) {
        MechanicsService.delete(id).then(data => {
          this.refreshTable()
        })
      }
    },
  },
}
</script>
