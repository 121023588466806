<template>
  <McLayoutForm>
    <div v-if="loadingState">
      <McSpinner large />
    </div>
    <div v-else>
      <h2>{{ $t('general.Error') }}</h2>
      <p>{{ $t('users.InvalidConfirmationToken') }}</p>
      <p>{{ $t('users.RetryChanging') }}</p>
    </div>
  </McLayoutForm>
</template>

<script>
import Router from '../../setup/Router'
import { FormatError } from '../../helpers/ErrorFormatting'
import { mapActions } from 'vuex'
import AuthenticationService from '../../services/AuthenticationService'

export default {
  data() {
    return {
      loadingState: true,
    }
  },
  created() {
    // Log out when opening this form
    this.logout()
    this.checkToken(this.$route.params.token, this.$route.params.email)
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),
    ...mapActions('LoginStore', [
      'logout', //
    ]),

    checkToken(token, email) {
      return new Promise((resolve, reject) => {
        AuthenticationService.checkChangeToken(token, email)
          .then(data => {
            Router.push('/login')
            this.toastSuccess(this.$t('users.EmailChangeSuccess'))
            resolve(data)
          })
          .catch(error => {
            console.error(FormatError(error))
            this.loadingState = false
          })
      })
    },
  },
}
</script>
