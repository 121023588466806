<template>
  <div v-if="true" class="btn-group" role="group">
    <button :class="'btn btn-sm ' + (value == 'unknown' ? 'btn-primary' : 'btn-secondary')" title="$t('mileage.UnknownType')" @click="$emit('input', 'unknown')">
      <font-awesome-icon icon="question" fixed-width />
    </button>
    <button :class="'btn btn-sm ' + (value == 'business' ? 'btn-primary' : 'btn-secondary')" title="$t('mileage.BusinessType')" @click="$emit('input', 'business')">
      <font-awesome-icon icon="building" fixed-width />
    </button>
    <button :class="'btn btn-sm ' + (value == 'private' ? 'btn-primary' : 'btn-secondary')" title="$t('mileage.PrivateType')" @click="$emit('input', 'private')">
      <font-awesome-icon :icon="['fad', 'house']" fixed-width />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
  },
}
</script>
