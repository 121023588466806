<template>
  <div>
    <span v-if="address" v-html="address.replace(/, D[ea]nmark/, '').replace(/,/g, '<br>')" />
    <McSpinner v-else />
  </div>
</template>

<script>
export default {
  props: {
    address: String,
  },
}
</script>
