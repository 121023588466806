import { apiRequest, authorizedApiRequest, addLanguageAndTimezone, queryParameters } from '../setup/ApiService'

export default {
  getByImei: imei => {
    return authorizedApiRequest(
      '/installations/' + imei, //
      'GET',
    )
  },
  getMechanicInstructions: imei => {
    return authorizedApiRequest(
      '/installations/' + imei + '/mechanic-instructions', //
      'GET',
    )
  },
  getInstallationBySecret: (imei, secret) => {
    return apiRequest(
      '/installations/do-installation/' + imei + '?' + queryParameters({ secret }), //
      'GET',
    )
  },
  setInstallationBySecret: (imei, installation, secret) => {
    return apiRequest(
      '/installations/do-installation/' + imei + '?' + queryParameters({ secret }), //
      'PUT',
      { installation },
    )
  },
  getInsuranceDetailsBySecret: secret => {
    return apiRequest(
      '/installations/insurance-details?' + queryParameters({ secret }), //
      'GET',
    )
  },
  setInsuranceDetailsBySecret: (insuranceDetails, secret) => {
    return apiRequest(
      '/installations/insurance-details?' + queryParameters({ secret }), //
      'PUT',
      addLanguageAndTimezone({
        insuranceDetails,
      }),
    )
  },
  setInstallationStepBySecret: (imei, step, secret) => {
    return apiRequest(
      '/installations/do-installation/' + imei + '/step?' + queryParameters({ secret }), //
      'PUT',
      { step },
    )
  },
  getInstallationStepBySecret: (imei, secret) => {
    return apiRequest(
      '/installations/do-installation/' + imei + '/step?' + queryParameters({ secret }), //
      'GET',
    )
  },
  setByImei: (imei, installation, download) => {
    return authorizedApiRequest(
      '/installations/' + imei, //
      'PUT',
      addLanguageAndTimezone({
        installation,
        download,
      }),
    )
  },
}
