<template>
  <div class="contact-address-wrap">
    <div v-if="contact.type === 'I'">
      <strong>{{ $t('general.ContactTypeIndividual') }}</strong>
    </div>
    <div v-if="contact.type === 'C'">
      <strong>{{ $t('general.ContactTypeCompany') }}</strong>
    </div>
    <div v-if="contact.type === 'A'">
      <strong>{{ $t('general.ContactTypeAssociation') }}</strong>
    </div>
    <div v-if="contact.type === 'P'">
      <strong>{{ $t('general.ContactTypePublic') }}</strong>
    </div>
    <div v-if="contact.name && contact.type === 'I'">{{ contact.name }}</div>
    <div v-if="contact.company">{{ contact.company }}</div>
    <div v-if="contact.name && contact.type !== 'I'">att: {{ contact.name }}</div>
    <div v-if="contact.address1">{{ contact.address1 }}</div>
    <div v-if="contact.address2">{{ contact.address2 }}</div>
    <div>{{ contact.zip }} {{ contact.city }}</div>
    <div v-if="contact.country.toLowerCase() !== 'danmark'">{{ contact.country }}</div>
    <div v-if="contact.cvr">
      <small>{{ $t('general.CvrColon', { cvr: contact.cvr }) }}</small>
    </div>
    <div v-if="contact.ean">
      <small>{{ $t('general.EanColon', { ean: contact.ean }) }}</small>
    </div>
    <div v-if="contact.reference">
      <small>{{ $t('general.ReferenceColon', { reference: contact.reference }) }}</small>
    </div>
    <div v-if="contact.rekvisition">
      <small>{{ $t('general.RekvisitionColon', { rekvisition: contact.rekvisition }) }}</small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contact: Object,
  },
}
</script>