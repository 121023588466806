<template>
  <McForm
    ref="mcFormInstance"
    :loading="loadingState"
    v-bind="$attrs"
    @reset="handleReset"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    :title="$t('headings.statusMessage')"
    :submittext="$t('general.Save')"
    class="statusMessageForm"
    :successText="$t('statusMessage.success')"
  >
    <b-card :title="$t('statusMessage.' + lang)" class="mb-2" v-for="lang in ['da', 'en']" v-bind:key="lang">
      <!-- eslint-disable-next-line -->
      <mc-form-input
        name="subject"
        type="text"
        v-model="curData.subject[lang]"
        :placeholder="$t('statusMessage.EnterSubject')"
        key="statusmessageform.subject"
      />

      <wysiwyg v-model="curData.body[lang]" :placeholder="$t('statusMessage.EnterBody')" class="mb-3" />
    </b-card>

    <!--pre> {{ curData }} </pre-->
  </McForm>
</template>

<script>
import { mapActions } from 'vuex'
import AdminService from '../services/AdminService'

const defaultData = {
  subject: {
    en: '',
    da: '',
  },
  body: {
    en: '',
    da: '',
  },
}

export default {
  components: {},
  data() {
    return {
      loadingState: true,
      curData: { ...defaultData },
    }
  },
  props: {
    value: Object,
  },
  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  mounted() {
    this.loadingState = true
    AdminService.getStatusMessage()
      .then(data => {
        if (typeof data === 'object') {
          this.curData = data
        }
      })
      .then(data => {
        this.loadingState = false
      })
      .catch(error => {
        this.loadingState = FormatError(error)
      })
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    handleReset() {},

    handleSubmit(e) {
      return AdminService.setStatusMessage(this.curData).then(
        data => {
          this.toastSuccess(this.$t('statusMessage.success'))
          return Promise.resolve(data)
        },
        error => {
          console.error('Error saving', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
