<template>
  <div>
    <mcFormDualInputs
      :errorHint="errors.first('insuranceContact1Name') || errors.first('insuranceContact1Phone')"
      :state="!errors.has('insuranceContact1Name') && !errors.has('insuranceContact1Phone')"
    >
      <template v-slot:input1>
        <mc-form-input
          name="insuranceContact1Name"
          type="text"
          v-model="insuranceSettings.contact1.name"
          v-validate="$perms.isAdministrator() ? '' : 'required'"
          :data-vv-as="$t('general.Name')"
          :label="$t('general.NameForPerson1')"
          :placeholder="$t('general.EnterName')"
          key="insuranceSettings.insuranceContact1Name"
          icon="signature"
        />
      </template>
      <template v-slot:input2>
        <mc-form-input
          name="insuranceContact1Phone"
          type="text"
          v-model="insuranceSettings.contact1.phone"
          v-validate="$perms.isAdministrator() ? 'phone' : 'required|phone'"
          :data-vv-as="$t('general.Phone')"
          :label="$t('general.PhoneForPerson1')"
          :placeholder="$t('general.EnterPhone')"
          key="insuranceSettings.insuranceContact1Phone"
          icon="phone"
        />
      </template>
    </mcFormDualInputs>
    <mcFormDualInputs
      :errorHint="errors.first('insuranceContact2Name') || errors.first('insuranceContact2Phone')"
      :state="!errors.has('insuranceContact2Name') && !errors.has('insuranceContact2Phone')"
    >
      <template v-slot:input1>
        <mc-form-input
          name="insuranceContact2Name"
          type="text"
          v-model="insuranceSettings.contact2.name"
          v-validate="$perms.isAdministrator() ? '' : 'required'"
          :data-vv-as="$t('general.Name')"
          :label="$t('general.NameForPerson2')"
          :placeholder="$t('general.EnterName')"
          key="insuranceSettings.insuranceContact2Name"
          icon="signature"
        />
      </template>
      <template v-slot:input2>
        <mc-form-input
          name="insuranceContact2Phone"
          type="text"
          v-model="insuranceSettings.contact2.phone"
          v-validate="$perms.isAdministrator() ? 'phone' : 'required|phone'"
          :data-vv-as="$t('general.Phone')"
          :label="$t('general.PhoneForPerson2')"
          :placeholder="$t('general.EnterPhone')"
          key="insuranceSettings.insuranceContact2Phone"
          icon="phone"
        />
      </template>
    </mcFormDualInputs>

    <b-form-group v-if="$perms.isAdministrator() && withBackupContact">
      <b-input-group class="text-center">
        <b-form-checkbox name="remember" v-model="insuranceSettings.enableBackupContact">
          {{ $t('alarms.EnableBackupContact') }}
        </b-form-checkbox>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
export default {
  inject: ['$validator'],
  data() {
    return {
      insuranceSettings: {},
    }
  },
  created() {
    this.insuranceSettings = this.value
  },
  props: {
    value: Object,
    withBackupContact: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
