<template>
  <McForm v-bind="$attrs" @reset="handleReset" :onSubmit="handleSubmit" :hasReset="!hasCancel" buttonsOnRight :submiticon="['fad', 'history']" :submittext="$t('map.historysubmit')">
    <p class="hidewhensmall">
      <strong>{{ $t('map.history') }}</strong>
    </p>
    <p class="hidewhensmall">{{ $t('map.historydescription') }}</p>

    <McFormDateRange v-model="curData.dateRange" :min="minDate" :max="maxDate" />
    <template v-slot:secondarybuttons>
      <McButton v-if="hasCancel" @click="$emit('cancel')" :text="$t('general.Cancel')" variant="link" />
    </template>
  </McForm>
</template>

<script>
import moment from 'moment'
import { mapState, mapActions } from 'vuex'
import { mysqlTimeStringFromDate } from '../helpers/Time'
import TrackerStatusService from './../services/TrackerStatusService'

const defaultData = {
  dateRange: {
    from: moment()
      .subtract(1, 'days')
      .startOf('day')
      .toDate(),
    to: moment()
      .endOf('day')
      .toDate(),
  },
}

export default {
  components: {},
  props: {
    hasCancel: Boolean,
  },
  data() {
    return {
      curData: {
        dateRange: {
          ...defaultData.dateRange,
        },
      },
      minDate: moment(new Date())
        .subtract(6, 'month')
        .startOf('day')
        .toDate(),
      maxDate: moment(new Date())
        .endOf('day')
        .toDate(),
    }
  },
  computed: {
    ...mapState({
      MapViewStore: state => state.MapViewStore,
    }),
  },
  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    ...mapActions('MapViewStore', [
      'showHistory', //
    ]),
    handleReset() {
      //Don't reset this one...
    },

    handleSubmit(e) {
      return TrackerStatusService.getHistory(
        this.MapViewStore.zoomedTracker, //
        mysqlTimeStringFromDate(this.curData.dateRange.from),
        mysqlTimeStringFromDate(this.curData.dateRange.to),
      ).then(
        data => {
          if (data.length === 0) {
            return Promise.reject(this.$t('map.nohistorypoints'))
          } else {
            let last = {}
            for (let i = 0; i < data.length; i++) {
              for (let prop in last) {
                if (!data[i].hasOwnProperty(prop)) {
                  data[i][prop] = last[prop]
                }
              }
              last = data[i]
            }
            this.showHistory({
              points: data,
              range: this.curData.dateRange,
            })
            return Promise.resolve(data)
          }
        },
        error => {
          console.error(error)
        },
      )
    },
  },
}
</script>
