<template>
  <div v-bind="$attrs" :style="{ fontSize: large ? '4em' : '1em', display: inline ? 'inline-block' : 'block', textAlign: 'center' }">
    <font-awesome-icon icon="spinner" spin fixed-width />
  </div>
</template>

<script>
export default {
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
