<template>
  <div class="header_wrapper pushContentBelowFixedHeader">
    <b-navbar fixed="top" toggleable="md" type="light" variant="white" class="shadow">
      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
      <b-navbar-brand to="/home"><img src="../assets/TrackNordic-logo-dark-80h.png" width="321" height="80"/></b-navbar-brand>
      <b-collapse is-nav id="nav_collapse">
        <b-navbar-nav v-if="LoginStore.user">
          <b-nav-item :active="$route.path == '/'" to="/">{{ $t('headings.home') }}</b-nav-item>
          <b-nav-item :active="$route.path.startsWith('/map')" v-if="$perms.hasTrackersWithPerm('currentPosition') || $perms.isAdministrator()" to="/map">{{ $t('headings.map') }}</b-nav-item>
          <b-nav-item :active="$route.path == '/mileage'" v-if="$perms.hasTrackersWithPerm('mileage') || $perms.isAdministrator()" to="/mileage">{{ $t('headings.mileage') }}</b-nav-item>
          <b-nav-item-dropdown v-bind:text="$t('interface.administration')">
            <b-dropdown-item :active="$route.path == '/orders'" v-if="$perms.isAdministrator()" to="/orders">{{ $t('headings.orders') }}</b-dropdown-item>
            <b-dropdown-item :active="$route.path == '/trackers'" v-if="$perms.isAdministratorOfTrackers() || $perms.hasTrackersWithAnyEditPerms() || $perms.isAdministrator()" to="/trackers">{{ $t('headings.trackers') }}</b-dropdown-item>
            <b-dropdown-item :active="$route.path == '/users'" v-if="$perms.isAdministratorOfTrackers() || $perms.isAdministrator()" to="/users">{{ $t('headings.users') }}</b-dropdown-item>
            <b-dropdown-item :active="$route.path == '/contacts'" v-if="true || $perms.isAdministratorOfTrackers() || $perms.isAdministrator()" to="/contacts">{{ $t('headings.contacts') }}</b-dropdown-item>
            <b-dropdown-item :active="$route.path == '/billing'" v-if="$perms.isBillingOfTrackers() || $perms.isAdministrator()" to="/billing">{{ $t('headings.billing') }}</b-dropdown-item>
            <b-dropdown-item :active="$route.path == '/new-customer'" v-if="$perms.isAdministrator()" to="/new-customer">{{ $t('headings.newCustomer') }}</b-dropdown-item>
            <b-dropdown-item :active="$route.path == '/mechanics'" v-if="$perms.isAdministrator()" to="/mechanics">{{ $t('headings.mechanics') }}</b-dropdown-item>
            <b-dropdown-item :active="$route.path == '/cancellation'" v-else-if="$perms.isOwnerOfTrackers() || $perms.isAdministratorOfTrackers()" to="/cancellation">{{ $t('headings.cancellationRecreation') }}</b-dropdown-item>
            <b-dropdown-item :active="$route.path == '/newsletter'" v-if="$perms.isAdministrator()" to="/newsletter">{{ $t('headings.newsletter') }}</b-dropdown-item>
            <b-dropdown-item :active="$route.path == '/status-message'" v-if="$perms.isAdministrator()" to="/status-message">{{ $t('headings.statusMessage') }}</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <!--b-nav-item-dropdown text="Tests" right>
            <b-nav-item to="/test/toast">Toast</b-nav-item>
            <b-nav-item to="/test/permissions">Permissions</b-nav-item>
          </b-nav-item-dropdown-->

          <b-nav-item-dropdown v-bind:text="$t('interface.language')" right>
            <b-dropdown-item :active="LanguageStore.currentLanguage == 'da'" @click="setLang('da')">
              Dansk
            </b-dropdown-item>
            <b-dropdown-item :active="LanguageStore.currentLanguage == 'en'" @click="setLang('en')">
              English
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown v-if="LoginStore.user" right>
            <template slot="button-content">
              <em>{{ LoginStore.user.name }}</em>
            </template>
            <b-dropdown-item :active="$route.path == '/profile'" to="/profile">{{ $t('headings.Profile') }}</b-dropdown-item>
            <b-dropdown-item to="/login" right>{{ $t('general.logOut') }}</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item v-if="!LoginStore.user" to="/login" right>
            {{ $t('general.logIn') }}
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProfileService from '../services/ProfileService'

export default {
  methods: {
    setLang: function(lang) {
      this.$store.dispatch('LanguageStore/setLang', lang)
      if (this.LoginStore.user) {
        ProfileService.setLanguage(lang)
      }
    },
  },
  computed: {
    ...mapState({
      LoginStore: state => state.LoginStore,
      LanguageStore: state => state.LanguageStore,
    }),
  },
}
</script>

<style lang="scss">
@media (min-width: 768px) {
  .header_wrapper {
    .dropdown-menu {
      display: block;
      opacity: 0;
    }
    .navbar-nav .dropdown-menu {
      transform: scale(0.5, 0) translate(0, -10px);
      transform-origin: 50% 0%;
      transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    }

    .navbar-nav .dropdown-menu.show {
      opacity: 1;
      transform: scale(1) translate(0, 0);
    }
  }
}

.navbar-brand > img {
  height: 40px;
  width: auto;
}

.pushContentBelowFixedHeader {
  height: 66px;
  flex-shrink: 0;
}
</style>
