<template>
  <McForm
    ref="mcFormInstance"
    :loading="loadingState"
    v-bind="$attrs"
    @reset="handleReset"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    :submiticon="isEditing ? 'user-edit' : 'user-plus'"
    :title="isEditing ? $t('users.edit') : $t('users.add')"
    :submittext="isEditing ? $t('general.Save') : $t('users.add')"
  >
    <p v-if="!isEditing">{{ $t('users.createDescription') }}</p>

    <mc-form-checkboxes
      v-if="$perms.isAdministrator()"
      :disabled="isEditing"
      name="role"
      v-model="curData.role"
      :label="$t('users.role')"
      key="userform.role"
      :errorHint="errors.first('role')"
      :state="!errors.has('role')"
      :options="userRoles"
      :icon="['fad', 'user-tag']"
    />

    <mc-form-input
      name="name"
      type="text"
      v-model="curData.name"
      v-validate="'required'"
      :data-vv-as="$t('general.Name')"
      :label="$t('general.Name')"
      :placeholder="$t('general.EnterName')"
      key="userform.name"
      :errorHint="errors.first('name')"
      :state="!errors.has('name')"
      icon="signature"
      :disabled="isEditing && !$perms.isAdministrator()"
    />

    <mc-form-input
      name="email"
      type="email"
      v-model="curData.email"
      v-validate="'required|email'"
      :data-vv-as="$t('general.Email')"
      :label="$t('general.Email')"
      :placeholder="$t('general.enterEmail')"
      key="userform.email"
      :errorHint="errors.first('email')"
      :state="!errors.has('email')"
      icon="at"
      :disabled="isEditing && !$perms.isAdministrator()"
    />

    <b-form-group :label="$t('permissions.Permissions')" v-if="curData.role !== 'administrator'">
      <div class="trackerperms__wrapper">
        <div v-for="(tracker, imei) in trackerList" v-bind:key="imei">
          <div class="trackerperms__majorlabels">
            <label>
              <McIcon v-if="tracker.icon" sized :style="{ color: tracker.color }" :icon="tracker.icon" />
              {{ tracker.name }}
            </label>

            <label v-if="tracker.userId != curData.id || curData.id < 1">
              <input type="radio" value="none" v-model="curData.permsMajor[imei]" v-on:input="v => applyMajor(imei, v.target.value)" :name="'permsMajor[' + imei + ']'" />
              {{ $t('general.permsNone') }}
            </label>
            <label v-if="tracker.userId != curData.id || curData.id < 1">
              <input type="radio" value="all" v-model="curData.permsMajor[imei]" v-on:input="v => applyMajor(imei, v.target.value)" :name="'permsMajor[' + imei + ']'" />
              {{ $t('general.permsAll') }}
            </label>
            <label v-if="tracker.userId != curData.id || curData.id < 1">
              <input type="radio" value="some" v-model="curData.permsMajor[imei]" v-on:input="v => applyMajor(imei, v.target.value)" :name="'permsMajor[' + imei + ']'" />
              {{ $t('general.permsSome') }}
            </label>
            <label v-if="tracker.userId == curData.id && curData.id > 0">
              {{ $t('permissions.isAdmin') }}
            </label>
            <!-- pre>{{ tracker.perms }}</pre -->
          </div>
          <div v-if="curData.permsMajor[imei] === 'some'" class="trackerperms__minorlabels">
            <label v-for="permName in tracker.perms" v-bind:key="imei + 'pp' + permName">
              <input type="checkbox" :value="permName" v-model="curData.permissions[imei][permName]" :name="'permsMinor[' + imei + ']'" />
              {{ $t('permissions.' + permName) }}
            </label>
          </div>
        </div>
      </div>
    </b-form-group>
    <!-- pre>{{ curData }}</pre -->
  </McForm>
</template>

<script>
import { mapActions } from 'vuex'
import UserService from '../services/UserService'
import TrackerService from '../services/TrackerService'
import { isEmptyObject } from '../helpers/Misc'
import { FormatError } from '../helpers/ErrorFormatting'

const defaultData = {
  id: -1,
  name: '',
  email: '',
  role: 'user',
  permissions: {},
  permsMajor: {},
}

export default {
  components: {},
  data() {
    return {
      curData: { ...defaultData },
      loadingState: true,
      isEditing: false,
      trackerList: [],
      userRoles: [
        { value: 'user', label: this.$t('users.userTypeUser') }, //
        { value: 'administrator', label: this.$t('users.userTypeAdministrator') },
      ],
    }
  },
  props: {
    value: Object,
  },
  watch: {
    value(val) {
      this.$refs['mcFormInstance'].forceReset()

      //If empty object passed, we are not editing an existing object
      this.isEditing = !isEmptyObject(val)

      let newVal = {}
      for (var field in defaultData) {
        newVal[field] = val.hasOwnProperty(field) ? val[field] : defaultData[field]
      }
      newVal.permsMajor = {}
      newVal.permissions = {}

      for (var imei in this.trackerList) {
        let hasAll = true
        let hasSome = false
        newVal.permissions[imei] = {}
        for (var pId in this.trackerList[imei].perms) {
          let permName = this.trackerList[imei].perms[pId]
          if (typeof val.assignedPermissions !== 'undefined' && typeof val.assignedPermissions[imei] !== 'undefined') {
            newVal.permissions[imei][permName] = val.assignedPermissions[imei][permName]
            //console.log(imei, permName, val.assignedPermissions[imei][permName])
            if (val.assignedPermissions[imei][permName]) {
              hasSome = true
            } else {
              hasAll = false
            }
          } else {
            hasAll = false
            newVal.permissions[imei][permName] = false
          }
        }
        if (this.trackerList[imei].userId == newVal.id && newVal.id > 0) {
          hasAll = true
        }
        if (hasAll) {
          newVal.permsMajor[imei] = 'all'
        } else {
          newVal.permsMajor[imei] = hasSome ? 'some' : 'none'
        }
      }

      this.curData = newVal
    },
  },

  mounted() {
    TrackerService.list()
      .then(data => {
        let trackerList = {}
        for (var imei in data) {
          if (this.$perms.isAdministrator() || this.$perms.isAdministratorOfTracker(imei)) {
            trackerList[imei] = data[imei]
          }
        }
        this.trackerList = trackerList

        defaultData.permissions = {}
        for (var imei in this.trackerList) {
          defaultData.permsMajor[imei] = 'none'
          defaultData.permissions[imei] = {}
          for (var pId in this.trackerList[imei].perms) {
            let permName = this.trackerList[imei].perms[pId]
            defaultData.permissions[imei][permName] = false
          }
        }
        this.loadingState = false
      })
      .catch(error => {
        this.loadingState = FormatError(error)
      })
  },

  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    applyMajor(imei, newVal) {
      for (var pId in this.trackerList[imei].perms) {
        let permName = this.trackerList[imei].perms[pId]
        if (newVal === 'all') {
          this.curData.permissions[imei][permName] = true
        } else if (newVal === 'none') {
          this.curData.permissions[imei][permName] = false
        }
      }
    },

    handleReset() {
      this.curData = { ...defaultData }
    },

    handleSubmit(e) {
      var action = UserService.create
      if (this.curData.id > 0) {
        action = UserService.update
      }

      let postData = { ...this.curData }
      delete postData['permsMajor']
      postData.permissions = {}
      if (this.curData.role !== 'administrator') {
        let hasPerms = false
        for (let imei in this.curData.permissions) {
          for (let permName in this.curData.permissions[imei]) {
            if (this.curData.permissions[imei][permName]) {
              postData.permissions[imei] = postData.permissions[imei] ? postData.permissions[imei] : []
              postData.permissions[imei].push(permName)
              hasPerms = true
            }
          }
        }
        if (!hasPerms && !this.$perms.isAdministrator()) {
          return Promise.reject(this.$t('permissions.PermissionsRequired'))
        }
      }

      return action(postData).then(
        data => {
          if (data.message && data.message === 'userExistedPermissionsAssigned') {
            this.toastSuccess(this.$t('users.successUserExistedPermissionsAssigned'))
          } else if (this.curData.id > 0) {
            this.toastSuccess(this.$t('users.successUpdate'))
          } else {
            this.toastSuccess(this.$t('users.successCreate'))
          }
          return Promise.resolve(data)
        },
        error => {
          console.error('Error saving contact', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>

<style lang="scss">
.trackerperms__wrapper {
  border: 1px solid #888;
  border-radius: 3px;
  overflow: auto;
  max-height: 230px;
  > div {
    &:nth-child(odd) {
      background: #eee;
    }
  }
}
.trackerperms__majorlabels {
  display: flex;
  label {
    white-space: nowrap;
    padding: 0 1em;
    flex-grow: 0;
    &:first-child {
      white-space: normal;
      padding-left: 0;
      flex-grow: 1;
      float: left;
    }
  }
}
.trackerperms__minorlabels {
  font-size: 0.8em;
  padding: 0 0 1em 4em;
  label {
    display: block;
    padding: 0;
    margin: 0;
  }
}
</style>
