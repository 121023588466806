<template>
  <McLayoutDefault>
    <h1>Testpage...</h1>
    <McButton type="link" class="mr-2" text="success" @click="toastSuccess('test')" />
    <McButton type="link" class="mr-2" text="danger" @click="toastDanger('test')" />
    <McButton type="link" class="mr-2" text="warning" @click="toastWarning('test')" />
    <McButton type="link" class="mr-2" text="info" @click="toastInfo('test')" />
    <McButton type="link" class="mr-2" text="simple" @click="toastSimple('test')" />
    <!-- prettier-ignore -->
    <McButton type="link" text="persistent" @click="toastWarning({
      heading: 'persistent',
      body: 'test',
      timeout: false,
      icon: 'spinner'
    })" />
  </McLayoutDefault>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('ToastStore', [
      'toastSimple', //
      'toastSuccess',
      'toastDanger',
      'toastInfo',
      'toastWarning',
    ]),
  },
}
</script>
