import { authorizedApiRequest, queryParameters, addLanguageAndTimezone } from '../setup/ApiService'

export default {
  all,
  query,
  createOrUpdate,
  delete: _delete,
}

function query(params) {
  return authorizedApiRequest(
    '/mechanics?' + queryParameters(params), //
    'GET',
  )
}

function all(params) {
  return authorizedApiRequest(
    '/mechanics/all', //
    'GET',
  )
}

function createOrUpdate(mechanic) {
  return authorizedApiRequest(
    `/mechanics/${mechanic.id}`, //
    'PUT',
    addLanguageAndTimezone(mechanic),
  )
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return authorizedApiRequest(
    `/mechanics/${id}`, //
    'DELETE',
  )
}
