<template>
  <!-- eslint-disable-next-line -->
  <McForm
    ref="mcFormInstance"
    v-bind="$attrs"
    @reset="handleReset"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    :submiticon="['fas', 'piggy-bank']"
    :submittext="$t('billing.RegisterPayment')"
  >
    <mc-form-date-picker
      name="dateReceived"
      v-model="curData.dateReceived"
      :label="$t('billing.DateReceived')"
      key="billingForm.dateReceived"
      :errorHint="errors.first('dateReceived')"
      :state="!errors.has('dateReceived')"
      :icon="['far', 'calendar']"
      :config="{ format: 'L', timeZone: 'GMT' }"
    />

    <mc-form-select
      name="method"
      v-model="curData.method"
      :placeholder="$t('billing.ChooseMethod')"
      :label="$t('billing.Method')"
      key="billingForm.method"
      :options="methodList"
      :icon="['fad', 'user-chart']"
    />

    <mc-form-input
      name="amountInCents"
      ref="amountInCents"
      type="text"
      :value="curData.amountInCents"
      @change="curData.amountInCents = $parseCurrency($event, { allowNegative: false, locale: LanguageStore.currentLanguage, valueAsInteger: true })"
      :label="$t('billing.Amount')"
      v-currency="{ allowNegative: false, locale: LanguageStore.currentLanguage, valueAsInteger: true }"
      :placeholder="$t('billing.EnterAmount')"
      key="billingForm.amountInCents"
      :nondescription="$t('billing.AmountDescription')"
      :icon="['fad', 'badge-dollar']"
    />

    <mc-form-input
      name="humanPaymentId"
      type="text"
      v-model="curData.humanPaymentId"
      v-validate="'max:64'"
      :data-vv-as="$t('billing.HumanPaymentId')"
      :label="$t('billing.HumanPaymentId')"
      :placeholder="$t('billing.EnterHumanPaymentIdOptional')"
      key="billingForm.humanPaymentId"
      :errorHint="errors.first('humanPaymentId')"
      :state="!errors.has('humanPaymentId')"
      icon="signature"
    />

    <!--pre>{{ invoiceIds }}</pre>
    <pre>{{ selectedAmountInCents }}</pre>
    <pre>{{ selectedCurrency }}</pre>
    <pre> {{ curData }} </pre-->
  </McForm>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BillingService from '../services/BillingService'
//import { datetimeLocalStringToDate } from '../helpers/Time'
import moment from 'moment'
import { setValue } from 'vue-currency-input';
import { mysqlTimeStringFromDate } from '../helpers/Time'

const defaultData = {
  dateReceived: moment().toDate(),
  humanPaymentId: '',
  method: 'bank',
  amountInCents: 0,
  currency: 'DKK',
}

export default {
  components: {},
  data() {
    return {
      curData: this.defaultData(),
    }
  },
  props: {
    invoices: {
      type: [Array, null],
      default: () => null,
    },
    currency: {
      type: String,
      default: 'DKK',
    },
    amountInCents: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.curData.currency = this.selectedCurrency
    this.curData.amountInCents = this.selectedAmountInCents
  },
  watch: {
    invoices(val) {
      this.curData.currency = this.selectedCurrency
      setValue(this.$refs.amountInCents.inputElm().$el, this.selectedAmountInCents)
    },
  },
  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    defaultData() {
      return {
        ...defaultData,
        amountInCents: this.selectedAmountInCents,
        currency: this.selectedCurrency,
      }
    },

    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    handleReset() {
      this.curData = this.defaultData()
    },

    handleSubmit(e) {
      let postData = this.curData
      postData.dateReceived = mysqlTimeStringFromDate(postData.dateReceived)
      postData.invoiceIds = this.invoiceIds
      return BillingService.manualPayment(postData).then(
        data => {
          this.toastSuccess(this.$t('billing.PaymentRegistered'))
          return Promise.resolve(data)
        },
        error => {
          console.error('Error registering payment', error)
          return Promise.reject(error)
        },
      )
    },
  },
  computed: {
    ...mapState({
      LanguageStore: state => state.LanguageStore,
    }),
    methodList: function() {
      return [
        { value: 'bank', label: this.$t('billing.methods.bank') }, //
        { value: 'ean', label: this.$t('billing.methods.ean') },
        { value: 'cash', label: this.$t('billing.methods.cash') },
        { value: 'other', label: this.$t('billing.methods.other') },
      ]
    },
    invoiceIds() {
      let invoiceIds = []
      this.invoices.forEach(element => {
        invoiceIds.push(element.id)
      })
      return invoiceIds
    },
    selectedAmountInCents() {
      let total = 0
      this.invoices.forEach(element => {
        total += element.amountIncVatInCents
      })
      return total
    },
    selectedCurrency() {
      this.invoices.forEach(element => {
        return element.currency
      })
      return 'DKK'
    },
  },
}
</script>
