<template>
  <div class="billing-cancellation-wrap">
    <div v-if="autorenewedTrackers.length > 0">
      <p class="my-3">{{ $t('billing.cancellationDescription') }}</p>

      <!-- eslint-disable-next-line -->
      <mc-form-select
        name="type"
        v-model="imeiCancel"
        :label="$t('general.Tracker')"
        :placeholder="$t('general.ChooseTracker')"
        :options="autorenewedTrackers"
        :icon="['fad', 'boxes']"
      />

      <b-button class="my-2" variant="danger" block v-if="imeiCancel" @click="cancelTracker">
        <i18n path="billing.CancelSubscriptionFor" tag="span">
          <template v-slot:tracker>
            <McIcon v-if="trackers[imeiCancel] && trackers[imeiCancel].icon" sized :style="{ background: trackers[imeiCancel].color }" :icon="trackers[imeiCancel].icon" />
            <span v-if="trackers[imeiCancel]" class="trackerName">{{ trackers[imeiCancel].trackerName }}</span>
            <span v-else>{{ imeiCancel }}</span>
          </template>
        </i18n>
      </b-button>
    </div>

    <div v-if="cancelledTrackers.length > 0">
      <p class="my-3">{{ $t('billing.recreateDescription') }}</p>

      <!-- eslint-disable-next-line -->
      <mc-form-select
        name="type"
        v-model="imeiRecreate"
        :label="$t('general.Tracker')"
        :placeholder="$t('general.ChooseTracker')"
        :options="cancelledTrackers"
        :icon="['fad', 'boxes']"
      />

      <b-button class="my-2" variant="success" block v-if="imeiRecreate" @click="recreateTracker">
        <i18n path="billing.RecreateSubscriptionFor" tag="span">
          <template v-slot:tracker>
            <McIcon v-if="trackers[imeiRecreate] && trackers[imeiRecreate].icon" sized :style="{ background: trackers[imeiRecreate].color }" :icon="trackers[imeiRecreate].icon" />
            <span v-if="trackers[imeiRecreate]" class="trackerName">{{ trackers[imeiRecreate].trackerName }}</span>
            <span v-else>{{ imeiRecreate }}</span>
          </template>
        </i18n>
      </b-button>
    </div>

    <!--pre>{{ $perms.permissionToCancelTrackers() }}</pre>
    <pre>{{ MapViewStore.trackerList }}</pre-->
  </div>
</template>
<script>
import TrackerService from './../services/TrackerService'
import { FormatError } from './../helpers/ErrorFormatting'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      imeiCancel: '',
      imeiRecreate: '',
      trackers: {},
    }
  },
  mounted() {
    TrackerService.listForCancellation().then(data => {
      this.trackers = data
    })
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),
    cancelTracker() {
      let tracker = this.trackers[this.imeiCancel]
      if (confirm(this.$t('billing.ConfirmCancellationOfTracker', tracker))) {
        TrackerService.cancel(this.imeiCancel)
          .then(data => {
            this.toastSuccess(this.$t('billing.SubscriptionCancellationStarted', tracker))
          })
          .catch(error => {
            alert(FormatError(error))
          })
      }
    },
    recreateTracker() {
      let tracker = this.trackers[this.imeiRecreate]
      if (confirm(this.$t('billing.ConfirmRecreationOfTracker', tracker))) {
        TrackerService.recreate(this.imeiRecreate)
          .then(data => {
            this.toastSuccess(this.$t('billing.SubscriptionRecreationStarted', tracker))
          })
          .catch(error => {
            alert(FormatError(error))
          })
      }
    },
  },
  computed: {
    autorenewedTrackers() {
      let opts = [{ value: 0, label: this.$t('general.ChooseTracker') }]
      let permittedTrackers = this.$perms.permissionToCancelTrackers()
      for (let id in permittedTrackers) {
        let imei = permittedTrackers[id]
        if (this.trackers[imei] && this.trackers[imei].autoRenew) {
          opts.push({
            value: imei,
            label: this.trackers[imei].trackerName ? this.trackers[imei].trackerName + ' (' + imei + ')' : imei,
          })
        }
      }
      return opts
    },
    cancelledTrackers() {
      let opts = [{ value: 0, label: this.$t('general.ChooseTracker') }]
      let permittedTrackers = this.$perms.permissionToCancelTrackers()
      for (let id in permittedTrackers) {
        let imei = permittedTrackers[id]
        if (this.trackers[imei] && !this.trackers[imei].autoRenew) {
          opts.push({
            value: imei,
            label: this.trackers[imei].trackerName ? this.trackers[imei].trackerName + ' (' + imei + ')' : imei,
          })
        }
      }
      return opts
    },
  },
}
</script>
