import { authorizedApiRequest, queryParameters } from '../setup/ApiService'

export default {
  getStatus,
  getHistory,
}

function getStatus(trackersAndFields) {
  return authorizedApiRequest(
    `/trackers/status`, //
    'POST',
    {
      trackersAndFields,
    },
  )
}

function getHistory(imei, from, to) {
  const params = {
    imei,
    from,
    to,
  }
  return authorizedApiRequest(
    '/trackers/history?' + queryParameters(params), //
    'GET',
  )
}
