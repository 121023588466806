import Vue from 'vue'
import store from './Store'

Vue.mixin({
  beforeCreate() {
    const options = this.$options
    if (options.perms) {
      this.$perms = options.perms
    } else if (options.parent && options.parent.$perms) {
      this.$perms = options.parent.$perms
    }
  },
})

export default {
  userId,
  isLoggedIn,
  loggedInUser,
  isAdministrator,
  hasRole,
  trackersWithPerm,
  hasTrackersWithPerm,
  hasPermission,
  isAdministratorOfTracker,
  isAdministratorOfTrackers,
  isOwnerOfTracker,
  isOwnerOfTrackers,
  isBillingOfTracker,
  isBillingOfTrackers,
  hasTrackersWithAnyEditPerms,
  allImeis,
  administratorImeis,
  permissionToCancelTrackers,
  isDevelopment,
}

function userId() {
  if (!store.state.LoginStore.user) {
    return 0
  }
  return store.state.LoginStore.user.id
}

function isLoggedIn() {
  if (!store.state.LoginStore.user) {
    return false
  }
  return true
}

function loggedInUser() {
  if (!store.state.LoginStore.user) {
    return null
  }
  return store.state.LoginStore.user
}

function hasRole(role) {
  if (!isLoggedIn()) {
    return false
  }
  return store.state.LoginStore.user.role === role
}

function isAdministrator() {
  return hasRole('administrator')
}

function isAdministratorOfTracker(imei) {
  return store.state.LoginStore.permissions.adminOf && store.state.LoginStore.permissions.adminOf.includes(imei)
}

function isAdministratorOfTrackers() {
  if (!store.state.LoginStore.permissions || !store.state.LoginStore.permissions.adminOf) {
    return false
  }
  return Object.keys(store.state.LoginStore.permissions.adminOf).length > 0
}

function isOwnerOfTracker(imei) {
  return store.state.LoginStore.permissions.ownerOf && store.state.LoginStore.permissions.ownerOf.includes(imei)
}

function isOwnerOfTrackers() {
  if (!store.state.LoginStore.permissions || !store.state.LoginStore.permissions.ownerOf) {
    return false
  }
  return Object.keys(store.state.LoginStore.permissions.ownerOf).length > 0
}

function isBillingOfTracker(imei) {
  return store.state.LoginStore.permissions.billingOf && store.state.LoginStore.permissions.billingOf.includes(imei)
}

function isBillingOfTrackers() {
  if (!store.state.LoginStore.permissions || !store.state.LoginStore.permissions.billingOf) {
    return false
  }
  return Object.keys(store.state.LoginStore.permissions.billingOf).length > 0
}

function hasPermission(imei, permission) {
  if (!store.state.LoginStore.permissions || !store.state.LoginStore.permissions.trackers || !store.state.LoginStore.permissions.trackers[imei]) {
    return false
  }
  let perms = store.state.LoginStore.permissions.trackers[imei]
  if (perms[permission]) {
    return true
  }
  return false
}

function trackersWithPerm(permission) {
  if (!store.state.LoginStore.permissions || !store.state.LoginStore.permissions.trackers || Object.keys(store.state.LoginStore.permissions.trackers).length === 0) {
    return []
  }

  let retval = []
  for (let imei in store.state.LoginStore.permissions.trackers) {
    let perms = store.state.LoginStore.permissions.trackers[imei]
    if (perms[permission]) {
      retval.push(imei)
    }
  }

  return retval
}

function hasTrackersWithPerm(permission) {
  return trackersWithPerm(permission).length > 0
}

function hasTrackersWithAnyEditPerms() {
  return hasTrackersWithPerm('name') || hasTrackersWithPerm('colorAndIcon') || hasTrackersWithPerm('settings')
}

function allImeis() {
  if (!isLoggedIn()) {
    return false
  }
  return Object.keys(store.state.LoginStore.permissions.trackers)
}

function administratorImeis() {
  if (!isLoggedIn()) {
    return false
  }
  return store.state.LoginStore.permissions.adminOf
}

function permissionToCancelTrackers() {
  if (!isLoggedIn()) {
    return []
  }
  return Array.from(new Set([...store.state.LoginStore.permissions.billingOf, ...store.state.LoginStore.permissions.ownerOf, ...store.state.LoginStore.permissions.adminOf]))
}

function isDevelopment() {
  return process.env.NODE_ENV === 'development'
}
