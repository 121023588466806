let trackerOptions = [
  { value: 10, label: 'TN10 - Mini' }, //
  { value: 20, label: 'TN20 - Easy' },
  { value: 22, label: 'TN22 - Easy' },
  { value: 25, label: 'TN25 - Battery' },
  { value: 30, label: 'TN30 - Permanent' },
  { value: 110, label: 'TN110 - Long' },
]

let trackersObject = {}
for (var i in trackerOptions) {
  trackersObject[trackerOptions[i].value] = trackerOptions[i].label
}

export default {
  options: trackerOptions,
  object: trackersObject,
}
