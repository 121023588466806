<template>
  <McLayoutForm>
    <div v-if="loadingState">
      <McSpinner large />
    </div>
    <div v-else-if="errorState">
      <h2>{{ $t('general.Error') }}</h2>
      <p>{{ $t('general.InvalidLink') }}</p>
    </div>
    <div v-else>
      <h2>{{ $t('billing.SMSaccount') }}</h2>

      <b-card class="my-4">
        <div>
          <span>{{ $t('billing.currentBalanceColon') }}</span>
          <strong class="ml-4">{{ $t('billing.xSmss', { x: smsBalance.balance }) }}</strong>
          <select style="max-width: 90%;" class="ml-4" v-model="chosenPackage">
            <option :value="null">{{ $t('billing.ChooseAmountToRefill') }}</option>
            <option v-for="(packageInfo, pId) in packages" v-bind:key="pId" :value="packageInfo">{{ $t('billing.BuyNsmsForX', { N: packageInfo.count, X: niceCurrency(smsBalance.vatApplies ? packageInfo.priceInCentsIncVat : packageInfo.priceInCents, currency) }) }}</option>
          </select>
        </div>
        <div v-if="chosenPackage">
          {{ $t('billing.PayXTotalForNSmss', { N: chosenPackage.count, X: niceCurrency(chosenPackage[smsBalance.vatApplies ? 'priceInCentsIncVat' : 'priceInCents'], currency) }) }}
          <BillingAutopayCards
            style="font-size: 13px"
            :invoiceIds="['sms|' + smsBalance.contactId + '|' + chosenPackage.count]"
            :withSmsToken="{
              count: chosenPackage.count,
              secret: $route.params.secret,
            }"
            :autopayCards="autopayCards"
            :currency="currency"
            @invoicesUpdated="doUpdateAndReset"
            @cardsUpdated="doUpdate"
          />
        </div>
        <template v-slot:footer>
          <p>
            {{ $tc('billing.ThisSmsAccountIsSharedFor', Object.keys(smsBalance.trackers).length) }}
            <br v-if="smsBalance.trackers.length > 1" />
            <span v-for="(tracker, imei) in smsBalance.trackers" v-bind:key="imei" class="badge mx-1" :style="{ background: tracker.color, color: contrast(tracker.color) }">
              <McIcon v-if="tracker && tracker.icon" sized :icon="tracker.icon" />
              <span v-if="tracker && tracker.name" class="trackerName">{{ tracker.name }}</span>
              <span v-else>{{ imei }}</span>
            </span>
          </p>
        </template>
        <!--pre>{{ smsBalance }}</pre-->
      </b-card>
    </div>
    <!--pre>{{ invoice }}</pre-->
    <!--pre>{{ autopayCards }}</pre-->
  </McLayoutForm>
</template>

<script>
var Color = require('color')
import { FormatError } from '../../helpers/ErrorFormatting'
import BillingService from '../../services/BillingService'
import { niceCurrency } from '../../helpers/Formatting'

export default {
  data() {
    return {
      chosenPackage: null,
      smsBalance: null,
      packages: {},
      trackers: [],
      loadingState: true,
      errorState: false,
      autopayCards: null,
      currency: 'DKK', //TODO: Make this switchable
    }
  },
  methods: {
    doUpdateAndReset() {
      this.doUpdate()
      this.chosenPackage = null
    },
    doUpdate() {
      return BillingService.smsRefillByToken(this.$route.params.secret).then(data => {
        this.autopayCards = data.cards
        this.smsBalance = data.balance
        this.trackers = data.trackers
        this.packages = data.packages
      })
    },
    contrast(color) {
      return Color(color).contrast(Color('#000')) > 12 ? '#000' : '#fff'
    },
    niceCurrency(amountInCents, currency) {
      return niceCurrency(amountInCents, currency)
    },
  },
  created() {
    this.doUpdate()
      .then(() => {
        this.loadingState = false
      })
      .catch(error => {
        console.error(FormatError(error))
        this.loadingState = false
        this.errorState = true
      })
  },
}
</script>
