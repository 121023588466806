<template>
  <McForm v-bind="$attrs" @reset="handleReset" :onSubmit="handleSubmit">
    <mc-form-input
      name="name"
      type="text"
      v-model="curData.name"
      v-validate="'required'"
      :data-vv-as="$t('general.Name')"
      :label="$t('general.Name')"
      :placeholder="$t('general.EnterYourName')"
      key="profileform.name"
      :errorHint="errors.first('name')"
      :state="!errors.has('name')"
      icon="signature"
    />

    <div class="alert alert-warning" v-if="LoginStore.user.pendingEmailChange">{{ $t('profile.AlreadyHasPendingEmailChange', { email: LoginStore.user.pendingEmailChange }) }}</div>
    <mc-form-input
      name="email"
      type="email"
      v-model="curData.email"
      v-validate="'required|email'"
      :data-vv-as="$t('general.Email')"
      :label="$t('general.YourEmail')"
      :placeholder="$t('general.EnterYourEmail')"
      key="profileform.email"
      :errorHint="errors.first('email')"
      :state="!errors.has('email')"
      icon="at"
      :description="$t('general.YourEmailWillBeConfirmed')"
    />

    <mc-form-input
      name="password"
      type="password"
      v-model="curData.password"
      v-validate="'min:6'"
      :data-vv-as="$t('general.Password')"
      :placeholder="$t('general.ChooseNewPassword')"
      key="profileform.password"
      :errorHint="errors.first('password')"
      :state="!errors.has('password')"
      icon="key"
      :description="$t('general.LeaveFieldEmptyToNotChange')"
    />

    <TransitionToAuto v-if="curData.password">
      <mc-form-input
        name="password2"
        type="password"
        v-model="curData.password2"
        v-validate="{ required: true, is: curData.password }"
        :data-vv-as="$t('general.PasswordRepeat')"
        :placeholder="$t('general.EnterPasswordAgain')"
        key="profileform.password2"
        :errorHint="errors.first('password2')"
        :state="!errors.has('password2')"
        icon="key"
      />
    </TransitionToAuto>
  </McForm>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ProfileService from '../services/ProfileService'
import store from '../setup/Store'

export default {
  components: {},
  data() {
    return {
      curData: {},
    }
  },
  created() {
    this.curData = this.defaultData
  },
  computed: {
    ...mapState({
      LoginStore: state => state.LoginStore,
    }),
    defaultData() {
      return {
        id: store.state.LoginStore.user.id,
        name: store.state.LoginStore.user.name,
        email: store.state.LoginStore.user.email,
        password: '',
        password2: '',
      }
    },
  },
  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    handleReset() {
      this.curData = this.defaultData
    },

    handleSubmit(e) {
      return ProfileService.update(this.curData).then(
        data => {
          this.toastSuccess(this.$t('general.ChangesSaved'))
          store.commit('LoginStore/loginSuccess', data.newUserInfo)
          return Promise.resolve(data)
        },
        error => {
          console.error('Error updating user', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
