<template>
  <McLayoutDefault :heading="$t('headings.mileage')">
    <!-- pre>
      {{ loadingStateImei }}
      {{ imei }}
      {{ internalImei }}
      {{ trackerNotices }}
      {{ trackerOptions }}
    </pre -->
    <b-card :title="$t('permissions.LackingPermissions')" class="mb-2" v-if="!$perms.hasTrackersWithPerm('mileage') && !$perms.isAdministrator()">
      <p>{{ $t('permissions.LackingPermissionsLonger') }}</p>
    </b-card>
    <div v-else>
      <!--pre>{{ trackerInfo }}</pre>
      <pre>{{$perms.trackersWithPerm('mileage')}}</pre-->
      <div>
        <McSpinner v-if="loadingState === true" large class="my-5" />
        <McAlert v-else-if="loadingState === 'string'">
          {{ loading }}
        </McAlert>
        <div v-else>
          <!-- eslint-disable-next-line -->
          <mc-form-select
            v-if="trackerOptions.length > 2"
            name="type"
            v-model="imei"
            :label="$t('general.Tracker')"
            :placeholder="$t('general.ChooseTracker')"
            :options="trackerOptions"
            :icon="['fad', 'boxes']"
          />

          <div v-if="internalImei">
            <b-tabs content-class="mt-3">
              <b-tab :title="$t('mileage.Trips')" active>
                <mileageForm :imei="internalImei" />
              </b-tab>
              <b-tab :title="$t('mileage.Export')">
                <MileageExportForm :imei="internalImei" />
              </b-tab>
              <b-tab :title="$t('mileage.MileageNotices')">
                <b-row>
                  <b-col md="4" cols="12">
                    <b-card class="mb-2" :title="$t('mileage.MileageNotices')">
                      <div v-html="$t('mileage.MileageNoticesDescription')" />
                    </b-card>
                  </b-col>
                  <b-col md="8" cols="12">
                    <McSpinner v-if="loadingStateImei === true" large class="my-5" />
                    <McAlert v-else-if="loadingStateImei === 'string'">
                      {{ loading }}
                    </McAlert>
                    <mileageNoticeForm :imei="internalImei" :value="mileageNotice" />
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab :title="$t('mileage.Odometer')">
                <b-row>
                  <b-col md="4" cols="12">
                    <b-card class="mb-2" :title="$t('mileage.Odometer')">
                      <div v-html="$t('mileage.OdometerDescription')" />
                    </b-card>
                  </b-col>
                  <b-col md="8" cols="12">
                    <McSpinner v-if="loadingStateImei === true" large class="my-5" />
                    <McAlert v-else-if="loadingStateImei === 'string'">
                      {{ loading }}
                    </McAlert>
                    <MileageOdometerForm :imei="internalImei" @odometerUpdated="odometerUpdated" :value="trackerOdometer" />
                    <hr class="my-5" />
                    <MileageOdometerAdjustmentNoticeForm :imei="internalImei" :value="odometerAdjustmentNotice" />
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </McLayoutDefault>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TrackerService from './../../services/TrackerService'
import AlarmService from '../../services/AlarmService'
import MileageService from '../../services/MileageService'

export default {
  data() {
    return {
      imei: '-1',
      loadingState: true,
      loadingStateImei: true,
      trackerNotices: null,
      trackerOdometer: null,
    }
  },
  watch: {
    internalImei(imei) {
      this.fetchMileageAlarms(imei)
    },
  },
  computed: {
    ...mapState({
      MapViewStore: state => state.MapViewStore,
    }),
    internalImei() {
      return this.trackerOptions.length === 2 ? this.trackerOptions[1].value : this.imei
    },
    mileageNotice() {
      let retval = this.trackerNotices && this.trackerNotices.mileageNotice ? { ...this.trackerNotices.mileageNotice } : {}
      return retval
    },
    odometerAdjustmentNotice() {
      let retval = this.trackerNotices && this.trackerNotices.odometerAdjustmentNotice ? { ...this.trackerNotices.odometerAdjustmentNotice } : {}
      return retval
    },
    trackerInfo() {
      return { ...this.MapViewStore.trackerList }
    },
    trackerOptions() {
      let opts = [{ value: 0, label: this.$t('general.ChooseTracker') }]
      let permittedTrackers = this.$perms.trackersWithPerm('mileage')
      if (this.$perms.isAdministrator()) {
        permittedTrackers = Object.keys(this.trackerInfo)
      }
      for (let id in permittedTrackers) {
        let imei = permittedTrackers[id]
        if (this.trackerInfo[imei] && this.trackerInfo[imei].hasIgnition) {
          opts.push({
            value: imei,
            label: this.trackerInfo[imei].name ? this.trackerInfo[imei].name + ' (' + imei + ')' : imei,
          })
        }
      }
      return opts
    },
  },
  created() {
    TrackerService.list()
      .then(data => {
        this.updateTrackerList({ ...data })
        this.loadingState = false
        if (this.trackerOptions.length === 2) {
          this.fetchMileageAlarms(this.trackerOptions[1].value)
        }
      })
      .catch(error => {
        this.loadingState = FormatError(error)
      })
  },
  methods: {
    ...mapActions('MapViewStore', [
      'updateTrackerList', //
    ]),
    odometerUpdated(data) {
      this.trackerOdometer = data
    },
    fetchMileageAlarms(imei) {
      this.loadingStateImei = true
      if (imei < 1) {
        this.trackerNotices = null
        return
      }
      Promise.all([
        AlarmService.mileageAlarmsByImei(imei).then(data => {
          this.trackerNotices = { ...data }
        }),
        MileageService.getOdometer(imei).then(data => {
          this.trackerOdometer = data
        }),
      ])
        .then(data => {
          this.loadingStateImei = false
        })
        .catch(error => {
          this.loadingStateImei = FormatError(error)
        })
    },
  },
}
</script>
