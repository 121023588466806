<template>
  <VueBootstrapTypeahead v-bind="$attrs" v-on="{ ...$listeners }" ref="theInputComponent" />
</template>

<script>
import VueBootstrapTypeahead from './autocomplete/VueBootstrapTypeahead.vue'

export default {
  components: {
    VueBootstrapTypeahead,
  },
}
</script>
