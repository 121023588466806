<template>
  <McForm
    ref="mcFormInstance"
    v-bind="$attrs"
    @reset="handleReset"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    :submiticon="isEditing ? 'edit' : 'plus'"
    :title="isEditing ? $t('schedule.editTimespan') : $t('schedule.addTimespan')"
    :submittext="isEditing ? $t('general.Save') : $t('schedule.addTimespan')"
  >

    <!-- eslint-disable-next-line -->
    <mcFormDualInputs
      :errorHint="errors.first('startDay') || errors.first('startTime')"
      :state="!errors.has('startDay') && !errors.has('startTime')"
    >
      <template v-slot:input1>
        <!-- eslint-disable-next-line -->
        <mc-form-select
          name="startDay"
          v-model="curData.startDay"
          :data-vv-as="$t('schedule.StartDay')"
          :label="$t('schedule.StartDay')"
          :placeholder="$t('schedule.ChooseStartDay')"
          key="timespanform.startDay"
          :errorHint="errors.first('startDay')"
          :state="!errors.has('startDay')"
          :options="daysList"
          :icon="['far', 'calendar']"
        />
      </template>
      <template v-slot:input2>
        <!-- eslint-disable-next-line -->
        <mc-form-select
          name="startTime"
          v-model="curData.startTime"
          :data-vv-as="$t('schedule.StartTime')"
          :label="$t('schedule.StartTime')"
          :placeholder="$t('schedule.ChooseStartTime')"
          key="timespanform.startTime"
          :errorHint="errors.first('startTime')"
          :state="!errors.has('startTime')"
          :options="startTimeList"
          :icon="['far', 'clock']"
        />
      </template>
    </mcFormDualInputs>

    <!-- eslint-disable-next-line -->
    <mcFormDualInputs
      :errorHint="errors.first('endDay') || errors.first('endTime')"
      :state="!errors.has('endDay') && !errors.has('endTime')"
    >
      <template v-slot:input1>
        <!-- eslint-disable-next-line -->
        <mc-form-select
          name="endDay"
          v-model="curData.endDay"
          :data-vv-as="$t('schedule.EndDay')"
          :label="$t('schedule.EndDay')"
          :placeholder="$t('schedule.ChooseEndDay')"
          key="timespanform.endDay"
          :errorHint="errors.first('endDay')"
          :state="!errors.has('endDay')"
          :options="daysList"
          :icon="['far', 'calendar']"
        />
      </template>
      <template v-slot:input2>
        <!-- eslint-disable-next-line -->
        <mc-form-select
          name="endTime"
          v-model="curData.endTime"
          :data-vv-as="$t('schedule.EndTime')"
          :label="$t('schedule.EndTime')"
          :placeholder="$t('schedule.ChooseEndTime')"
          key="timespanform.endTime"
          :errorHint="errors.first('endTime')"
          :state="!errors.has('endTime')"
          :options="endTimeList"
          :icon="['far', 'clock']"
        />
      </template>
    </mcFormDualInputs>
  </McForm>
</template>

<script>
import { isEmptyObject } from '../helpers/Misc'

const defaultData = {
  startDay: 1,
  startTime: '00:00',
  endDay: 7,
  endTime: '23:59',
}

export default {
  components: {},
  data() {
    return {
      curData: { ...defaultData },
      isEditing: false,
    }
  },
  props: {
    value: Object,
  },
  computed: {
    daysList() {
      return [
        { value: 1, label: this.$t('schedule.dayname1') },
        { value: 2, label: this.$t('schedule.dayname2') },
        { value: 3, label: this.$t('schedule.dayname3') },
        { value: 4, label: this.$t('schedule.dayname4') },
        { value: 5, label: this.$t('schedule.dayname5') },
        { value: 6, label: this.$t('schedule.dayname6') },
        { value: 7, label: this.$t('schedule.dayname7') },
      ]
    },
    startTimeList() {
      return [
        { value: '00:00', label: '00:00' },
        { value: '00:30', label: '00:30' },
        { value: '01:00', label: '01:00' },
        { value: '01:30', label: '01:30' },
        { value: '02:00', label: '02:00' },
        { value: '02:30', label: '02:30' },
        { value: '03:00', label: '03:00' },
        { value: '03:30', label: '03:30' },
        { value: '04:00', label: '04:00' },
        { value: '04:30', label: '04:30' },
        { value: '05:00', label: '05:00' },
        { value: '05:30', label: '05:30' },
        { value: '06:00', label: '06:00' },
        { value: '06:30', label: '06:30' },
        { value: '07:00', label: '07:00' },
        { value: '07:30', label: '07:30' },
        { value: '08:00', label: '08:00' },
        { value: '08:30', label: '08:30' },
        { value: '09:00', label: '09:00' },
        { value: '09:30', label: '09:30' },
        { value: '10:00', label: '10:00' },
        { value: '10:30', label: '10:30' },
        { value: '11:00', label: '11:00' },
        { value: '11:30', label: '11:30' },
        { value: '12:00', label: '12:00' },
        { value: '12:30', label: '12:30' },
        { value: '13:00', label: '13:00' },
        { value: '13:30', label: '13:30' },
        { value: '14:00', label: '14:00' },
        { value: '14:30', label: '14:30' },
        { value: '15:00', label: '15:00' },
        { value: '15:30', label: '15:30' },
        { value: '16:00', label: '16:00' },
        { value: '16:30', label: '16:30' },
        { value: '17:00', label: '17:00' },
        { value: '17:30', label: '17:30' },
        { value: '18:00', label: '18:00' },
        { value: '18:30', label: '18:30' },
        { value: '19:00', label: '19:00' },
        { value: '19:30', label: '19:30' },
        { value: '20:00', label: '20:00' },
        { value: '20:30', label: '20:30' },
        { value: '21:00', label: '21:00' },
        { value: '21:30', label: '21:30' },
        { value: '22:00', label: '22:00' },
        { value: '22:30', label: '22:30' },
        { value: '23:00', label: '23:00' },
        { value: '23:30', label: '23:30' },
      ]
    },
    endTimeList() {
      return [
        { label: '00:29', value: '00:29' },
        { label: '00:59', value: '00:59' },
        { label: '01:29', value: '01:29' },
        { label: '01:59', value: '01:59' },
        { label: '02:29', value: '02:29' },
        { label: '02:59', value: '02:59' },
        { label: '03:29', value: '03:29' },
        { label: '03:59', value: '03:59' },
        { label: '04:29', value: '04:29' },
        { label: '04:59', value: '04:59' },
        { label: '05:29', value: '05:29' },
        { label: '05:59', value: '05:59' },
        { label: '06:29', value: '06:29' },
        { label: '06:59', value: '06:59' },
        { label: '07:29', value: '07:29' },
        { label: '07:59', value: '07:59' },
        { label: '08:29', value: '08:29' },
        { label: '08:59', value: '08:59' },
        { label: '09:29', value: '09:29' },
        { label: '09:59', value: '09:59' },
        { label: '10:29', value: '10:29' },
        { label: '10:59', value: '10:59' },
        { label: '11:29', value: '11:29' },
        { label: '11:59', value: '11:59' },
        { label: '12:29', value: '12:29' },
        { label: '12:59', value: '12:59' },
        { label: '13:29', value: '13:29' },
        { label: '13:59', value: '13:59' },
        { label: '14:29', value: '14:29' },
        { label: '14:59', value: '14:59' },
        { label: '15:29', value: '15:29' },
        { label: '15:59', value: '15:59' },
        { label: '16:29', value: '16:29' },
        { label: '16:59', value: '16:59' },
        { label: '17:29', value: '17:29' },
        { label: '17:59', value: '17:59' },
        { label: '18:29', value: '18:29' },
        { label: '18:59', value: '18:59' },
        { label: '19:29', value: '19:29' },
        { label: '19:59', value: '19:59' },
        { label: '20:29', value: '20:29' },
        { label: '20:59', value: '20:59' },
        { label: '21:29', value: '21:29' },
        { label: '21:59', value: '21:59' },
        { label: '22:29', value: '22:29' },
        { label: '22:59', value: '22:59' },
        { label: '23:29', value: '23:29' },
        { label: '23:59', value: '23:59' },
      ]
    },
  },
  watch: {
    value(val) {
      this.handleNewValue(val)
    },
  },
  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    handleNewValue(val) {
      this.$refs['mcFormInstance'].forceReset()

      //If empty object passed, we are not editing an existing object
      this.isEditing = !isEmptyObject(val)

      let newVal = {}
      for (var field in defaultData) {
        newVal[field] = val.hasOwnProperty(field) ? val[field] : defaultData[field]
      }
      this.curData = newVal
    },
    handleReset() {
      this.curData = { ...defaultData }
    },

    handleSubmit(e) {
      return new Promise((resolve, reject) => {
        this.$emit('timespanUpdated', this.curData)
        resolve(this.curData)
      })
    },
  },
}
</script>
