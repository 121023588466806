import Vue from 'vue'
import { i18n } from './i18n'

const moment = require('moment')
require('moment/locale/da')
require('moment/locale/de')
require('moment/locale/fr')
require('moment/locale/es')

moment.locale(i18n.locale)

Vue.use(require('vue-moment'), {
  moment,
})
