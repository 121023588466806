<template>
  <!-- eslint-disable-next-line -->
  <McForm
    ref="mcFormInstance"
    v-bind="$attrs"
    @reset="handleReset"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    :title="isEditing ? $t('alarms.EditAlarmRule') : $t('alarms.AddAlarmRule')"
  >
    <mc-form-select
      name="ruleType"
      v-model="curData.type"
      :data-vv-as="$t('alarms.RuleType')"
      :label="$t('alarms.RuleType')"
      :placeholder="$t('alarms.ChooseRuleType')"
      key="trackeralarmsform.ruleType"
      :errorHint="errors.first('ruleType')"
      :state="!errors.has('ruleType')"
      :options="typesList"
      :icon="['fad', 'user-cog']"
    />

    <mc-form-geofence
      name="geofence"
      v-if="['fenceEntry', 'fenceExit'].includes(curData.type)"
      v-model="curData.details.geofence"
      :geofences="newGeofences == null ? geofences : newGeofences"
      @geofencesEdited="geofencesEdited"
      :data-vv-as="$t('alarms.Geofence')"
      :label="$t('alarms.Geofence')"
      :placeholder="$t('alarms.ChooseGeofence')"
      key="trackeralarmsform.geofence"
      :errorHint="errors.first('geofence')"
      :state="!errors.has('geofence')"
      :icon="['fad', 'map-marked-alt']"
    />

    <mc-form-select
      name="minDistance"
      v-if="['movement', 'tow'].includes(curData.type)"
      v-model="curData.details.minDistance"
      :data-vv-as="$t('alarms.MinDistance')"
      :label="$t('alarms.MinDistance')"
      :placeholder="$t('alarms.ChooseMinDistance')"
      key="trackeralarmsform.minDistance"
      :errorHint="errors.first('minDistance')"
      :state="!errors.has('minDistance')"
      :options="minDistanceList"
      :icon="['far', 'ruler']"
    />

    <mc-form-select
      name="timespan"
      v-if="['movement'].includes(curData.type)"
      v-model="curData.details.timespanInSeconds"
      :data-vv-as="$t('alarms.Timespan')"
      :label="$t('alarms.Timespan')"
      :placeholder="$t('alarms.ChooseTimespan')"
      key="trackeralarmsform.timespan"
      :errorHint="errors.first('timespan')"
      :state="!errors.has('timespan')"
      :options="timespanList"
      :icon="['far', 'clock']"
    />

    <mc-form-select
      name="maxSpeed"
      v-if="['speed'].includes(curData.type)"
      v-model="curData.details.maxSpeed"
      :data-vv-as="$t('alarms.MaxSpeed')"
      :label="$t('alarms.MaxSpeed')"
      :placeholder="$t('alarms.ChooseMaxSpeed')"
      key="trackeralarmsform.maxSpeed"
      :errorHint="errors.first('maxSpeed')"
      :state="!errors.has('maxSpeed')"
      :options="maxSpeedList"
      :icon="['fad', 'tachometer-slow']"
    />

    <mc-form-select
      name="maxAge"
      v-if="['age'].includes(curData.type)"
      v-model="curData.details.maxAge"
      :data-vv-as="$t('alarms.MaxAge')"
      :label="$t('alarms.MaxAge')"
      :placeholder="$t('alarms.ChooseMaxAge')"
      key="trackeralarmsform.maxAge"
      :errorHint="errors.first('maxAge')"
      :state="!errors.has('maxAge')"
      :options="maxAgeList"
      :icon="['fad', 'history']"
    />

    <mc-form-schedule
      name="schedule"
      v-if="curData.type != 'none'"
      v-model="curData.details.schedule"
      :data-vv-as="$t('alarms.Schedule')"
      :label="$t('alarms.Schedule')"
      :placeholder="$t('alarms.ChooseSchedule')"
      key="trackeralarmsform.schedule"
      :errorHint="errors.first('schedule')"
      :state="!errors.has('schedule')"
      :icon="['far', 'calendar']"
    />

    <mc-form-defaulted-textarea
      name="emailText"
      v-if="curData.type != 'none'"
      type="textarea"
      v-model="curData.emailText"
      :label="$t('alarms.EmailText')"
      :placeholder="$t('alarms.EnterEmailText')"
      key="trackeralarmsform.emailText"
      :icon="['far', 'envelope-open-text']"
      :tags="possibleTags"
      :defaultValue="defaultTexts[curData.type] ? defaultTexts[curData.type]['email'] : ''"
    />

    <mc-form-defaulted-textarea
      name="SmsText"
      v-if="curData.type != 'none'"
      type="textarea"
      v-model="curData.smsText"
      :label="$t('alarms.SmsText')"
      :placeholder="$t('alarms.EnterSmsText')"
      key="trackeralarmsform.smsText"
      :icon="['fad', 'comments-alt']"
      :tags="possibleTags"
      :defaultValue="defaultTexts[curData.type] ? defaultTexts[curData.type]['sms'] : ''"
    />

    <!-- eslint-disable-next-line -->
    <McFormAlarmContacts
      name="contacts"
      v-if="curData.type != 'none'"
      v-model="curData.contacts"
      :alarmContacts="alarmContacts"
      :label="$t('alarms.recipient(s)')"
      :errorHint="errors.first('contacts')"
      :state="!errors.has('contacts')"
      :icon="['fad', 'users']"
    />
    <!--pre style="max-height: 200px;overflow:auto;border:1px solid">{{ curData }}</pre-->
  </McForm>
</template>

<script>
import { isEmptyObject } from '../helpers/Misc'

const defaultData = {
  id: 'new',
  type: '',
  smsText: 'default',
  emailText: 'default',
  details: {
    schedule: true,
    minDistance: 500,
    maxAge: 3600,
    maxSpeed: 130,
    timespanInSeconds: 1200,
    geofence: -1,
  },
  contacts: [],
}

export default {
  components: {},
  data() {
    return {
      curData: { ...defaultData },
      isEditing: false,
      newGeofences: null,
    }
  },
  props: {
    value: Object,
    alarmContacts: Array,
    supported: Array,
    geofences: Array,
    defaultTexts: Object,
  },
  watch: {
    value(val) {
      this.$refs['mcFormInstance'].forceReset()

      //If empty object passed, we are not editing an existing object
      this.isEditing = !isEmptyObject(val)
      this.newGeofences = null

      let newVal = {}
      for (var field in defaultData) {
        if (field == 'details') {
          newVal.details = {}
          for (var dField in defaultData.details) {
            newVal.details[dField] = val.hasOwnProperty('details') && val.details.hasOwnProperty(dField) ? val.details[dField] : defaultData.details[dField]
          }
        } else {
          newVal[field] = val.hasOwnProperty(field) ? val[field] : defaultData[field]
        }
      }

      this.curData = newVal
    },
  },
  computed: {
    typesList: function() {
      let theList = [
        { value: 'age', label: this.$t('alarmTypes.when.age') },
        { value: 'disconnect', label: this.$t('alarmTypes.when.disconnect') },
        { value: 'battery', label: this.$t('alarmTypes.when.battery') },
        { value: 'speed', label: this.$t('alarmTypes.when.speed') },
        { value: 'tow', label: this.$t('alarmTypes.when.tow') },
        { value: 'movement', label: this.$t('alarmTypes.when.movement') },
        { value: 'ignitionOn', label: this.$t('alarmTypes.when.ignitionOn') },
        { value: 'ignitionOff', label: this.$t('alarmTypes.when.ignitionOff') },
        { value: 'din1On', label: this.$t('alarmTypes.when.din1On') },
        { value: 'din1Off', label: this.$t('alarmTypes.when.din1Off') },
        { value: 'din2On', label: this.$t('alarmTypes.when.din2On') },
        { value: 'din2Off', label: this.$t('alarmTypes.when.din2Off') },
        { value: 'din3On', label: this.$t('alarmTypes.when.din3On') },
        { value: 'din3Off', label: this.$t('alarmTypes.when.din3Off') },
        { value: 'fenceEntry', label: this.$t('alarmTypes.when.fenceEntry') },
        { value: 'fenceExit', label: this.$t('alarmTypes.when.fenceExit') },
        { value: 'panic', label: this.$t('alarmTypes.when.panic') },
        { value: 'fall', label: this.$t('alarmTypes.when.fall') },
      ]

      theList = theList.filter(elm => this.supported.includes(elm.value))

      if (!this.isEditing) {
        theList = [{ value: 'none', label: this.$t('alarms.ChooseAlarmType') }, ...theList]
      }

      return theList
    },
    minDistanceList: function() {
      let retval = []

      retval.push({ value: 1, label: this.$t('alarms.minDistances.AnyMovement') })

      let meters = [50, 250, 500]

      meters.forEach(x => {
        retval.push({ value: x, label: this.$t('alarms.minDistances.xMeters', { x }) })
      })

      let kilometers = [1, 2, 5]

      kilometers.forEach(x => {
        retval.push({ value: 1000 * x, label: this.$t('alarms.minDistances.xKiloMeters', { x }) })
      })

      return retval
    },
    maxSpeedList: function() {
      let retval = []

      let speeds = [30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150]

      speeds.forEach(x => {
        retval.push({ value: x, label: this.$t('alarms.maxSpeeds.xKiloMetersPerHour', { x }) })
      })

      return retval
    },
    maxAgeList: function() {
      let retval = []

      /*let minutes = [15, 30, 60]

      minutes.forEach(x => {
        retval.push({ value: x * 60, label: this.$tc('alarms.maxAges.xMinutes', x) })
      })*/

      let hours = [6, 12, 18, 24, 36, 48]

      hours.forEach(x => {
        retval.push({ value: x * 60 * 60, label: this.$tc('alarms.maxAges.xHours', x) })
      })

      let days = [3, 4, 5, 6, 7, 10, 14, 21, 28]

      days.forEach(x => {
        retval.push({ value: x * 60 * 60 * 24, label: this.$tc('alarms.maxAges.xDays', x) })
      })

      return retval
    },
    timespanList: function() {
      let retval = []

      let minutes = [5, 10, 15, 20, 30, 45, 60, 90]

      minutes.forEach(x => {
        retval.push({ value: x * 60, label: this.$tc('alarms.timespans.xMinutes', x) })
      })

      let hours = [2, 3, 6, 12, 18, 24, 36, 48]

      hours.forEach(x => {
        retval.push({ value: x * 60 * 60, label: this.$tc('alarms.timespans.xHours', x) })
      })

      return retval
    },
    possibleTags() {
      let list = []
      list.push('RECIPIENT_NAME')
      list.push('TRACKER_NAME')

      if (['tow', 'movement'].includes(this.curData.type)) {
        list.push('MIN_DISTANCE')
      }
      if (['age'].includes(this.curData.type)) {
        list.push('MAX_AGE')
      }
      if (['speed'].includes(this.curData.type)) {
        list.push('MAX_SPEED')
      }
      if (['movement'].includes(this.curData.type)) {
        list.push('TIMESPAN')
      }
      if (['fenceEntry', 'fenceExit'].includes(this.curData.type)) {
        list.push('FENCE_NAME')
      }

      list.push('TRACKER_ADDRESS')
      list.push('ALARM_LINK')
      return list
    },
  },
  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    handleReset() {
      this.curData = { ...defaultData }
    },

    geofencesEdited(newVal) {
      this.newGeofences = newVal
    },

    handleSubmit(e) {
      this.errors.clear()
      let rule = {
        id: this.curData.id,
        type: this.curData.type,
        smsText: this.curData.smsText,
        emailText: this.curData.emailText,
        contacts: this.curData.contacts,
        details: {
          schedule: this.curData.details.schedule,
        },
      }
      if (['tow', 'movement'].includes(this.curData.type)) {
        rule.details.minDistance = this.curData.details.minDistance
      }
      if (['age'].includes(this.curData.type)) {
        rule.details.maxAge = this.curData.details.maxAge
      }
      if (['speed'].includes(this.curData.type)) {
        rule.details.maxSpeed = this.curData.details.maxSpeed
      }
      if (['movement'].includes(this.curData.type)) {
        rule.details.timespanInSeconds = this.curData.details.timespanInSeconds
      }
      if (['fenceEntry', 'fenceExit'].includes(this.curData.type)) {
        rule.details.geofence = this.curData.details.geofence
        if (this.curData.details.geofence == -1) {
          this.errors.add({
            field: 'geofence',
            msg: this.$t('alarms.YouNeedToChooseAGeofence'),
          })
        }
      }

      if (this.curData.type == 'none') {
        this.errors.add({
          field: 'ruleType',
          msg: this.$t('alarms.YouNeedToChooseARuleType'),
        })
      }

      if (rule.contacts.length < 1) {
        this.errors.add({
          field: 'contacts',
          msg: this.$t('error.YouNeedToChooseAtLeastOneRecipient'),
        })
      }

      if (rule.details.schedule !== true) {
        if (rule.details.schedule.length < 1) {
          this.errors.add({
            field: 'schedule',
            msg: this.$t('alarms.YouNeedToAddAtLeastOneTimespan'),
          })
        } else if (rule.details.schedule.length == 1) {
          let schedule = rule.details.schedule[0]
          if (schedule.startDay == 1 && schedule.startTime == '00:00' && schedule.endDay == 7 && schedule.endTime == '23:59') {
            rule.details.schedule = true
          }
        }
      }

      if (this.errors.items.length > 0) {
        return Promise.reject()
      }

      if (this.newGeofences !== null) {
        this.$emit('geofencesEdited', this.newGeofences)
      }

      this.$emit('ruleEdited', rule)
      return Promise.resolve()
    },
  },
}
</script>
