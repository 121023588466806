<template>
  <McForm
    ref="mcFormInstance"
    v-bind="$attrs"
    @reset="handleReset"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    :submiticon="['far', 'envelope-open-text']"
    :title="$t('headings.newsletter')"
    :submittext="$t('general.Send')"
    class="newsletterForm"
    :successText="$t('newsletter.success')"
  >
    <mc-form-checkboxes
      name="contactTypes"
      v-model="curData.contactTypes"
      :label="$t('newsletter.SendTo')"
      key="newsletterform.contactTypes"
      :options="contactTypes"
      :icon="['fad', 'user-tag']"
      multiple
    />

    <!-- eslint-disable-next-line -->
    <mc-form-input
      name="testmails"
      type="text"
      v-model="curData.testmails"
      :placeholder="$t('newsletter.EnterTestMails')"
      :label="$t('newsletter.TestMails')"
      key="newsletterform.testmails"
    />

    <b-card :title="$t('newsletter.' + lang)" class="mb-2" v-for="lang in ['da', 'en']" v-bind:key="lang">
      <!-- eslint-disable-next-line -->
      <mc-form-input
        name="subject"
        type="text"
        v-model="curData.subject[lang]"
        :placeholder="$t('newsletter.EnterSubject')"
        key="newsletterform.subject"
      />

      <wysiwyg v-model="curData.body[lang]" :placeholder="$t('newsletter.EnterBody')" class="mb-3" />
      <p class="possible_tags">
        <span>{{ $tc('general.YouCanUseTheseTagsClickToCopyColon', tags.length) }}</span><br>
        <span v-for="(tag, index) in tags" v-bind:key="tag"><code @click="copyTag(tag)">{{ '{' + tag + '}' }}</code><span v-if="index + 1 < tags.length">, </span></span>
      </p>
    </b-card>

    <!--pre> {{ curData }} </pre-->
  </McForm>
</template>

<script>
import { mapActions } from 'vuex'
import NewsletterService from '../services/NewsletterService'

const defaultData = {
  subject: {
    en: '',
    da: '',
  },
  body: {
    en: '<p>Hi {RECIPIENT_NAME},</p><p>Dorem lorem ipsum dolor sit amet...</p><p>Kind regards,<br>TrackNordic</p>',
    da: '<p>Hej {RECIPIENT_NAME},</p><p>Dorem lorem ipsum dolor sit amet...</p><p>Med venlig hilsen,<br>TrackNordic</p>',
  },
  contactTypes: ['test'],
  testmails: 'mikkel@softwork.dk, mikkel@i123.dk',
}

export default {
  components: {},
  data() {
    return {
      curData: { ...defaultData },
    }
  },
  props: {
    value: Object,
  },
  computed: {
    contactTypeError() {
      return this.curData.contactTypes.length == 0 ? 'Error' : ''
    },
    contactTypes() {
      let ret = []
      ret.push({ value: 'test', label: this.$t('newsletter.testemailsSpecBelow') })
      ret.push({ value: 'allUsers', label: this.$t('newsletter.allUsers') })
      if (!this.curData.contactTypes.includes('allUsers')) {
        ret.push({ value: 'administrator', label: this.$t('newsletter.administrator') })
      }
      ret.push({ value: 'allContacts', label: this.$t('newsletter.allContacts') })
      if (!this.curData.contactTypes.includes('allContacts')) {
        ret.push({ value: 'billing', label: this.$t('newsletter.billing') })
        ret.push({ value: 'owner', label: this.$t('newsletter.owner') })
      }
      return ret
    },
    tags() {
      let list = []
      list.push('RECIPIENT_NAME')
      list.push('RECIPIENT_EMAIL')
      return list
    },
  },
  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    handleReset() {
      this.curData = { ...defaultData }
    },

    handleSubmit(e) {
      return NewsletterService.send(this.curData).then(
        data => {
          this.toastSuccess(this.$t('newsletter.success'))
          return Promise.resolve(data)
        },
        error => {
          console.error('Error sending', error)
          return Promise.reject(error)
        },
      )
    },
    copyTag(tag) {
      this.$copyText('{' + tag + '}')
    },
  },
}
</script>

<style lang="scss">
.newsletterForm {
  .possible_tags {
    font-size: 0.8em;
  }
  code {
    color: #666;
    cursor: pointer;
    &:hover {
      color: #000;
    }
  }
}
</style>
