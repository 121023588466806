<template>
  <div>
    <!-- eslint-disable-next-line -->
    <McForm
      ref="mcFormInstance"
      v-bind="$attrs"
      @reset="handleReset"
      @completed="e => $emit('completed', e)"
      :onSubmit="handleSubmit"
      :submiticon="isEditing ? 'edit' : 'save'"
      :title="isEditing ? $t('orders.EditTracker') : $t('orders.AddTracker')"
      :submittext="isEditing ? $t('general.Save') : $t('orders.AddTracker')"
    >
      <mc-form-select :options="trackerOptions" v-model="curData.type" :icon="['fad', 'boxes']" />

      <mc-form-select
        name="subscription"
        v-model="curData.subscription"
        :data-vv-as="$t('trackers.subscription')"
        :label="$t('trackers.subscription')"
        :placeholder="$t('trackers.chooseSubscription')"
        key="trackerform.subscription"
        :errorHint="errors.first('subscription')"
        :state="!errors.has('subscription')"
        :options="subscriptionOptions"
        :icon="['fad', 'money-check-alt']"
      />

      <TrackerAlarmsInsuranceContactsInner v-if="curData.subscription == 'insurance'" v-model="curData.insuranceSettings" />

      <b-form-group v-if="curData.type == 30 && curData.subscription != 'insurance'">
        <b-input-group class="text-center">
          <b-form-checkbox name="remember" v-model="curData.withInstallation">
            {{ $t('orders.WithInstallation') }}
          </b-form-checkbox>
        </b-input-group>
      </b-form-group>

      <TrackerInstallationFormInner v-if="curData.subscription == 'insurance'" v-model="curData.installation" />

      <!--pre>{{ curData }}</pre-->
    </McForm>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import subscriptionTypes from '../helpers/SubscriptionTypes'
import trackerTypes from '../helpers/TrackerTypes'
import { isEmptyObject } from '../helpers/Misc'

const defaultData = {
  type: 10,
  subscription: 'standard',
  withInstallation: false,
  insuranceSettings: {
    contact1: {
      name: '',
      phone: '',
    },
    contact2: {
      name: '',
      phone: '',
    },
    enableBackupContact: true,
  },
  installation: {
    vehicleDetails: {
      type: '',
      brand: '',
      insurer: '',
      color: '',
      regNo: '',
      vin: '',
      policeNo: '',
      specialMarks: '',
    },
  },
}

export default {
  components: {},
  data() {
    return {
      trackerOptions: trackerTypes.options,
      subscriptionOptions: subscriptionTypes.options,
      curData: { ...defaultData },
      isEditing: false,
    }
  },
  props: {
    value: Object,
  },
  watch: {
    value(val) {
      this.$refs['mcFormInstance'].forceReset()

      //If empty object passed, we are not editing an existing object
      this.isEditing = !isEmptyObject(val)

      let newVal = {}
      for (var field in defaultData) {
        newVal[field] = val.hasOwnProperty(field) ? val[field] : defaultData[field]
      }
      this.curData = JSON.parse(JSON.stringify(newVal))
    },
  },

  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    handleReset() {
      // Don't reset this one
    },

    handleSubmit(e) {
      this.$emit('edit-completed', this.curData)
      return Promise.resolve(this.curData)
    },
  },
}
</script>
