<template>
  <div>
    <div class="bg-summersky text-white py-2" v-if="heading">
      <div class="container">
        <h1 class="text-center">{{heading}}</h1>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 my-5">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['heading'],
}
</script>
