<template>
  <b-form-group :label="label" :description="description" :state="!errorHint">
    <b-input-group class="mc-form-select">
      <b-input-group-text v-if="icon" slot="prepend" class="bg-primary text-white">
        <McIcon :icon="icon" />
      </b-input-group-text>
      <div class="custom-file">
        <multiselect
          v-model="internalValue"
          :options="options"
          :placeholder="placeholder"
          track-by="value"
          selectLabel=" "
          :selectedLabel="$t('general.Selected')"
          deselectLabel=" "
          :allow-empty="allowEmpty"
          :multiple="multiple"
          label="label"
        >
          <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData"/></template>
        </multiselect>
      </div>
      <slot name="append"></slot>
    </b-input-group>
    <TransitionToAuto v-if="errorHint">
      <div class="invalid-feedback d-block">{{ errorHint }}</div>
    </TransitionToAuto>
  </b-form-group>
</template>

<script>
export default {
  computed: {
    internalValue: {
      get: function() {
        if (this.multiple) {
          var internal = []
          if (this.options && this.options.length > 0) {
            for (var id in this.options) {
              if (this.value.includes(this.options[id].value)) {
                internal.push(this.options[id])
              }
            }
          }
          return internal
        } else {
          var fallback = { value: 0, label: 'noopt' }
          if (this.options && this.options.length > 0) {
            fallback = this.options[0]
            for (var oid in this.options) {
              if (this.options[oid].value == this.value) {
                return this.options[oid]
              }
            }
          }
          this.updateValue(fallback.value)
        }
        return fallback
      },
      set: function(selectedObject) {
        if (this.multiple) {
          let newValue = []
          for (var x in selectedObject) {
            newValue.push(selectedObject[x].value)
          }
          this.updateValue(newValue)
        } else {
          if (selectedObject) {
            this.updateValue(selectedObject.value)
          }
        }
      },
    },
  },
  props: {
    icon: [String, Array],
    errorHint: String,
    label: String,
    description: String,
    placeholder: String,
    value: [Number, String, Array],
    options: [Array, Object],
    multiple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateValue(newValue) {
      this.$emit('input', newValue)
    },
  },
}
</script>

<style>
fieldset[disabled] .input-group.mc-form-select .multiselect__tags,
fieldset[disabled] .input-group.mc-form-select .multiselect__single {
  background: #eee;
}
.input-group.mc-form-select .multiselect__tags {
  border-radius: 0px 3px 3px 0px;
  border: 1px solid #ced4da;
  border-left: 0;
}
.input-group.mc-form-select .multiselect__tag-icon::after {
  transform: translateY(-2px);
  display: inline-block;
}
.input-group.mc-form-select > .custom-file {
  height: auto;
  user-select: none;
  margin-top: 0px;
  margin-bottom: 0px;
}
.input-group.mc-form-select .multiselect__option--selected.multiselect__option--highlight {
  background-color: #41b883;
}
</style>
