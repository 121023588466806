<template>
  <!-- eslint-disable-next-line -->
  <McForm
    ref="mcFormInstance"
    v-bind="$attrs"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    submiticon="save"
    :loading="loadingState"
    :title="$t('orders.HandleOrder')"
    :submittext="$t('general.Save')"
  >
    <div v-if="order">
      <OrderTrackers @addMechanic="addMechanic" @addTracker="addTracker" v-model="order.trackers" details handleOrder :mechanics="mechanics" :availableTrackers="availableTrackers" />

      <!-- eslint-disable-next-line -->
      <MechanicForm
        id="mechanicform"
        isModal
        :value="editingMechanic"
        @mechanicSaved="id => (setMechanicWhenFetchedId = id)"
        @completed="getMechanics"
      />
      <TrackerForm
        id="addtrackerform"
        isModal
        :value="editingTracker"
        @trackerSaved="imei => (setImeiWhenFetchedImei = imei)"
        @completed="getTrackers"
        :forceTrackerType="forceTrackerType"
        forceNoAdmin
      />
    </div>
  </McForm>
</template>

<script>
import OrdersService from '../services/OrdersService'
import { mapActions } from 'vuex'
import MechanicsService from '../services/MechanicsService'
import TrackerService from '../services/TrackerService'

const defaultData = {
  trackers: null,
}

export default {
  components: {},
  data() {
    return {
      order: null,
      curData: { ...defaultData },
      mechanics: [],
      availableTrackers: null,
      editingMechanic: {},
      editingTracker: {},
      forceTrackerType: 10,
      setImeiWhenFetchedIndex: null,
      setImeiWhenFetchedImei: null,
      setMechanicWhenFetchedIndex: null,
      setMechanicWhenFetchedId: null,
      loadingState: true,
    }
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    handleOrder(order) {
      this.$refs['mcFormInstance'].forceReset()
      this.getMechanics()
      this.getTrackers()
      this.order = JSON.parse(JSON.stringify(order))
    },

    addMechanic(info) {
      this.editingMechanic = {}
      this.setMechanicWhenFetchedIndex = info.index
      this.$bvModal.show('mechanicform')
    },

    addTracker(info) {
      this.editingTracker = {}
      this.forceTrackerType = info.tracker.type
      this.setImeiWhenFetchedIndex = info.index
      this.$bvModal.show('addtrackerform')
    },

    getMechanics() {
      return MechanicsService.all().then(
        data => {
          let mechs = [
            {
              value: -1,
              label: this.$t('orders.CustomerFindsMechanic'),
            },
          ]
          for (var i in data) {
            mechs.push({
              value: data[i].id,
              label: data[i].name,
            })
          }
          this.mechanics = mechs

          if (this.setMechanicWhenFetchedIndex !== null && this.setMechanicWhenFetchedId) {
            setTimeout(() => {
              let newTrackers = [...this.order.trackers]
              newTrackers[this.setMechanicWhenFetchedIndex].mechanic = this.setMechanicWhenFetchedId
              this.$set(this.order, 'trackers', newTrackers)
              this.setMechanicWhenFetchedIndex = null
              this.setMechanicWhenFetchedId = null
            }, 1)
          }

          this.loadingState = false

          return Promise.resolve(data)
        },
        error => {
          console.error('Error getting mechanics', error)
          return Promise.reject(error)
        },
      )
    },

    getTrackers() {
      return TrackerService.listAvailable().then(
        data => {
          let availableTrackers = []
          for (var i in data) {
            availableTrackers.push(data[i])
          }
          this.availableTrackers = availableTrackers

          if (this.setImeiWhenFetchedIndex !== null && this.setImeiWhenFetchedImei) {
            setTimeout(() => {
              let newTrackers = [...this.order.trackers]
              newTrackers[this.setImeiWhenFetchedIndex].imei = this.setImeiWhenFetchedImei
              this.$set(this.order, 'trackers', newTrackers)
              this.setImeiWhenFetchedIndex = null
              this.setImeiWhenFetchedImei = null
            }, 1)
          }

          return Promise.resolve(data)
        },
        error => {
          console.error('Error getting mechanics', error)
          return Promise.reject(error)
        },
      )
    },

    handleSubmit(e) {
      let imeis = []
      for (var i in this.order.trackers) {
        if (typeof this.order.trackers[i].imei == 'undefined' || this.order.trackers[i].imei == -1) {
          return Promise.reject('Du skal vælge eller oprette en tracker for hver tracker på ordren.')
        } else if (imeis.includes(this.order.trackers[i].imei)) {
          return Promise.reject('Du har valgt samme tracker til mere end en af de bestilte trackere på ordren.')
        } else {
          imeis.push(this.order.trackers[i].imei)
        }
        if (this.order.trackers[i].withInstallation && (typeof this.order.trackers[i].mechanic == 'undefined' || this.order.trackers[i].mechanic  == -1)) {
          //return Promise.reject('Du skal vælge mekaniker for hver tracker der skal installeres.')
        }
        if (typeof this.order.trackers[i].dateExpires == 'undefined' || !this.order.trackers[i].dateExpires || this.order.trackers[i].dateExpires == '0000-00-00 00:00:00') {
          return Promise.reject('Du skal vælge udløbsdato for hver tracker.')
        }
      }
      //console.log(this.order.trackers)
      //return Promise.reject('TODO: TEST')
      return OrdersService.createOrUpdate(this.order).then(
        data => {
          this.toastSuccess(this.$t('orders.OrderUpdated'))
          if (data.downloadUrl) {
            setTimeout(() => {
              window.location = data.downloadUrl
            }, 500)
          } else {
            console.log(data)
          }
          return Promise.resolve(data)
        },
        error => {
          console.error('Error saving note', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
