<template>
  <div id="theMapAutofitButton">
    <div v-if="!MapViewStore.autofit">
      <McButton onmap v-on:click="enableAutofit" class="m-0 boxShadow" variant="primary" :icon="['fad', 'wand-magic']" :text="$t('map.enableautofit')" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('MapViewStore', [
      'enableAutofit', //
    ]),
  },
  computed: {
    ...mapState({
      MapViewStore: state => state.MapViewStore,
    }),
  },
}
</script>

<style lang="scss">
#theMapAutofitButton {
  text-align: right;
  margin: 10px;
  z-index: 9999999 !important;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
</style>
