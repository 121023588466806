<template>
  <McForm
    ref="mcFormInstance"
    :loading="loadingState"
    v-bind="$attrs"
    @reset="handleReset"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    :submiticon="isEditing ? 'edit' : 'plus'"
    :title="isEditing ? $t('orders.EditOrder') : $t('orders.AddOrder')"
    :submittext="isEditing ? $t('general.Save') : $t('orders.AddOrder')"
  >
    <h3>{{ $t('orders.Trackers') }}</h3>
    <p v-if="curData.trackers.length < 1">Tilføj en eller flere trackere til ordren...</p>
    <OrderTrackers v-model="curData.trackers" details editable />

    <hr />

    <h3>{{ $t('orders.Customer') }}</h3>

    <mc-form-select
      v-if="$perms.isAdministrator()"
      name="administratorUserId"
      v-model="curData.adminUserId"
      :data-vv-as="$t('general.adminUser')"
      :label="$t('general.adminUser')"
      :placeholder="$t('trackerform.chooseUser')"
      key="trackerform.userId"
      :errorHint="errors.first('userId')"
      :state="!errors.has('userId')"
      :options="userList"
      :icon="['fad', 'user-cog']"
    />

    <mc-form-select
      v-if="curData.adminUserId > 0"
      name="ownerContactId"
      v-model="curData.ownerContactId"
      :data-vv-as="$t('trackers.ownerContact')"
      :label="$t('trackers.ownerContact')"
      :placeholder="$t('trackerform.chooseOwnerContact')"
      key="trackerform.ownerContactId"
      :errorHint="errors.first('ownerContactId')"
      :state="!errors.has('ownerContactId')"
      :options="ownerContactList"
      :icon="['fad', 'user-crown']"
    />

    <mc-form-select
      v-if="curData.adminUserId > 0"
      name="billingContactId"
      v-model="curData.billingContactId"
      :placeholder="$t('trackerform.chooseBillingContact')"
      :data-vv-as="$t('trackers.billingContact')"
      :label="$t('trackers.billingContact')"
      key="trackerform.billingContactId"
      :errorHint="errors.first('billingContactId')"
      :state="!errors.has('billingContactId')"
      :options="billingContactList"
      :icon="['fad', 'user-chart']"
    />

    <div v-if="curData.adminUserId == 0 || curData.billingContactId == 0 || curData.ownerContactId == 0">
      <hr />
      <ContactFormInner v-model="curData.customer" />

      <b-form-group>
        <b-input-group>
          <b-form-checkbox class="ml" v-model="curData.customer.vatApplies">
            {{ $t('contactform.vatApplies') }}
          </b-form-checkbox>
        </b-input-group>
      </b-form-group>
    </div>

    <!--pre> {{ curData }} </pre-->
  </McForm>
</template>

<script>
import { mapActions } from 'vuex'
import { FormatError } from '../helpers/ErrorFormatting'
import OrdersService from '../services/OrdersService'
import MiscService from '../services/MiscService'
import { isEmptyObject } from '../helpers/Misc'

const defaultData = {
  id: -1,
  adminUserId: 0,
  ownerContactId: 0,
  billingContactId: 0,
  customer: {
    id: -2,
    userId: 0,
    type: 'I',
    name: '',
    company: '',
    cvr: '',
    ean: '',
    reference: '',
    rekvisition: '',
    address1: '',
    address2: '',
    zip: '',
    city: '',
    country: 'Danmark',
    email: '',
    phone: '',
    website: '',
    vatApplies: true,
  },
  trackers: [],
}

export default {
  data() {
    return {
      curData: JSON.parse(JSON.stringify(defaultData)),

      loadingState: true,
      contactsAndUsers: [],
      isEditing: false,

      settingVal: false,
    }
  },
  props: {
    value: Object,
  },
  computed: {
    userList: function() {
      var theList = [{ value: 0, label: this.$t('orders.NewUser') }]
      for (var userId in this.contactsAndUsers) {
        theList.push({ value: userId, label: this.contactsAndUsers[userId].label })
      }
      return theList
    },
    ownerContactList: function() {
      var theList = [{ value: 0, label: this.$t('orders.NewOwnerContact') }]
      if (typeof this.contactsAndUsers[this.curData.adminUserId] === 'object') {
        for (var contactId in this.contactsAndUsers[this.curData.adminUserId].contacts) {
          theList.push({ value: contactId, label: this.contactsAndUsers[this.curData.adminUserId].contacts[contactId] })
        }
      }
      return theList
    },
    billingContactList: function() {
      var theList = [{ value: 0, label: this.$t('orders.NewBillingContact') }]
      if (typeof this.contactsAndUsers[this.curData.adminUserId] === 'object') {
        for (var contactId in this.contactsAndUsers[this.curData.adminUserId].contacts) {
          theList.push({ value: contactId, label: this.contactsAndUsers[this.curData.adminUserId].contacts[contactId] })
        }
      }
      return theList
    },
  },
  watch: {
    value(val) {
      this.$refs['mcFormInstance'].forceReset()

      //If empty object passed, we are not editing an existing object
      this.isEditing = !isEmptyObject(val)

      let newVal = {}
      for (var field in defaultData) {
        newVal[field] = val.hasOwnProperty(field) ? val[field] : defaultData[field]
      }
      this.settingVal = true
      this.curData = JSON.parse(JSON.stringify(newVal))
      setTimeout(() => {
        this.settingVal = false
      }, 200)
    },
    'curData.adminUserId'() {
      if (this.settingVal) {
        return
      }
      if (this.ownerContactList.length > 1) {
        this.curData.ownerContactId = this.ownerContactList[1].value
      }
      if (this.billingContactList.length > 1) {
        this.curData.billingContactId = this.billingContactList[1].value
      }
    },
  },
  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  created() {
    MiscService.contactsAndUsers()
      .then(data => {
        this.contactsAndUsers = data
        this.loadingState = false
      })
      .catch(error => {
        this.loadingState = FormatError(error)
      })
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    handleReset() {
      this.curData = JSON.parse(JSON.stringify(defaultData))
    },

    handleSubmit(e) {
      if (this.curData.trackers.length == 0) {
        return Promise.reject('Der skal tilføjes mindst en tracker!')
      }
      return OrdersService.createOrUpdate(this.curData).then(
        data => {
          this.handleReset()
          this.toastSuccess(this.isEditing ? this.$t('orders.OrderSaved') : this.$t('orders.OrderCreated'))
          return Promise.resolve(data)
        },
        error => {
          console.error('Error creating order', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
