<template>
  <b-form-group :label="label" :description="description" :state="!errorHint">
    <b-form-row>
      <b-col sm="6" class="mb-2 mb-sm-0">
        <slot name="input1"></slot>
      </b-col>
      <b-col sm="6">
        <slot name="input2"></slot>
      </b-col>
    </b-form-row>
    <TransitionToAuto v-if="errorHint">
      <div class="invalid-feedback d-block">{{ errorHint }}</div>
    </TransitionToAuto>
  </b-form-group>
</template>

<script>
export default {
  props: {
    errorHint: String,
    label: String,
    description: String,
  },
}
</script>
