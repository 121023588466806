<template>
  <b-form-group :label="label" :description="description" :state="!errorHint">
    <b-input-group class="mc-form-defaulted-textarea">
      <b-input-group-text v-if="icon" slot="prepend" class="bg-primary text-white">
        <McIcon :icon="icon" />
      </b-input-group-text>
      <!-- prettier-ignore -->

      <div class="custom-file">
        <div class="radioboxes">
          <label><input type="radio" v-model="majorValue" value="default" /><span>{{ $t('alarms.UseDefault') }}</span></label>
          <label><input type="radio" v-model="majorValue" value="specify" /><span>{{ $t('alarms.UseSpecial') }}</span></label>
        </div>
        <textarea v-if="majorValue === 'specify'" ref="textarea" @input="e => $emit('input', e.target.value)" :value="value" :placeholder="placeholder"></textarea>
        <p class="possible_tags" v-if="majorValue === 'specify' && tags.length > 0">
          <span>{{ $tc('general.YouCanUseTheseTagsColon', tags.length) }}</span><br>
          <span v-for="(tag, index) in tags" v-bind:key="tag"><code @click="insertTag(tag)">{{ '{' + tag + '}' }}</code><span v-if="index + 1 < tags.length">, </span></span>
        </p>
      </div>
    </b-input-group>
    <TransitionToAuto v-if="errorHint">
      <div class="invalid-feedback d-block">{{ errorHint }}</div>
    </TransitionToAuto>
  </b-form-group>
</template>

<script>
export default {
  props: {
    value: String,
    defaultValue: {
      type: String,
      default: '',
    },
    icon: [String, Array],
    errorHint: String,
    label: String,
    description: String,
    placeholder: String,
    tags: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    majorValue: {
      get: function() {
        return this.value === 'default' ? 'default' : 'specify'
      },
      set: function(val) {
        if (val === 'default') {
          this.$emit('input', 'default')
        } else {
          this.$emit('input', this.defaultValue)
        }
      },
    },
  },
  methods: {
    insertTag(tag) {
      let cursorPos = this.$refs['textarea'].selectionStart
      let newValue = this.value.substring(0, cursorPos) + '{' + tag + '}' + this.value.substring(cursorPos)
      this.$emit('input', newValue)
      setTimeout(() => {
        this.$refs['textarea'].selectionStart = this.$refs['textarea'].selectionEnd = cursorPos + tag.length + 2
        this.$refs['textarea'].focus()
      })
    },
  }
}
</script>

<style lang="scss">
.input-group.mc-form-defaulted-textarea {
  > .custom-file {
    height: auto;
    display: block;
    padding: 0 10px;
    overflow: auto;
    border: 1px solid #ced4da;
    border-left: 0;
    border-radius: 0 3px 3px 0;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  input {
    margin-right: 10px;
    margin-left: 10px;
  }
  label {
    display: block;
    margin: 0;
    white-space: nowrap;
    display: inline-block;
    line-height: 2em;
  }
  label span {
    display: inline-block;
    white-space: normal;
    vertical-align: top;
    max-width: calc(100% - 40px);
    max-width: none;
  }
  textarea {
    width: 100%;
    height: 80.4px;
    margin: 0 0 0.5em 0;
    font-size: 0.8em;
  }
  .possible_tags {
    font-size: 0.8em;
  }
  code {
    color: #666;
    cursor: pointer;
    &:hover {
      color: #000;
    }
  }
}
</style>
