<template>
  <div class="autopay-card-wrapper">
    <div v-if="addCard" class="autopay-card autopay-add-card" @click="$emit('click')">
      <span class="autopay-card-icon-add"><McIcon :icon="'plus'"/></span>
      <span class="autopay-card-label">{{ text ? text : $t('billing.AddNewCard') }}</span>
    </div>
    <div v-else-if="payByCard" class="autopay-card autopay-pay-by-card" @click="$emit('click')">
      <span class="autopay-card-icon-pay-by"><McIcon :icon="'question'"/></span>
      <span class="autopay-card-label">{{ text ? text : $t('billing.PayByCreditCardDontSave') }}</span>
    </div>
    <div v-else-if="payByMobilepay" class="autopay-card autopay-pay-by-mobilepay" @click="$emit('click')">
      <span class="autopay-card-icon-pay-by"><img src="@/assets/mobilepay.png"/></span>
      <span class="autopay-card-label">{{ text ? text : $t('billing.PayByMobilepay') }}</span>
    </div>
    <div v-else>
      <div :class="'autopay-card stored-card' + (' card-' + card.status) + ' card-brand-' + card.brand + (isPayment ? ' is-payment' : '')" @click="$emit('click')">
        <span class="autopay-card-number">{{ card.cardNo | cardNo }}</span>
        <span class="autopay-card-expiry"><small>exp.</small>{{ card.expiry }}</span>
      </div>
      <McButton v-if="true || !isPayment" class="autopay-card-delete-button" icon="trash" variant="danger" size="sm" :text="$t('billing.deleteAutopayCard')" @click="$emit('delete')" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    card: Object,
    addCard: Boolean,
    payByCard: Boolean,
    payByMobilepay: Boolean,
    isPayment: Boolean,
    text: String,
  },
  filters: {
    cardNo(cardNoWithoutSpaces) {
      return cardNoWithoutSpaces
        .split('-')
        .join('')
        .match(new RegExp('.{1,4}', 'g'))
        .join('-')
    },
  },
}
</script>

<style lang="scss">
.autopay-card-wrapper {
  display: inline-block;
  text-align: center;
  padding: 1em;
  vertical-align: top;
}
.autopay-card {
  height: 10em;
  width: 16em;
  margin: 0 0 1em 0;
  border-radius: 0.7em;
  position: relative;
}
.autopay-card.stored-card {
  color: #fff;
  &.card-active {
    background-color: #094ba2;
  }
  &.card-failed {
    background-color: #888888;
    * {
      text-decoration: line-through !important;
    }
  }
  background-image: url('~@/assets/cards/card-details.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  &.is-payment.card-active {
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    &:hover {
      transform: translateY(-4px) scale(1.02) rotate(2deg);
      box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.2);
    }
  }
}
.autopay-pay-by-card,
.autopay-add-card {
  cursor: pointer;
  background-color: #ddd;
  .McIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .autopay-card-icon-pay-by,
  .autopay-card-icon-add {
    color: #ddd;
    background: rgba(0, 0, 0, 0.2);
    font-size: 3em;
    border-radius: 9em;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1.5em;
    height: 1.5em;
  }
  &:hover {
    .autopay-card-icon-pay-by,
    .autopay-card-icon-add {
      color: #fff;
      background: #094ba2;
    }
  }
}
.autopay-pay-by-mobilepay {
  background-color: #5673b8;
  color: #fff;
  img {
    width: 50%;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  &:hover {
    transform: translateY(-4px) scale(1.02) rotate(2deg);
    box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.2);
  }
}
.autopay-card-label {
  position: absolute;
  top: 75%;
  left: 0;
  right: 0;
  text-align: center;
}
.autopay-card-number {
  position: absolute;
  top: 55%;
  left: 10%;
  right: 0;
  text-align: left;
}
.autopay-card-expiry {
  font-size: 0.8em;
  position: absolute;
  top: 73%;
  left: 10%;
  right: 0;
  text-align: left;
}

.card-brand-visa:before,
.card-brand-dankort:before,
.card-brand-jcb:before,
.card-brand-mastercard-debet:before,
.card-brand-mastercard:before,
.card-brand-maestro:before {
  content: '';
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 73%;
  right: 1em;
  width: 2em;
  height: 1.5em;
}
.card-brand-visa:after,
.card-brand-dankort:after,
.card-brand-jcb:after,
.card-brand-mastercard-debet:after,
.card-brand-mastercard:after,
.card-brand-maestro:after {
  content: '';
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 1em;
  left: 1em;
  height: 1em;
}

.card-brand-visa:before {
  background-image: url('~@/assets/cards/visa.svg');
}
.card-brand-visa:after {
  content: 'VISA';
}
.card-brand-dankort:before {
  background-image: url('~@/assets/cards/dankort.svg');
}
.card-brand-dankort:after {
  content: 'Dankort';
}

.card-brand-jcb:before {
  background-image: url('~@/assets/cards/jcb.svg');
}
.card-brand-jcb:after {
  content: 'JCB';
}

.card-brand-mastercard-debet:before,
.card-brand-mastercard:before {
  background-image: url('~@/assets/cards/mastercard.svg');
}
.card-brand-mastercard:after {
  content: 'MasterCard';
}
.card-brand-mastercard-debet:after {
  content: 'MasterCard (debet)';
}
.card-brand-maestro:before {
  background-image: url('~@/assets/cards/maestro.svg');
}
.card-brand-maestro:after {
  content: 'Maestro';
}
</style>
