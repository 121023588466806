import { authorizedApiRequest, apiRequest, queryParameters } from '../setup/ApiService'

export default {
  query,
  list,
  getByImei,
  createOrUpdate,
  listForCancellation,
  cancel,
  confirmCancellationByToken,
  recreate,
  upgrade,
  upgradePrice,
  confirmRecreationByToken,
  delete: _delete,
  listExpired,
  listAvailable,
  setMode,
  getMode,
}

function listExpired() {
  return authorizedApiRequest(
    '/trackers/list-expired', //
    'GET',
  )
}

function query(params) {
  return authorizedApiRequest(
    '/trackers?' + queryParameters(params), //
    'GET',
  )
}

function list() {
  return authorizedApiRequest(
    '/trackers/list', //
    'GET',
  )
}

function listAvailable() {
  return authorizedApiRequest(
    '/trackers/list-available', //
    'GET',
  )
}

function listForCancellation() {
  return authorizedApiRequest(
    '/trackers/cancellable', //
    'GET',
  )
}

function cancel(imei) {
  return authorizedApiRequest(
    `/trackers/${imei}/cancel`, //
    'POST',
  )
}

function confirmCancellationByToken(imei, secret) {
  return apiRequest(
    '/trackers/' + imei + '/confirm-cancel', //
    'POST',
    {
      secret,
    },
  )
}

function upgrade(imei) {
  return authorizedApiRequest(
    `/trackers/${imei}/upgrade`, //
    'POST',
  )
}
function upgradePrice(imei) {
  return authorizedApiRequest(
    `/trackers/${imei}/upgrade-price`, //
    'GET',
  )
}

function recreate(imei) {
  return authorizedApiRequest(
    `/trackers/${imei}/recreate`, //
    'POST',
  )
}

function confirmRecreationByToken(imei, secret) {
  return apiRequest(
    '/trackers/' + imei + '/confirm-recreate', //
    'POST',
    {
      secret,
    },
  )
}

function getByImei(imei) {
  return authorizedApiRequest(
    `/trackers/${imei}`, //
    'GET',
  )
}

function createOrUpdate(tracker) {
  return authorizedApiRequest(
    `/trackers/${tracker.imei}`, //
    'PUT',
    tracker,
  )
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(imei) {
  return authorizedApiRequest(
    `/trackers/${imei}`, //
    'DELETE',
  )
}

function setMode(imei, mode) {
  return authorizedApiRequest(
    `/trackers/${imei}/mode`, //
    'PUT',
    { mode },
  )
}

function getMode(imei) {
  return authorizedApiRequest(
    `/trackers/${imei}/mode`, //
    'GET',
  )
}
