<template>
  <div id="theMapTrackerMenu">
    <transition name="fadezoom">
      <div v-if="!MapViewStore.zoomedTracker">
        <McButton onmap v-on:click="toggleMenu" class="m-0 boxShadow" variant="primary" :icon="MapViewStore.menuIsOut ? 'times' : ['fad', 'cars']" :text="$t('headings.trackers')" />
        <transition name="fadezoom">
          <div v-if="MapViewStore.menuIsOut" class="theMapTrackerMenuList boxShadow">
            <ul>
              <li v-for="(trackerInfo, imei) in filteredTrackerList" :key="imei" @click="toggleTracker(imei)" :class="MapViewStore.viewedTrackers[imei] === true ? 'viewed' : 'unviewed'">
                <McIcon sized :style="{ color: trackerInfo.color }" :icon="trackerInfo.icon" />
                <span class="trackerName">{{ trackerInfo.name }}</span>
              </li>
            </ul>
            <!--pre>{{ MapViewStore.trackerList }}</pre-->
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TrackerService from './../services/TrackerService'

export default {
  mounted() {
    TrackerService.list().then(data => {
      this.waitForGoogleMap().then(() => {
        this.updateTrackerList({ ...data })
      })
    })
  },
  methods: {
    waitForGoogleMap() {
      return new Promise((resolve, reject) => {
        const tryAgain = () => {
          let retval = typeof google !== 'undefined'
          if (retval) {
            //console.log('ready1')
            resolve()
            return
          }
          //console.log('not ready')
          setTimeout(tryAgain, 100)
        }
        tryAgain()
      })
    },
    toggleMenu() {
      if (this.MapViewStore.menuIsOut) {
        this.hideMenu()
      } else {
        this.showMenu()
      }
    },
    unzoom() {
      this.$router.push('/map/')
      this.unzoomTracker()
    },
    ...mapActions('MapViewStore', [
      'viewTracker', //
      'unviewTracker',
      'unzoomTracker',
      'updateTrackerList',
      'hideMenu',
      'showMenu',
    ]),
    toggleTracker(imei) {
      if (this.MapViewStore.viewedTrackers[imei] === true) {
        this.unviewTracker(imei)
      } else {
        this.viewTracker(imei)
      }
    },
  },
  computed: {
    ...mapState({
      MapViewStore: state => state.MapViewStore,
    }),
    filteredTrackerList() {
      let retval = {}
      for (let imei in this.MapViewStore.trackerList) {
        if (this.$perms.isAdministrator() || this.$perms.hasPermission(imei, 'currentPosition')) {
          retval[imei] = this.MapViewStore.trackerList[imei]
        }
      }
      return retval
    },
  },
}
</script>

<style lang="scss">
.theMapTrackerMenuList {
  background: #fff;
  max-height: calc(100vh - 198px);
  border-radius: 2px;
  overflow-y: auto;
  margin: 10px 0 0 0;
  font-size: 14px;
  text-align: left;
  position: absolute;
  right: 0;
  top: 100%;
  width: 280px;
  transform-origin: top right;
  .trackerName {
    width: calc(100% - 2.2rem);
    display: inline-block;
    vertical-align: middle;
  }
}
#theMapTrackerMenu {
  //background: #f99;
  text-align: right;
  margin: 10px;
  z-index: 9 !important;
  position: absolute;
  top: 0;
  right: 0;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0.4em;
      cursor: pointer;
      &.viewed {
        background: #eee;
      }
      &:hover {
        background: #ddd;
      }
    }
  }
}

.fadezoom-enter-active,
.fadezoom-leave-active {
  transition: transform 0.2s, opacity 0.2s;
}
.fadezoom-enter,
.fadezoom-leave-to {
  transform: scale(0.01);
  opacity: 0;
}
</style>
