<template>
  <McForm
    ref="mcFormInstance"
    v-bind="$attrs"
    @reset="handleReset"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    :submiticon="isEditing ? 'edit' : 'plus'"
    :title="isEditing ? $t('mechanics.edit') : $t('mechanics.add')"
    :submittext="isEditing ? $t('general.Save') : $t('mechanics.add')"
  >
    <ContactFormInner v-model="curData" :isEditing="isEditing" skipType skipCountry />

    <!--pre> {{ curData }} </pre-->
  </McForm>
</template>

<script>
import { mapActions } from 'vuex'
import { isEmptyObject } from '../helpers/Misc'
import MechanicsService from '../services/MechanicsService'

const defaultData = {
  id: -1,
  userId: 0,
  type: 'C',
  name: '',
  company: '',
  cvr: '',
  ean: '',
  reference: '',
  rekvisition: '',
  address1: '',
  address2: '',
  zip: '',
  city: '',
  country: 'Danmark',
  email: '',
  phone: '',
  website: '',
  vatApplies: true,
}

export default {
  components: {},
  data() {
    return {
      curData: { ...defaultData },
      types: [],

      isEditing: false,
    }
  },
  props: {
    value: Object,
  },
  watch: {
    value(val) {
      this.$refs['mcFormInstance'].forceReset()

      //If empty object passed, we are not editing an existing object
      this.isEditing = !isEmptyObject(val)

      let newVal = {}
      for (var field in defaultData) {
        newVal[field] = val.hasOwnProperty(field) ? val[field] : defaultData[field]
      }
      this.curData = newVal
    },
  },
  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    handleReset() {
      this.curData = { ...defaultData }
    },

    handleSubmit(e) {
      return MechanicsService.createOrUpdate(this.curData).then(
        data => {
          this.toastSuccess(this.$t('mechanics.MechanicCreated'))

          this.$emit('mechanicSaved', data.id)

          return Promise.resolve(data)
        },
        error => {
          console.error('Error saving mechanic', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
