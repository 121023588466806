<template>
  <div id="app">
    <TheHeader />
    <div class="mainContent">
      <PageTransition>
        <router-view></router-view>
      </PageTransition>
    </div>
    <TheFooter class="footerwrap" />
    <b-modal @ok="saveShownMessage" id="status-message-modal" ok-only :title="localStatusMessage.subject">
      <div v-html="localStatusMessage.body" />
    </b-modal>
    <McToast />
  </div>
</template>

<style>
html,
body {
  height: 100%;
}
body {
  overflow-y: scroll;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
}
.mainContent {
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: auto;
}
@supports (backdrop-filter: blur(3px)) {
  body .modal-backdrop {
    backdrop-filter: blur(3px);
    transition: backdrop-filter 0.2s, opacity 0.2s;
    opacity: 1;
    background: rgba(0, 0, 0, 0.5);
  }
}
</style>

<script>
import { mapState } from 'vuex'
import MiscService from './services/MiscService'
import './assets/globalStyles.scss'

export default {
  data() {
    return {
      statusMessage: {
        body: { da: '', en: '' },
        subject: { da: '', en: '' },
      },
    }
  },
  mounted() {
    setTimeout(this.updateMessage, 500)
    setInterval(this.updateMessage, 1000 * 60 * 15)
  },
  methods: {
    updateMessage() {
      if (!this.LoginStore.user) {
        localStorage.removeItem('statusMessageShown')
        localStorage.removeItem('statusMessage')
        this.statusMessage = {
          body: { da: '', en: '' },
          subject: { da: '', en: '' },
        }
        return
      }
      MiscService.getStatusMessage().then(data => {
        if (typeof data === 'object') {
          localStorage.setItem('statusMessage', JSON.stringify(data))
          this.statusMessage = data
          this.showMessageIfChanged()
        }
      })
    },
    showMessageIfChanged() {
      if (localStorage.getItem('statusMessageShown') !== JSON.stringify(this.localStatusMessage) && this.localStatusMessage.body.length > 10 && this.localStatusMessage.subject.length > 0) {
        this.$bvModal.show('status-message-modal')
      }
    },
    saveShownMessage() {
      localStorage.setItem('statusMessageShown', JSON.stringify(this.localStatusMessage))
    },
  },
  watch: {
    localStatusMessage() {
      this.showMessageIfChanged()
    },
    'LoginStore.user'() {
      setTimeout(this.updateMessage, 500)
    },
  },
  computed: {
    ...mapState({
      LayoutStore: state => state.LayoutStore,
      LanguageStore: state => state.LanguageStore,
      LoginStore: state => state.LoginStore,
    }),
    localStatusMessage() {
      if (!this.LoginStore.user) {
        return {
          body: '',
          subject: '',
        }
      }
      let currentMessage = {
        ...this.statusMessage,
        lang: this.LanguageStore.currentLanguage,
      }
      if (!currentMessage.body) {
        return {
          body: '',
          subject: '',
        }
      }
      return {
        body: currentMessage.body[this.LanguageStore.currentLanguage],
        subject: currentMessage.subject[this.LanguageStore.currentLanguage],
      }
    },
  },
}
</script>
