<template>
  <div>
    <!-- eslint-disable-next-line -->
    <McForm
      ref="mcFormInstance"
      v-bind="$attrs"
      :loading="loadingState"
      :onSubmit="handleSubmit"
    >
      <mc-form-checkboxes name="enable" v-model="curData.enable" :label="$t('mileage.EnableMileageNotices')" key="mileagenoticeform.enable" :options="enabledOptions" :icon="['fad', 'inbox']" inline />

      <mc-form-select
        name="minDistance"
        v-if="curData.enable"
        v-model="curData.minDistance"
        :label="$t('general.minDistance')"
        :placeholder="$t('general.chooseMinDistance')"
        :description="$t('alarms.chooseMinDistanceForMileageNotice')"
        key="mileagenoticeform.minDistance"
        :errorHint="errors.first('minDistance')"
        :state="!errors.has('minDistance')"
        :options="minDistances"
        :icon="['far', 'ruler']"
      />

      <!-- eslint-disable-next-line -->
      <McFormAlarmContacts
        v-if="curData.enable"
        name="contacts"
        v-model="curData.contacts"
        :alarmContacts="alarmContacts"
        :label="$t('alarms.recipient(s)')"
        :icon="['fad', 'users']"
      />

      <!--pre>{{ curData }}</pre-->
    </McForm>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AlarmService from '../services/AlarmService'
import MiscService from '../services/MiscService'
import { FormatError } from '../helpers/ErrorFormatting'

const defaultData = {
  enable: false,
  ruleId: -1,
  minDistance: 500,
  contacts: [],
}

export default {
  components: {},
  mounted() {
    MiscService.alarmContactsDefaultTextsAndFences()
      .then(data => {
        this.loadingState = false
        this.alarmContacts = data.contacts
      })
      .catch(error => {
        this.loadingState = FormatError(error)
      })
  },
  data() {
    return {
      loadingState: true,
      alarmContacts: [],
      curData: { ...defaultData },
      minDistances: [
        { value: 0, label: this.$t('general.minDistanceAnyMovement') },
        { value: 250, label: this.$t('general.minDistance250') },
        { value: 500, label: this.$t('general.minDistance500') },
        { value: 1000, label: this.$t('general.minDistance1000') },
      ],
      enabledOptions: [
        { value: false, label: this.$t('general.Disable') }, //
        { value: true, label: this.$t('general.Enable') },
      ],
    }
  },
  props: {
    imei: {
      type: String,
      default: null,
    },
    value: Object,
  },
  watch: {
    value(val) {
      if (typeof val !== 'object') {
        val = {}
      }
      this.$refs['mcFormInstance'].forceReset()

      this.$set(this.curData, 'enable', val.hasOwnProperty('ruleId'))
      this.$set(this.curData, 'ruleId', val.hasOwnProperty('ruleId') ? val.ruleId : defaultData.ruleId)
      this.$set(this.curData, 'minDistance', val.hasOwnProperty('details') && val.details.hasOwnProperty('minDistance') ? val.details.minDistance : defaultData.minDistance)
      this.$set(this.curData, 'contacts', val.hasOwnProperty('contacts') ? [...val.contacts] : [...defaultData.contacts])
    },
  },

  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    handleReset() {
      // Don't reset this one
    },

    handleSubmit(e) {
      let action = null
      if (this.curData.enable) {
        if (this.curData.contacts.length < 1) {
          return new Promise((resolve, reject) => {
            reject(this.$t('error.YouNeedToChooseAtLeastOneRecipient'))
          })
        }
        action = AlarmService.replaceMileageNoticeByImei(this.imei, this.curData.minDistance, this.curData.contacts)
      } else {
        action = AlarmService.removeMileageNoticeByImei(this.imei)
      }
      return action.then(
        data => {
          //console.log(data)
        },
        error => {
          console.error('Error saving mileage notice form', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
