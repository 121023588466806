<template>
  <transition name="expand" v-bind="$attrs" @enter="enter" @afterEnter="afterEnter" @leave="leave">
    <div v-if="visible" :class="classes">
      <div ref="staticWidth">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    }
  },
  props: {
    horisontal: Boolean,
    zoom: Boolean,
    move: Boolean,
    noFade: Boolean,
  },
  mounted: function() {
    setTimeout(() => {
      this.visible = true
    })
  },
  computed: {
    axis: function() {
      return this.horisontal ? 'width' : 'height'
    },
    anti: function() {
      return this.horisontal ? 'height' : 'width'
    },
    classes: function() {
      return [
        this.zoom ? 'zoomTransition' : null, //
        this.move ? 'moveTransition' : null,
        this.noFade ? null : 'fadeTransition',
        this.horisontal ? 'horisontal' : null,
      ]
    },
  },
  methods: {
    afterEnter(element) {
      if (!this.$refs.staticWidth) return
      element.style[this.axis] = `auto`
      this.$refs.staticWidth.style.width = `auto`
    },
    enter(element) {
      if (!this.$refs.staticWidth) return
      const { width } = getComputedStyle(this.$refs.staticWidth)
      this.$refs.staticWidth.style.width = width

      const aSize = getComputedStyle(element)[this.anti]

      element.style[this.anti] = aSize
      element.style.position = `absolute`
      element.style.visibility = `hidden`
      element.style[this.axis] = `auto`

      const size = getComputedStyle(element)[this.axis]

      element.style[this.anti] = null
      element.style.position = null
      element.style.visibility = null
      element.style[this.axis] = 0

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element)[this.axis]

      setTimeout(() => {
        element.style[this.axis] = size
      })
    },
    leave(element) {
      const size = getComputedStyle(element)[this.axis]

      element.style[this.axis] = size

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element)[this.axis]

      setTimeout(() => {
        element.style[this.axis] = 0
      })
    },
  },
}
</script>

<style scoped>
* {
  will-change: height width;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
.horisontal {
  display: inline-block;
}
.expand-enter-active,
.expand-leave-active {
  transition: height 0.2s ease-in-out, width 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  overflow: hidden;
}
.horisontal.expand-enter-active,
.horisontal.expand-leave-active {
  overflow: visible;
}

.fadeTransition.expand-enter,
.fadeTransition.expand-leave-to {
  opacity: 0;
}
.moveTransition.expand-enter,
.moveTransition.expand-leave-to {
  transform: translate(0, -20px);
}
.zoomTransition.expand-enter,
.zoomTransition.expand-leave-to {
  transform: scale(0.8);
}
.zoomTransition.moveTransition.expand-enter,
.zoomTransition.moveTransition.expand-leave-to {
  transform: scale(0.8) translate(0, -20px);
}
</style>
