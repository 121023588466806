<template>
  <McForm
    ref="mcFormInstance"
    :loading="loadingState"
    v-bind="$attrs"
    @reset="handleReset"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    :submiticon="isEditing ? 'edit' : 'plus'"
    :title="isEditing ? $t('contacts.edit') : $t('contacts.add')"
    :submittext="isEditing ? $t('general.Save') : $t('contacts.add')"
  >
    <mc-form-select
      v-if="$perms.isAdministrator()"
      name="administratorUserId"
      v-model="curData.userId"
      :data-vv-as="$t('general.adminUser')"
      :label="$t('general.adminUser')"
      :placeholder="$t('contactform.chooseUser')"
      key="contactform.userId"
      :errorHint="errors.first('userId')"
      :state="!errors.has('userId')"
      :options="userList"
      :icon="['fad', 'user-cog']"
    />

    <b-form-group v-if="$perms.isAdministrator()">
      <b-input-group>
        <b-form-checkbox class="ml-5" v-model="curData.vatApplies">
          {{ $t('contactform.vatApplies') }}
        </b-form-checkbox>
      </b-input-group>
    </b-form-group>

    <ContactFormInner v-model="curData" :isEditing="isEditing" />

    <!--pre> {{ curData }} </pre-->
  </McForm>
</template>

<script>
import { mapActions } from 'vuex'
import { FormatError } from '../helpers/ErrorFormatting'
import { isEmptyObject } from '../helpers/Misc'
import ContactsService from '../services/ContactsService'
import MiscService from '../services/MiscService'

const defaultData = {
  id: -1,
  userId: 0,
  type: 'I',
  name: '',
  company: '',
  cvr: '',
  ean: '',
  reference: '',
  rekvisition: '',
  address1: '',
  address2: '',
  zip: '',
  city: '',
  country: 'Danmark',
  email: '',
  phone: '',
  website: '',
  vatApplies: true,
}

export default {
  components: {},
  data() {
    return {
      curData: { ...defaultData },
      types: [],

      isEditing: false,

      loadingState: true,
      contactsAndUsers: [],
    }
  },
  props: {
    value: Object,
  },
  computed: {
    userList: function() {
      var theList = []
      for (var userId in this.contactsAndUsers) {
        theList.push({ value: userId, label: this.contactsAndUsers[userId].label })
      }
      return theList
    },
  },
  watch: {
    value(val) {
      this.$refs['mcFormInstance'].forceReset()

      //If empty object passed, we are not editing an existing object
      this.isEditing = !isEmptyObject(val)

      let newVal = {}
      for (var field in defaultData) {
        newVal[field] = val.hasOwnProperty(field) ? val[field] : defaultData[field]
      }
      this.curData = newVal
    },
  },
  created() {
    if (this.$perms.isAdministrator()) {
      MiscService.contactsAndUsers()
        .then(data => {
          this.contactsAndUsers = data
          this.loadingState = false
        })
        .catch(error => {
          this.loadingState = FormatError(error)
        })
    } else {
      this.loadingState = false
    }
  },
  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    handleReset() {
      this.curData = { ...defaultData }
    },

    handleSubmit(e) {
      return ContactsService.createOrUpdate(this.curData).then(
        data => {
          this.toastSuccess(this.$t('contactform.success'))
          return Promise.resolve(data)
        },
        error => {
          console.error('Error saving contact', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
