<template>
  <McLayoutForm :heading="$t('installations.InstallationOfTracker')">
    <div v-if="loadingState">
      <McSpinner large />
    </div>
    <div v-else-if="errorState">
      <h1>{{ $t('general.Error') }}</h1>
      <p>{{ $t('installations.InvalidLinkPleaseContactUs') }}</p>
    </div>
    <div v-else>
      <TransitionToAuto move zoom :key="'step0'" v-if="installation.misc.installationStep == 0">
        <b-card class="mb-4"><div v-html="isInsurance ? $t('installations.InstallationInstructions') : $t('installations.InstallationInstructionsNotInsurance')" /></b-card>
        <!-- eslint-disable-next-line -->
        <McForm
          ref="mcFormInstance"
          @completed="e => $emit('completed', e)"
          :onSubmit="handleSubmit"
          :successText="false"
          :submittext="$t('installations.SaveAndTestInstallation')"
          buttonsOnRight
        >
          <div v-if="isInsurance">
            <h3>{{ $t('installations.VehicleAndInsurance') }}</h3>
            <TrackerInstallationFormInner :isInsurance="isInsurance" v-model="installation" />
          </div>
          <div>
            <h3>{{ $t('installations.Workshop') }}</h3>
            <ContactFormInner v-model="installation.mechanic" skipType skipCountry />
          </div>
          <h3>{{ $t('installations.MechanicAndPlacement') }}</h3>
          <!-- eslint-disable-next-line -->
          <mc-form-input
            type="text"
            :label="$t('installations.MechanicName')"
            v-model="installation.misc.mechanicName"
            :placeholder="$t('general.EnterYourName')"
            v-validate="'required'"
            :data-vv-as="$t('installations.MechanicName')"
            name="mechanicName"
            key="installationform.mechanicName"
            :errorHint="errors.first('mechanicName')"
            :state="!errors.has('mechanicName')"
          />

          <!-- eslint-disable-next-line -->
          <mc-form-input
            type="text"
            :label="$t('installations.Placement')"
            v-model="installation.misc.placement"
            :placeholder="$t('installations.EnterPlacement')"
            v-validate="'required'"
            :data-vv-as="$t('installations.Placement')"
            name="placement"
            key="installationform.placement"
            :errorHint="errors.first('placement')"
            :state="!errors.has('placement')"
          />
        </McForm>
      </TransitionToAuto>
      <TransitionToAuto move zoom :key="'step1'" v-if="installation.misc.installationStep == 1">
        <b-card class="mb-4" :title="$t('installations.ConfirmStartTestsHeading')">
          <div v-html="$t('installations.ConfirmStartTestsBody')" />
          <template #footer>
            <div class="text-right">
              <McButton variant="link" :text="$t('installations.GoBack')" @click="setInstallationStep(0)" />
              <McButton variant="primary" :text="$t('installations.YesStartTesting')" @click="setInstallationStep(2)" />
            </div>
          </template>
        </b-card>
      </TransitionToAuto>

      <TransitionToAuto move zoom :key="'step2'" v-if="installation.misc.installationStep == 2">
        <b-card class="mb-4 text-center" :title="$t('installations.TestStep1of3')">
          <div class="blink-green" v-html="$t('installations.TestInstructionsTurnIgnitionOn')" />
          <div style="font-size:4em;display:block">
            <font-awesome-icon class="blink-green" icon="key" fixed-width />
          </div>
          <div>{{ $t('installations.TestWillContinueAutomaticallyOnIgnitionOn') }} <font-awesome-icon icon="spinner" spin fixed-width /></div>
          <template #footer>
            <div class="text-center">
              <McButton variant="danger" :text="$t('installations.StopTesting')" @click="setInstallationStep(0)" />
            </div>
          </template>
        </b-card>
      </TransitionToAuto>

      <TransitionToAuto move zoom :key="'step3'" v-if="installation.misc.installationStep == 3">
        <b-card class="mb-4 text-center" :title="$t('installations.TestStep2of3')">
          <div class="blink-red" v-html="$t('installations.TestInstructionsTurnIgnitionOff')" />
          <div style="font-size:4em;display:block">
            <font-awesome-icon class="blink-red" icon="key" fixed-width />
          </div>
          <div>{{ $t('installations.TestWillContinueAutomaticallyOnIgnitionOff') }} <font-awesome-icon icon="spinner" spin fixed-width /></div>
          <template #footer>
            <div class="text-center">
              <McButton variant="danger" :text="$t('installations.StopTesting')" @click="setInstallationStep(0)" />
            </div>
          </template>
        </b-card>
      </TransitionToAuto>

      <TransitionToAuto move zoom :key="'step4'" v-if="installation.misc.installationStep == 4">
        <b-card class="mb-4 text-center" :title="$t('installations.TestStep3of3')">
          <div>{{ $t('installations.AwaitingControlCentralConfirmation') }}</div>
          <div style="font-size:4em;display:block">
            <font-awesome-icon icon="spinner" spin fixed-width />
          </div>
          <div>{{ $t('installations.TestWillContinueAutomaticallyOnControlCentralConfirmation') }}</div>
          <template #footer>
            <div class="text-center">
              <McButton variant="danger" :text="$t('installations.StopTesting')" @click="setInstallationStep(0)" />
            </div>
          </template>
        </b-card>
      </TransitionToAuto>

      <TransitionToAuto move zoom :key="'step5'" v-if="installation.misc.installationStep >= 5">
        <b-card class="mb-4 text-center">
          <div style="font-size:4em;display:block" class="text-success">
            <font-awesome-icon icon="check-circle" fixed-width />
          </div>
          <div>{{ $t('installations.TestsCompleted') }}</div>
        </b-card>
      </TransitionToAuto>
      <!--pre>{{ $route.params }}</pre>
      <pre>{{ installation }}</pre>
      <pre>{{ tracker }}</pre-->
      <!-- {{ isInsurance ? 'is insurance' : 'is not insurance' }} -->
      <div class="help_phone_notice">{{ $t('installations.InCaseOfTrouble') }}: <a href="tel:+4561683715">+45 61 68 37 15</a></div>
    </div>
  </McLayoutForm>
</template>

<script>
import { FormatError } from '../../helpers/ErrorFormatting'
import InstallationService from '../../services/InstallationService'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      errorState: false,
      loadingState: true,
      installation: null,
      updateTimer: null,
      isInsurance: true,
    }
  },
  created() {
    this.getInstallationBySecret(this.$route.params.imei, this.$route.params.secret)
    this.updateTimer = setInterval(this.updateState, 5000)
  },
  beforeDestroy() {
    clearInterval(this.updateTimer)
  },
  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),
    getInstallationBySecret(imei, secret) {
      return new Promise((resolve, reject) => {
        InstallationService.getInstallationBySecret(imei, secret)
          .then(data => {
            this.loadingState = false
            if (typeof data.isInsurance === 'boolean') {
              this.isInsurance = data.isInsurance
            } else {
              this.isInsurance = true
            }
            this.installation = data.installation
            resolve(data)
          })
          .catch(error => {
            console.error(FormatError(error))
            this.loadingState = false
            this.errorState = true
          })
      })
    },
    setInstallationStep(step) {
      return InstallationService.setInstallationStepBySecret(this.$route.params.imei, step, this.$route.params.secret).then(
        data => {
          this.installation.misc.installationStep = data.installationStep
          if (data.installationStep == 0) {
            //We're back at first step because of another device - reload form info...
            this.getInstallationBySecret(this.$route.params.imei, this.$route.params.secret)
          }
        },
        error => {
          console.error('Error saving installation step', error)
          return Promise.reject(error)
        },
      )
    },
    updateState() {
      if (!this.installation) {
        return
      }
      let prevStep = this.installation.misc.installationStep
      return InstallationService.getInstallationStepBySecret(this.$route.params.imei, this.$route.params.secret).then(
        data => {
          if (data.installationStep != prevStep) {
            this.installation.misc.installationStep = data.installationStep
            if (data.installationStep == 0) {
              //We're back at first step because of another device - reload form info...
              this.getInstallationBySecret(this.$route.params.imei, this.$route.params.secret)
            }
          }
        },
        error => {
          console.error('Error getting installation step', error)
        },
      )
    },
    handleSubmit(e) {
      if (this.isInsurance && !this.installation.vehicleDetails.regNo && !this.installation.vehicleDetails.vin) {
        return Promise.reject(this.$t('installations.EitherVinOrRegNoIsRequired'))
      }

      return InstallationService.setInstallationBySecret(this.$route.params.imei, this.installation, this.$route.params.secret).then(
        data => {
          this.toastSuccess(this.$t('installations.InstallationSaved'))
          this.installation.misc.installationStep = data.installationStep
          return Promise.resolve(data)
        },
        error => {
          console.error('Error saving installation', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>

<style>
.help_phone_notice {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5em;
  text-align: center;
  background: #fee;
  border: 2px solid #d00;
  border-radius: 5px 5px 0 0;
  border-bottom: 0;
  z-index: 999;
  width: 100%;
  max-width: 310px;
}
.blink-green {
  animation: blinkGreen 1s linear infinite;
}
.blink-red {
  animation: blinkRed 1s linear infinite;
}

@keyframes blinkGreen {
  0% {
    color: #0d0;
  }
  100% {
    color: #080;
  }
}

@keyframes blinkRed {
  0% {
    color: #d00;
  }
  100% {
    color: #800;
  }
}
</style>
