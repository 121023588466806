export default {
  phone: phone => {
    let output = phone.replace(/[^\+0-9]/g, '')
    if (output.substring(0, 1) == '+') {
      output = '00' + output.substring(1)
    } else if (output.substring(0, 2) != '00') {
      output = '0045' + output
    }
    output = output.replace(/[^0-9]/g, '')
    return output
  },
  email: email => {
    return email.trim()
  },
}
