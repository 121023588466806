import { authorizedApiRequest } from '../setup/ApiService'

export default {
  recent: function() {
    return authorizedApiRequest(
      '/insurance-tests', //
      'GET',
    )
  },

  createTest: function() {
    return authorizedApiRequest(
      '/insurance-tests', //
      'PUT',
    )
  },

  mark: function(id, status) {
    return authorizedApiRequest(
      '/insurance-tests/' + id + '/status', //
      'PUT',
      { status },
    )
  },

  delete: function(id, status) {
    return authorizedApiRequest(
      '/insurance-tests/' + id, //
      'DELETE',
    )
  },
}
