<template>
  <!-- eslint-disable-next-line -->
  <McForm
    ref="mcFormInstance"
    :loading="loadingState"
    v-bind="$attrs"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    :title="$t('installations.EditInstallation')"
    :warnBeforeClose="warnBeforeClose"
    :successText="false"
  >
    <template slot="secondarybuttons">
      <b-button variant="link" @click="$refs['mcFormInstance'].hideModal()">{{ $t('general.Cancel') }}</b-button>
      <mcButton variant="secondary" @click="saveAndDownload()" :icon="['fad', 'cloud-download-alt']" :text="$t('installations.SaveAndDownload')" />
    </template>
    <b-tabs content-class="mt-3">
      <b-tab :title="$t('installations.Customer')" active>
        <ContactFormInner v-model="installation.customer" skipEmail skipPhone allowEmpty skipCountry />
      </b-tab>
      <b-tab v-if="installation.vehicleDetails" :title="$t('installations.VehicleAndInsurance')">
        <TrackerInstallationFormInner v-model="installation" />
        <!--pre>{{ installation.vehicleDetails }}</pre-->
      </b-tab>
      <b-tab :title="$t('installations.Mechanic')">
        <ContactFormInner v-model="installation.mechanic" skipType skipCountry allowEmpty />
      </b-tab>
      <b-tab v-if="installation.misc" :title="$t('installations.Misc')">
        <p class="text-center"><mcButton variant="link" @click="getMechanicInstructions" :text="$t('installations.getMechanicInstructions')" /></p>
        <p>{{ $t('installations.NormallyDontEditMisc') }}</p>
        <!-- eslint-disable-next-line -->
        <mc-form-input
          type="text"
          :label="$t('installations.MechanicName')"
          v-model="installation.misc.mechanicName"
          :placeholder="$t('installations.EnterMechanicName')"
        />

        <!-- eslint-disable-next-line -->
        <mc-form-input
          type="text"
          :label="$t('installations.Placement')"
          v-model="installation.misc.placement"
          :placeholder="$t('installations.EnterPlacement')"
        />

        <!-- eslint-disable-next-line -->
        <mc-form-input
          type="text"
          :label="$t('installations.InstallationDate')"
          v-model="installation.misc.date"
          :placeholder="$t('installations.InstallationDate')"
        />

        <!-- eslint-disable-next-line -->
        <mc-form-select
          icon="hashtag"
          :options="installationStepOptions"
          :label="$t('installations.InstallationStep')"
          v-model="installation.misc.installationStep"
          :placeholder="$t('installations.InstallationStep')"
        />

        <!-- eslint-disable-next-line -->
        <mc-form-checkbox
          type="checkbox"
          :label="$t('installations.Controltest')"
          description=""
          v-model="installation.misc.testCompleted"
        >
          {{ $t('installations.ControltestCompleted') }}
        </mc-form-checkbox>
      </b-tab>
      <!--b-tab v-if="installation.misc" :title="$t('installations.Installation')">
        <pre>{{ installation.misc }}</pre>
      </b-tab-->
    </b-tabs>

    <!--pre>{{ installation }}</pre-->
  </McForm>
</template>

<script>
import { mapActions } from 'vuex'
import { FormatError } from '../helpers/ErrorFormatting'
import InstallationService from '../services/InstallationService'
import deepEqual from 'deep-eql'

export default {
  components: {},
  data() {
    return {
      installation: {},
      origInstallation: {},

      loadingState: true,
      imei: '',
      doDownloadAfterSave: false,
    }
  },
  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  computed: {
    warnBeforeClose() {
      //console.log(this.hashAlarms(this.alarmRules), this.hashAlarms(this.origAlarmRules))
      return deepEqual(this.installation, this.origInstallation) ? '' : this.$t('general.YouHaveUnsavedChangesDoYouWantToCloseTheFormWithoutSaving')
    },
    installationStepOptions() {
      return [
        { value: '0', label: this.$t('installations.InstallationStep0') }, //
        { value: '1', label: this.$t('installations.InstallationStep1') },
        { value: '2', label: this.$t('installations.InstallationStep2') },
        { value: '3', label: this.$t('installations.InstallationStep3') },
        { value: '4', label: this.$t('installations.InstallationStep4') },
        { value: '9', label: this.$t('installations.InstallationStep9') },
      ]
    },
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),
    getMechanicInstructions() {
      return InstallationService.getMechanicInstructions(this.imei, this.installation, this.doDownloadAfterSave).then(
        data => {
          if (data.downloadUrl) {
            window.location = data.downloadUrl
          } else {
            console.log(data)
          }
        },
        error => {
          console.error('Error getting link', error)
          return Promise.reject(error)
        },
      )
    },
    saveAndDownload() {
      this.doDownloadAfterSave = true
      this.$refs['mcFormInstance'].handleSubmit()
    },
    setImei(imei) {
      this.imei = imei

      this.doDownloadAfterSave = false
      this.loadingState = true
      Promise.all([
        InstallationService.getByImei(this.imei).then(data => {
          this.installation = data.installation
          this.origInstallation = JSON.parse(JSON.stringify(data.installation))
        }),
      ])
        .then(data => {
          this.loadingState = false
        })
        .catch(error => {
          this.loadingState = FormatError(error)
        })
    },

    handleSubmit(e) {
      return InstallationService.setByImei(this.imei, this.installation, this.doDownloadAfterSave).then(
        data => {
          this.origInstallation = this.installation
          this.toastSuccess(this.$t('installations.InstallationSaved'))
          if (data.downloadUrl) {
            window.location = data.downloadUrl
          }
          return Promise.resolve(data)
        },
        error => {
          console.error('Error saving installation', error)
          return Promise.reject(error)
        },
      )
      this.doDownloadAfterSave = false
    },
  },
}
</script>
