<template>
  <McLayoutForm>
    <div v-if="loadingState">
      <McSpinner large />
    </div>
    <div v-else-if="errorState">
      <h2>{{ $t('general.Error') }}</h2>
      <p>{{ $t('general.InvalidLink') }}</p>
    </div>
    <div v-else>
      <h2>{{ $t('headings.recreation') }}</h2>
      <p>{{ $t('billing.recreationConfirmed1', tracker) }}</p>
      <p>{{ $t('billing.recreationConfirmed2', tracker) }}</p>
    </div>
  </McLayoutForm>
</template>

<script>
import { FormatError } from '../../helpers/ErrorFormatting'
import TrackerService from '../../services/TrackerService'

export default {
  data() {
    return {
      errorState: false,
      loadingState: true,
      tracker: null,
    }
  },
  created() {
    TrackerService.confirmRecreationByToken(this.$route.params.imei, this.$route.params.secret)
      .then(data => {
        this.loadingState = false
        this.tracker = data.tracker
      })
      .catch(error => {
        console.error(FormatError(error))
        this.loadingState = false
        this.errorState = true
      })
  },
}
</script>
