import { queryParameters, apiRequest, authorizedApiRequest, addLanguageAndTimezone } from '../setup/ApiService'

export default {
  alarmsByImei: imei => {
    return authorizedApiRequest(
      '/alarms/' + imei, //
      'GET',
    )
  },
  replaceAlarmsByImei: (imei, alarmRules, geofences, insuranceSettings) => {
    return authorizedApiRequest(
      '/alarms/' + imei, //
      'PUT',
      addLanguageAndTimezone({
        alarmRules,
        geofences,
        insuranceSettings,
      }),
    )
  },

  mileageAlarmsByImei: imei => {
    return authorizedApiRequest(
      '/mileage/notices/' + imei, //
      'GET',
    )
  },
  replaceMileageNoticeByImei: (imei, minDistance, contacts) => {
    return authorizedApiRequest(
      '/mileage/notices/' + imei + '/mileageNotice', //
      'PUT',
      addLanguageAndTimezone({
        minDistance,
        contacts,
      }),
    )
  },
  removeMileageNoticeByImei: imei => {
    return authorizedApiRequest(
      '/mileage/notices/' + imei + '/mileageNotice', //
      'DELETE',
    )
  },
  replaceOdometerAdjustmentNoticeByImei: (imei, contacts) => {
    return authorizedApiRequest(
      '/mileage/notices/' + imei + '/odometerAdjustmentNotice', //
      'PUT',
      addLanguageAndTimezone({
        contacts,
      }),
    )
  },
  removeOdometerAdjustmentNoticeByImei: imei => {
    return authorizedApiRequest(
      '/mileage/notices/' + imei + '/odometerAdjustmentNotice', //
      'DELETE',
    )
  },
  getEventBySecret: (imei, alarmId, secret) => {
    return apiRequest(
      '/alarms/event/' + imei + '/' + alarmId + '?' + queryParameters(addLanguageAndTimezone({ secret })), //
      'GET',
    )
  },
}
