<template>
  <div ref="mapdiv" v-bind="$attrs"></div>
</template>

<script>
import gmapsInit from '../map/googleMaps'
import tracknordicMap from '../map/tracknordicMap'

export default {
  data() {
    return {
      polyline: null,
      tnMap: null,
    }
  },
  props: {
    points: {
      type: Array,
      default: () => [],
    },
    circles: {
      type: Array,
      default: () => [],
    },
    markers: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: '#0000ff',
    },
  },
  computed: {
    firstPoint() {
      return { ...this.points[0] }
    },
    lastPoint() {
      return { ...this.points[this.points.length - 1] }
    },
  },
  mounted() {
    //setTimeout(() => {
    try {
      this.loadMap(this.$refs['mapdiv']).then(() => {
        this.testinit()
      })
    } catch (error) {
      console.error(error)
    }
    //}, 500)
  },

  methods: {
    drawHistory: function(data) {
      this.tnMap.drawHistory('fakeimei', 'test', 'car', '#f00', data.points)
    },
    updateHistory: function(status) {
      this.tnMap.updateHistory(status)
    },
    async loadMap(elm) {
      try {
        const google = await gmapsInit()
        const mapOpts = {
          center: {
            lat: 55.68,
            lng: 11.1,
          },
          zoom: 12,
          minZoom: 2,
          restriction: {
            latLngBounds: {
              north: 85,
              south: -85,
              west: -180,
              east: 180,
            },
          },
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
        }
        this.map = new google.maps.Map(elm, mapOpts)
      } catch (error) {
        console.error(error)
      }
    },
    testinit: function() {
      this.tnMap = new tracknordicMap(this.map, google)
      this.drawStuff()
      this.fitBounds()
    },
    drawStuff: function() {
      var bounds = new google.maps.LatLngBounds()
      this.points.forEach(point => {
        bounds.extend(point)
      })

      if (this.markers) {
        this.markers.forEach((marker, mIndex) => {
          this.tnMap.addMarkerAndUpdateIfExists(mIndex, marker.coordinate, marker.color, marker.html, marker.offset, marker.arrowLength)
        })
      }
      if (this.circles) {
        this.circles.forEach((circle, mIndex) => {
          new google.maps.Circle({
            radius: circle.radius,
            center: circle.center,
            strokeColor: circle.color,
            fillColor: circle.color,
            map: this.map,
          })
        })
      }

      this.polyline = new google.maps.Polyline({
        path: this.points,
        geodesic: true,
        strokeColor: this.color,
        strokeOpacity: 1.0,
        strokeWeight: 3,
        zIndex: 0,
        map: this.map,
        icons: [
          {
            icon: {
              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              fillOpacity: 1,
              fillColor: '#fff',
              strokeWeight: 1,
              scale: 2,
            },
            offset: '50%',
            repeat: '20px',
          },
          {
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              fillOpacity: 1,
              strokeWeight: 2,
              fillColor: '#fff',
              scale: 7,
            },
            offset: '0%',
          },
          {
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              fillOpacity: 1,
              strokeWeight: 2,
              fillColor: '#fff',
              scale: 7,
            },
            offset: '100%',
          },
        ],
      })
    },
    fitBounds: function() {
      var bounds = new google.maps.LatLngBounds()
      this.points.forEach(point => {
        bounds.extend(point)
      })

      if (this.markers) {
        this.markers.forEach((marker, mIndex) => {
          bounds.union(
            new google.maps.Circle({
              radius: 2500,
              center: marker.coordinate,
            }).getBounds(),
          )
        })
      }
      if (this.circles) {
        this.circles.forEach((circle, mIndex) => {
          bounds.union(theCircle.getBounds())
        })
      }
      this.map.fitBounds(bounds)
    },
  },
}
</script>
