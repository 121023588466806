<template>
  <div>
    <!-- eslint-disable-next-line -->
    <McForm
      ref="mcFormInstance"
      v-bind="$attrs"
      :onSubmit="handleSubmit"
      :submittext="$t('mileage.SaveOdometer')"
    >
      <mc-form-input
        name="odometer"
        type="text"
        v-model="curData.odometer"
        v-validate="'required|numeric'"
        :data-vv-as="$t('mileage.Odometer')"
        :label="$t('mileage.Odometer')"
        :placeholder="$t('mileage.EnterCurrentOdometer')"
        key="mileageodometeradjustmentnoticeform.odometer"
        :errorHint="errors.first('odometer')"
        :state="!errors.has('odometer')"
        :icon="['fad', 'tachometer-slow']"
        :description="odometerUpdateDescription"
      />
    </McForm>
  </div>
</template>

<script>
import MileageService from '../services/MileageService'
import { localizedDatetime, dateToLocalizedHumanreadableDate } from '../helpers/Time'

const defaultData = {
  odometer: '',
}

export default {
  components: {},
  data() {
    return {
      curData: { ...defaultData },
      latestOdometerAdjustment: null,
      expectedOdometerValue: null,
    }
  },
  props: {
    imei: {
      type: String,
      default: null,
    },
    value: Object,
    secret: {
      type: String,
      default: null,
    },
  },
  computed: {
    odometerUpdateDescription() {
      return this.latestOdometerAdjustment === null
        ? this.$t('mileage.NeverAdjusted')
        : this.$t('mileage.LatestAdjustment', { date: dateToLocalizedHumanreadableDate(localizedDatetime(this.latestOdometerAdjustment)) })
    },
  },
  watch: {
    value(val) {
      this.handleNewValue(val)
    },
  },

  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  mounted() {
    this.handleNewValue(this.value)
  },
  methods: {
    handleNewValue(val) {
      if (typeof val !== 'object' || val === null) {
        val = {}
      }
      this.$refs['mcFormInstance'].forceReset()

      this.$set(this.curData, 'odometer', val.hasOwnProperty('value') && val.metersSinceAdjustment !== null ? val.value + Math.round(val.metersSinceAdjustment / 1000) : '')
      this.$set(this, 'expectedOdometerValue', val.hasOwnProperty('value') && val.metersSinceAdjustment !== null ? val.value + Math.round(val.metersSinceAdjustment / 1000) : null)
      this.$set(this, 'latestOdometerAdjustment', val.hasOwnProperty('datetime') ? val.datetime : null)
    },

    handleReset() {
      // Don't reset this one
    },

    handleSubmit(e) {
      let discrepancyPercent = 0
      let discrepancyKm = 0
      if (this.expectedOdometerValue !== null) {
        discrepancyPercent = Math.abs(this.curData.odometer / this.expectedOdometerValue - 1) * 100
        discrepancyKm = Math.abs(this.curData.odometer - this.expectedOdometerValue)
      }
      if (discrepancyPercent > 10) {
        let confirmation = this.$t('mileage.DiscrepancyHigherThanExpectedConfirmation', {
          discrepancyPercent: Math.round(discrepancyPercent) + '%', //
          discrepancyKm: Math.round(discrepancyKm) + 'km',
          odometer: Math.round(this.curData.odometer) + 'km',
          expectedOdometer: Math.round(this.expectedOdometerValue) + 'km',
        })
        if (!confirm(confirmation)) {
          return Promise.reject(this.$t('general.CheckYourInputAndSubmitAgain'))
        }
      }
      let action = null
      if (this.secret) {
        action = MileageService.setOdometerWithSecret(this.imei, this.curData.odometer, this.secret)
      } else {
        action = MileageService.setOdometer(this.imei, this.curData.odometer)
      }
      return action.then(
        data => {
          //console.log('done', data)
          this.$emit('odometerUpdated', data)
        },
        error => {
          console.error('Error saving odometer adjustment notice', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
