import { authorizedApiRequest, apiRequest, queryParameters, addLanguageAndTimezone } from '../setup/ApiService'

export default {
  smsBalances: function() {
    return authorizedApiRequest(
      '/billing/sms-balances', //
      'GET',
    )
  },
  invoices: function() {
    return authorizedApiRequest(
      '/billing/invoices', //
      'GET',
    )
  },
  adminInvoices: function(params) {
    return authorizedApiRequest(
      '/billing/admin-invoices?' + queryParameters(params), //
      'GET',
    )
  },
  adminInvoicesExport: function(params) {
    return authorizedApiRequest(
      '/billing/admin-invoices/export?' + queryParameters(addLanguageAndTimezone(params)), //
      'GET',
    )
  },
  manualPayment: function(params) {
    return authorizedApiRequest(
      '/billing/manual-payment', //
      'POST',
      params,
    )
  },
  cancelInvoice: function(invoiceId) {
    return authorizedApiRequest(
      '/billing/invoices/' + invoiceId, //
      'PATCH',
      {
        textStatus: 'cancelled',
      },
    )
  },
  autopayCards: function() {
    return authorizedApiRequest(
      '/billing/autopay-cards', //
      'GET',
    )
  },
  addAutopayCard: function(currency) {
    return authorizedApiRequest(
      '/billing/autopay-cards/add', //
      'POST',
      addLanguageAndTimezone({
        currency,
      }),
    )
  },
  payByAutopayCard: function(cardId, currency, invoiceIds) {
    return authorizedApiRequest(
      '/billing/autopay-cards/' + cardId + '/pay-invoices', //
      'POST',
      {
        currency,
        invoiceIds,
      },
    )
  },
  payByCard: function(cardType, currency, invoiceIds) {
    return authorizedApiRequest(
      '/billing/pay-invoices', //
      'POST',
      addLanguageAndTimezone({
        cardType,
        currency,
        invoiceIds,
      }),
    )
  },
  deleteAutopayCard: function(cardId) {
    return authorizedApiRequest(
      '/billing/autopay-cards/' + cardId, //
      'DELETE',
    )
  },
  checkPaymentStatus: function(paymentId) {
    return authorizedApiRequest(
      '/billing/payments/' + paymentId + '/status', //
      'GET',
    )
  },
  checkSubscriptionStatus: function(cardId) {
    return authorizedApiRequest(
      '/billing/autopay-cards/' + cardId + '/status', //
      'GET',
    )
  },

  smsRefillByToken: function(secret) {
    return apiRequest(
      '/refill-sms?' + queryParameters({ secret }), //
      'GET',
    )
  },
  addAutopayCardBySmsToken: function(currency, secret) {
    return authorizedApiRequest(
      '/refill-sms/add-autopay-card?' + queryParameters({ secret }), //
      'POST',
      addLanguageAndTimezone({
        currency,
      }),
    )
  },
  payByAutopayCardBySmsToken: function(cardId, currency, count, secret) {
    return authorizedApiRequest(
      '/refill-sms/pay-by-auto-card?' + queryParameters({ secret }), //
      'POST',
      {
        currency,
        cardId,
        count,
      },
    )
  },
  payByCardBySmsToken: function(cardType, currency, count, secret) {
    return authorizedApiRequest(
      '/refill-sms/pay-by-card?' + queryParameters({ secret }), //
      'POST',
      addLanguageAndTimezone({
        cardType,
        currency,
        count,
      }),
    )
  },
  deleteAutopayCardBySmsToken: function(cardId, secret) {
    return authorizedApiRequest(
      '/refill-sms/autopay-cards/' + cardId + '?' + queryParameters({ secret }), //
      'DELETE',
    )
  },
  checkPaymentStatusBySmsToken: function(paymentId, secret) {
    return authorizedApiRequest(
      '/refill-sms/payment-status/' + paymentId + '?' + queryParameters({ secret }), //
      'GET',
    )
  },
  checkSubscriptionStatusBySmsToken: function(cardId, secret) {
    return authorizedApiRequest(
      '/refill-sms/autopay-card-status/' + cardId + '?' + queryParameters({ secret }), //
      'GET',
    )
  },

  paymentByToken: function(invoice, secret) {
    return apiRequest(
      '/payment/' + invoice + '?' + queryParameters({ secret }), //
      'GET',
    )
  },
  addAutopayCardByToken: function(currency, invoice, secret) {
    return authorizedApiRequest(
      '/payment/' + invoice + '/add-autopay-card?' + queryParameters({ secret }), //
      'POST',
      addLanguageAndTimezone({
        currency,
      }),
    )
  },
  payByAutopayCardByToken: function(cardId, currency, invoice, secret) {
    return authorizedApiRequest(
      '/payment/' + invoice + '/pay-by-auto-card?' + queryParameters({ secret }), //
      'POST',
      {
        currency,
        cardId,
      },
    )
  },
  payByCardByToken: function(cardType, currency, invoice, secret) {
    return authorizedApiRequest(
      '/payment/' + invoice + '/pay-by-card?' + queryParameters({ secret }), //
      'POST',
      addLanguageAndTimezone({
        cardType,
        currency,
      }),
    )
  },
  deleteAutopayCardByToken: function(cardId, invoice, secret) {
    return authorizedApiRequest(
      '/payment/' + invoice + '/autopay-cards/' + cardId + '?' + queryParameters({ secret }), //
      'DELETE',
    )
  },
  checkPaymentStatusByToken: function(paymentId, invoice, secret) {
    return authorizedApiRequest(
      '/payment/' + invoice + '/payment-status/' + paymentId + '?' + queryParameters({ secret }), //
      'GET',
    )
  },
  checkSubscriptionStatusByToken: function(cardId, invoice, secret) {
    return authorizedApiRequest(
      '/payment/' + invoice + '/autopay-card-status/' + cardId + '?' + queryParameters({ secret }), //
      'GET',
    )
  },
}
