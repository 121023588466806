<template>
  <b-tr variant="success" v-if="trip.type === 'adjustment'">
    <b-td colspan="6" class="text-center">
      <TnLocalTime class="badge badge-light m-3" :time="trip.datetime" format="l LT" />
      <span>{{ $t('mileage.OdometerAdjustedToColon') }}</span>
      <b-badge variant="light" class="m-3">{{ $options.filters.odometer(trip.value) }}</b-badge>
    </b-td>
  </b-tr>
  <b-tr v-else>
    <b-td :stacked-heading="$t('mileage.TripType')">
      <MileageTypeSelector :value="trip.type" @input="value => $emit('setType', value)" />
    </b-td>
    <b-td :stacked-heading="$t('mileage.Start')">
      <TnLocalTime class="badge badge-dark" :time="trip.startDatetime" format="l LT" />
      <TnTripAddress :address="trip.startAddress[LanguageStore.currentLanguage]" />
      <b-badge variant="info">{{ $t('mileage.OdometerColon', { odometer: $options.filters.odometer(trip.startOdometer) }) }}</b-badge>
    </b-td>
    <b-td class="d-none d-lg-table-cell">
      <span class="mileage_rightarrow"></span>
    </b-td>
    <b-td :stacked-heading="$t('mileage.End')">
      <TnLocalTime class="badge badge-dark" :time="trip.endDatetime" format="l LT" />
      <TnTripAddress :address="trip.endAddress[LanguageStore.currentLanguage]" />
      <b-badge variant="info">{{ $t('mileage.OdometerColon', { odometer: $options.filters.odometer(trip.startOdometer + trip.distanceMeter) }) }}</b-badge>
    </b-td>
    <b-td class="text-right" :stacked-heading="$t('mileage.Distance')">{{ $options.filters.distance(trip.distanceMeter) }}</b-td>
    <b-td :stacked-heading="$t('mileage.Note')">
      <pre v-if="trip.note" class="mileage_note" v-html="trip.note" />
      <McButton icon="edit" variant="primary" size="sm" @click="$emit('editNote')" v-if="!trip.locked" />
    </b-td>
  </b-tr>
</template>

<script>
import { FormatDistance, FormatOdometer } from '../helpers/Formatting'
import { mapState } from 'vuex'

export default {
  props: {
    trip: Object,
  },
  filters: {
    odometer(meters) {
      return FormatOdometer(meters)
    },
    distance(meters) {
      return FormatDistance(meters)
    },
  },
  computed: {
    ...mapState({
      LanguageStore: state => state.LanguageStore,
    }),
  },
}
</script>
