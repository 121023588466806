<template>
  <b-form-group :label="label" :description="description" :state="!errorHint">
    <b-input-group>
      <b-input-group-text v-if="icon" slot="prepend" class="bg-primary text-white">
        <McIcon :icon="icon" />
      </b-input-group-text>
      <!-- prettier-ignore -->
      <component
        :is="$attrs.type === 'textarea' ? 'b-textarea' : 'b-form-input'"
        v-bind="$attrs"
        v-on="listeners"
        ref="inputElm"
      />
    </b-input-group>
    <TransitionToAuto v-if="errorHint">
      <div class="invalid-feedback d-block">{{ errorHint }}</div>
    </TransitionToAuto>
  </b-form-group>
</template>

<script>
export default {
  computed: {
    listeners() {
      return {
        ...this.$listeners,
      }
    },
  },
  props: {
    icon: [String, Array],
    errorHint: String,
    label: String,
    description: String,
  },
  methods: {
    inputElm() {
      return this.$refs.inputElm
    },
  },
}
</script>
