<template>
  <div class="alarm_list_rule" v-bind="$attrs" v-on="{ ...$listeners }">
    <div>
      <strong>
        <span v-if="rule.type == 'age'">{{ $t('alarmTypes.when.' + rule.type + 'With', { maxAge: niceMaxAge }) }}</span>
        <span v-else-if="rule.type == 'movement'">{{ $t('alarmTypes.when.' + rule.type + 'With', { minDistance: niceMinDistance, timespan: niceTimespanInSeconds }) }}</span>
        <span v-else-if="rule.type == 'tow'">{{ $t('alarmTypes.when.' + rule.type + 'With', { minDistance: niceMinDistance }) }}</span>
        <span v-else-if="rule.type == 'speed'">{{ $t('alarmTypes.when.' + rule.type + 'With', { maxSpeed: niceMaxSpeed }) }}</span>
        <span v-else-if="['fenceEntry', 'fenceExit'].includes(rule.type)">{{ $t('alarmTypes.when.' + rule.type + 'With', { geofence: geofenceNames[rule.details.geofence] }) }}</span>
        <span v-else>{{ $t('alarmTypes.when.' + rule.type) }}</span>
      </strong>
      <span v-if="rule.details.schedule !== true">
        <br />
        {{ $t('alarms.withinCertainTimespans') }}
      </span>
      <span><br />{{ contactSummary }}</span>
    </div>
    <div class="text-center">
      <McButton icon="edit" variant="primary" size="sm" @click="$emit('editClick')" />
      <McButton icon="trash" variant="danger" size="sm" @click="$emit('removeClick')" />
    </div>
  </div>
</template>

<script>
import { FormatPhone, niceMinDistance, niceTimespanInSeconds, niceMaxAge, niceMaxSpeed } from '../helpers/Formatting'

export default {
  props: {
    rule: Object,
    geofences: {},
  },
  computed: {
    geofenceNames() {
      let list = {}
      this.geofences.forEach(elm => {
        list[elm.id] = elm.name
      })
      return list
    },

    contactSummary() {
      if (this.contactCount == 1) {
        if (this.phoneContactCount == 1) {
          return this.$t('alarms.thenSendSmsTo', { to: FormatPhone(this.rule.contacts[0].phone) })
        } else {
          return this.$t('alarms.thenSendEmailTo', { to: this.rule.contacts[0].email })
        }
      } else {
        if (this.phoneContactCount == this.contactCount) {
          return this.$t('alarms.thenSendSmsToXRecipients', { x: this.phoneContactCount })
        } else if (this.emailContactCount == this.contactCount) {
          return this.$t('alarms.thenSendEmailToXRecipients', { x: this.emailContactCount })
        } else {
          return this.$t('alarms.thenSendEmailToXAndSmsToX', {
            email: this.$tc('alarms.xRecipients', this.emailContactCount),
            phone: this.$tc('alarms.xRecipients', this.phoneContactCount),
          })
        }
      }
      return ''
    },
    contactCount() {
      return this.rule.contacts.length
    },
    emailContactCount() {
      return this.rule.contacts.filter(c => c.email !== '').length
    },
    phoneContactCount() {
      return this.rule.contacts.filter(c => c.phone !== '').length
    },
    niceMinDistance(meters) {
      return niceMinDistance(this.rule.details.minDistance)
    },
    niceTimespanInSeconds(seconds) {
      return niceTimespanInSeconds(this.rule.details.timespanInSeconds)
    },
    niceMaxSpeed(maxSpeed) {
      return niceMaxSpeed(this.rule.details.maxSpeed)
    },
    niceMaxAge(seconds) {
      return niceMaxAge(this.rule.details.maxAge)
    },
  },
  methods: {
    FormatPhone(phone) {
      return FormatPhone(phone)
    },
  },
}
</script>

<style lang="scss">
.alarm_list_rule {
  padding: 0.5em;
  border: 1px solid #eee;
  margin: 0 0 0.5em;
  display: flex;
  > div {
    flex-grow: 1;
  }
  > div:last-child {
    padding-left: 1em;
    width: 55px;
    flex-grow: 0;
  }
}
</style>
