<template>
  <input
    ref="autocomplete"
    type="text"
    :class="classname + ' ' + (hasFocus ? 'focused' : 'blurred')"
    :id="id"
    :placeholder="placeholder"
    v-model="autocompleteText"
    @focus="onFocus()"
    @blur="onBlur()"
    @change="onChange"
    @keypress="onKeyPress"
    @keyup="onKeyUp"
    @keydown="onKeyDown"
  />
</template>

<script>
import gmapsInit from '../map/googleMaps'
import { userCoordinates } from '../helpers/Misc'

export default {
  name: 'VueGoogleAutocomplete',

  props: {
    id: {
      type: String,
      required: true,
    },

    classname: String,

    placeholder: {
      type: String,
      default: 'Start typing',
    },
  },

  data() {
    return {
      google: {},
      autocomplete: null,
      autocompleteText: '',
      userCoords: null,
      hasFocus: false,
    }
  },

  watch: {
    autocompleteText: function(newVal, oldVal) {
      this.$emit('inputChange', { newVal, oldVal }, this.id)
    },
  },

  mounted: function() {
    try {
      this.loadMap().then(google => {
        this.google = google
        //console.log('initing')
        this.autocomplete = new google.maps.places.Autocomplete(document.getElementById(this.id), {
          types: ['address'],
        })
        this.autocomplete.addListener('place_changed', this.onPlaceChanged)
      })
    } catch (error) {
      console.error(error)
    }
  },

  methods: {
    async loadMap() {
      try {
        const google = await gmapsInit()
        await userCoordinates().then(userCoords => {
          this.userCoords = {
            lat: userCoords.lat,
            lng: userCoords.lng,
          }
        })
        return google
      } catch (error) {
        console.error(error)
      }
    },
    onPlaceChanged() {
      let place = this.autocomplete.getPlace()

      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        this.$emit('cleared', this.id)
        return
      }

      if (place.address_components !== undefined) {
        // return returnData object and PlaceResult object
        this.autocompleteText = place.formatted_address
        this.$emit('placechanged', place, this.id)

        // update autocompleteText then emit change event
        //this.autocompleteText = document.getElementById(this.id).value
        this.onChange()
      }
    },

    onFocus() {
      let circle = new this.google.maps.Circle({
        center: this.userCoords,
        radius: 100000,
      })
      //console.log('bias', circle)
      this.autocomplete.setBounds(circle.getBounds())

      this.hasFocus = true

      setTimeout(() => {
        const simulated = new KeyboardEvent('keydown', {
          keyCode: 0,
        })
        this.$refs['autocomplete'].dispatchEvent(simulated)
      }, 200)

      this.$emit('focus')
    },

    /**
     * When the input loses focus
     */
    onBlur() {
      this.hasFocus = false
      this.$emit('blur')
    },

    /**
     * When the input got changed
     */
    onChange() {
      if (this.autocompleteText == '') {
        this.$emit('cleared', this.id)
      }
      this.$emit('change', this.autocompleteText)
    },

    /**
     * When a key gets pressed
     * @param  {Event} event A keypress event
     */
    onKeyPress(event) {
      this.$emit('keypress', event)
    },

    onKeyDown(event) {
      //console.log('kd', event)
      if (event.key == 'Enter') {
        //console.log(document.querySelectorAll(".pac-item-selected"))
        if (document.querySelectorAll('.pac-item-selected').length == 0) {
          let firstItem = document.querySelector('.pac-item')
          //console.log('Select: ', firstItem)
          if (firstItem) {
            const simulated = new KeyboardEvent('keydown', {
              key: 'ArrowDown',
              keyCode: 40,
            })
            event.target.dispatchEvent(simulated)
          }
        }
      }
    },

    /**
     * When a keyup occurs
     * @param  {Event} event A keyup event
     */
    onKeyUp(event) {
      this.$emit('keyup', event)
    },

    /**
     * Clear the input
     */
    clear() {
      this.autocompleteText = ''
    },

    /**
     * Focus the input
     */
    focus() {
      this.$refs.autocomplete.focus()
    },

    /**
     * Blur the input
     */
    blur() {
      this.$refs.autocomplete.blur()
    },

    /**
     * Update the value of the input
     * @param  {String} value
     */
    update(value) {
      this.autocompleteText = value
    },
  },
}
</script>
<style>
.pac-container {
  z-index: 9999;
}
</style>
