<template>
  <b-form-group :label="label" :description="description">
    <div class="custom-file mc-color-picker">
      <div class="color-row" v-for="(row, index) in trackerColors" v-bind:key="index">
        <div @click="setColor(item)" :class="item == value ? 'active' : ''" v-for="item in row" v-bind:key="item">
          <span :style="'background:' + item" />
        </div>
      </div>
      <div style="clear:both" />
    </div>
  </b-form-group>
</template>

<script>
export default {
  props: {
    label: String,
    description: String,
    value: String,
    options: [Array],
  },
  computed: {
    trackerColors: () => [
      ['#f1948a', '#bb8fce', '#85c1e9', '#73c6b6', '#82e0aa', '#f8c471', '#e59866', '#d7dbdd', '#b2babb', '#808b96'],
      ['#e74c3c', '#8e44ad', '#3498db', '#16a085', '#2ecc71', '#f39c12', '#d35400', '#bdc3c7', '#7f8c8d', '#2c3e50'],
      ['#78281f', '#4a235a', '#1b4f72', '#0b5345', '#186a3b', '#7e5109', '#6e2c00', '#626567', '#424949', '#000000'],
    ],
  },
  methods: {
    setColor(newColor) {
      //console.log(newColor)
      this.$emit('input', newColor)
    },
  },
}
</script>

<style>
.mc-color-picker span {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 3px;
}
.mc-color-picker .color-row {
  display: flex;
  width: 100%;
}
.mc-color-picker .color-row div {
  border-radius: 3px;
  margin: 0;
  width: 10%;
  height: 2em;
  padding: 0;
  border: 3px solid transparent;
  display: block;
  float: left;
  cursor: pointer;
}
.mc-color-picker div.active {
  background: #333;
  border: 3px solid #333;
}
</style>
