<template>
  <b-form-group :label="label" :description="description" :state="!errorHint">
    <b-input-group class="mc-form-schedule-input">
      <b-input-group-text v-if="icon" slot="prepend" class="bg-primary text-white">
        <McIcon :icon="icon" />
      </b-input-group-text>
      <!-- prettier-ignore -->

      <div class="custom-file">
        <div class="radioboxes">
          <label><input type="radio" v-model="majorSchedule" value="always" /><span>{{ $t('schedule.always') }}</span></label>
          <label><input type="radio" v-model="majorSchedule" value="specify" /><span>{{ $t('schedule.specify') }}</span></label>
        </div>
        <div class="minorSchedule" v-if="majorSchedule === 'specify'">
          <hr />
          <select @change="setQuickChoice" v-model="quickChoice">
            <option value="none">{{ $t('schedule.ChoosePreset') }}</option>
            <option value="OfficeHours">{{ $t('schedule.OfficeHours') }}</option>
            <option value="NotOfficeHours">{{ $t('schedule.NotOfficeHours') }}</option>
            <option value="WorkingHours">{{ $t('schedule.WorkingHours') }}</option>
            <option value="NotWorkingHours">{{ $t('schedule.NotWorkingHours') }}</option>
            <option value="DayTime">{{ $t('schedule.DayTime') }}</option>
            <option value="NightTime">{{ $t('schedule.NightTime') }}</option>
          </select>
          <p style="padding: 1em 0" v-if="value.length < 1">{{ $t('schedule.NoTimespansDefined')}}</p>
          <ul v-else>
            <li v-for="(timespan, id) in value" v-bind:key="id" @click="editTimespan(id)">
              <i18n path="schedule.scheduleItem">
                <template v-slot:startDay>
                  <strong>{{ $t('schedule.dayname' + timespan.startDay) }}</strong>
                </template>
                <template v-slot:startTime>
                  <strong>{{ timespan.startTime }}</strong>
                  <br class="d-md-none" />
                </template>
                <template v-slot:endDay>
                  <strong>{{ $t('schedule.dayname' + timespan.endDay) }}</strong>
                </template>
                <template v-slot:endTime>
                  <strong>{{ timespan.endTime }}</strong>
                </template>
              </i18n>
              <button class="btn ml-1 btn-sm remove_timespan" @click.prevent.stop="e => removeTimeSpan(id)"><McIcon icon="times" /></button>
            </li>
          </ul>
          <McButton icon="plus" size="sm" class="mb-2" :text="$t('schedule.addTimespan')" @click="editTimespan(-1)" />
        </div>

      </div>
    </b-input-group>
    <TransitionToAuto v-if="errorHint">
      <div class="invalid-feedback d-block">{{ errorHint }}</div>
    </TransitionToAuto>
    <TimespanForm :id="'timespanform' + _uid" isModal :value="editingTimespan" @timespanUpdated="timespanUpdated" />
  </b-form-group>
</template>

<script>
export default {
  data() {
    return {
      timespans: [],
      quickChoice: 'none',
      editingTimespan: {},
      editingTimespanId: 0,
    }
  },
  props: {
    value: [Boolean, Array],
    icon: [String, Array],
    errorHint: String,
    label: String,
    description: String,
  },
  computed: {
    majorSchedule: {
      get: function() {
        return this.value === true ? 'always' : 'specify'
      },
      set: function(val) {
        if (val === 'always') {
          this.$emit('input', true)
        } else {
          this.$emit('input', this.timespans)
        }
      },
    },
  },
  watch: {
    value(newVal) {
      if (typeof newVal === 'object') {
        this.timespans = newVal
      }
    },
  },
  mounted() {
    if (typeof this.value === 'object') {
      this.timespans = this.value
    }
  },
  methods: {
    setQuickChoice(val) {
      switch (this.quickChoice) {
        case 'OfficeHours':
          this.timespans = [
            { startDay: 1, endDay: 1, startTime: '08:00', endTime: '15:59' },
            { startDay: 2, endDay: 2, startTime: '08:00', endTime: '15:59' },
            { startDay: 3, endDay: 3, startTime: '08:00', endTime: '15:59' },
            { startDay: 4, endDay: 4, startTime: '08:00', endTime: '15:59' },
            { startDay: 5, endDay: 5, startTime: '08:00', endTime: '15:59' },
          ]
          break
        case 'NotOfficeHours':
          this.timespans = [
            { startDay: 1, endDay: 2, startTime: '16:00', endTime: '07:59' },
            { startDay: 2, endDay: 3, startTime: '16:00', endTime: '07:59' },
            { startDay: 3, endDay: 4, startTime: '16:00', endTime: '07:59' },
            { startDay: 4, endDay: 5, startTime: '16:00', endTime: '07:59' },
            { startDay: 5, endDay: 1, startTime: '16:00', endTime: '07:59' },
          ]
          break
        case 'WorkingHours':
          this.timespans = [
            { startDay: 1, endDay: 1, startTime: '07:00', endTime: '18:59' },
            { startDay: 2, endDay: 2, startTime: '07:00', endTime: '18:59' },
            { startDay: 3, endDay: 3, startTime: '07:00', endTime: '18:59' },
            { startDay: 4, endDay: 4, startTime: '07:00', endTime: '18:59' },
            { startDay: 5, endDay: 5, startTime: '07:00', endTime: '18:59' },
          ]
          break
        case 'NotWorkingHours':
          this.timespans = [
            { startDay: 1, endDay: 2, startTime: '19:00', endTime: '06:59' },
            { startDay: 2, endDay: 3, startTime: '19:00', endTime: '06:59' },
            { startDay: 3, endDay: 4, startTime: '19:00', endTime: '06:59' },
            { startDay: 4, endDay: 5, startTime: '19:00', endTime: '06:59' },
            { startDay: 5, endDay: 1, startTime: '19:00', endTime: '06:59' },
          ]
          break
        case 'DayTime':
          this.timespans = [
            { startDay: 1, endDay: 1, startTime: '06:00', endTime: '20:59' },
            { startDay: 2, endDay: 2, startTime: '06:00', endTime: '20:59' },
            { startDay: 3, endDay: 3, startTime: '06:00', endTime: '20:59' },
            { startDay: 4, endDay: 4, startTime: '06:00', endTime: '20:59' },
            { startDay: 5, endDay: 5, startTime: '06:00', endTime: '20:59' },
            { startDay: 6, endDay: 6, startTime: '06:00', endTime: '20:59' },
            { startDay: 7, endDay: 7, startTime: '06:00', endTime: '20:59' },
          ]
          break
        case 'NightTime':
          this.timespans = [
            { startDay: 1, endDay: 2, startTime: '21:00', endTime: '05:59' },
            { startDay: 2, endDay: 3, startTime: '21:00', endTime: '05:59' },
            { startDay: 3, endDay: 4, startTime: '21:00', endTime: '05:59' },
            { startDay: 4, endDay: 5, startTime: '21:00', endTime: '05:59' },
            { startDay: 5, endDay: 6, startTime: '21:00', endTime: '05:59' },
            { startDay: 6, endDay: 7, startTime: '21:00', endTime: '05:59' },
            { startDay: 7, endDay: 1, startTime: '21:00', endTime: '05:59' },
          ]
          break
      }
      this.quickChoice = 'none'
      this.optimizeAndEmitTimespans()
    },
    editTimespan(id) {
      this.editingTimespanId = id
      //console.log(id, this.timespans)
      if (id == -1) {
        this.editingTimespan = {}
      } else {
        this.editingTimespan = this.timespans[id]
      }
      this.$bvModal.show('timespanform' + this._uid)
    },
    removeTimeSpan(id) {
      this.timespans.splice(id, 1)
      this.optimizeAndEmitTimespans()
    },

    timespanUpdated(timespan) {
      if (this.editingTimespanId == -1) {
        this.timespans.push(timespan)
      } else {
        this.timespans[this.editingTimespanId] = timespan
      }
      this.optimizeAndEmitTimespans()
    },

    optimizeAndEmitTimespans() {
      let numericTimespans = []

      //Transform each timespan to an offset
      this.timespans.forEach(ts => {
        numericTimespans.push(this.convertTimeSpanToNumeric(ts))
      })

      //console.log(this.timespans, [ ...numericTimespans ])

      //Find overlapping timespans and combine them until none are found
      let repeat = true
      let rep = 0
      let oneDay = 60 * 24
      let oneWeek = 7 * oneDay
      while (repeat && rep < 100) {
        rep++
        repeat = false
        breakThis: for (let fk = 0; fk < numericTimespans.length; fk++) {
          let first = numericTimespans[fk]
          for (let sk = 0; sk < numericTimespans.length; sk++) {
            if (sk == fk) {
              continue
            }
            let second = numericTimespans[sk]
            let combined = false
            if (first.start <= second.start && first.end >= second.start) {
              //If first contains the start of second
              //   mtwtfss mtwtfss
              //1   x----x
              //2      x----x
              //=   x-------x
              combined = {
                start: first.start,
                end: first.end > second.end ? first.end : second.end,
              }
              //console.log('first contains the start of second', first, second, combined)
            } else if (first.start <= second.end && first.end >= second.end) {
              //If first contains the end of second
              //   mtwtfss mtwtfss
              //1      x----x
              //2   x----x
              //=   x-------x
              combined = {
                start: first.start < second.start ? first.start : second.start,
                end: first.end,
              }
              //console.log('first contains the end of second', first, second, combined)
            } else if (first.start > second.end && (first.start <= second.start + oneWeek && first.end >= second.start + oneWeek)) {
              //If first is after second but contains the start of second plus one week
              //   mtwtfss mtwtfss
              //1        x---x
              //2   x--x
              //=        x-----x
              combined = {
                start: first.start,
                end: first.end > second.end + oneWeek ? first.end : second.end + oneWeek,
              }
              //console.log('first is after second but contains the start of second plus one week', first, second, combined)
            }
            if (combined) {
              if (combined.end - combined.start > oneWeek) {
                //If any timespan has become greater than one week
                //console.log('combined is greater than one week', first, second, combined)
                numericTimespans = [
                  {
                    start: 1440,
                    end: oneWeek + 1440,
                  },
                ]
                break breakThis
              }
              numericTimespans[fk] = combined
              numericTimespans.splice(sk, 1)
              repeat = true
              break breakThis
            }
          }
        }
      }

      this.timespans = []

      numericTimespans
        .sort((a, b) => a.start - b.start)
        .forEach(numericTimespan => {
          this.timespans.push(this.convertTimeSpanFromNumeric(numericTimespan))
        })

      this.$emit('input', this.timespans)
    },

    convertTimeSpanToNumeric(timespan) {
      let oneDay = 1440
      let st = timespan.startTime.match(/^([0-9]{2}):([0-9]{2})$/)
      let et = timespan.endTime.match(/^([0-9]{2}):([0-9]{2})$/)
      let numericTimespan = {
        start: timespan.startDay * oneDay + parseInt(st[1]) * 60 + parseInt(st[2]),
        end: timespan.endDay * oneDay + parseInt(et[1]) * 60 + parseInt(et[2]) + 1,
      }

      //"Expand" timespans with a week if they end before they start
      //   mtwtfss mtwtfss
      //   --x  x-
      //=       x---x
      if (numericTimespan.end < numericTimespan.start) {
        numericTimespan.end += 7 * oneDay
      }
      return numericTimespan
    },
    convertTimeSpanFromNumeric(numericTimespan) {
      let oneDay = 1440
      let startDay = Math.floor(numericTimespan.start / oneDay)
      let startTime = numericTimespan.start % oneDay
      let startHour = Math.floor(startTime / 60)
      let startMinute = startTime % 60
      let endDay = Math.floor((numericTimespan.end - 1) / oneDay)
      let endTime = (numericTimespan.end - 1) % oneDay
      let endHour = Math.floor(endTime / 60)
      let endMinute = endTime % 60

      return {
        startDay: startDay > 7 ? startDay - 7 : startDay,
        startTime: ('00' + startHour).slice(-2) + ':' + ('00' + startMinute).slice(-2),
        endDay: endDay > 7 ? endDay - 7 : endDay,
        endTime: ('00' + endHour).slice(-2) + ':' + ('00' + endMinute).slice(-2),
      }
    },
  },
}
</script>

<style lang="scss">
.input-group.mc-form-schedule-input {
  > .custom-file {
    height: auto;
    display: block;
    padding: 0 10px;
    overflow: auto;
    border: 1px solid #ced4da;
    border-left: 0;
    border-radius: 0 3px 3px 0;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  input {
    margin-right: 10px;
    margin-left: 10px;
  }
  label {
    display: block;
    margin: 0;
    white-space: nowrap;
    display: inline-block;
    line-height: 2em;
  }
  label span {
    display: inline-block;
    white-space: normal;
    vertical-align: top;
    max-width: calc(100% - 40px);
    max-width: none;
  }
  .minorSchedule {
    text-align: center;
  }
  select {
    text-align: center;
    width: 100%;
  }
  ul {
    text-align: center;
    list-style: none;
    padding: 1em 0;
    margin: 0;
  }
  li {
    margin: 0;
    font-size: 0.8em;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
    > span {
      display: inline-block;
      padding: 0.2em;
      vertical-align: middle;
    }
    strong {
      display: inline-block;
      min-width: 54px;
      text-align: center;
    }
    &:hover {
      background: #eee;
    }
  }
  button.remove_timespan {
    padding: 0.1em;
  }
  li:hover button.remove_timespan {
    color: #dc3545;
    &:hover {
      background: #dc3545;
      color: #fff;
    }
  }
}
</style>
